import {Orientation, Tab, Tabs} from '@mui/material';
import DashboardLayout from 'layout/DashboardLayout';
import DashboardNavbar from 'layout/navbar/DashboardNavbar';
import Footer from 'layout/footer/Footer';
import AppBar from '@mui/material/AppBar';
import {EditNotificationsOutlined} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';
import {useEffect, useState} from 'react';
import EditNotificationSettingsTab from 'pages/me/settings/EditNotificationSettingsTab';
import Grid from '@mui/material/Grid';
import PaymentSettingsTab from 'pages/me/settings/payment/PaymentSettingsTab';
import {MdPayment} from 'react-icons/md';
import Card from '@mui/material/Card';
import TabOrientation from 'components/TabOrientation/TabOrientation';
import {useRemoteConfig} from 'components/Firebase/FirebaseConfigProvider';

type SettingsPageProps = {
  tab: 'general' | 'notifications' | 'payment';
};

const SettingsPage = (props: SettingsPageProps) => {
  const navigate = useNavigate();
  const {feature_payment} = useRemoteConfig();
  const [tabsOrientation, setTabsOrientation] = useState<Orientation>('horizontal');
  const [tabValue, setTabValue] = useState(props.tab);

  useEffect(() => {
    setTabValue(props.tab);
  }, [props.tab]);

  const handleSetTabValue = (event, newValue) => {
    let url = '/me/settings';
    switch (newValue) {
      case 'notifications':
        url += '/notifications';
        break;
      case 'payment':
        url += '/payment';
        break;
    }
    navigate(url);
    setTabValue(newValue);
  };

  const renderTabContent = () => {
    if (tabValue === 'notifications') {
      return <EditNotificationSettingsTab />;
    }
    if (tabValue === 'payment') {
      return <PaymentSettingsTab />;
    }
  };

  const renderContent = () => {
    return (
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Grid container item xs={12}>
            {renderTabContent()}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <DashboardLayout>
      <TabOrientation changeOrientation={setTabsOrientation} />
      <DashboardNavbar />
      <Grid container item xs={12} rowSpacing={3} pr={{xl: 3}} py={3}>
        <Grid item xs={12} container>
          <Card sx={{width: '100%'}}>
            <Grid item xs={12} p={3}>
              <AppBar position="static">
                <Tabs
                  orientation={tabsOrientation}
                  value={tabValue}
                  onChange={handleSetTabValue}
                  sx={{background: 'transparent'}}>
                  <Tab value="notifications" label="Notifications" icon={<EditNotificationsOutlined />} />
                  {feature_payment?.asBoolean() && <Tab value="payment" label="Payment" icon={<MdPayment />} />}
                </Tabs>
              </AppBar>
            </Grid>
            <Grid item xs={12}>
              {renderContent()}
            </Grid>
          </Card>
        </Grid>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
};

export default SettingsPage;
