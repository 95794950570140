import {Card, Grid} from '@mui/material';
import {Link} from 'react-router-dom';
import DashboardLayout from 'layout/DashboardLayout';
import DashboardNavbar from 'layout/navbar/DashboardNavbar';
import Footer from 'layout/footer/Footer';
import {useEffect} from 'react';
import {useSetRecoilState} from 'recoil';
import {coachSubscriptionState, loadCoachSubscription} from 'components/Paywall/coachSubscription';
import SuiBox from 'components/SuiBox/index';
import * as React from 'react';
import SuiButton from 'components/SuiButton/index';
import SuiTypography from 'components/SuiTypography/index';
import {GiPartyPopper} from 'react-icons/gi';

const SubscribeSuccessPage = () => {
  const setSubscription = useSetRecoilState(coachSubscriptionState);

  useEffect(() => {
    const load = async () => {
      const coachSubscription = await loadCoachSubscription();
      setSubscription(coachSubscription);
    };
    load();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container alignItems="flex-start">
              <Grid container item xs={12} rowSpacing={3} pr={{xl: 3}} py={3}>
                <Card sx={{width: '100%', py: 3}}>
                  <SuiBox
                    display="flex"
                    sx={{justifyContent: 'center', alignItems: 'center', flexDirection: 'column', mt: 3}}>
                    <GiPartyPopper fontSize={130} color={'#e9ecef'} />
                    <SuiTypography variant="h6" fontWeight="medium" sx={{mt: 2}} color={'secondary'}>
                      🎉 Successfully subscribed! 🎉
                    </SuiTypography>
                    <SuiBox mt={3} mb={2}>
                      <SuiButton component={Link} to={'/me/subscriptions'} color="primary" size="small">
                        Back to Subscriptions
                      </SuiButton>
                    </SuiBox>
                  </SuiBox>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
};

export default SubscribeSuccessPage;
