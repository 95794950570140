import {useEffect, useState} from 'react';
import axios from 'axios';
import {Plan} from 'pages/coaching/plans/PlanTypes';
import PlanStatisticsChart from 'pages/coaching/plans/statistics/chart/PlanStatisticsChart';

type ChartDataset = {
  label: string;
  backgroundColors: string[];
  data: number[];
};

type ChartData = {
  labels?: string[];
  images?: string[];
  datasets?: ChartDataset;
};

const PlanStatisticsCard = () => {
  const [data, setData] = useState<ChartData>({
    labels: [],
    images: [],
    datasets: {
      label: '',
      backgroundColors: [],
      data: [],
    },
  } as ChartData);

  useEffect(() => {
    axios
      .get(`/api/v0/plans?page=0&size=8&sort=name,asc&sort=availability.subscribed,desc&status=PROMOTED`)
      .then(response => response.data)
      .then(p => setData(createChartData(p?.content || [])))
      .catch(error => Promise.reject(error));
  }, []);

  const createChartData = (plans: Plan[]): ChartData => {
    const plansWithSubscriptions = plans.filter(plan => plan.availability.subscribed > 0);
    const totalSubscribed = plansWithSubscriptions.reduce((acc, plan) => acc + plan.availability.subscribed, 0);
    const names = plansWithSubscriptions.map(plan => plan.name);
    const images = plansWithSubscriptions.map(plan => (plan.icon != null ? plan.icon.location : null));
    const percentages = plansWithSubscriptions.map(plan => (plan.availability.subscribed / totalSubscribed) * 100);
    const roundedPercentagesWithOneDecimal = percentages.map(percentage => Math.round(percentage * 10) / 10);
    return {
      labels: names,
      images: images,
      datasets: {
        label: 'Plan Distribution',
        backgroundColors: ['primary', 'success', 'info', 'secondary', 'warning', 'error', 'dark', 'light'],
        data: roundedPercentagesWithOneDecimal,
      },
    };
  };

  return (
    <>
      <PlanStatisticsChart
        title="Client Distribution by Plan"
        chart={data}
        action={{
          type: 'internal',
          route: '/coaching/plans',
          color: 'secondary',
          label: 'View Plans',
        }}
      />
    </>
  );
};

export default PlanStatisticsCard;
