import React, {useState} from 'react';

// prop-types is a library for typechecking of props
// @mui material components
import Icon from '@mui/material/Icon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

// Soft UI Dasboard PRO Material components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiAvatar from 'components/SuiAvatar';

// Soft UI Dasboard PRO Material base styles
import borders from 'assets/theme/base/borders';
import colors from 'assets/theme/base/colors';
import {UserNotification} from 'pages/me/notifications/NotificationsList';
import {Divider} from '@mui/material';
import {AdminPanelSettingsOutlined, Notifications} from '@mui/icons-material';
import {useConfirm} from 'components/ConfirmDialog';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';
import {MdOutlineTaskAlt} from 'react-icons/md';

export type NotificationItemProps = {
  notification: UserNotification;
  noDivider?: boolean;
  onToggleNotificationRead: (notification: UserNotification) => Promise<void>;
  onDeleteNotification: (notification: UserNotification) => Promise<void>;
};

const NotificationItem = (props: NotificationItemProps) => {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const {borderWidth} = borders;
  const [openMenu, setOpenMenu] = useState(null);

  const handleOpenMenu = event => {
    event.stopPropagation();
    setOpenMenu(event.currentTarget);
  };
  const handleCloseMenu = () => setOpenMenu(null);

  const deleteNotification = async (notification: UserNotification) => {
    try {
      await confirm({
        title: 'Danger Zone: Delete Notification',
        content: 'Are you sure you want to delete this notification? There will be no way back!',
        confirmationText: 'Delete',
      });
      await props.onDeleteNotification(notification);
    } catch (error) {
      // do nothing
    }
  };

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{vertical: 'top', horizontal: 'left'}}
      transformOrigin={{vertical: 'top', horizontal: 'right'}}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      keepMounted>
      <MenuItem
        onClick={async e => {
          e.stopPropagation();
          handleCloseMenu();
          await props.onToggleNotificationRead(props.notification);
        }}>
        Mark as {props.notification.read ? 'unread' : 'read'}
      </MenuItem>
      <MenuItem
        onClick={async e => {
          e.stopPropagation();
          handleCloseMenu();
          await deleteNotification(props.notification);
        }}>
        Delete
      </MenuItem>
    </Menu>
  );

  const determineIcon = () => {
    if (props.notification.metadata.iconLocation !== '') {
      return <SuiAvatar variant="circle" size="sm" src={props.notification.metadata.iconLocation} alt="Avatar" />;
    }
    switch (props.notification.type.type) {
      case 'system':
        return <AdminPanelSettingsOutlined fontSize="large" />;
      case 'new-task':
        return <MdOutlineTaskAlt fontSize="30" />;
      default:
        return <Notifications fontSize="large" />;
    }
  };

  const determineColor = () => {
    if (props.notification.metadata.color !== null && props.notification.metadata.color !== undefined) {
      return colors[props.notification.metadata.color].main;
    }
    return colors['secondary'].main;
  };

  const determineCursor = () => {
    if (props.notification.metadata.action !== null && props.notification.metadata.action !== '') {
      return 'pointer';
    }
    return 'default';
  };

  const onMessageClick = e => {
    e.stopPropagation();
    if (props.notification.metadata.action !== null && props.notification.metadata.action !== '') {
      props.onToggleNotificationRead(props.notification);
      navigate(props.notification.metadata.action);
    }
  };

  return (
    <>
      <SuiBox
        component="li"
        width="100%"
        pr={2}
        mb={2}
        borderLeft={`${borderWidth[3]} solid ${determineColor()}`}
        sx={{listStyle: 'none', cursor: determineCursor()}}
        onClick={onMessageClick}>
        <SuiBox width="100%" pl={1} ml={2}>
          <SuiBox display="flex" alignItems="center">
            {determineIcon()}
            <SuiBox ml={1} lineHeight={1}>
              <SuiTypography variant="subtitle2" fontWeight={props.notification.read ? 'light' : 'medium'}>
                {props.notification.title}
              </SuiTypography>
            </SuiBox>
            <SuiBox ml="auto" color="secondary" pr={3} lineHeight={0}>
              <Icon sx={{cursor: 'pointer'}} onClick={handleOpenMenu}>
                more_horiz
              </Icon>
            </SuiBox>
            {renderMenu()}
          </SuiBox>
          <SuiBox
            display="flex"
            alignItems={{xs: 'flex-start', sm: 'center'}}
            flexDirection={{xs: 'column', sm: 'row'}}
            ml={5}
            pl={1}>
            <SuiBox lineHeight={1} mb={{xs: 1, sm: 0}} width="90%">
              <SuiTypography
                display="block"
                variant="body2"
                fontWeight={props.notification.read ? 'light' : 'medium'}
                color="secondary">
                {props.notification.content}
              </SuiTypography>
            </SuiBox>
            <SuiBox lineHeight={1} mb={{xs: 1, sm: 0}} ml={3}>
              <SuiTypography display="block" variant="caption" fontWeight="regular" color="secondary">
                {moment(props.notification.auditMetadata.created).format('MM-DD-YYYY kk:mm')}
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        </SuiBox>
        {props.noDivider ? null : <Divider sx={{marginBottom: 0}} />}
      </SuiBox>
    </>
  );
};

export default NotificationItem;
