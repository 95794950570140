import {atom} from 'recoil';
import {TaskStatus} from 'pages/coaching/tasks/TaskTypes';

type TaskListState = {
  status: TaskStatus;
  page: number;
};

export const taskListState = atom<Map<string, TaskListState>>({
  key: 'TaskList',
  default: new Map(),
});
