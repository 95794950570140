import {useEffect, useState} from 'react';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import DashboardLayout from 'layout/DashboardLayout';
import DashboardNavbar from 'layout/navbar/DashboardNavbar';
import Footer from 'layout/footer/Footer';
import Grid from '@mui/material/Grid';
import {Task} from 'pages/coaching/tasks/TaskTypes';
import SuiAlert from 'components/SuiAlert';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import InitialQuestionnaireTask from 'pages/coaching/tasks/type-initial-questionnaire/InitialQuestionnaireTask';
import moment from 'moment';
import {WarningAmberRounded} from '@mui/icons-material';
import PrepareIntervalTask from 'pages/coaching/tasks/type-prepare-interval/PrepareIntervalTask';
import {useSetRecoilState} from 'recoil';
import {breadCrumbSegmentsState} from 'state/breadcrumb';
import SubscriptionOnboardingTask from './type-subscription-onboarding/SubscriptionOnboardingTask';
import ProductTourTask from 'pages/coaching/tasks/type-product-tour/ProductTourTask';

const TaskPage = () => {
  const {taskId} = useParams();
  const [task, setTask] = useState<Task>();
  const setTaskSegment = useSetRecoilState(breadCrumbSegmentsState(taskId));

  useEffect(() => {
    axios
      .get(`/api/v0/tasks/${taskId}`)
      .then(response => response.data)
      .then(t => {
        setTaskSegment(t.title);
        setTask(t);
      })
      .catch(error => Promise.reject(error));
  }, []);

  const renderContent = () => {
    if (task) {
      return renderTypedTask();
    }
    return renderLoadingIndicator();
  };

  // TODO: improve decoupling e.g. a factory
  const renderTypedTask = () => {
    switch (task.type) {
      case 'subscription-onboarding':
        return <SubscriptionOnboardingTask task={task} />;
      case 'initial-questionnaire':
        return <InitialQuestionnaireTask task={task} />;
      case 'prepare-interval':
        return <PrepareIntervalTask task={task} />;
      case 'product-tour':
        return <ProductTourTask task={task} />;
      default:
        return <p>Could not render task. Type `{task.type}` is not supported.</p>;
    }
  };

  const renderLoadingIndicator = () => {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{minHeight: '50vh'}}
        item
        xs={12}
        pb={3}>
        <CircularProgress />
      </Grid>
    );
  };

  const renderTaskStatus = () => {
    if (task && task.status === 'DONE') {
      return (
        <SuiAlert color="info" fontSize="default">
          <WarningAmberRounded fontSize={'small'} sx={{mr: 0.5}} />
          {`This is task has been marked done at ${moment(task.lastModified)
            .local()
            .format('MM-DD-YYYY, HH:mm')} and can\'t be modified.`}
        </SuiAlert>
      );
    }
    return null;
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        {renderTaskStatus()}
        <Grid container py={task && task.status === 'DONE' ? 1 : 3}>
          {renderContent()}
        </Grid>
        <Footer />
      </DashboardLayout>
    </>
  );
};

export default TaskPage;
