import {EditPlanProps} from 'pages/coaching/plans/edit/EditPlan.types';
import * as React from 'react';
import {useState} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AppBar from '@mui/material/AppBar';
import {Grid, Orientation} from '@mui/material';
import EditInitialQuestionnaireTemplateTab from 'pages/coaching/plans/edit/templates/EditInitialQuestionnaireTemplateTab';
import EditIntervalTemplateTab from 'pages/coaching/plans/edit/templates/EditIntervalTemplateTab';
import TabOrientation from 'components/TabOrientation/TabOrientation';
import {QuizOutlined} from '@mui/icons-material';
import {VscNotebookTemplate} from 'react-icons/vsc';

const EditTemplatesTab = (props: EditPlanProps) => {
  const [tabValue, setTabValue] = useState<'interval' | 'initial-questionnaire'>('initial-questionnaire');
  const [tabsOrientation, setTabsOrientation] = useState<Orientation>('horizontal');

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
  };

  const renderTabContent = () => {
    switch (tabValue) {
      case 'initial-questionnaire':
        return <EditInitialQuestionnaireTemplateTab plan={props.plan} onSubmit={props.onSubmit} />;
      case 'interval':
        return <EditIntervalTemplateTab plan={props.plan} onSubmit={props.onSubmit} />;
      default:
        return null;
    }
  };

  return (
    <>
      <TabOrientation changeOrientation={setTabsOrientation} />
      <Grid container item xs={12}>
        <Grid item xs={12} lg={12}>
          <AppBar position="static">
            <Tabs
              orientation={tabsOrientation}
              value={tabValue}
              onChange={handleSetTabValue}
              sx={{background: 'transparent', px: 3, pt: 3}}>
              <Tab
                label="Initial Questionnaire"
                value="initial-questionnaire"
                icon={<QuizOutlined fontSize={'small'} />}
              />
              <Tab label="Check-In" value="interval" icon={<VscNotebookTemplate fontSize={'medium'} />} />
            </Tabs>
          </AppBar>
        </Grid>
        <Grid item xs={12}>
          {renderTabContent()}
        </Grid>
      </Grid>
    </>
  );
};

export default EditTemplatesTab;
