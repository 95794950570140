import Grid from '@mui/material/Grid';
import * as React from 'react';
import SuiBox from 'components/SuiBox/index';
import {FaStripeS} from 'react-icons/fa';
import {Backdrop, CircularProgress, SpeedDial, SpeedDialAction, SpeedDialIcon} from '@mui/material';
import {VscDebugDisconnect} from 'react-icons/vsc';
import SuiTypography from 'components/SuiTypography/index';
import PaymentProviderInfo from 'pages/me/settings/payment/PaymentProviderInfo';
import PaymentProviderTable from 'pages/me/settings/payment/PaymentProviderTable';
import {useConfirm} from 'components/ConfirmDialog/index';
import ConnectStripeDialog from 'pages/me/settings/payment/ConnectStripeDialog';
import axios from 'axios';
import {useState} from 'react';

export type PaymentProviderStatus = 'CONNECTED' | 'DISCONNECTED' | 'PENDING' | 'INVALID';

export type StripeBusinessAddress = {
  city: string;
  country: string;
  line1: string;
  line2: string;
  postalCode: string;
  state: string;
};

export type StripeBusinessProfile = {
  mcc: string;
  name: string;
  productDescription: string;
  supportAddress: StripeBusinessAddress;
  supportEmail: string;
  supportPhone: string;
  supportUrl: string;
  url: string;
};

export type StripePaymentProviderDetails = {
  type: 'stripe';
  accountId: string;
  detailsSubmitted: boolean;
  businessProfile: StripeBusinessProfile;
};

export type PaymentProvider = {
  id: string;
  description: string;
  status: PaymentProviderStatus;
  details: StripePaymentProviderDetails;
  created: string;
  lastModified: string;
};

type ConnectStripeResponse = {
  accountLinkUrl: string;
  provider: PaymentProvider;
};

const PaymentSettingsTab = () => {
  const confirm = useConfirm();
  const [showProgress, setShowProgress] = useState(false);

  const connect = async () => {
    try {
      await confirm({
        title: 'Connect Stripe',
        content: <ConnectStripeDialog />,
        confirmationText: 'Confirm & Connect',
        confirmationButtonProps: {
          color: 'primary',
        },
      });
      await connectAndRedirect();
    } catch (error) {
      // do nothing
    }
  };
  const connectAndRedirect = async () => {
    setShowProgress(true);
    const response = await axios.post(`/api/v0/payment/stripe/connect`, {
      description: 'My Stripe Account',
      refreshPath: '/me/settings/payment',
      providerPrefixPath: '/me/settings/payment',
    });
    const accountLinkUrl = (response.data as ConnectStripeResponse).accountLinkUrl;
    window.location.replace(accountLinkUrl);
  };

  const reconnect = async (providerId: string) => {
    setShowProgress(true);
    const response = await axios.post(`/api/v0/payment/stripe/reconnect`, {
      providerId: providerId,
      refreshPath: '/me/settings/payment',
      providerPrefixPath: '/me/settings/payment',
    });
    const accountLinkUrl = (response.data as ConnectStripeResponse).accountLinkUrl;
    window.location.replace(accountLinkUrl);
  };

  return (
    <>
      <SuiBox display="flex" justifyContent="space-between" alignItems="center" px={2} sx={{width: '100%'}}>
        <SuiTypography variant="h6">Payment Providers</SuiTypography>
        <SpeedDial
          ariaLabel="Connect Payment Provider"
          sx={{mr: 2, alignSelf: 'flex-end'}}
          icon={<SpeedDialIcon icon={<VscDebugDisconnect color={'white'} fontSize={'1.6rem'} />} />}
          FabProps={{color: 'primary', size: 'medium'}}
          direction={'left'}>
          <SpeedDialAction
            key={'Connect Stripe'}
            icon={<FaStripeS fontSize={'18'} color={'#635BFF'} />}
            tooltipTitle={'Connect Stripe'}
            onClick={() => connect()}
          />
        </SpeedDial>
      </SuiBox>
      <SuiBox position="relative" px={2}>
        <PaymentProviderInfo />
      </SuiBox>
      <Grid container item py={3} xs={12}>
        <Backdrop sx={{color: '#fff', zIndex: theme => theme.zIndex.drawer + 1}} open={showProgress}>
          <CircularProgress color="primary" />
        </Backdrop>
        <PaymentProviderTable onConnect={connect} onReconnect={reconnect} />
      </Grid>
    </>
  );
};

export default PaymentSettingsTab;
