import axios from 'axios';
import {getFirebaseToken, subscribe} from 'components/Firebase/FirebaseMessaging';
import NotificationSnackbar from 'pages/notifictions/NotificationSnackbar';
import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {unreadNotificationState} from 'state/notification';
import {NotificationSettings} from 'pages/notifictions/NotificationTypes';
import {userSettingsState} from 'utils/settings/userSettingsState';
import {useSnackbar} from 'notistack';

export const updateUnreadNotifications = async (onCount: (count) => void) => {
  try {
    const response = await axios.get(`/api/v0/notifications/count/unread`);
    onCount(response.data?.count || 0);
  } catch (error) {
    console.warn('Could not get unread notifications', error);
  }
};

const NotificationOrchestrator = ({children}) => {
  const setUnreadNotifications = useSetRecoilState(unreadNotificationState);
  const userSettings = useRecoilValue(userSettingsState);
  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    const updateNotificationSettings = async token => {
      const settings: NotificationSettings = await userSettings.getSettings('notifications');
      settings.coachFirebaseTokens = settings.coachFirebaseTokens || [];
      if (settings.coachFirebaseTokens.indexOf(token) === -1) {
        settings.coachFirebaseTokens.push(token);
      }
      await userSettings.updateSettings(settings);
    };
    const issueToken = async () => {
      try {
        const token = await getFirebaseToken();
        if (token) {
          await updateNotificationSettings(token);
        }
      } catch (error) {
        console.warn('Could not get Firebase token', error);
      }
    };
    const schedulePeriodicCountUpdate = () => {
      const intervalId = setInterval(
        () => updateUnreadNotifications(count => setUnreadNotifications(() => count)),
        120_000,
      );
      return () => clearInterval(intervalId);
    };
    if (userSettings) {
      updateUnreadNotifications(count => setUnreadNotifications(count));
      issueToken();
      return schedulePeriodicCountUpdate();
    }
  }, [userSettings]);

  useEffect(() => {
    return subscribe(onNewMessage);
  }, []);

  const onNewMessage = payload => {
    if (payload.data?.user && payload.data?.type) {
      const notification = {
        id: payload.data.id,
        title: payload.notification.title,
        content: payload.notification.body,
        color: payload.data.color,
        iconLocation: payload.data.iconLocation,
        action: payload.data.action,
        created: payload.data.created,
        user: payload.data.user,
        referencedEntity: payload.data.referencedEntity,
        type: payload.data.type,
      };
      enqueueSnackbar(payload.notification.title, {
        key: payload.data.id,
        content: () => <NotificationSnackbar message={notification} onMessageClick={onMessageClick} />,
      });
      setUnreadNotifications(v => v + 1);
    }
  };

  const onMessageClick = () => {
    setUnreadNotifications(v => v - 1);
  };

  return <>{children}</>;
};

NotificationOrchestrator.defaultProps = {};

NotificationOrchestrator.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NotificationOrchestrator;
