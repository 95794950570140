import {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import GitHub from '@mui/icons-material/GitHub';
import Lan from '@mui/icons-material/Lan';
import LaptopChromebookIcon from '@mui/icons-material/LaptopChromebook';
import axios from 'axios';
import VersionInfoCard from './VersionInfoCard';

type GitCommit = {
  id: string;
};

type GitInfo = {
  commit: GitCommit;
};

type Info = {
  git?: GitInfo;
};

function VersionInfo() {
  const [info, setInfo] = useState<Info>({});

  useEffect(() => {
    axios
      .get('/actuator/info')
      .then(response => response.data)
      .catch(error => Promise.reject(error))
      .then(setInfo);
  }, [setInfo]);

  const getApiCommitLink = () => {
    if (info.git !== undefined) {
      return `https://github.com/inmycorner/inmycorner/commit/${info.git.commit.id}`;
    }
    return '';
  };

  const getUiCommitLink = () => {
    const commit = process.env.REACT_APP_UI_COMMIT;
    if (commit !== 'n/a') {
      return `https://github.com/inmycorner/inmycorner-web/commit/${commit}`;
    }
    return '';
  };

  return (
    <>
      <Grid item xs={12} md={12} lg={3} pr={{xs: 0, md: 0, lg: 3}}>
        <VersionInfoCard
          icon={<Lan />}
          color="success"
          title="API Url"
          link={process.env.REACT_APP_API_URL}
          linkText={process.env.REACT_APP_API_URL}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={3} pr={{xs: 0, md: 0, lg: 3}}>
        <VersionInfoCard
          icon={<GitHub />}
          color="info"
          title="API Commit"
          link={getApiCommitLink()}
          linkText={info.git !== undefined ? info.git.commit.id : 'n/a'}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={3} pr={{xs: 0, md: 0, lg: 3}}>
        <VersionInfoCard
          icon={<LaptopChromebookIcon />}
          color="warning"
          title="UI Version"
          link=""
          linkText={process.env.REACT_APP_UI_VERSION}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={3} pr={{xs: 0, md: 0, lg: 3}}>
        <VersionInfoCard
          icon={<GitHub />}
          color="error"
          title="UI Commit"
          link={getUiCommitLink()}
          linkText={process.env.REACT_APP_UI_COMMIT?.substring(0, 7)}
        />
      </Grid>
    </>
  );
}

export default VersionInfo;
