/**
 =========================================================
 * Soft UI Dashboard PRO React - v3.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useState} from 'react';

// prop-types is a library for typechecking of props
// @mui material components
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

// Soft UI Dasboard PRO Material components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

// Soft UI Dasboard PRO Material base styles
import borders from 'assets/theme/base/borders';
import colors from 'assets/theme/base/colors';
import SuiAvatar from 'components/SuiAvatar';
import {useLocation, useNavigate} from 'react-router-dom';
import {Task, UserDetails} from 'pages/coaching/tasks/TaskTypes';
import moment from 'moment';
import {Info} from '@mui/icons-material';

type TaskProps = {
  task: Task;
  noDivider: boolean;
};

function TaskListItem(props: TaskProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const {borderWidth} = borders;
  const [openMenu, setOpenMenu] = useState(null);

  const handleOpenMenu = event => {
    event.stopPropagation();
    setOpenMenu(event.currentTarget);
  };
  const handleCloseMenu = event => {
    event.stopPropagation();
    setOpenMenu(null);
  };

  const renderMenuContent = () => {
    return (
      <Menu
        anchorEl={openMenu}
        anchorOrigin={{vertical: 'top', horizontal: 'left'}}
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
        open={Boolean(openMenu)}
        onClose={handleCloseMenu}
        keepMounted>
        <MenuItem onClick={onMessageClick}>Open</MenuItem>
      </Menu>
    );
  };

  const renderMenu = () => {
    if (props.task.status === 'OPEN') {
      return (
        <>
          <SuiBox ml="auto" color="secondary" pr={3} lineHeight={0}>
            <Icon fontSize="medium" sx={{cursor: 'pointer'}} onClick={handleOpenMenu}>
              more_horiz
            </Icon>
          </SuiBox>
          {renderMenuContent()}
        </>
      );
    }
  };

  const determineIcon = () => {
    let clientDetails = props.task.attributes.find(a => a.key === 'client');
    if (clientDetails !== undefined) {
      return (
        <SuiAvatar variant="circle" size="sm" src={(clientDetails.value as UserDetails).picture} alt="Task Icon" />
      );
    }
    let icon = props.task.attributes.find(a => a.key === 'icon');
    if (icon !== undefined) {
      return <SuiAvatar variant="circle" size="sm" src={`/${icon.value}`} alt="Task Icon" />;
    }
    return <Info fontSize="medium" />;
  };

  const sameDay = (d1, d2) => {
    return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
  };

  const determineColor = () => {
    if (props.task.dueDate !== null) {
      const dueDate = new Date(props.task.dueDate);
      if (sameDay(dueDate, new Date())) {
        return colors['warning'].main;
      } else if (dueDate < new Date()) {
        return colors['error'].main;
      }
      return colors['success'].main;
    }
    return colors['primary'].main;
  };

  const renderDueDate = () => {
    if (props.task.dueDate !== null && props.task.status === 'OPEN') {
      return (
        <SuiBox ml={{xs: 'auto'}} mb={{xs: 1, sm: 0}} lineHeight={1}>
          <SuiTypography display="block" variant="caption" fontWeight="medium" color="secondary">
            Due
          </SuiTypography>
          <SuiTypography variant="caption" fontWeight="bold" color="text">
            {moment(props.task.dueDate).local().format('MM-DD-YYYY, h:mma')}
          </SuiTypography>
        </SuiBox>
      );
    }
  };

  const renderDoneDate = () => {
    if (props.task.status === 'DONE') {
      return (
        <SuiBox ml={{xs: 'auto'}} mb={{xs: 1, sm: 0}} lineHeight={1}>
          <SuiTypography display="block" variant="caption" fontWeight="medium" color="secondary">
            Done
          </SuiTypography>
          <SuiTypography variant="caption" fontWeight="bold" color="text">
            {moment(props.task.lastModified).local().format('MM-DD-YYYY, h:mma')}
          </SuiTypography>
        </SuiBox>
      );
    }
  };

  const onMessageClick = e => {
    e.stopPropagation();
    navigate(`/coaching/tasks/${props.task.id}`, {state: location.pathname});
  };

  return (
    <>
      <SuiBox
        component="li"
        width="100%"
        pr={2}
        mb={2}
        borderLeft={`${borderWidth[3]} solid ${determineColor()}`}
        sx={{listStyle: 'none', cursor: 'pointer'}}
        onClick={onMessageClick}>
        <SuiBox width="100%" pl={1} ml={2}>
          <SuiBox display="flex" alignItems="center">
            {determineIcon()}
            <SuiBox ml={1} lineHeight={1}>
              <SuiTypography variant="subtitle2" fontWeight="medium">
                {props.task.title}
              </SuiTypography>
            </SuiBox>
            {renderMenu()}
          </SuiBox>
          <SuiBox display="flex" alignItems="center" ml={5} pl={1} mr={10}>
            <SuiTypography display="block" variant="body2" fontWeight="light" color="secondary">
              {props.task.description}
            </SuiTypography>
          </SuiBox>
          <SuiBox display="flex" alignItems="flex-end" flexDirection="row" ml={3} pl={0.5}>
            {renderDueDate()}
            {renderDoneDate()}
            <SuiBox
              ml={() => (props.task.dueDate !== null || props.task.status === 'DONE' ? 6 : 'auto')}
              mb={{xs: 1, sm: 0}}
              lineHeight={1}>
              <SuiTypography display="block" variant="caption" fontWeight="medium" color="secondary">
                Created
              </SuiTypography>
              <SuiTypography variant="caption" fontWeight="bold" color="text">
                {moment(props.task.created).local().format('MM-DD-YYYY, h:mma')}
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        </SuiBox>
        {props.noDivider ? null : <Divider sx={{marginBottom: 0}} />}
      </SuiBox>
    </>
  );
}

export default TaskListItem;
