import {Task, UserDetails} from 'pages/coaching/tasks/TaskTypes';
import {Grid} from '@mui/material';
import QuestionnaireForm from 'components/Questionnaire/QuestionnaireForm';
import ClientCard from 'components/ClientCard/ClientCard';
import axios from 'axios';
import {useConfirm} from 'components/ConfirmDialog/index';
import {useLocation, useNavigate} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {Questionnaire} from 'components/Questionnaire/Questionnaire';

type InitialQuestionnaireTaskProps = {
  task: Task;
};

const InitialQuestionnaireTask = (props: InitialQuestionnaireTaskProps) => {
  const confirm = useConfirm();
  const location = useLocation();
  const navigate = useNavigate();

  const [questionnaire, setQuestionnaire] = useState<Questionnaire>({
    id: '',
    description: '',
    questions: [],
    status: 'PUBLISHED',
  });

  useEffect(() => {
    axios
      .get(`/api/v0/questionnaires/${props.task.referencedEntity}`)
      .then(response => response.data)
      .then(setQuestionnaire)
      .catch(error => Promise.reject(error));
  }, [props.task.referencedEntity]);

  const getClientId = () => {
    const clientAttribute = props.task.attributes.find(a => a.key === 'client');
    return (clientAttribute.value as UserDetails).user;
  };

  const getSubscriptionId = () => {
    const clientAttribute = props.task.attributes.find(a => a.key === 'subscription');
    return clientAttribute.value as string;
  };

  const submitQuestionnaire = async (values, actions) => {
    try {
      await confirm({
        title: 'Publishing Questionnaire',
        content:
          'Publishing the questionnaire will make it available to your client. After publishing, ' +
          'you will not be able to edit the questionnaire. Are you sure you want to continue?',
        confirmationText: 'Publish',
        confirmationButtonProps: {
          color: 'primary',
        },
        cancellationButtonProps: {
          color: 'light',
        },
      });
      await publishQuestionnaire(values, actions);
    } catch (error) {
      actions.setSubmitting(false);
    }
  };

  const publishQuestionnaire = async (values, actions) => {
    values.status = 'PUBLISHED';
    try {
      await axios.patch(`/api/v0/questionnaires/${props.task.referencedEntity}`, values);
      if (location.state != null) {
        navigate(location.state);
      } else {
        navigate('/coaching/tasks');
      }
    } catch (error) {
      actions.setStatus(error?.response?.data?.message);
    }
    actions.setSubmitting(false);
  };

  return (
    <>
      <Grid item xs={12} xl={8} pr={{xl: 3}}>
        <QuestionnaireForm
          title={'Initial Questionnaire'}
          description={
            'Below you can submit the initial questionnaire for your client. Please check for correctness before publishing. ' +
            "Once published, the questionnaire will be available to the client and can't be edited anymore."
          }
          questionnaire={questionnaire}
          onSubmit={submitQuestionnaire}
        />
      </Grid>
      <Grid item xs={12} xl={4}>
        <ClientCard clientId={getClientId()} subscriptionId={getSubscriptionId()} layout={'vertical'} />
      </Grid>
    </>
  );
};

export default InitialQuestionnaireTask;
