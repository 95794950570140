import React, {useEffect, useState} from 'react';
import {useRecoilValue} from 'recoil';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiButton from 'components/SuiButton';
import SafeHtml from 'components/SafeHtml/SafeHtml';

import {accessTokenState} from 'state/authentication';
import {CircularProgress, ThemeProvider} from '@mui/material';

import CssBaseline from '@mui/material/CssBaseline';
import theme from 'assets/theme';
import axios from 'axios';

const Terms = props => {
  const accessToken = useRecoilValue(accessTokenState);
  const [userTerms, setUserTerms] = useState(null);
  const [terms, setTerms] = useState(null);
  const {children} = props;

  const acceptsTerms = async () => {
    const body = {
      acceptedTerms: {
        ...userTerms.acceptedTerms,
      },
    };
    body.acceptedTerms[userTerms.termsToAccept] = new Date().toISOString();
    const response = await axios.put(`/api/v0/me/terms`, body);
    setUserTerms(response.data);
  };

  useEffect(() => {
    const fetchUserTerms = async () => {
      const response = await axios.get(`/api/v0/me/terms`);
      const terms = response.data;
      setUserTerms(() => terms);
      if (terms.termsToAccept != null) {
        const toAcceptResponse = await axios.get(`/api/v0/terms/${terms.termsToAccept}`);
        setTerms(toAcceptResponse.data);
      }
    };
    if (accessToken != null) {
      fetchUserTerms();
    }
  }, [accessToken]);

  const termsContent = () => {
    if (terms != null) {
      return terms.content;
    }
    return '';
  };

  const termsTitle = () => {
    if (terms != null) {
      return terms.title;
    }
    return '';
  };

  const renderTermsToAccept = () => (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Grid container spacing={3} justifyContent="center" sx={{minHeight: '75vh'}}>
        <Grid item xs={12} sm={10} md={6}>
          <SuiBox mt={32} mb={3} px={{xs: 0, lg: 2}}>
            <Card>
              <SuiBox pt={3} px={3} pb={1}>
                <SuiBox textAlign="center" p={0}>
                  <SuiTypography variant="h4" fontWeight="bold" textGradient textAlign="center">
                    Please Accept the Terms and Agreements
                  </SuiTypography>
                </SuiBox>
                <br />
                <br />
                <SuiTypography variant="h5" fontWeight="bold" textGradient>
                  {termsTitle()}
                </SuiTypography>
                <SafeHtml html={termsContent()} variant="body2" color="text" px={3} />
              </SuiBox>
              <SuiBox p={3}>
                <Grid container item xs={12} justifyContent="center">
                  <Grid item xs={3}>
                    <SuiButton size="small" variant="outlined" color="dark" fullWidth xs={6} onClick={acceptsTerms}>
                      accept
                    </SuiButton>
                  </Grid>
                </Grid>
              </SuiBox>
            </Card>
          </SuiBox>
        </Grid>
      </Grid>
    </ThemeProvider>
  );

  const renderLoadingScreen = () => (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{minHeight: '100vh'}}>
      <Grid item xs={3}>
        <CircularProgress color="primary" />
      </Grid>
    </Grid>
  );

  const renderContent = () => {
    if (userTerms != null) {
      if (userTerms.termsToAccept != null) {
        return renderTermsToAccept();
      }
      return children;
    }
    return renderLoadingScreen();
  };

  return renderContent();
};

// Setting default values for the props of TimelineItem
Terms.defaultProps = {};

// Typechecking props for the TimelineItem
Terms.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Terms;
