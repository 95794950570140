import * as React from 'react';
import SuiTypography from 'components/SuiTypography/index';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import SuiBox from 'components/SuiBox/index';
import {FaMoneyBill, FaStripeS} from 'react-icons/fa';
import {useTheme} from '@mui/material';
import {MdOutlineSupportAgent} from 'react-icons/md';
import {RiExchangeDollarLine} from 'react-icons/ri';

const PlanPaymentInfo = () => {
  const {palette} = useTheme();

  const renderTitle = (title: string, icon: React.ReactNode) => {
    return (
      <SuiBox position="relative" display={'flex'} flexDirection={'columns'} sx={{alignItems: 'flex-start'}}>
        <SuiBox>{icon}</SuiBox>
        <SuiTypography variant="button" fontWeight="medium">
          {title}
        </SuiTypography>
      </SuiBox>
    );
  };

  const renderCard = (title: string, icon: React.ReactNode, description: string) => {
    return (
      <Card sx={{p: 3, height: '100%'}}>
        {renderTitle(title, icon)}
        <SuiTypography variant="button" color="text" fontWeight="regular">
          {description}
        </SuiTypography>
      </Card>
    );
  };

  return (
    <>
      <Grid container p={3} spacing={2}>
        <Grid item xs={12} md={6} xl={3}>
          {renderCard(
            'A Plan is a Stripe Product',
            <FaStripeS fontSize={26} style={{paddingRight: 10}} color={'#635BFF'} />,
            `For every plan you connect we create a stripe product in your account. 
              You can configure this product with different pricing options, coupons, currencies and more. Please 
              ensure to never delete this product from your stripe account. If you do so users will not be able to 
              subscribe to your plan anymore.`,
          )}
        </Grid>

        <Grid item xs={12} md={6} xl={3}>
          {renderCard(
            'Conflict Resolution',
            <MdOutlineSupportAgent
              fontSize={37}
              style={{paddingRight: 10, paddingBottom: 9}}
              color={palette.primary.main}
            />,
            `In case of a conflict between you and your client, you are responsible for resolving the issue. 
            The same is true for any refunds or chargebacks. If you have trouble resolving the issue, please contact 
            our support team for assistance.`,
          )}
        </Grid>

        <Grid item xs={12} md={6} xl={3}>
          {renderCard(
            'Tax Responsibilities',
            <FaMoneyBill fontSize={35} style={{paddingRight: 10, paddingBottom: 13}} color={'#85BB65'} />,
            `While our platform ensures accurate client charges, it's important to note that you are responsible for any
          applicable taxes related to your business. This includes any sales tax, VAT, or other taxes specific to your
          location or industry. Please consult with a tax professional to understand your tax obligations and ensure
          compliance.`,
          )}
        </Grid>

        <Grid item xs={12} md={6} xl={3}>
          {renderCard(
            'Changing the Provider',
            <RiExchangeDollarLine fontSize={35} style={{paddingRight: 10, paddingBottom: 9}} color={'#25377f'} />,
            `Changing the provider here will not affect existing subscriptions. In order to move your clients to a new provider you will need to cancel their stripe subscription and ask them to subscribe again.`,
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default PlanPaymentInfo;
