import dompurify from 'dompurify';
import SuiTypography from 'components/SuiTypography/index';
import React from 'react';

export type SafeHtmlProps = {
  html: string;
  [key: string]: any;
};

const SafeHtml = (props: SafeHtmlProps) => {
  return (
    <>
      <SuiTypography {...props} dangerouslySetInnerHTML={{__html: dompurify.sanitize(props.html)}} />
    </>
  );
};

export default SafeHtml;
