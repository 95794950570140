import {useEffect, useState} from 'react'; // formik components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card'; // Soft UI Dashboard PRO React components
import * as Yup from 'yup';
import DataForm, {DataFormField} from 'components/DataForm';
import {HelpOutline} from '@mui/icons-material';
import SuiBox from 'components/SuiBox/index';
import {Tooltip} from '@mui/material';
import SuiTypography from 'components/SuiTypography/index';
import {Question, Questionnaire, QuestionType} from 'components/Questionnaire/Questionnaire';
import {FormikHelpers} from 'formik/dist/types';
import {QuestionSupportFactory} from 'components/Questionnaire/QuestionSupportFactory';
import axios from 'axios';
import QuestionTypeAutoComplete from 'components/Questionnaire/QuestionTypeAutoComplete';
import {AiOutlineDelete} from 'react-icons/ai';
import {ErrorMessage} from 'formik';

type QuestionnaireFormProps = {
  title: string;
  description: string;
  submitLabel?: string;
  questionDescription?: string;
  questionnaire?: Questionnaire;
  onSubmit: (
    values: Questionnaire,
    formikHelpers: FormikHelpers<any>,
    updateQuestionnaire: (Questionnaire) => void,
  ) => void | Promise<any>;
};

const QuestionnaireForm = (props: QuestionnaireFormProps) => {
  const supportFactory = new QuestionSupportFactory();
  const [questionnaire, setQuestionnaire] = useState<Questionnaire>({
    id: '',
    description: '',
    questions: [],
    status: 'DRAFT',
  });
  const [questionTypes, setQuestionTypes] = useState<QuestionType[]>([]);
  const [fields, setFields] = useState<DataFormField[]>([
    {
      name: 'description',
      label: 'Description',
      type: 'editor',
      placeholder: 'eg. tell me about you',
    },
    // {
    //   name: 'questionsDeliveryTime',
    //   label: 'Preferred time to deliver questions',
    //   type: 'select',
    //   options: [
    //     {label: 'In the Morning', value: 'MORNING'},
    //     {label: 'In the Afternoon', value: 'AFTERNOON'},
    //     {label: 'In the Evening', value: 'EVENING'},
    //   ],
    //   placeholder: '',
    //   hint: 'The time of the day the client should receive the questions',
    //   columns: 6,
    // },
    {
      type: 'description',
      label: props.questionDescription
        ? props.questionDescription
        : "Add your questions below. Questions can't be edited once the questionnaire is published.",
      data: 'add-question-container',
    },
  ]);

  const validation = Yup.object().shape({
    description: Yup.string()
      .transform(v => (v === null ? '' : v))
      .required('Description is required.')
      .test('len', 'Description is required.', val => {
        return val !== undefined && val.length > 0 && val.replace(/(<([^>]+)>)/gi, '').length > 0;
      }),
    // questionsDeliveryTime: Yup.string().ensure().required('Delivery time is required.'),
    questions: Yup.array()
      .ensure()
      .min(1, 'At least one question is required.')
      .of(
        //@ts-ignore
        Yup.lazy(value => {
          return supportFactory
            .supportFor(value.type)
            .createValidationSchema()
            .concat(
              Yup.object().shape({
                content: Yup.string().required('Content is required'),
              }),
            );
        }),
      ),
  });

  useEffect(() => {
    setQuestionnaire(props.questionnaire);
    axios
      .get(`/api/v0/questionnaires/question-types`)
      .then(response => response.data)
      .then(setQuestionTypes)
      .catch(error => Promise.reject(error));
  }, [props.questionnaire]);

  useEffect(() => {
    setFields(fieldsWithQuestions(questionnaire));
  }, [questionnaire, questionTypes]);

  const fieldsWithQuestions = (q: Questionnaire) => {
    const newFields = [...fields].filter(f => !f.name || !f.name.startsWith('questions['));
    updateAddQuestionComponent(newFields);
    if (questionTypes.length > 0) {
      q.questions.forEach((question, index) => {
        newFields.push(...createCommonQuestionFields(question, index));
        newFields.push(...createTypeSpecificQuestionFields(question, index));
      });
    }
    return newFields;
  };

  const updateAddQuestionComponent = (newFields: DataFormField[]) => {
    newFields.find(f => f.data === 'add-question-container').bottomComponent = context =>
      createAddQuestionComponent(context);
  };

  const createAddQuestionComponent = formikContext => {
    return (
      <>
        <SuiTypography component="div" variant="caption" color="error">
          <ErrorMessage name="questions">{error => (typeof error === 'string' ? error : undefined)}</ErrorMessage>
        </SuiTypography>
        <QuestionTypeAutoComplete
          questionTypes={questionTypes}
          readOnly={questionnaire.status !== 'DRAFT'}
          onQuestionTypeSelected={t => addQuestion(t, formikContext)}
        />
      </>
    );
  };

  const createCommonQuestionFields = (question: Question, index: number): DataFormField[] => {
    const questionType = questionTypes.find(d => d.type === question.type);
    return [
      {
        name: `questions[${index}].divider`,
        type: 'divider',
      },
      {
        name: `questions[${index}].section`,
        type: 'section',
        label: `Question #${index + 1}: ${questionType.summary}`,
        rightComponent: context => createQuestionControls(question.id, context),
      },
      {
        name: `questions[${index}].description`,
        type: 'description',
        label: questionType.description,
      },
      {
        name: `questions[${index}].type`,
        type: 'hidden',
        value: question.type,
        data: question.id,
      },
      {
        name: `questions[${index}].id`,
        type: 'hidden',
        value: question.id,
        data: question.id,
      },
      {
        name: `questions[${index}].content`,
        label: 'Question',
        type: 'text',
        placeholder: '',
        icon: <HelpOutline />,
        data: question.id,
      },
    ];
  };

  const createTypeSpecificQuestionFields = (question: Question, index: number): DataFormField[] => {
    return supportFactory.supportFor(question.type).createFields(question, index);
  };

  const createQuestionControls = (questionId, formikContext) => {
    return (
      <SuiBox ml={1} sx={{display: questionnaire.status === 'DRAFT' ? 'flex' : 'none', alignItems: 'center', mt: 1}}>
        <Tooltip title="Delete Goal">
          <span>
            <AiOutlineDelete
              style={{color: '#8392ab', cursor: 'pointer'}}
              onClick={() => removeQuestion(questionId, formikContext)}
            />
          </span>
        </Tooltip>
      </SuiBox>
    );
  };

  const addQuestion = (questionType: QuestionType, formikContext) => {
    const {values} = formikContext;
    const newQuestionnaire = structuredClone(values);
    const question = supportFactory.supportFor(questionType.type).createQuestion();
    newQuestionnaire.questions.push(question);
    setQuestionnaire(newQuestionnaire);
  };

  const removeQuestion = (questionId, formikContext) => {
    const {values} = formikContext;
    const indexOfQuestion = values.questions.findIndex(g => g.id === questionId);
    const newQuestionnaire = structuredClone(values);
    newQuestionnaire.questions.splice(indexOfQuestion, 1);
    setQuestionnaire(newQuestionnaire);
  };

  const submitForm = async (values, actions) => {
    await props.onSubmit(values, actions, setQuestionnaire);
  };

  return (
    <>
      <Card>
        <Grid container>
          <Grid item xs={12} pt={2} px={2}>
            <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              {props.title}
            </SuiTypography>
          </Grid>
          <Grid item xs={12} px={2}>
            <SuiTypography variant="button" color="text" fontWeight="regular">
              {props.description}
            </SuiTypography>
          </Grid>
          <Grid item xs={12} lg={12}>
            <DataForm
              validation={validation}
              onSubmit={submitForm}
              fields={fields}
              initialValues={questionnaire}
              readOnly={questionnaire.status === 'PUBLISHED'}
              submitLabel={props.submitLabel ? props.submitLabel : 'Send'}
            />
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default QuestionnaireForm;
