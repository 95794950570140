import {PhotoGoalResult} from 'components/IntervalExecutionCard/IntervalTypes';
import * as React from 'react';
import {useEffect, useState} from 'react';
import 'react-medium-image-zoom/dist/styles.css';
import PhotoGallery, {Photo} from 'components/Photo/PhotoGallery';

type PhotoGoalResultViewProps = {
  result: PhotoGoalResult;
};

const PhotoGoalResultView = (props: PhotoGoalResultViewProps) => {
  const [photos, setPhotos] = useState<Photo[]>([]);

  useEffect(() => {
    setPhotos(determinePhotoUserFiles());
  }, [props.result]);

  const determinePhotoUserFiles = () => {
    const files = [];
    files.push({name: 'Front', file: props.result.front});
    files.push({name: 'Left', file: props.result.leftSide});
    files.push({name: 'Back', file: props.result.back});
    files.push({name: 'Right', file: props.result.rightSide});
    return files;
  };

  return <PhotoGallery photos={photos} />;
};

export default PhotoGoalResultView;
