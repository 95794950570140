import DashboardLayout from 'layout/DashboardLayout';
import DashboardNavbar from 'layout/navbar/DashboardNavbar';
import Footer from 'layout/footer/Footer';

import {useParams} from 'react-router-dom';
import EditTermsForm from './EditTermsForm';
import {Grid} from '@mui/material';
import SuiAlert from 'components/SuiAlert';

const EditTermsPage = () => {
  const {termsId} = useParams();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container py={3}>
        <Grid item xs={12} xl={8} order={{xs: 2, xl: 1}}>
          <EditTermsForm termsId={termsId} />
        </Grid>
        <Grid item xs={12} xl={4} pl={{xs: 0, xl: 3}} order={{xs: 1, xl: 2}}>
          <SuiAlert color="info" fontSize="small">
            Already published terms can&apos;t be updated.
          </SuiAlert>
        </Grid>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
};

export default EditTermsPage;
