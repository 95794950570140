import DataForm, {DataFormField} from 'components/DataForm/index';
import * as Yup from 'yup';
import {EditPlanProps} from 'pages/coaching/plans/edit/EditPlan.types';
import FileUploader from 'components/FileUploader/index';
import {useEffect, useState} from 'react';
import SuiTypography from 'components/SuiTypography/index';
import Grid from '@mui/material/Grid';

const EditPlanDetailsTab = (props: EditPlanProps) => {
  const [icon, setIcon] = useState(null);

  const fields: DataFormField[] = [
    {
      name: 'name',
      label: 'Name',
      type: 'text',
      placeholder: 'Power Athlete Super Plan',
      hint: 'A crisp name for the plan. Something that will make the clients want to buy it.',
    },
    {
      name: 'summary',
      label: 'Summary',
      type: 'text',
      rows: 2,
      placeholder: 'This plan is for power athletes that want to be even more powerful.',
      inputProps: {maxLength: 140},
      hint: 'A short summary of the plan. This will be shown to the clients in the search results.',
    },
    {
      name: 'description',
      label: 'Description',
      type: 'editor',
      placeholder: 'eg. a really, really, really awesome plan.',
      hint: 'This is the description that will be shown to the clients. It should tell the clients what they will get from this plan. How it is different from other plans. What are the benefits of this plan. You name it!',
    },
    {type: 'divider', label: ''},
    {type: 'section', label: 'Plan Settings'},
    {
      name: 'metadata.capacity',
      label: 'Capacity',
      type: 'number',
      placeholder: '10',
      columns: 6,
      hint: 'The number of clients that can subscribe to this plan.',
    },
  ];

  const validation = Yup.object().shape({
    name: Yup.string().ensure().required('Name is required.'),
    summary: Yup.string().ensure().required('Summary is required.'),
    description: Yup.string()
      .ensure()
      .required('Description is required.')
      .test('len', 'Description is required.', val => {
        return val !== undefined && val.length > 0 && val.replace(/(<([^>]+)>)/gi, '').length > 0;
      }),
    metadata: Yup.object().shape({
      capacity: Yup.number()
        .positive('Capacity must be a positive number.')
        .min(1, 'Capacity must be greater than or equal to 1.')
        .required('Capacity is required.'),
    }),
  });

  useEffect(() => {
    if (props.plan && props.plan.icon) {
      setIcon(props.plan.icon);
    }
  }, [props.plan]);

  const onSubmit = async (values, actions) => {
    values.icon = icon;
    await props.onSubmit(values, actions);
  };

  const userFiles = () => {
    if (icon) {
      return [icon];
    }
    return [];
  };

  return (
    <>
      <Grid item xs={12} pt={4} px={2}>
        <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Plan Details
        </SuiTypography>
      </Grid>
      <Grid item xs={12} px={2}>
        <SuiTypography variant="button" color="text" fontWeight="regular">
          When clients search for coaches they will see your plan details. Make sure to include all the important
          details you are offering to the client.
        </SuiTypography>
      </Grid>
      <FileUploader
        label="Image"
        message='Drag & Drop your image or <span class=" filepond--label-action">Browse</span>'
        visibility="PUBLIC"
        onFileUploaded={file => {
          setIcon(file);
        }}
        onFileRemoved={() => {
          setIcon(null);
        }}
        userFiles={userFiles()}
        targetHeight={500}
        targetWidth={500}
      />
      <DataForm
        validation={validation}
        onSubmit={onSubmit}
        fields={fields}
        initialValues={props.plan}
        submitLabel="save"
      />
    </>
  );
};

export default EditPlanDetailsTab;
