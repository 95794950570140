import Grid from '@mui/material/Grid';
import ClientCard from 'components/ClientCard/ClientCard';
import SubscriptionCard from 'pages/coaching/clients/client/SubscriptionCard';
import Card from '@mui/material/Card';
import Chat from 'components/Chat/Chat';
import Paywall from 'components/Paywall/Paywall';

type ClientMessagesTabProps = {
  clientId: string;
  subscriptionId: string;
};

const ClientMessagesTab = (props: ClientMessagesTabProps) => {
  return (
    <>
      <Grid container alignItems="flex-start" spacing={3}>
        <Grid item xs={12} xl={8}>
          <Paywall>
            <Card sx={{height: {xl: '50vh', md: '500px'}, minHeight: '500px'}}>
              <Chat clientId={props.clientId} />
            </Card>
          </Paywall>
        </Grid>
        <Grid container item xs={12} xl={4} spacing={3}>
          <Grid item xs={12}>
            <ClientCard clientId={props.clientId} subscriptionId={props.subscriptionId} layout={'vertical'} />
          </Grid>
          <Grid item xs={12}>
            <SubscriptionCard clientId={props.clientId} subscriptionId={props.subscriptionId} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ClientMessagesTab;
