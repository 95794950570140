import * as React from 'react';
import {IntervalExecution} from 'components/IntervalExecutionCard/IntervalTypes';
import {UserDetails} from 'pages/coaching/tasks/TaskTypes';
import IntervalGoalResultView from 'components/IntervalExecutionCard/IntervalGoalResultView';
import IntervalExecutionProgressChart from 'components/IntervalExecutionCard/IntervalExecutionProgressChart';
import SuiTypography from 'components/SuiTypography/index';
import Grid from '@mui/material/Grid';

type IntervalExecutionGoalResultsProps = {
  intervalExecution: IntervalExecution;
  client: UserDetails;
};

const IntervalExecutionGoalResultsTab = (props: IntervalExecutionGoalResultsProps) => {
  return (
    <>
      <Grid item xs={12}>
        <SuiTypography variant="button" fontWeight="regular" color={'text'}>
          Below you can see the results and the progress of <b>{props.client.name}</b> during this check-in.
        </SuiTypography>
      </Grid>
      <Grid item xs={12} sx={{pt: 2}}>
        <IntervalExecutionProgressChart execution={props.intervalExecution} />
      </Grid>
      {props.intervalExecution &&
        props.intervalExecution.goalResults.map((goalResult, index) => {
          return <IntervalGoalResultView result={goalResult} key={index} index={index} client={props.client} />;
        })}
    </>
  );
};

export default IntervalExecutionGoalResultsTab;
