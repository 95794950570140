import {Grid, SpeedDial, SpeedDialAction, SpeedDialIcon} from '@mui/material';
import DashboardLayout from 'layout/DashboardLayout';
import DashboardNavbar from 'layout/navbar/DashboardNavbar';
import Footer from 'layout/footer/Footer';
import SuiBox from 'components/SuiBox/index';
import PlanStatisticsCard from 'pages/coaching/plans/statistics/PlansStatisticsCard';
import ClientStatisticsCard from 'components/ClientCard/ClientStatisticsCard';
import ClientList from 'components/ClientList/ClientList';
import Card from '@mui/material/Card';
import * as React from 'react';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {RiUserSettingsLine} from 'react-icons/ri';
import {FaEnvelopeOpenText} from 'react-icons/fa';
import EncryptionInfoCard from 'pages/coaching/clients/EncryptionInfoCard';

const ClientsPage = () => {
  const navigate = useNavigate();

  const [contextSwitchDialOpen, setContextSwitchDialOpen] = useState(false);
  const handleContextSwitchDialOpen = () => setContextSwitchDialOpen(true);
  const handleContextSwitchDialClose = () => setContextSwitchDialOpen(false);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox py={3}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container alignItems="flex-start">
                <Grid container item xs={12} xl={8} rowSpacing={3} pr={{xl: 3}} py={3} className={'imc-tour-clients'}>
                  <Card sx={{width: '100%'}}>
                    <SpeedDial
                      ariaLabel="User Operations"
                      sx={{mt: 1, mr: 2, position: 'absolute', top: 0, right: 0}}
                      icon={<SpeedDialIcon icon={<RiUserSettingsLine color={'white'} fontSize={'1.6rem'} />} />}
                      FabProps={{color: 'primary', size: 'medium'}}
                      direction={'left'}
                      onClose={handleContextSwitchDialClose}
                      onOpen={handleContextSwitchDialOpen}
                      open={contextSwitchDialOpen}>
                      <SpeedDialAction
                        key={'Invite Client'}
                        icon={<FaEnvelopeOpenText fontSize={'18'} />}
                        tooltipTitle={'Invite Client'}
                        onClick={() => navigate('/coaching/clients/invitation')}
                      />
                    </SpeedDial>
                    <ClientList
                      showPagination={true}
                      showFilter={true}
                      maxRows={-1}
                      sorting={'name'}
                      showBadge={true}
                    />
                  </Card>
                </Grid>

                <Grid container item xs={12} xl={4} mt={{xs: 3, xl: 0}}>
                  <Grid item xs={12} pb={3}>
                    <EncryptionInfoCard />
                  </Grid>

                  <Grid item xs={12}>
                    <ClientStatisticsCard />
                  </Grid>

                  <Grid item xs={12} pb={3}>
                    <PlanStatisticsCard />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SuiBox>
        <Footer />
      </DashboardLayout>
    </>
  );
};

export default ClientsPage;
