import {Interval} from 'components/IntervalExecutionCard/IntervalTypes';
import DataForm, {DataFormField} from 'components/DataForm/index';
import * as Yup from 'yup';
import moment from 'moment/moment';
import Grid from '@mui/material/Grid';
import {FormikHelpers} from 'formik/dist/types';
import React from 'react';
import EndExecutionSelector from 'pages/coaching/tasks/type-prepare-interval/EndExecutionSelector';

type PrepareIntervalGeneralFormProps = {
  interval: Interval;
  isFirstInterval: boolean;
  onSubmit: (interval: Interval, actions: FormikHelpers<any>) => void;
};

const PrepareIntervalGeneralForm = (props: PrepareIntervalGeneralFormProps) => {
  const fields: DataFormField[] = [
    {
      name: 'description',
      label: '',
      type: 'editor',
      placeholder: 'tell your client what this check-in is all about...',
    },
    {
      name: 'start',
      label: 'Start',
      type: 'date',
      placeholder: 'start of the interval',
      columns: 6,
      hint: "The start date defines the first day of the Check-in. It will start at midnight in your client's timezone. You can set the start date to be in the past, but not more than 2 days ago.",
    },
    {
      name: 'end',
      label: 'End',
      type: 'date',
      placeholder: 'end of the interval',
      columns: 6,
      hint: "The end date defines the last day of the Check-in. It will end at midnight in your client's timezone. The end date must be after the start date.",
      rightComponent: context => {
        return (
          <EndExecutionSelector
            onDateClick={date => {
              const dateString =
                date.getFullYear() +
                '-' +
                (date.getMonth() + 1).toString().padStart(2, '0') +
                '-' +
                date.getDate().toString().padStart(2, '0');
              context.setFieldValue('end', dateString, true);
              context.setFieldTouched('end', true, true);
            }}
          />
        );
      },
    },
  ];

  const validation = Yup.object().shape({
    description: Yup.string()
      .transform(v => (v === null ? '' : v))
      .required('Description is required.')
      .test('len', 'Description is required.', val => {
        return val !== undefined && val.length > 0 && val.replace(/(<([^>]+)>)/gi, '').length > 0;
      }),
    start: Yup.string()
      .transform(v => (v === null ? '' : v))
      .required('Start is required.')
      .test('len', 'Start must be in the future or 2 days in the past max.', val => {
        return val !== undefined && moment(val).diff(new Date(), 'days') >= -2;
      }),
    end: Yup.string()
      .transform(v => (v === null ? '' : v))
      .required('End is required.')
      .when('start', (start, schema) => {
        return schema.test('len', 'End must be after start and in the future.', val => {
          return val !== undefined && moment(val).diff(moment(start), 'days') > 0;
        });
      }),
  });

  return (
    <>
      <Grid container item xs={12}>
        <Grid item xs={12} lg={12}>
          <DataForm
            validation={validation}
            onSubmit={props.onSubmit}
            fields={fields}
            initialValues={props.interval}
            readOnly={props.interval.status !== 'DRAFT'}
            hideButtons={props.interval.status !== 'DRAFT'}
            submitLabel="next"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PrepareIntervalGeneralForm;
