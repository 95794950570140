import * as React from 'react';
import {useEffect, useState} from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import SuiBox from 'components/SuiBox/index';
import SuiTypography from 'components/SuiTypography/index';
import DashboardLayout from 'layout/DashboardLayout';
import DashboardNavbar from 'layout/navbar/DashboardNavbar';
import Card from '@mui/material/Card';
import 'pages/admin/entity/style.css';
import moment from 'moment';
import {Divider} from '@mui/material';
import Table from 'components/SuiTable/index';
import {useParams} from 'react-router-dom';

type Trace = {
  name: string;
  utcTime: string;
  userTime: string;
};

type UserTimezoneTrace = {
  userId: string;
  utcTime: string;
  userTime: string;
  utcOffset: number;
  traces: Trace[];
};

function AdminTimezoneTracePage() {
  const {userId} = useParams();
  const [trace, setTrace] = useState<UserTimezoneTrace>(null);

  useEffect(() => {
    const load = async () => {
      const response = await axios.get(`/api/v0/admin/timezone/trace/${encodeURI(userId)}`);
      setTrace(response.data);
    };
    load();
  }, [userId]);

  const columns = [
    {name: 'name', align: 'left'},
    {name: 'user time', align: 'right'},
    {name: 'utc time', align: 'left'},
  ];

  const createRows = () =>
    trace.traces?.map(t => ({
      name: [null, t.name],
      'user time': moment.utc(t.userTime).format('YYYY-MM-DD HH:mm:ss'),
      'utc time': moment.utc(t.utcTime).format('YYYY-MM-DD HH:mm:ss'),
    }));

  const renderTrace = () => {
    if (trace) {
      return (
        <Grid container item xs={12} mt={{xs: 1, lg: 3}} mb={1}>
          <Grid item xs={12}>
            <Table columns={columns} rows={createRows()} tableStyles={{boxShadow: 'none'}} />
          </Grid>
        </Grid>
      );
    }
  };

  const renderContent = () => {
    if (trace) {
      return (
        <>
          <Grid item xs={6} pt={4} px={2} justifyContent={'flex-end'} display={'flex'}>
            <SuiTypography variant="h6" fontWeight="bold">
              UTC Time
            </SuiTypography>
          </Grid>
          <Grid item xs={6} pt={4} px={2}>
            <SuiTypography variant="h6" fontWeight="regular">
              {moment.utc(trace?.utcTime).format('YYYY-MM-DD HH:mm:ss')}
            </SuiTypography>
          </Grid>
          <Grid item xs={6} pt={4} px={2} justifyContent={'flex-end'} display={'flex'}>
            <SuiTypography variant="h6" fontWeight="bold">
              User Time
            </SuiTypography>
          </Grid>
          <Grid item xs={6} pt={4} px={2}>
            <SuiTypography variant="h6" fontWeight="regular">
              {moment.utc(trace?.userTime).format('YYYY-MM-DD HH:mm:ss')}
            </SuiTypography>
          </Grid>
          <Grid item xs={6} pt={4} px={2} justifyContent={'flex-end'} display={'flex'}>
            <SuiTypography variant="h6" fontWeight="bold">
              UTC Offset
            </SuiTypography>
          </Grid>
          <Grid item xs={6} pt={4} px={2}>
            <SuiTypography variant="h6" fontWeight="regular">
              {trace?.utcOffset} min
            </SuiTypography>
          </Grid>
          <Divider />
          {renderTrace()}
        </>
      );
    }
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox py={3}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container alignItems="flex-start">
                <Grid container item xs={12} rowSpacing={3} pr={{xl: 3}} py={3}>
                  <Card sx={{width: '100%'}}>
                    <Grid item xs={12} pt={4} px={2}>
                      <SuiTypography variant="h6" fontWeight="medium">
                        User Timezone Trace
                      </SuiTypography>
                    </Grid>
                    <Grid item xs={12} px={2}>
                      <SuiTypography variant="button" color="text" fontWeight="regular">
                        Analyze the timezone trace of a user.
                      </SuiTypography>
                    </Grid>
                    <Grid container p={1}>
                      {renderContent()}
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SuiBox>
      </DashboardLayout>
    </>
  );
}

export default AdminTimezoneTracePage;
