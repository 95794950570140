import {IntervalGoal, SleepGoal} from 'pages/coaching/tasks/type-prepare-interval/IntervalGoalTypes';
import * as Yup from 'yup';
import {BaseSchema} from 'yup';
import {DataFormField} from 'components/DataForm/index';
import {HotelOutlined} from '@mui/icons-material';
import {IntervalGoalSupport} from 'pages/coaching/tasks/type-prepare-interval/IntervalGoalSupportFactory';
import {GiSandsOfTime} from 'react-icons/gi';
import ObjectID from 'bson-objectid';

export class SleepGoalSupport implements IntervalGoalSupport<SleepGoal> {
  createFields(goal: IntervalGoal, index: number): DataFormField[] {
    return [
      {
        name: `goals[${index}].minHours`,
        label: 'Min Hours',
        type: 'number',
        placeholder: '',
        icon: <GiSandsOfTime />,
        hint: 'Desired minimum hours of sleep',
        data: goal.id,
        columns: 3,
      },
    ];
  }

  createGoal(): SleepGoal {
    return {
      id: ObjectID().toHexString(),
      type: 'sleep',
      minHours: 8,
    };
  }

  createValidationSchema(): BaseSchema {
    return Yup.object().shape({
      minHours: Yup.number()
        .positive('Min Hours must be a positive number.')
        .lessThan(25, 'Min Hours must be between 0 and 24')
        .required('Min Hours is required'),
    });
  }

  getIcon(): React.ReactElement {
    return <HotelOutlined fontSize={'medium'} />;
  }
}
