import SuiInput from 'components/SuiInput/index';
import {FieldProps} from 'formik';
import {ReactElement} from 'react';

type FormFieldInputProps = {
  fieldProps: FieldProps;
  placeholder: string;
  multiline: boolean;
  value: string;
  readOnly: boolean;
  icon: ReactElement;
  type: string;
  tabIndex: number;
};

const FormFieldInput = (props: FormFieldInputProps) => {
  return (
    <>
      <SuiInput
        {...props.fieldProps.field}
        icon={{
          component: props.icon !== undefined ? props.icon : null,
          direction: 'left',
        }}
        value={props.value}
        multiline={props.multiline}
        placeholder={props.placeholder}
        disabled={props.readOnly}
        type={props.type}
        inputProps={{tabIndex: props.tabIndex, autoCapitalize: 'none'}}
      />
    </>
  );
};

export default FormFieldInput;
