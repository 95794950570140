import * as React from 'react';
import {useState} from 'react';
import {Grid, SpeedDial, SpeedDialAction, SpeedDialIcon} from '@mui/material';
import {QuizOutlined} from '@mui/icons-material';
import Card from '@mui/material/Card';
import IntervalExecutionBrowser from 'components/IntervalExecutionBrowser/IntervalExecutionBrowser';
import {UserDetails} from 'pages/coaching/tasks/TaskTypes';
import QuestionnaireResponseBrowser from 'components/Questionnaire/QuestionnaireResponseBrowser';
import {GiCardExchange, GiProgression} from 'react-icons/gi';
import {HiOutlineChat} from 'react-icons/hi';
import Chat from 'components/Chat/Chat';

type IntervalContextViewProps = {
  subscriptionId: string;
  client: UserDetails;
  intervalStartingNumber: number;
};

type IntervalContext = 'intervals' | 'questionnaires' | 'messages';

const IntervalContextView = (props: IntervalContextViewProps) => {
  const [activeContext, setActiveContext] = useState<IntervalContext>('intervals');

  const [contextSwitchDialOpen, setContextSwitchDialOpen] = useState(false);
  const handleContextSwitchDialOpen = () => setContextSwitchDialOpen(true);
  const handleContextSwitchDialClose = () => setContextSwitchDialOpen(false);

  const handelContextSwitch = (context: IntervalContext) => {
    handleContextSwitchDialClose();
    setActiveContext(context);
  };

  const renderContextTabs = () => {
    switch (activeContext) {
      case 'questionnaires':
        return <QuestionnaireResponseBrowser subscriptionId={props.subscriptionId ? props.subscriptionId : ''} />;
      case 'messages':
        return (
          <Card sx={{height: {xl: '50vh', md: '500px'}, minHeight: '500px'}}>
            <Chat clientId={props.client?.user} />
          </Card>
        );
      default:
        return (
          <IntervalExecutionBrowser
            subscriptionId={props.subscriptionId ? props.subscriptionId : ''}
            intervalNumber={props.intervalStartingNumber}
            client={props.client}
            showLabels={true}
          />
        );
    }
  };

  const renderTabContent = () => {
    return (
      <>
        <Grid item xs={12}>
          <SpeedDial
            ariaLabel="Switch Context"
            sx={{mt: 1, mr: 2, position: 'absolute', top: 0, right: 0}}
            icon={<SpeedDialIcon icon={<GiCardExchange color={'white'} fontSize={'1.6rem'} />} />}
            FabProps={{color: 'primary', size: 'medium'}}
            direction={'left'}
            onClose={handleContextSwitchDialClose}
            onOpen={handleContextSwitchDialOpen}
            open={contextSwitchDialOpen}>
            <SpeedDialAction
              key={'Check-Ins'}
              icon={<GiProgression fontSize={'medium'} />}
              tooltipTitle={'Check-Ins'}
              onClick={() => handelContextSwitch('intervals')}
            />
            <SpeedDialAction
              key={'Questionnaires'}
              icon={<QuizOutlined fontSize={'small'} />}
              tooltipTitle={'Questionnaires'}
              onClick={() => handelContextSwitch('questionnaires')}
            />
            <SpeedDialAction
              key={'Messages'}
              icon={<HiOutlineChat fontSize={'large'} />}
              tooltipTitle={'Messages'}
              onClick={() => handelContextSwitch('messages')}
            />
          </SpeedDial>
        </Grid>
        <Grid item xs={12}>
          {renderContextTabs()}
        </Grid>
      </>
    );
  };

  const renderContent = () => {
    if (props.intervalStartingNumber >= 1) {
      return renderTabContent();
    }
    return <QuestionnaireResponseBrowser subscriptionId={props.subscriptionId ? props.subscriptionId : ''} />;
  };

  return (
    <>
      <Card sx={{width: '100%'}}>{renderContent()}</Card>
    </>
  );
};

export default IntervalContextView;
