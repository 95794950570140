export default {
  broadcast_message: '',
  broadcast_message_color: '#FFA500',
  // feature toggles
  /**
   * feature_messaging: enable/disable coach - client chat
   */
  feature_messaging: true,
  /**
   * feature_payment: enable/disable payment configuration for coaches
   */
  feature_payment: false,
  /**
   * feature_coaching_paywall: enable/disable paywall for coaches
   */
  feature_coaching_paywall: false,
};
