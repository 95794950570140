import React, {useEffect, useMemo, useState} from 'react';
import axios from 'axios';

import Calendar, {CalendarEvent} from 'components/Calendar/Calendar';
import {useMediaQuery} from '@mui/material';
import theme from 'assets/theme';

type IntervalExecutionScheduleEntry = {
  id: string;
  status: 'DRAFT' | 'IN_EXECUTION' | 'SUBMITTED';
  clientName: string;
  clientId: string;
  clientPicture?: string;
  start: string;
  end: string;
  subscription: string;
};

type DateRange = {
  start: string;
  end: string;
};

export type ViewMode = 'month' | 'week' | 'day';

export type IntervalExecutionCalendarProps = {
  onDateClick?: (date: Date) => void;
  onEventClick?: (date: Date, clientId: string, subscription: string) => void;
  initialDate?: Date;
  mode: ViewMode;
  title?: string;
};

const IntervalExecutionCalendar = (props: IntervalExecutionCalendarProps) => {
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const [schedule, setSchedule] = useState<CalendarEvent[]>([]);
  const [dateRange, setDateRange] = useState<DateRange>();

  const getInitialView = () => {
    if (props.mode === 'day') {
      return 'listDay';
    } else if (props.mode === 'week') {
      return 'listWeek';
    } else {
      return isXs ? 'listMonth' : 'dayGridMonth';
    }
  };

  useEffect(() => {
    if (dateRange) {
      const loadSchedule = async () => {
        const response = await axios.get(
          `/api/v0/interval-executions/schedule?start=${dateRange.start}&end=${dateRange.end}`,
        );
        const scheduleEntries = response.data.map((entry: IntervalExecutionScheduleEntry): CalendarEvent => {
          return {
            title: entry.clientName,
            className: entry.status === 'IN_EXECUTION' ? 'primary' : 'light',
            start: entry.end.substring(0, 10), // yyyy-mm-dd
            end: entry.end.substring(0, 10), // yyyy-mm-dd
            extendedProps: {
              subscription: entry.subscription,
              clientId: entry.clientId,
              picture: entry.clientPicture,
            },
          };
        });
        setSchedule(scheduleEntries);
      };
      loadSchedule();
    }
  }, [dateRange]);

  return (
    <>
      {useMemo(
        () => (
          <Calendar
            header={{title: props.title ?? 'Check-ins'}}
            initialView={getInitialView()}
            lazyFetching={true}
            initialDate={props.initialDate ?? new Date()}
            events={schedule}
            selectable
            eventClick={info => {
              const clientId = info.event.extendedProps.clientId;
              const subscription = info.event.extendedProps.subscription;
              if (props.onEventClick) {
                props.onEventClick(info.event.start, clientId, subscription);
              }
            }}
            datesSet={info => {
              const startOfDay = new Date(info.start);
              const endOfDay = new Date(info.end);
              endOfDay.setHours(23, 59, 59, 999).toString();
              setDateRange({start: startOfDay.toISOString(), end: endOfDay.toISOString()});
            }}
            dateClick={info => {
              if (props.onDateClick) {
                props.onDateClick(info.date);
              }
            }}
          />
        ),
        [schedule],
      )}
    </>
  );
};

export default IntervalExecutionCalendar;
