import React, {PropsWithChildren} from 'react';
import {useNavigate} from 'react-router-dom';
import {Auth0Provider} from '@auth0/auth0-react';

const Auth0ProviderWithHistory = ({children}: PropsWithChildren<any>) => {
  const navigate = useNavigate();

  const onRedirectCallback = appState => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      scope="openid profile email offline_access"
      useRefreshTokens={true}
      onRedirectCallback={onRedirectCallback}
      //@ts-ignore
      cacheLocation={process.env.REACT_APP_AUTH0_CACHE}>
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
