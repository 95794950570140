/**
 =========================================================
 * Soft UI Dashboard PRO React - v3.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useEffect, useState} from 'react'; // react-router components
import {Route, Routes, useLocation} from 'react-router-dom'; // @mui material components
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline'; // Soft UI Dashboard PRO React components
// Soft UI Dashboard PRO React example components
import Sidenav from 'layout/sidenav/Sidenav'; // Soft UI Dashboard PRO React themes
import theme from 'assets/theme'; // RTL plugins
// Soft UI Dashboard PRO React routes
import appRoutes from 'routes'; // Soft UI Dashboard PRO React contexts
import {setMiniSidenav, useSoftUIController} from 'context'; // Images
import {ConfirmProvider} from 'components/ConfirmDialog';
import Slash from 'pages/Home';
import Error404 from 'pages/error/Error404';

import {useRecoilValue} from 'recoil';
import {authenticatedUserRolesState} from 'state/authentication';
import NotificationOrchestrator from 'pages/notifictions/NotificationOrchestrator';
import {SnackbarProvider} from 'notistack';
import Fade from '@mui/material/Fade';
import TimezoneOrchestrator from 'pages/me/settings/general/TimezoneOrchestrator';
import ObservabilityBar from 'components/Observability/ObservabilityBar';
import ProductTour from 'components/ProductTour/ProductTour';
import BroadcastMessage from 'components/Broadcast/BroadcastMessage';
import TwilioSession from 'components/Chat/twilio/TwilioSession';
import ClientsLoader from 'components/ClientList/ClientsLoader';

const brand = require('assets/images/logo-transparent.png');

export default function App() {
  const [controller, dispatch] = useSoftUIController();
  const {miniSidenav, direction, sidenavColor} = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const {pathname} = useLocation();
  const authenticatedUserRoles = useRecoilValue(authenticatedUserRolesState);

  const isAdmin = () => {
    return authenticatedUserRoles !== undefined && (authenticatedUserRoles as string[]).indexOf('ADMIN') !== -1;
  };

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = allRoutes => {
    return allRoutes.map(route => {
      if (route.admin && !isAdmin()) {
        return null;
      }

      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.hidden) {
        const hiddenRoutes = getRoutes(route.hidden);
        hiddenRoutes.push(<Route path={route.route} element={route.component} key={route.key} />);
        return hiddenRoutes;
      }

      if (route.route) {
        return <Route path={route.route} element={route.component} key={route.key} />;
      }
      return null;
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ConfirmProvider>
        <ProductTour>
          <SnackbarProvider anchorOrigin={{horizontal: 'right', vertical: 'bottom'}} TransitionComponent={Fade}>
            <NotificationOrchestrator>
              <TwilioSession />
              <ClientsLoader />
              <TimezoneOrchestrator />
              <BroadcastMessage />
              <ObservabilityBar isAdmin={isAdmin()} />
              <Sidenav
                color={sidenavColor}
                brand={brand}
                brandName="inmycorner"
                routes={appRoutes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Routes>
                <Route path="*" element={<Error404 />} />
                <Route path="/" element={<Slash />} />
                {getRoutes(appRoutes)}
              </Routes>
            </NotificationOrchestrator>
          </SnackbarProvider>
        </ProductTour>
      </ConfirmProvider>
    </ThemeProvider>
  );
}
