import * as Yup from 'yup';
import {BaseSchema} from 'yup';
import {DataFormField} from 'components/DataForm/index';
import {LibraryAddCheck} from '@mui/icons-material';
import {QuestionSupport} from 'components/Questionnaire/QuestionSupportFactory';
import {
  BooleanAnswer,
  BooleanAnswerStatistics,
  BooleanQuestion,
  CompoundAnswer,
  Question,
} from 'components/Questionnaire/Questionnaire';
import SuiTypography from 'components/SuiTypography/index';
import * as React from 'react';
import AnswerTable from 'components/Questionnaire/types/AnswerTable';
import Grid from '@mui/material/Grid';
import SuiBox from 'components/SuiBox/index';
import CircularProgressWithLabel from 'components/IntervalExecutionCard/CircularProgressWithLabel';
import ObjectID from 'bson-objectid';

export class BooleanQuestionSupport
  implements QuestionSupport<BooleanQuestion, BooleanAnswer, BooleanAnswerStatistics>
{
  createFields(question: Question, index: number): DataFormField[] {
    return [
      {
        name: `questions[${index}].defaultValue`,
        label: 'Yes by default',
        type: 'switch',
        data: question.id,
        columns: 6,
      },
      {
        name: `questions[${index}].allowDetails`,
        label: 'Allow Client to define details',
        type: 'switch',
        data: question.id,
        columns: 6,
      },
    ];
  }

  createQuestion(): BooleanQuestion {
    return {id: ObjectID().toHexString(), content: '', type: 'boolean', defaultValue: false, allowDetails: true};
  }

  createValidationSchema(): BaseSchema {
    return Yup.object().shape({});
  }

  getIcon(): React.ReactElement {
    return <LibraryAddCheck fontSize={'medium'} />;
  }

  renderAnswer(answer: BooleanAnswer): React.ReactElement {
    return (
      <>
        <SuiTypography variant="button" fontWeight="regular">
          {answer.value ? 'Yes' : 'No'} {this.renderDetails(answer)}
        </SuiTypography>
      </>
    );
  }

  private renderDetails(answer): React.ReactElement {
    if (answer.details) {
      return <span> - {answer.details}</span>;
    }
  }

  renderCompoundAnswer(
    answer: CompoundAnswer<BooleanQuestion, BooleanAnswer, BooleanAnswerStatistics>,
  ): React.ReactElement {
    return (
      <>
        <Grid item xs={12} sx={{pt: 2, pb: 1}}>
          <SuiBox display={'flex'} sx={{justifyContent: 'space-around'}}>
            {this.renderPercentage(answer.statistics.truePercentage, 'YES', 'success')}
            {this.renderPercentage(answer.statistics.falsePercentage, 'NO', 'warning')}
          </SuiBox>
        </Grid>

        <AnswerTable
          answer={answer}
          labelProvider={(a: BooleanAnswer) => {
            let details = '';
            if (a.details) {
              details = ' - ' + a.details;
            }
            return a.value ? 'Yes' + details : 'No' + details;
          }}
        />
      </>
    );
  }

  private renderPercentage(
    value: number,
    label: string,
    color: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'inherit',
  ): React.ReactElement {
    return (
      <SuiBox display={'flex'} sx={{flexGrow: 1, justifyContent: 'center', width: '100%'}}>
        <SuiBox
          display={'flex'}
          sx={{
            flexDirection: 'column',
            alignItems: 'center',
            flexGrow: 1,
            justifyContent: 'center',
          }}>
          <SuiBox display={'flex'} sx={{justifyContent: 'center'}}>
            <CircularProgressWithLabel
              value={value * 100}
              size={80}
              thickness={5}
              fontSize={'1.45rem'}
              fontWeight={'bold'}
              color={color}
            />
          </SuiBox>
          <SuiBox display={'flex'} sx={{justifyContent: 'center'}}>
            <SuiTypography
              variant="button"
              fontWeight="regular"
              color={'text'}
              textTransform={'capitalize'}
              sx={{flexGrow: 1}}>
              <b>{label}</b>
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      </SuiBox>
    );
  }
}
