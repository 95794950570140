import Card from '@mui/material/Card';
import SuiBox from 'components/SuiBox/index';
import SuiTypography from 'components/SuiTypography/index';
import SuiAvatar from 'components/SuiAvatar/index';
import {Icon} from '@mui/material';

type IntervalGoalCardProps = {
  type: string;
  value: React.ReactNode;
  icon: React.ReactNode;
  color: 'white' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'dark';
};

const IntervalGoalCard = (props: IntervalGoalCardProps) => {
  return (
    <Card sx={{height: '100%'}}>
      <SuiBox
        display="flex"
        sx={{flexDirection: 'row', justifyContent: 'left', alignItems: 'center'}}
        p={1}
        pl={2}
        pt={2}>
        <SuiAvatar bgColor={props.color}>
          <Icon fontSize="medium">{props.icon}</Icon>
        </SuiAvatar>

        <SuiBox display="flex" sx={{flexDirection: 'column', justifyContent: 'left', alignItems: 'left', pt: 1, pl: 2}}>
          <SuiTypography variant="button" color="dark" opacity={0.7} textTransform="capitalize" fontWeight="bold">
            {props.type}
          </SuiTypography>
          <SuiTypography variant="h6" fontWeight="bold" color="dark">
            {props.value}
          </SuiTypography>
        </SuiBox>
      </SuiBox>
    </Card>
  );
};

export default IntervalGoalCard;
