import {StringGoal} from 'pages/coaching/tasks/type-prepare-interval/IntervalGoalTypes';
import SuiTypography from 'components/SuiTypography/index';
import {UserDetails} from 'pages/coaching/tasks/TaskTypes';

type StringGoalViewProps = {
  goal: StringGoal;
  client: UserDetails;
};

const StringGoalView = (props: StringGoalViewProps) => {
  return (
    <>
      <SuiTypography variant="button" fontWeight="regular" color={'text'}>
        <b>You:</b> {props.goal.content}
      </SuiTypography>
    </>
  );
};

export default StringGoalView;
