import * as React from 'react';
import {IntervalExecution} from 'components/IntervalExecutionCard/IntervalTypes';
import {UserDetails} from 'pages/coaching/tasks/TaskTypes';
import SuiTypography from 'components/SuiTypography/index';
import Grid from '@mui/material/Grid';
import CompoundQuestionnaireResponseView from 'components/Questionnaire/CompoundQuestionnaireResponseView';

type CompoundQuestionnaireResponseTabProps = {
  intervalExecution: IntervalExecution;
  client: UserDetails;
};

const DailyQuestionsResponseTab = (props: CompoundQuestionnaireResponseTabProps) => {
  return (
    <>
      <Grid item xs={12}>
        <SuiTypography variant="button" fontWeight="regular" color={'text'}>
          Below you can see the answers to the daily questions <b>{props.client.name}</b> provided during this check-in.
        </SuiTypography>
      </Grid>
      <CompoundQuestionnaireResponseView response={props.intervalExecution.dailyQuestionsResponse} />
    </>
  );
};

export default DailyQuestionsResponseTab;
