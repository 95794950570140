import {Client, Conversation, Paginator, Participant} from '@twilio/conversations';
import {UserDetails} from 'pages/coaching/tasks/TaskTypes';

export class ClientConversation {
  constructor(readonly clientId: string, public twilioConversation: Conversation) {}
}

export class TwilioConversationFetcher {
  private conversations: ClientConversation[] = [];

  constructor(private readonly client: Client, private readonly user: UserDetails) {}

  public async getConversation(clientId: string): Promise<Conversation> {
    let conversation = this.conversations.find(c => c.clientId === clientId);
    if (conversation && !conversation.twilioConversation) {
      conversation.twilioConversation = await this.fetchConversation(clientId);
    } else if (!conversation) {
      conversation = new ClientConversation(clientId, await this.fetchConversation(clientId));
      this.conversations.push(conversation);
    }
    return conversation.twilioConversation;
  }

  public async getConversations(): Promise<ClientConversation[]> {
    this.conversations = [];
    let page: Paginator<Conversation>;
    do {
      page = await this.client.getSubscribedConversations();
      this.conversations.push(
        ...page.items.map(conversation => {
          conversation.setUserNotificationLevel('default');
          return new ClientConversation(this.getClientId(conversation.uniqueName), conversation);
        }),
      );
    } while (page.hasNextPage);
    return this.conversations;
  }

  private getClientId(uniqueName) {
    const pattern = /coach-(.+?)-client-(.+)/;
    const match = uniqueName.match(pattern);
    if (match) {
      return match[2];
    }
    return null;
  }

  private async fetchConversation(clientId: string) {
    try {
      const twilioConversation = await this.loadConversation(clientId);
      if (twilioConversation.status !== 'joined') {
        await twilioConversation.join();
        console.log('Joined conversation', twilioConversation);
      }
      const participants = await twilioConversation.getParticipants();
      await this.addClient(participants, clientId, twilioConversation);
      await twilioConversation.setUserNotificationLevel('default');
      return twilioConversation;
    } catch (e) {
      console.log('Error', JSON.stringify(e));
    }
  }

  private async loadConversation(clientId: string) {
    try {
      const conversation = await this.client.getConversationByUniqueName(`coach-${this.user.user}-client-${clientId}`);
      return conversation;
    } catch (e) {
      if (e.body.status === 404) {
        return await this.client.createConversation({
          uniqueName: `coach-${this.user.user}-client-${clientId}`,
        });
      }
      console.log('Error', JSON.stringify(e));
    }
  }

  private async addClient(participants: Participant[], clientId: string, twilioConversation: Conversation) {
    if (participants.filter(p => p.identity === clientId).length === 0) {
      try {
        await twilioConversation.add(clientId);
        console.log('Added client to conversation', twilioConversation);
      } catch (e) {
        console.log('Could not add client to conversation', JSON.stringify(e));
        // client might not have signed in yet
      }
    }
  }
}
