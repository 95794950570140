import {useEffect} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {clientsState, loadClients} from 'state/clients';
import {twilioControllerState} from 'components/Chat/twilio/TwilioController';
import {loadingMessagesState} from 'components/Chat/UnreadMessagesList';
import axios from 'axios';
import {UserDetails} from 'pages/coaching/tasks/TaskTypes';

type UnreadCount = {
  userDetails: UserDetails;
  unreadCount: number;
};

const ClientsLoader = () => {
  const setClients = useSetRecoilState(clientsState);
  const twilioController = useRecoilValue(twilioControllerState);
  const setLoadingMessages = useSetRecoilState(loadingMessagesState);

  useEffect(() => {
    if (twilioController) {
      loadClients().then(clients => {
        setClients(clients);
        updateAllClientsUnreadMessages(clients);
      });
    }
  }, [twilioController]);

  const updateAllClientsUnreadMessages = async clients => {
    const unreadCount: UnreadCount[] = (await axios.get('/api/v0/messages/unread-count')).data;
    for (const client of clients) {
      const unread = unreadCount.find(unread => unread.userDetails.user === client.client.userId)?.unreadCount;
      setClients(oldClients => {
        return oldClients.map(oldClient => {
          if (oldClient.client.userId === client.client.userId) {
            return {
              client: oldClient.client,
              unreadMessages: unread,
            };
          }
          return oldClient;
        });
      });
    }
    setLoadingMessages(false);
  };

  return <></>;
};

export default ClientsLoader;
