import {Interval} from 'components/IntervalExecutionCard/IntervalTypes';
import {IntervalGoalDescription, IntervalGoalType} from 'pages/coaching/tasks/type-prepare-interval/IntervalGoalTypes';
import SuiBox from 'components/SuiBox/index';
import {Autocomplete, TextField} from '@mui/material';
import {useState} from 'react';
import {IntervalGoalSupportFactory} from 'pages/coaching/tasks/type-prepare-interval/IntervalGoalSupportFactory';
import SuiTypography from 'components/SuiTypography/index';

type IntervalGoalDescriptionAutoCompleteProps = {
  goalDescriptions: IntervalGoalDescription[];
  interval: Interval;
  onDescriptionSelected: (description: IntervalGoalDescription) => void;
};
const IntervalGoalDescriptionAutoComplete = (props: IntervalGoalDescriptionAutoCompleteProps) => {
  const supportFactory = new IntervalGoalSupportFactory();
  const [selectedInput, setSelectedInput] = useState<string>('');

  const hasReachedMaxInstanceLimit = (description: IntervalGoalDescription) => {
    return goalCount(description) >= description.maxInstancesPerInterval;
  };

  const goalCount = (description: IntervalGoalDescription) => {
    return props.interval.goals.filter(g => g.type === description.type).length;
  };

  const renderAutocompleteItem = (props, option) => {
    return (
      <li {...props}>
        <SuiBox display={'flex'} flexDirection={'row'}>
          {supportFactory.supportFor(option.type as IntervalGoalType).getIcon()}
          <SuiBox display={'flex'} ml={3} flexDirection={'column'}>
            <SuiTypography color={'dark'} variant={'caption'} fontWeight={'bold'}>
              {option.summary}
            </SuiTypography>
            <SuiTypography color={'secondary'} variant={'caption'} fontWeight={'regular'}>
              {option.description}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      </li>
    );
  };

  return (
    <>
      <SuiBox sx={{display: 'flex', mt: 1}}>
        <SuiBox sx={{flexGrow: 1}}>
          <Autocomplete
            disabled={props.interval.status !== 'DRAFT'}
            id="interval-goal-description"
            sx={{width: '100%'}}
            noOptionsText="No Goals"
            disableClearable={true}
            options={props.goalDescriptions}
            getOptionLabel={option => (option as IntervalGoalDescription).summary}
            renderOption={renderAutocompleteItem}
            renderInput={params => <TextField {...params} placeholder="Add a Goal" />}
            onChange={(event, value: unknown) => {
              // TODO: changes values of other fields
              props.onDescriptionSelected(value as IntervalGoalDescription);
              setSelectedInput('');
            }}
            onInputChange={(event, value) => {
              setSelectedInput(value);
            }}
            value={null}
            inputValue={selectedInput}
            isOptionEqualToValue={(option, value) => option.type === value.type}
            getOptionDisabled={hasReachedMaxInstanceLimit}
            autoHighlight={false}
          />
        </SuiBox>
      </SuiBox>
    </>
  );
};

export default IntervalGoalDescriptionAutoComplete;
