import {FormControlLabel, Switch} from '@mui/material';
import SuiTypography from 'components/SuiTypography/index';
import {Field, FormikContextType} from 'formik';

type FormFieldCheckboxProps = {
  name: string;
  label: string;
  formikContext: FormikContextType<any>;
  value: string;
  readOnly: boolean;
};

const FormFieldCheckbox = (props: FormFieldCheckboxProps) => {
  const checked = () => {
    return props.formikContext.getFieldMeta(props.name).value
      ? props.formikContext.getFieldMeta(props.name).value === true
      : false;
  };

  return (
    <>
      <Field
        as={FormControlLabel}
        type="switch"
        name={props.name}
        readOnly={props.readOnly}
        control={
          <Switch
            sx={{ml: 2, mr: 1}}
            color="primary"
            checked={checked()}
            onChange={e => props.formikContext.setFieldValue(props.name, e.target.checked, true)}
            disabled={props.readOnly}
          />
        }
        disableTypography={true}
        label={
          <SuiTypography variant="button" color="text" fontWeight="regular">
            {props.label}
          </SuiTypography>
        }
      />
    </>
  );
};

export default FormFieldCheckbox;
