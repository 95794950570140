import Grid from '@mui/material/Grid';
import {
  CompoundAnswer,
  PhotoAnswer,
  PhotoAnswerStatistics,
  PhotoQuestion,
} from 'components/Questionnaire/Questionnaire';
import React, {useEffect, useState} from 'react';
import PhotoGallery, {Photo} from 'components/Photo/PhotoGallery';
import moment from 'moment/moment';

type PhotoGalleryProps = {
  answer: CompoundAnswer<PhotoQuestion, PhotoAnswer, PhotoAnswerStatistics>;
};

const PhotoAnswerCard = (props: PhotoGalleryProps) => {
  const [photos, setPhotos] = useState<Photo[]>([]);

  useEffect(() => {
    const answers: Map<string, PhotoAnswer> = new Map(Object.entries(props.answer.answers));
    const photos: Photo[] = [];
    answers.forEach((value, key) => {
      photos.push({
        name: moment(key).format('dddd, MM-DD-YYYY'),
        file: value.photo,
      });
    });
    photos.sort((a, b) => (a.name > b.name ? 1 : -1));
    setPhotos(photos);
  }, [props.answer]);

  return (
    <>
      <Grid item xs={12} sx={{pt: 1}}>
        <PhotoGallery photos={photos} />
      </Grid>
    </>
  );
};

export default PhotoAnswerCard;
