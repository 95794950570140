import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';

import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiAvatar from 'components/SuiAvatar';

import borders from 'assets/theme/base/borders';
import colors from 'assets/theme/base/colors';
import {Skeleton} from '@mui/material';

const PlanStatisticsChartItem = ({image, title, percentage, hasBorder}) => {
  const {borderWidth} = borders;
  const {light} = colors;

  const renderIcon = image => {
    if (image != null) {
      return (
        <SuiAvatar
          src={image}
          alt="title"
          size="sm"
          variant="circular"
          bgColor="light"
          imgProps={{
            sx: {
              height: '100%',
              width: '100%',
              objectFit: 'cover',
            },
          }}
        />
      );
    }
    return (
      <Skeleton
        variant="circular"
        sx={{
          height: 36,
          width: 36,
        }}
      />
    );
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      sx={{borderBottom: hasBorder ? `${borderWidth[1]} solid ${light.main}` : 'none'}}>
      <Grid item xs={10}>
        <SuiBox display="flex" py={1.5} px={2}>
          {<SuiBox mr={1}>{renderIcon(image)}</SuiBox>}
          <SuiBox display="flex" flexDirection="column" justifyContent="center">
            <SuiTypography component="div" variant="button" textTransform="capitalize" fontWeight="medium">
              {title}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      </Grid>
      <Grid item xs={2}>
        <SuiBox py={0.8} px={0} textAlign="center">
          <SuiTypography variant="caption" color="text" fontWeight="medium">
            {percentage}
          </SuiTypography>
        </SuiBox>
      </Grid>
    </Grid>
  );
};

PlanStatisticsChartItem.defaultProps = {
  image: '',
  hasBorder: false,
};

PlanStatisticsChartItem.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  percentage: PropTypes.string.isRequired,
  hasBorder: PropTypes.bool,
};

export default PlanStatisticsChartItem;
