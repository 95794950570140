import {IntervalExecution} from 'components/IntervalExecutionCard/IntervalTypes';
import Grid from '@mui/material/Grid';
import SuiTypography from 'components/SuiTypography/index';
import moment from 'moment';
import {UserDetails} from 'pages/coaching/tasks/TaskTypes';
import * as React from 'react';
import {useState} from 'react';
import SuiBox from 'components/SuiBox/index';
import {Collapse, Orientation} from '@mui/material';
import IntervalExecutionGoalResultsTab from 'components/IntervalExecutionCard/IntervalExecutionGoalResultsTab';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {FaFlagCheckered} from 'react-icons/fa';
import {QuizOutlined} from '@mui/icons-material';
import TabOrientation from 'components/TabOrientation/TabOrientation';
import DailyQuestionsResponseTab from 'components/IntervalExecutionCard/DailyQuestionsResponseTab';
import SafeHtml from 'components/SafeHtml/SafeHtml';

type IntervalExecutionViewProps = {
  intervalExecution: IntervalExecution;
  client: UserDetails;
  titleComponent?: React.ReactNode;
};

const IntervalExecutionView = (props: IntervalExecutionViewProps) => {
  const [tabValue, setTabValue] = useState<'goals' | 'questions'>('goals');
  const [tabsOrientation, setTabsOrientation] = useState<Orientation>('horizontal');

  const [showDescription, setShowDescription] = useState<boolean>(false);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
  };

  const renderContextTabs = () => {
    if (tabValue === 'goals') {
      return <IntervalExecutionGoalResultsTab intervalExecution={props.intervalExecution} client={props.client} />;
    } else if (tabValue === 'questions') {
      return <DailyQuestionsResponseTab client={props.client} intervalExecution={props.intervalExecution} />;
    }
  };

  const renderTabBar = () => {
    if (props.intervalExecution.dailyQuestionsResponse) {
      return (
        <AppBar position="static">
          <Tabs
            orientation={tabsOrientation}
            value={tabValue}
            onChange={handleSetTabValue}
            sx={{background: 'transparent', px: 3, pt: 3}}>
            <Tab label="Goals" value="goals" icon={<FaFlagCheckered fontSize={'small'} />} />
            <Tab label="Daily Questions" value="questions" icon={<QuizOutlined />} />
          </Tabs>
        </AppBar>
      );
    }
  };

  const renderFinished = () => {
    if (props.intervalExecution.status === 'SUBMITTED') {
      return moment(props.intervalExecution.interval.end).local().format('dddd, MM-DD-YYYY');
    }
    return <span>will end on {moment(props.intervalExecution.interval.end).local().format('dddd, MM-DD-YYYY')}</span>;
  };

  const renderDescriptionEllipsis = () => {
    if (!showDescription) {
      return (
        <SuiTypography color={'primary'} variant={'h6'}>
          &hellip;
        </SuiTypography>
      );
    }
  };

  return (
    <>
      <TabOrientation changeOrientation={setTabsOrientation} />
      <Grid container pb={2}>
        <Grid item xs={12} pt={2} px={2}>
          <SuiBox display={'flex'}>
            <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              Check-in #{props.intervalExecution ? props.intervalExecution.interval.number : 0}
            </SuiTypography>
            {props.titleComponent}
          </SuiBox>
        </Grid>
        <Grid container item xs={12} pt={2} px={2}>
          <Grid container item xs={12} xl={6}>
            <Grid item xs={12}>
              <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
                Started:
              </SuiTypography>
            </Grid>
            <Grid item xs={12}>
              <SuiTypography variant="button" fontWeight="regular" color={'text'}>
                {props.intervalExecution
                  ? moment.utc(props.intervalExecution.interval.start).format('dddd, MM-DD-YYYY')
                  : null}
              </SuiTypography>
            </Grid>
          </Grid>
          <Grid item xs={12} xl={6}>
            <Grid item xs={12}>
              <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
                Finished:
              </SuiTypography>
            </Grid>
            <Grid item xs={12}>
              <SuiTypography variant="button" fontWeight="regular" color={'text'}>
                {props.intervalExecution ? renderFinished() : null}
              </SuiTypography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
                Description:
              </SuiTypography>
            </Grid>
            <Grid item xs={12} sx={{cursor: 'pointer'}} onClick={() => setShowDescription(!showDescription)}>
              <Collapse in={showDescription} collapsedSize={18}>
                <SafeHtml
                  html={props.intervalExecution ? props.intervalExecution.interval.description : ''}
                  variant="button"
                  fontWeight="regular"
                  color={'text'}
                />
              </Collapse>
              {renderDescriptionEllipsis()}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {renderTabBar()}
            <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize" sx={{pt: 2}}>
              {tabValue === 'goals' ? 'Goals' : 'Daily Questions'}
            </SuiTypography>
          </Grid>
          {renderContextTabs()}
        </Grid>
      </Grid>
    </>
  );
};

export default IntervalExecutionView;
