import {PhotoGoal} from 'pages/coaching/tasks/type-prepare-interval/IntervalGoalTypes';
import SuiTypography from 'components/SuiTypography/index';
import {UserDetails} from 'pages/coaching/tasks/TaskTypes';

type PhotoGoalViewProps = {
  goal: PhotoGoal;
  client: UserDetails;
};

const PhotoGoalView = (props: PhotoGoalViewProps) => {
  const countRequiredPhotos = () => {
    let count = 0;
    if (props.goal.requireFront) {
      count++;
    }
    if (props.goal.requireBack) {
      count++;
    }
    if (props.goal.requireLeftSide) {
      count++;
    }
    if (props.goal.requireRightSide) {
      count++;
    }
    return count;
  };

  return (
    <>
      <SuiTypography variant="button" fontWeight="regular" color={'text'}>
        During this Check-in <b>{countRequiredPhotos()}</b> out of <b>4</b> photos were required. {props.client.name}{' '}
        provided the photos below.
      </SuiTypography>
    </>
  );
};

export default PhotoGoalView;
