import {styled} from '@mui/material';
import MuiAccordion, {AccordionProps} from '@mui/material/Accordion';
import MuiAccordionSummary, {AccordionSummaryProps} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import SuiTypography from 'components/SuiTypography/index';
import {MdChevronRight} from 'react-icons/md';
import Grid from '@mui/material/Grid';
import {Link} from 'react-router-dom';
import borders from 'assets/theme/base/borders';

type FaqEntry = {
  question: string;
  answer: string | React.ReactNode;
};

const faq: FaqEntry[] = [
  {
    question: 'Is there a free trial?',
    answer:
      "You can enjoy all features for free with one active client. There's no limit on the duration for that single client. However, should you decide to add another client, upgrading your plan will be required.",
  },
  {
    question: `Can I accept payment?`,
    answer: `Our platform is designed to make running your coaching business hassle-free. It integrates with Stripe directly into your dashboard, so you can focus on delivering results to your clients without the headache of chasing payments.`,
  },
  {
    question: `Do I need individual plans for each client?`,
    answer: `You have the flexibility to create multiple plans, yet consolidate all your clients within a unified framework if that aligns with your preferences. While each plan can be customized for individual clients upon distribution, 
    the overarching structure remains consistent. As an illustration, your foundational plan may encompass elements such as pricing details, macro specifications, and a check-in regimen. Clients seeking this specific option can enroll 
    in this plan, and there is no limit to the number of clients accommodated within each plan.`,
  },
  {
    question: `How does my client get access to the app?`,
    answer: `Your client just needs to grab the inmycorner app from the app store. Once they’ve set up a login, they will be able to search for you as a coach. Once they click your profile they can sign up-easy peasy!`,
  },
  {
    question: `What if I add a client and want to upgrade my plan?`,
    answer: (
      <>
        To upgrade, go to your{' '}
        <Link relative={'route'} to={'/me/subscriptions'}>
          subscriptions
        </Link>
        , and then click whichever plan you want to subscribe to. From there, you will see your current plan and the
        monthly/yearly options you can subscribe to.`,
      </>
    ),
  },
  {
    question: `Do you have in-app messaging?`,
    answer: `Our platform features in-app messaging functionality, facilitating seamless communication between coaches and clients directly within the dashboard.`,
  },
  {
    question: `Can I be notified when a client sends a message or responds to a check-in?`,
    answer: (
      <>
        Navigate to{' '}
        <Link relative={'route'} to={'/me/settings/notifications'}>
          notification settings
        </Link>
        , and you will find the various options available for receiving notifications.
      </>
    ),
  },
];

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({theme}) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: borders.borderRadius.md,
    marginBottom: theme.spacing(1),
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&::before': {
      display: 'none',
    },
    '&.MuiAccordion-root.Mui-expanded': {
      marginBottom: '15px !important',
    },
  }),
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  // eslint-disable-next-line react/jsx-no-undef
  <MuiAccordionSummary expandIcon={<MdChevronRight />} {...props} />
))(({theme}) => ({
  backgroundColor: theme.palette.grey.A100,
  borderTopLeftRadius: borders.borderRadius.md,
  borderTopRightRadius: borders.borderRadius.md,
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const FaqTab = () => {
  return (
    <>
      <Grid container p={3}>
        {faq.map((item, index) => (
          <Grid key={index} item xs={12}>
            <Accordion key={index} title={item.question}>
              <AccordionSummary
                // expandIcon={<MdOutlineExpandMore />}
                aria-controls="panel1a-content"
                sx={{marginBottom: 0}}
                style={{marginBottom: 0}}
                id="panel1a-header">
                <SuiTypography variant="subtitle2" fontWeight="medium">
                  {item.question}
                </SuiTypography>
              </AccordionSummary>
              <AccordionDetails>
                <SuiTypography display="block" variant="body2" fontWeight="light">
                  {item.answer}
                </SuiTypography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default FaqTab;
