import * as Yup from 'yup';
import {BaseSchema} from 'yup';
import {DataFormField} from 'components/DataForm/index';
import {QuestionSupport} from 'components/Questionnaire/QuestionSupportFactory';
import {
  CompoundAnswer,
  PhotoAnswer,
  PhotoAnswerStatistics,
  PhotoQuestion,
} from 'components/Questionnaire/Questionnaire';
import ObjectID from 'bson-objectid';
import {PhotoCamera} from '@mui/icons-material';
import {Card} from '@mui/material';
import Zoom from 'react-medium-image-zoom';
import ProtectedImage from 'components/IntervalExecutionCard/goals-results/photo/ProtectedImage';
import * as React from 'react';
import PhotoAnswerCard from 'components/Questionnaire/types/PhotoAnswerCard';

export class PhotoQuestionSupport implements QuestionSupport<PhotoQuestion, PhotoAnswer, PhotoAnswerStatistics> {
  createFields(): DataFormField[] {
    return [];
  }

  createQuestion(): PhotoQuestion {
    return {id: ObjectID().toHexString(), content: '', type: 'photo'};
  }

  createValidationSchema(): BaseSchema {
    return Yup.object().shape({});
  }

  getIcon(): React.ReactElement {
    return <PhotoCamera fontSize={'medium'} />;
  }

  renderAnswer(answer: PhotoAnswer): React.ReactElement {
    return (
      <Card>
        <Zoom>
          <ProtectedImage
            file={answer.photo}
            imageStyle={{
              height: '100%',
              width: '100%',
              maxHeight: '300px',
              objectFit: 'cover',
              borderRadius: '12px',
            }}
          />
        </Zoom>
      </Card>
    );
  }

  renderCompoundAnswer(answer: CompoundAnswer<PhotoQuestion, PhotoAnswer, PhotoAnswerStatistics>): React.ReactElement {
    return <PhotoAnswerCard answer={answer} />;
  }
}
