/**
 =========================================================
 * Soft UI Dashboard PRO React - v3.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { createContext, forwardRef, PropsWithChildren, useContext } from "react"; // prop-types is a library for typechecking of props
import PropTypes from "prop-types"; // Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"; // Custom styles for SuiPagination
import SuiPaginationItemRoot from "components/SuiPagination/SuiPaginationItemRoot"; // The Pagination main context

// The Pagination main context
const Context = createContext(undefined);

const SuiPagination = forwardRef(
  ({item, variant, color, size, active, children, ...rest}: PropsWithChildren<any>, ref) => {
    const context = item ? useContext(Context) : null;
    const paginationSize = context ? context.size : null;

    return (
      <Context.Provider value={{variant, color, size}}>
        {item ? (
          <SuiPaginationItemRoot
            {...rest}
            ref={ref}
            variant={active ? context.variant : 'outlined'}
            color={active ? context.color : 'secondary'}
            iconOnly
            circular
            ownerState={{variant, active, paginationSize}}>
            {children}
          </SuiPaginationItemRoot>
        ) : (
          <SuiBox display="flex" justifyContent="flex-end" alignItems="center" sx={{listStyle: 'none'}}>
            {children}
          </SuiBox>
        )}
      </Context.Provider>
    );
  },
);

// Setting default values for the props of SuiPagination
SuiPagination.defaultProps = {
  item: false,
  variant: 'gradient',
  color: 'info',
  size: 'medium',
  active: false,
};

// Typechecking props for the SuiPagination
SuiPagination.propTypes = {
  item: PropTypes.bool,
  variant: PropTypes.oneOf(['gradient', 'contained']),
  color: PropTypes.oneOf(['white', 'primary', 'secondary', 'info', 'success', 'warning', 'error', 'light', 'dark']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  active: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default SuiPagination;
