import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import DataForm, {DataFormField} from 'components/DataForm/index';
import * as Yup from 'yup';
import DashboardLayout from 'layout/DashboardLayout';
import DashboardNavbar from 'layout/navbar/DashboardNavbar';
import SuiBox from 'components/SuiBox/index';
import Grid from '@mui/material/Grid';
import {Card} from '@mui/material';
import SuiTypography from 'components/SuiTypography/index';
import {PaymentProvider} from 'pages/me/settings/payment/PaymentSettingsTab';
import PaymentProviderIcon from 'pages/me/settings/payment/PaymentProviderIcon';
import PaymentProviderStatusBadge from 'pages/me/settings/payment/PaymentProviderStatusBadge';
import moment from 'moment/moment';
import {useSetRecoilState} from 'recoil';
import {breadCrumbSegmentsState} from 'state/breadcrumb';

const PaymentProviderPage = () => {
  const {providerId} = useParams();
  const setPaymentProviderSegment = useSetRecoilState(breadCrumbSegmentsState(providerId));
  const [provider, setProvider] = useState<PaymentProvider>({} as PaymentProvider);

  useEffect(() => {
    const load = async () => {
      const response = await axios.get(`/api/v0/payment/providers/${providerId}`);
      setProvider(response.data);
      setPaymentProviderSegment(response.data.description);
    };
    load();
  }, [providerId]);

  const fields: DataFormField[] = [
    {
      name: 'description',
      label: 'Description',
      type: 'text',
      hint: 'We recommend using a description that allows you to easily identify this connection on the payment provider’s website. You can always change this later.',
      placeholder: '',
      columns: 6,
    },
  ];

  const validation = Yup.object().shape({
    description: Yup.string().ensure().required('Description is required.'),
  });

  const onSubmit = async (values, actions) => {
    try {
      const response = await axios.patch(`/api/v0/payment/providers/${providerId}`, {
        description: values.description,
      });
      setProvider(response.data);
      setPaymentProviderSegment(response.data.description);
    } catch (error) {
      actions.setStatus(error?.response?.data?.message);
    }
    actions.setSubmitting(false);
  };

  const computeTitle = () => {
    if (provider?.details) {
      return (
        <>
          <SuiBox position="relative" display={'flex'} flexDirection={'columns'} sx={{alignItems: 'center'}}>
            <PaymentProviderIcon providerType={provider.details.type} fontSize={35} />
            <SuiTypography variant="button" fontWeight="medium" sx={{marginLeft: 1}}>
              Provider
            </SuiTypography>
          </SuiBox>
        </>
      );
    } else {
      return 'Payment provider';
    }
  };

  const renderBusinessDetails = () => {
    if (provider?.details?.businessProfile) {
      return (
        <>
          {renderDetail(provider.details.businessProfile?.name)}
          {renderDetail(provider.details.businessProfile?.supportAddress?.line1)}
          {renderDetail(provider.details.businessProfile?.supportAddress?.line2)}
          {renderDetail(
            `${provider.details.businessProfile?.supportAddress?.postalCode} ${provider.details.businessProfile?.supportAddress?.city}`,
          )}
          {renderDetail(provider.details.businessProfile?.supportAddress?.state)}
          {renderDetail(provider.details.businessProfile?.url)}
          {renderDetail(provider.details.businessProfile?.supportEmail)}
        </>
      );
    }
  };

  const renderDetail = (detail?: string) => {
    if (detail && detail.trim().length > 0) {
      return (
        <Grid item xs={12}>
          <SuiTypography variant="button" color="text" fontWeight="regular" pl={1}>
            {detail}
          </SuiTypography>
        </Grid>
      );
    }
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox py={3}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container alignItems="flex-start">
                <Grid container item xs={12} xl={8} rowSpacing={3} pr={{xl: 3}} py={3}>
                  <Card sx={{width: '100%'}}>
                    <Grid item xs={12} pt={4} px={2}>
                      <SuiTypography variant="h6" fontWeight="medium">
                        {computeTitle()}
                      </SuiTypography>
                    </Grid>
                    <Grid item xs={12} px={2}>
                      <SuiTypography variant="button" color="text" fontWeight="regular">
                        This connection was created on {moment(provider.created).local().format('MM-DD-YYYY, h:mma')}.
                      </SuiTypography>
                    </Grid>{' '}
                    <Grid item xs={12} px={2}>
                      <SuiTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
                        Connections Status:
                      </SuiTypography>
                    </Grid>
                    <Grid item xs={12} px={2}>
                      <SuiTypography variant="button" color="text" fontWeight="bold" sx={{marginLeft: 5}}>
                        <PaymentProviderStatusBadge status={provider?.status} />
                      </SuiTypography>
                    </Grid>
                    <Grid item xs={12} px={2}>
                      <SuiTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
                        Business Details
                      </SuiTypography>
                    </Grid>
                    <Grid item xs={12} px={2} container sx={{lineHeight: 0.7}}>
                      {renderBusinessDetails()}
                    </Grid>
                    <DataForm
                      validation={validation}
                      onSubmit={onSubmit}
                      fields={fields}
                      initialValues={provider}
                      submitLabel="save"
                    />
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SuiBox>
      </DashboardLayout>
    </>
  );
};

export default PaymentProviderPage;
