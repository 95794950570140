import {Card, CircularProgress, Grid} from '@mui/material';
import DashboardLayout from 'layout/DashboardLayout';
import DashboardNavbar from 'layout/navbar/DashboardNavbar';
import Footer from 'layout/footer/Footer';
import {Link, useNavigate, useParams} from 'react-router-dom';
import * as React from 'react';
import {useEffect, useState} from 'react';
import axios from 'axios';
import {loadStripe} from '@stripe/stripe-js';
import {EmbeddedCheckout, EmbeddedCheckoutProvider} from '@stripe/react-stripe-js';
import SuiBox from 'components/SuiBox/index';
import {CoachSubscriptionCadence, CoachSubscriptionPlan} from 'components/Paywall/coachSubscription';
import SuiButton from 'components/SuiButton/index';
import SuiTypography from 'components/SuiTypography/index';

export type CheckoutSession = {
  userId: string;
  sessionId: string;
  clientSecret: string;
  priceId: string;
  cadence: CoachSubscriptionCadence;
  plan: CoachSubscriptionPlan;
  completed: boolean;
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_API_KEY);

const SubscribePage = () => {
  const {plan, cadence} = useParams();
  const navigate = useNavigate();

  const [checkoutSession, setCheckoutSession] = useState<CheckoutSession>();

  useEffect(() => {
    const checkout = async () => {
      const response = await axios.post('/api/v0/coach/subscriptions/checkout', {
        plan,
        cadence,
      });
      setCheckoutSession(response.data);
    };
    checkout();
  }, []);

  const renderCheckout = () => {
    if (checkoutSession) {
      if (checkoutSession.plan === 'FREE') {
        setTimeout(() => {
          navigate(`/me/subscriptions/subscribe/success`);
        }, 1000);
      } else {
        return (
          <Grid container>
            <Grid item xs={12}>
              <EmbeddedCheckoutProvider stripe={stripePromise} options={{clientSecret: checkoutSession.clientSecret}}>
                <EmbeddedCheckout />
              </EmbeddedCheckoutProvider>
            </Grid>
            <Grid item xs={12} justifyContent={'center'} alignItems={'center'}>
              <SuiBox
                display="flex"
                sx={{justifyContent: 'center', alignItems: 'center', flexDirection: 'column', mt: 3}}>
                <SuiTypography variant="h6" fontWeight="medium" sx={{mt: 2}} color={'secondary'}>
                  <SuiBox mt={3} mb={2}>
                    <SuiButton component={Link} to={'/me/subscriptions'} color="white" size="small">
                      Cancel
                    </SuiButton>
                  </SuiBox>
                </SuiTypography>
              </SuiBox>
            </Grid>
          </Grid>
        );
      }
    }
    return (
      <SuiBox sx={{height: '300px'}} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress color="primary" />
      </SuiBox>
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container alignItems="flex-start">
              <Grid container item xs={12} rowSpacing={3} pr={{xl: 3}} py={3}>
                <Card sx={{width: '100%', py: 3}}>{renderCheckout()}</Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
};

export default SubscribePage;
