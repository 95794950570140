import SuiBox from 'components/SuiBox/index';
import {Autocomplete, TextField} from '@mui/material';
import {useState} from 'react';
import SuiTypography from 'components/SuiTypography/index';
import {QuestionSupportFactory} from 'components/Questionnaire/QuestionSupportFactory';
import {QuestionType, QuestionTypeIdentifier} from 'components/Questionnaire/Questionnaire';

type QuestionTypeAutoCompleteProps = {
  questionTypes: QuestionType[];
  readOnly: boolean;
  onQuestionTypeSelected: (questionType: QuestionType) => void;
};

const QuestionTypeAutoComplete = (props: QuestionTypeAutoCompleteProps) => {
  const supportFactory = new QuestionSupportFactory();
  const [selectedInput, setSelectedInput] = useState<string>('');

  const renderAutocompleteItem = (props, option) => {
    return (
      <li {...props}>
        <SuiBox display={'flex'} flexDirection={'row'}>
          {supportFactory.supportFor(option.type as QuestionTypeIdentifier).getIcon()}
          <SuiBox display={'flex'} ml={3} flexDirection={'column'}>
            <SuiTypography color={'dark'} variant={'caption'} fontWeight={'bold'}>
              {option.summary}
            </SuiTypography>
            <SuiTypography color={'secondary'} variant={'caption'} fontWeight={'regular'}>
              {option.description}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      </li>
    );
  };

  return (
    <>
      <SuiBox sx={{display: 'flex', mt: 1}}>
        <SuiBox sx={{flexGrow: 1}}>
          <Autocomplete
            disabled={props.readOnly}
            id="question-types"
            sx={{width: '100%'}}
            noOptionsText="No Questions"
            disableClearable={true}
            options={props.questionTypes}
            getOptionLabel={option => (option as QuestionType).summary}
            renderOption={renderAutocompleteItem}
            renderInput={params => <TextField {...params} placeholder="Add a Question" />}
            onChange={(event, value: unknown) => {
              props.onQuestionTypeSelected(value as QuestionType);
              setSelectedInput('');
            }}
            onInputChange={(event, value) => {
              setSelectedInput(value);
            }}
            value={null}
            inputValue={selectedInput}
            isOptionEqualToValue={(option, value) => option.type === value.type}
            autoHighlight={false}
          />
        </SuiBox>
      </SuiBox>
    </>
  );
};

export default QuestionTypeAutoComplete;
