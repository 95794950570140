import * as Yup from 'yup';
import {BaseSchema} from 'yup';
import {DataFormField} from 'components/DataForm/index';
import {NumbersOutlined} from '@mui/icons-material';
import {QuestionSupport} from 'components/Questionnaire/QuestionSupportFactory';
import SuiTypography from 'components/SuiTypography/index';
import {
  CompoundAnswer,
  NumberAnswer,
  NumberAnswerStatistics,
  NumberQuestion,
} from 'components/Questionnaire/Questionnaire';
import AnswerTable from 'components/Questionnaire/types/AnswerTable';
import Grid from '@mui/material/Grid';
import AverageNumberView from 'components/Questionnaire/types/AverageNumberView';
import ObjectID from 'bson-objectid';

export class NumberQuestionSupport implements QuestionSupport<NumberQuestion, NumberAnswer, NumberAnswerStatistics> {
  createFields(): DataFormField[] {
    return [];
  }

  createQuestion(): NumberQuestion {
    return {id: ObjectID().toHexString(), content: '', type: 'number'};
  }

  createValidationSchema(): BaseSchema {
    return Yup.object().shape({});
  }

  getIcon(): React.ReactElement {
    return <NumbersOutlined fontSize={'medium'} />;
  }

  renderAnswer(answer: NumberAnswer): React.ReactElement {
    return (
      <SuiTypography variant="button" fontWeight="regular">
        {answer.value}
      </SuiTypography>
    );
  }

  renderCompoundAnswer(
    answer: CompoundAnswer<NumberQuestion, NumberAnswer, NumberAnswerStatistics>,
  ): React.ReactElement {
    return (
      <>
        <Grid item xs={12} sx={{pt: 2, pb: 1}}>
          <AverageNumberView average={answer.statistics.average} />
        </Grid>
        <AnswerTable answer={answer} labelProvider={(a: NumberAnswer) => (a.value ? a.value + '' : '')} />
      </>
    );
  }
}
