import {useNavigate, useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';

import Grid from '@mui/material/Grid';
import DashboardLayout from 'layout/DashboardLayout';
import DashboardNavbar from 'layout/navbar/DashboardNavbar';
import Footer from 'layout/footer/Footer';
import Card from '@mui/material/Card';
import SuiTypography from 'components/SuiTypography';
import SuiBox from 'components/SuiBox';
import PlanSpecification from 'components/PlanSummary/index';
import Divider from '@mui/material/Divider';
import SuiButton from 'components/SuiButton/index';
import axios from 'axios';
import {Plan} from 'pages/coaching/plans/PlanTypes';
import {useConfirm} from 'components/ConfirmDialog/index';
import {useSetRecoilState} from 'recoil';
import {breadCrumbSegmentsState} from 'state/breadcrumb';
import {PaymentProvider} from 'pages/me/settings/payment/PaymentSettingsTab';
import {PlanPaymentFeature} from 'pages/coaching/plans/edit/payment/EditPlanPaymentTab';
import {useRemoteConfig} from 'components/Firebase/FirebaseConfigProvider';
import SuiAlert from 'components/SuiAlert/index';
import {WarningAmberRounded} from '@mui/icons-material';

const GoLivePage = () => {
  const {feature_payment} = useRemoteConfig();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const {planId} = useParams();
  const [plan, setPlan] = useState<Plan>({});
  const setPlanSegment = useSetRecoilState(breadCrumbSegmentsState(planId));
  const [paymentProvider, setPaymentProvider] = useState<PaymentProvider | null>(null);

  useEffect(() => {
    const load = async () => {
      const providerId = getPaymentProviderId();
      if (providerId) {
        const response = await axios.get(`/api/v0/payment/providers/${providerId}`);
        setPaymentProvider(response.data);
      }
    };
    load();
  }, [plan]);

  useEffect(() => {
    const load = async () => {
      const response = await axios.get(`/api/v0/plans/${planId}`);
      setPlan(response.data);
      setPlanSegment(response.data.name);
    };
    load();
  }, [planId]);

  const promotePlan = async () => {
    try {
      await confirm({
        title: 'Go Live with Plan',
        content: 'Are you sure you want to go live with this plan?',
        confirmationText: 'Go Live',
      });
      await updatePlan();
    } catch (error) {
      // Do nothing
    }
  };

  const updatePlan = async () => {
    plan.status = 'PROMOTED';
    try {
      await axios.put(`/api/v0/plans/${planId}`, plan);
      navigate('/coaching/plans/' + plan.id);
    } catch (error) {
      alert('Error updating plan');
    }
  };

  const getPaymentProviderId = () => {
    let providerId = null;
    plan?.coachingFeatures?.forEach(feature => {
      if (feature.type === 'feature-payment') {
        const paymentFeature = feature as PlanPaymentFeature;
        providerId = paymentFeature.providerId;
      }
    });
    return providerId;
  };

  const renderPromotionError = () => {
    if (feature_payment?.asBoolean() && getPaymentProviderId() === null) {
      return 'A plan needs to have payment setup to go live.';
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container py={3}>
        <Grid item xs={12} xl={8} order={{xs: 2, xl: 1}}>
          <Card sx={{height: '100%'}}>
            <SuiBox px={2} pt={2}>
              <SuiBox px={2}>
                <SuiBox mb={3}>
                  <Grid item xs={12} px={2}>
                    <SuiAlert color="info" fontSize="default">
                      <WarningAmberRounded fontSize={'large'} sx={{mr: 1.5}} />
                      <SuiTypography variant="button" color="white" fontWeight="regular">
                        {`Before launching, ensure you've updated the pricing of your plan on Stripe. By default, the
                        initial price is set to $99 when creating a plan. Once your plan is live, clients will be able
                        to purchase it at the price you've set. Note that once a client subscribes to a specific price,
                        that price cannot be adjusted. While you can still adjust the prices after going live, be aware
                        that it will not impact clients who have already subscribed to your plan.`}
                      </SuiTypography>
                    </SuiAlert>
                  </Grid>
                  <Grid item xs={12} pt={2} px={2}>
                    <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                      Plan Review
                    </SuiTypography>
                  </Grid>
                  <Grid item xs={12} px={2}>
                    <SuiTypography variant="button" color="text" fontWeight="regular">
                      {
                        'Please review your plan details before going live. After your plan is live, clients are able to see what you are offering.'
                      }
                    </SuiTypography>
                  </Grid>
                </SuiBox>
                <SuiBox>
                  <PlanSpecification plan={plan} paymentProvider={paymentProvider} />
                </SuiBox>
                <Divider />
                <SuiBox mt={2} pb={2} width="100%" display="flex" justifyContent="flex-end">
                  <SuiTypography variant="button" color="error" fontWeight="regular" pt={1} mr={2}>
                    {renderPromotionError()}
                  </SuiTypography>
                  <SuiButton
                    disabled={feature_payment?.asBoolean() && getPaymentProviderId() === null}
                    type="submit"
                    size="small"
                    color="primary"
                    onClick={promotePlan}>
                    Go Live
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            </SuiBox>
          </Card>
        </Grid>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
};

export default GoLivePage;
