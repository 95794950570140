import * as React from 'react';
import {FaStripe} from 'react-icons/fa';

export type PaymentProviderIconProps = {
  providerType: 'stripe' | 'paypal';
  fontSize?: number;
};

const PaymentProviderIcon = (props: PaymentProviderIconProps) => {
  if (props.providerType === 'stripe') {
    return <FaStripe fontSize={props.fontSize || 40} color={'#635BFF'} />;
  }

  return <></>;
};

export default PaymentProviderIcon;
