import DashboardLayout from 'layout/DashboardLayout';
import DashboardNavbar from 'layout/navbar/DashboardNavbar';
import Footer from 'layout/footer/Footer';

import {useParams} from 'react-router-dom';
import {Grid} from '@mui/material';
import EditPlanView from 'pages/coaching/plans/edit/EditPlanView';

const EditPlanPage = () => {
  const {planId} = useParams();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container py={3}>
        <Grid item xs={12} order={{xs: 2, xl: 1}}>
          <EditPlanView planId={planId} />
        </Grid>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
};

export default EditPlanPage;
