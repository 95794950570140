import React from 'react';
import DashboardLayout from 'layout/DashboardLayout';
import DashboardNavbar from 'layout/navbar/DashboardNavbar';
import Grid from '@mui/material/Grid';
import Footer from 'layout/footer/Footer';

import IntervalExecutionCalendar from 'components/Calendar/IntervalExecutionCalendar';
import {useNavigate} from 'react-router-dom';

const CalendarPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <Grid container>
          <Grid item xs={12} py={3}>
            <Grid item xs={12} sx={{height: 'max-content'}}>
              <IntervalExecutionCalendar
                mode={'month'}
                onEventClick={(date, clientId, subscription) => {
                  navigate(`/coaching/clients/${encodeURI(clientId)}/${subscription}/checkins`);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Footer />
      </DashboardLayout>
    </>
  );
};

export default CalendarPage;
