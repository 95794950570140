/**
 =========================================================
 * Soft UI Dashboard PRO React - v3.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

/**
 All of the routes for the Soft UI Dashboard PRO React are added here,
 You can add a new route, customize the routes and delete the routes here.

 Once you add a new route on this file it will be visible automatically on
 the Sidenav.

 For adding a new route you can follow the existing routes in the routes array.
 1. The `type` key with the `collapse` value is used for a route.
 2. The `type` key with the `title` value is used for a title inside the Sidenav.
 3. The `type` key with the `divider` value is used for a divider between Sidenav items.
 4. The `name` key is used for the name of the route on the Sidenav.
 5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
 6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
 7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
 inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
 8. The `route` key is used to store the route location which is used for the react router.
 9. The `href` key is used to store the external links location.
 10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
 10. The `component` key is used to store the component of its route.
 */

// Soft UI Dashboard PRO React icons
// Pages
import OverviewPage from 'pages/admin/dashboard/OverviewPage';
import AdminSendNotifications from 'pages/admin/dashboard/notifications/AdminSendNotifications';
import AdminTermsPage from 'pages/admin/terms/AdminTermsPage';
import EditTermsPage from 'pages/admin/terms/EditTermsPage';
import CoachDashboard from 'pages/coaching/dashboard/CoachDashboard';
import GoLivePage from 'pages/coaching/plans/GoLivePage';
import PlanPage from 'pages/coaching/plans/plan/PlanPage';
import PlansPage from 'pages/coaching/plans/PlansPage';
import NotificationsPage from 'pages/me/notifications/NotificationsPage';
import ProfilePage from 'pages/me/profile/ProfilePage';
import ClientsPage from 'pages/coaching/clients/ClientsPage';
import ClientPage from 'pages/coaching/clients/client/ClientPage';
import TasksPage from 'pages/coaching/tasks/TasksPage';
import TaskPage from 'pages/coaching/tasks/TaskPage';
import EditPlanPage from 'pages/coaching/plans/EditPlanPage';
import SettingsPage from 'pages/me/settings/SettingsPage';
import {FaUser, FaUserNinja, FaUsers} from 'react-icons/fa';
import InvitationPage from 'pages/coaching/clients/invite/InvitationPage';
import AdminInvitationsPage from 'pages/admin/invitations/AdminInvitationsPage';
import AdminCreateInvitationPage from 'pages/admin/invitations/AdminCreateInvitationPage';
import AdminEntityPage from 'pages/admin/entity/AdminEntityPage';
import AdminTimezoneTracePage from 'pages/admin/dashboard/timezone-trace/AdminTimezoneTracePage';
import PaymentProviderPage from 'pages/me/settings/payment/PaymentProviderPage';
import SubscriptionsPage from 'pages/me/subscription/SubscriptionsPage';
import SubscribePage from 'pages/me/subscription/SubscribePage';
import SubscribeSuccessPage from 'pages/me/subscription/SubscribeSuccessPage';
import BillingPortalPage from 'pages/me/subscription/BillingPortalPage';
import AdminMaxClients from 'pages/admin/dashboard/max-clients/AdminMaxClients';
import CalendarPage from 'pages/coaching/calendar/CalendarPage';
import HelpPage from 'pages/help/HelpPage';

const appRoutes = [
  {
    type: 'collapse',
    name: 'Coaching',
    key: 'coaching',
    icon: <FaUsers fontSize={'large'} />,
    collapse: [
      {
        name: 'Dashboard',
        key: 'dashboard',
        route: '/coaching/dashboard',
        component: <CoachDashboard />,
        hidden: [
          {
            name: 'Dashboard',
            key: 'dashboard',
            route: '/coaching',
            component: <CoachDashboard />,
          },
        ],
      },
      {
        name: 'Clients',
        key: 'clients',
        route: '/coaching/clients',
        component: <ClientsPage />,
        hidden: [
          {
            name: 'Client',
            key: 'client',
            route: '/coaching/clients/:clientId/:subscriptionId',
            component: <ClientPage />,
          },
          {
            name: 'Client Tab',
            key: 'client-tab',
            route: '/coaching/clients/:clientId/:subscriptionId/:tab',
            component: <ClientPage />,
          },
          {
            name: 'Client Invitation',
            key: 'client-invitation',
            route: '/coaching/clients/invitation',
            component: <InvitationPage />,
          },
        ],
      },
      {
        name: 'Tasks',
        key: 'tasks',
        route: '/coaching/tasks',
        component: <TasksPage />,
        hidden: [
          {
            name: 'Task',
            key: 'task',
            route: '/coaching/tasks/:taskId',
            component: <TaskPage />,
          },
        ],
      },
      {
        name: 'Calendar',
        key: 'calendar',
        route: '/coaching/calendar',
        component: <CalendarPage />,
      },
      {
        name: 'Plans',
        key: 'plans',
        route: '/coaching/plans',
        component: <PlansPage />,
        hidden: [
          {
            name: 'Edit Plan',
            key: 'edit-plan',
            route: '/coaching/plans/:planId/edit',
            component: <EditPlanPage />,
          },
          {
            name: 'Edit Plan Tab',
            key: 'edit-plan-tab',
            route: '/coaching/plans/:planId/edit/:category',
            component: <EditPlanPage />,
          },
          {
            name: 'Go Live with Plan',
            key: 'plan-go-live',
            route: '/coaching/plans/:planId/go-live',
            component: <GoLivePage />,
          },
          {
            name: 'Plan',
            key: 'plan',
            route: '/coaching/plans/:planId',
            component: <PlanPage tab="subscribers" />,
          },
          {
            name: 'Plan Subscribers',
            key: 'plan-subscribers',
            route: '/coaching/plans/:planId/subscribers',
            component: <PlanPage tab="subscribers" />,
          },
          {
            name: 'Plan Details',
            key: 'plan-details',
            route: '/coaching/plans/:planId/details',
            component: <PlanPage tab="details" />,
          },
          {
            name: 'Plan Client Invitations',
            key: 'plan-client-invitations',
            route: '/coaching/plans/:planId/invitations',
            component: <PlanPage tab="invitations" />,
          },
          {
            name: 'Plan Client Invitation',
            key: 'plan-client-invite',
            route: '/coaching/plans/:planId/invite',
            component: <InvitationPage />,
          },
        ],
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Me',
    key: 'me',
    icon: <FaUser fontSize={'medium'} />,
    collapse: [
      {
        name: 'Profile',
        key: 'profile',
        route: '/me/profile',
        component: <ProfilePage tab="basic" />,
        hidden: [
          {
            name: 'Profile',
            key: 'profile',
            route: '/me',
            component: <ProfilePage tab="basic" />,
          },
          {
            name: 'Profile media',
            key: 'profile-media',
            route: '/me/profile/media',
            component: <ProfilePage tab="media" />,
          },
        ],
      },
      {
        name: 'Notifications',
        key: 'notifications',
        route: '/me/notifications',
        component: <NotificationsPage />,
      },
      {
        name: 'Settings',
        key: 'settings',
        route: '/me/settings',
        component: <SettingsPage tab="notifications" />,
        hidden: [
          {
            name: 'Notification Settings',
            key: 'notification-settings',
            route: '/me/settings/notifications',
            component: <SettingsPage tab="notifications" />,
          },
          {
            name: 'Payment Settings',
            key: 'payment-settings',
            route: '/me/settings/payment',
            component: <SettingsPage tab="payment" />,
          },
          {
            name: 'Payment Provider',
            key: 'payment-provider',
            route: '/me/settings/payment/:providerId',
            component: <PaymentProviderPage />,
          },
        ],
      },
      {
        name: 'Subscriptions',
        key: 'subscriptions',
        route: '/me/subscriptions',
        component: <SubscriptionsPage />,
        hidden: [
          {
            name: 'Subscribe',
            key: 'subscriptions-subscribe',
            route: '/me/subscriptions/subscribe/:plan/:cadence',
            component: <SubscribePage />,
          },
          {
            name: 'Subscribe Success',
            key: 'subscriptions-subscribe-success',
            route: '/me/subscriptions/subscribe/success',
            component: <SubscribeSuccessPage />,
          },
          {
            name: 'Billing Portal',
            key: 'subscriptions-billing-portal',
            route: '/me/subscriptions/billing-portal',
            component: <BillingPortalPage />,
          },
        ],
      },
    ],
  },
  {
    type: 'hidden',
    name: 'Help',
    key: 'help',
    hidden: [
      {
        name: 'Help',
        key: 'help',
        route: '/help',
        component: <HelpPage tab={'faq'} />,
      },
      {
        name: 'FAQ',
        key: 'faq',
        route: '/help/faq',
        component: <HelpPage tab={'faq'} />,
      },
      {
        name: 'Videos',
        key: 'videos',
        route: '/help/videos',
        component: <HelpPage tab={'videos'} />,
      },
    ],
  },
  {type: 'divider', key: 'divider-1', admin: true},
  {type: 'title', title: 'Admin', key: 'title-pages', admin: true},
  {
    type: 'collapse',
    name: 'Admin',
    admin: true,
    key: 'admin',
    icon: <FaUserNinja fontSize={'medium'} />,
    collapse: [
      {
        name: 'Overview',
        key: 'overview',
        route: '/admin/overview',
        component: <OverviewPage />,
        admin: true,
        hidden: [
          {
            name: 'Overview',
            key: 'overview',
            route: '/admin',
            component: <OverviewPage />,
            admin: true,
          },
          {
            name: 'Timezone Trace',
            key: 'timezone-trace',
            route: '/admin/overview/users/:userId/timezone/trace',
            component: <AdminTimezoneTracePage />,
            admin: true,
          },
          {
            name: 'Max Clients Override',
            key: 'max-clients-override',
            route: '/admin/overview/users/:userId/max-clients',
            component: <AdminMaxClients />,
            admin: true,
          },
          {
            name: 'Send Notifications',
            key: 'send-notifications',
            route: '/admin/overview/users/:userId/send-notification',
            component: <AdminSendNotifications />,
            admin: true,
          },
        ],
      },
      {
        name: 'Coach Invitations',
        key: 'coach-invitations',
        route: '/admin/coach-invitations',
        component: <AdminInvitationsPage />,
        admin: true,
        hidden: [
          {
            name: 'Create Coach Invitation',
            key: 'coach-invitation-create',
            route: '/admin/coach-invitations/create',
            component: <AdminCreateInvitationPage />,
            admin: true,
          },
        ],
      },
      {
        name: 'Terms',
        key: 'terms',
        route: '/admin/terms',
        component: <AdminTermsPage />,
        admin: true,
        hidden: [
          {
            name: 'Edit Terms',
            key: 'edit-terms',
            route: '/admin/terms/:termsId',
            component: <EditTermsPage />,
          },
        ],
      },
      {
        name: 'Entities',
        key: 'entities',
        route: '/admin/entities',
        component: <AdminEntityPage />,
        admin: true,
      },
    ],
  },
];

export default appRoutes;
