import * as React from 'react';
import {useMemo} from 'react';

import {Link, useNavigate} from 'react-router-dom';

import PropTypes from 'prop-types';

import {Doughnut} from 'react-chartjs-2';

import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiButton from 'components/SuiButton';

import configs from 'pages/coaching/plans/statistics/chart/configs';
import PlanStatisticsChartItem from 'pages/coaching/plans/statistics/chart/PlanStatisticsChartItem';
import {FaChartPie} from 'react-icons/fa';

const PlanStatisticsChart = ({title, chart, action}) => {
  const navigate = useNavigate();
  const {data, options} = configs(chart.labels || [], chart.datasets || {});

  const renderItems = chart.labels
    ? chart.labels.map((label, key) => (
        <PlanStatisticsChartItem
          image={chart.images && chart.images[key]}
          title={label}
          key={label}
          percentage={`${chart.datasets && chart.datasets.data ? chart.datasets.data[key] : 0}%`}
          hasBorder={key !== chart.labels.length - 1}
        />
      ))
    : null;

  const renderButton = () => {
    let template;

    if (action) {
      template =
        action.type === 'internal' ? (
          <SuiBox mt={3} mb={2}>
            <SuiButton component={Link} to={action.route} color="primary" size="small">
              {action.label}
            </SuiButton>
          </SuiBox>
        ) : (
          <SuiBox mt={3} mb={2}>
            <SuiButton
              component="a"
              href={action.route}
              target="_blank"
              rel="noreferrer"
              variant="gradient"
              color={action.color}
              size="small">
              {action.label}
            </SuiButton>
          </SuiBox>
        );
    }

    return template;
  };

  const renderNoPlans = () => {
    return (
      <SuiBox display="flex" sx={{justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <FaChartPie fontSize={130} color={'#e9ecef'} />
        <SuiButton size="small" color="primary" xs={6} onClick={() => navigate('/coaching/plans')} sx={{mt: 3, mb: 2}}>
          Create a Plan today!
        </SuiButton>
      </SuiBox>
    );
  };

  const renderChart = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} xl={5} sx={{textAlign: 'center'}}>
          <SuiBox height="100%" display="flex" flexDirection="column">
            <SuiBox height="100%" mt={4} mx={1}>
              <Doughnut data={data} options={options} />
            </SuiBox>
            {renderButton()}
          </SuiBox>
        </Grid>
        <Grid item xs={12} xl={7}>
          {renderItems}
        </Grid>
      </Grid>
    );
  };

  const renderContent = () => {
    if (chart.labels?.length > 0) {
      return renderChart();
    }
    return renderNoPlans();
  };

  return (
    <Card sx={{height: '100%'}}>
      <SuiBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <SuiTypography variant="h6">{title}</SuiTypography>
      </SuiBox>
      <SuiBox position="relative" p={2}>
        {useMemo(renderContent, [chart])}
      </SuiBox>
    </Card>
  );
};

PlanStatisticsChart.defaultProps = {
  tooltip: '',
  action: false,
};

PlanStatisticsChart.propTypes = {
  title: PropTypes.string.isRequired,
  chart: PropTypes.shape({
    images: PropTypes.arrayOf(PropTypes.string),
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    datasets: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string])).isRequired,
  }).isRequired,
  tooltip: PropTypes.string,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(['external', 'internal']).isRequired,
      route: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark', 'light']),
    }),
  ]),
};

export default PlanStatisticsChart;
