import {IntervalGoal, MacrosGoal} from 'pages/coaching/tasks/type-prepare-interval/IntervalGoalTypes';
import * as Yup from 'yup';
import {BaseSchema} from 'yup';
import {DataFormField} from 'components/DataForm/index';
import {DinnerDiningOutlined, ScaleOutlined} from '@mui/icons-material';
import {IntervalGoalSupport} from 'pages/coaching/tasks/type-prepare-interval/IntervalGoalSupportFactory';
import ObjectID from 'bson-objectid';

export class MacrosGoalSupport implements IntervalGoalSupport<MacrosGoal> {
  createFields(goal: IntervalGoal, index: number): DataFormField[] {
    return [
      {
        name: `goals[${index}].min.protein`,
        label: 'Min Protein',
        type: 'number',
        placeholder: '',
        icon: <ScaleOutlined />,
        hint: 'Daily minimum protein intake in grams',
        data: goal.id,
        columns: 3,
        tabIndex: 1,
      },
      {
        name: `goals[${index}].min.carbs`,
        label: 'Min Carbs',
        type: 'number',
        placeholder: '',
        icon: <ScaleOutlined />,
        hint: 'Daily minimum carbs intake in grams',
        data: goal.id,
        columns: 3,
        tabIndex: 3,
      },
      {
        name: `goals[${index}].min.fat`,
        label: 'Min Fat',
        type: 'number',
        placeholder: '',
        icon: <ScaleOutlined />,
        hint: 'Daily minimum fat intake in grams',
        data: goal.id,
        columns: 3,
        tabIndex: 5,
      },
      {
        name: `goals[${index}].min.fiber`,
        label: 'Min Fiber',
        type: 'number',
        placeholder: '',
        icon: <ScaleOutlined />,
        hint: 'Daily minimum fiber intake in grams',
        data: goal.id,
        columns: 3,
        tabIndex: 7,
      },
      {
        name: `goals[${index}].max.protein`,
        label: 'Max Protein',
        type: 'number',
        placeholder: '',
        icon: <ScaleOutlined />,
        hint: 'Daily maximum protein intake in grams',
        data: goal.id,
        columns: 3,
        tabIndex: 2,
      },
      {
        name: `goals[${index}].max.carbs`,
        label: 'Max Carbs',
        type: 'number',
        placeholder: '',
        icon: <ScaleOutlined />,
        hint: 'Daily maximum carbs intake in grams',
        data: goal.id,
        columns: 3,
        tabIndex: 4,
      },
      {
        name: `goals[${index}].max.fat`,
        label: 'Max Fat',
        type: 'number',
        placeholder: '',
        icon: <ScaleOutlined />,
        hint: 'Daily maximum fat intake in grams',
        data: goal.id,
        columns: 3,
        tabIndex: 6,
      },
      {
        name: `goals[${index}].max.fiber`,
        label: 'Max Fiber',
        type: 'number',
        placeholder: '',
        icon: <ScaleOutlined />,
        hint: 'Daily maximum fiber intake in grams',
        data: goal.id,
        columns: 3,
        tabIndex: 8,
      },
    ];
  }

  createGoal(): MacrosGoal {
    return {
      id: ObjectID().toHexString(),
      type: 'macros',
      min: {
        protein: 0,
        fat: 0,
        carbs: 0,
        fiber: 0,
      },
      max: {
        protein: 0,
        fat: 0,
        carbs: 0,
        fiber: 0,
      },
    };
  }

  createValidationSchema(): BaseSchema {
    return Yup.object().shape({
      min: Yup.object().shape({
        protein: Yup.number().positive('Min Protein must be a positive number.').required('Min Protein is required'),
        fat: Yup.number().positive('Min Fat must be a positive number.').required('Min Fat is required'),
        carbs: Yup.number().positive('Min Carbs must be a positive number.').required('Min Carbs is required'),
        fiber: Yup.number().positive('Min Fiber must be a positive number.').required('Min Fiber is required'),
      }),
      max: Yup.object()
        .shape({})
        .when('min', min => {
          return Yup.object().shape({
            protein: Yup.number()
              .positive('Max Protein must be a positive number.')
              .moreThan(min.protein, 'Max protein must be greater than min protein.')
              .required('Max Protein is required'),
            fat: Yup.number()
              .positive('Max Fat must be a positive number.')
              .moreThan(min.fat, 'Max fat must be greater than min fat.')
              .required('Max Fat is required'),
            carbs: Yup.number()
              .positive('Max Carbs must be a positive number.')
              .moreThan(min.carbs, 'Max carbs must be greater than min carbs.')
              .required('Max Carbs is required'),
            fiber: Yup.number()
              .positive('Max Fiber must be a positive number.')
              .moreThan(min.fiber, 'Max fiber must be greater than min fiber.')
              .required('Max Fiber is required'),
          });
        }),
    });
  }

  getIcon(): React.ReactElement {
    return <DinnerDiningOutlined fontSize={'medium'} />;
  }
}
