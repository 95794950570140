/**
 =========================================================
 * Soft UI Dashboard PRO React - v3.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {forwardRef, PropsWithChildren} from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Custom styles for SuiTypography
import SuiTypographyRoot from 'components/SuiTypography/SuiTypographyRoot';

const SuiTypography = forwardRef(
  (
    {color, fontWeight, textTransform, verticalAlign, textGradient, opacity, children, ...rest}: PropsWithChildren<any>,
    ref,
  ) => (
    <SuiTypographyRoot
      {...rest}
      ref={ref}
      ownerState={{color, textTransform, verticalAlign, fontWeight, opacity, textGradient}}>
      {children}
    </SuiTypographyRoot>
  ),
);

// Setting default values for the props of SuiTypography
SuiTypography.defaultProps = {
  color: 'dark',
  fontWeight: false,
  textTransform: 'none',
  verticalAlign: 'unset',
  textGradient: false,
  opacity: 1,
};

// Typechecking props for the SuiTypography
SuiTypography.propTypes = {
  color: PropTypes.string,
  fontWeight: PropTypes.oneOf([false, 'light', 'regular', 'medium', 'bold']),
  textTransform: PropTypes.oneOf(['none', 'capitalize', 'uppercase', 'lowercase']),
  verticalAlign: PropTypes.oneOf([
    'unset',
    'baseline',
    'sub',
    'super',
    'text-top',
    'text-bottom',
    'middle',
    'top',
    'bottom',
  ]),
  textGradient: PropTypes.bool,
  children: PropTypes.node,
  opacity: PropTypes.number,
};

export default SuiTypography;
