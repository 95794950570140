import {Grid, Tab, Tabs} from '@mui/material';
import DashboardLayout from 'layout/DashboardLayout';
import DashboardNavbar from 'layout/navbar/DashboardNavbar';
import Footer from 'layout/footer/Footer';
import AppBar from '@mui/material/AppBar';
import {ArticleOutlined, PostAdd} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';
import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import EditProfileBasicTab from 'pages/me/profile/EditProfileBasicTab';
import EditProfileMediaTab from 'pages/me/profile/EditProfileMediaTab';
import axios from 'axios';
import {UserFile} from 'utils/UserImage';
import {useSetRecoilState} from 'recoil';
import {authenticatedUserState} from 'state/authentication';

export type CoachProfile = {
  fullName?: string;
  biography?: string;
  address?: {
    street?: string;
    city?: string;
    state?: string;
    zip?: string;
    country?: string;
  };
  social?: {
    facebook?: string;
    instagram?: string;
    twitter?: string;
    youtube?: string;
  };
  pictures?: UserFile[];
  introductionVideo?: string;
};

const ProfilePage = ({tab}) => {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(tab);
  const [profile, setProfile] = useState<CoachProfile>({});
  const setAuthenticatedUserState = useSetRecoilState(authenticatedUserState);

  useEffect(() => {
    setTabValue(tab);
    axios
      .get(`/api/v0/profile/coach`)
      .then(response => response.data)
      .catch(error => Promise.reject(error))
      .then(setProfile);
  }, [setProfile, tab]);

  const handleSetTabValue = (event, newValue) => {
    let url = '/me/profile';
    if (newValue === 'media') {
      url += '/media';
    }
    navigate(url);
    setTabValue(newValue);
  };

  const updateProfile = async (profile: CoachProfile) => {
    setProfile(profile);
    const userDetailsResponse = await axios.get('/api/v0/me/details');
    setAuthenticatedUserState(userDetailsResponse.data);
  };

  const renderContent = () => {
    return (
      <Grid container item xs={12}>
        <Grid item xs={12} hidden={tabValue !== 'basic'}>
          <Grid container item xs={12}>
            <EditProfileBasicTab profile={profile} onProfileUpdate={updateProfile} />
          </Grid>
        </Grid>
        <Grid item xs={12} hidden={tabValue !== 'media'}>
          <Grid container item xs={12}>
            <EditProfileMediaTab profile={profile} onProfileUpdate={updateProfile} />
          </Grid>
        </Grid>
      </Grid>
    );
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container>
        <Grid item xs={12} md={6} lg={4}>
          <AppBar position="static">
            <Tabs
              orientation="horizontal"
              value={tabValue}
              onChange={handleSetTabValue}
              sx={{background: 'transparent'}}>
              <Tab value="basic" label="Basic" icon={<ArticleOutlined />} />
              <Tab value="media" label="Media" icon={<PostAdd />} />
            </Tabs>
          </AppBar>
        </Grid>
        <Grid item xs={12} pb={3}>
          {renderContent()}
        </Grid>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
};

ProfilePage.propTypes = {
  tab: PropTypes.string,
};

export default ProfilePage;
