import {QuestionSupportFactory} from 'components/Questionnaire/QuestionSupportFactory';
import {useEffect, useState} from 'react';
import {Question, QuestionType} from 'components/Questionnaire/Questionnaire';
import DataForm, {DataFormField} from 'components/DataForm/index';
import * as Yup from 'yup';
import axios from 'axios';
import QuestionTypeAutoComplete from 'components/Questionnaire/QuestionTypeAutoComplete';
import {HelpOutline} from '@mui/icons-material';
import SuiBox from 'components/SuiBox/index';
import {Tooltip} from '@mui/material';
import Grid from '@mui/material/Grid';
import {Interval} from 'components/IntervalExecutionCard/IntervalTypes';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import {AiOutlineDelete} from 'react-icons/ai';
import SuiTypography from 'components/SuiTypography/index';
import {ErrorMessage} from 'formik';

type DailyQuestionsFormProps = {
  interval: Interval;
  description: string;
  isFirstInterval: boolean;
  onSubmit: (interval: Interval, actions: FormikHelpers<any>) => void;
  leftButton?: React.ReactElement;
  updateInterval: (interval: Interval) => void;
  submitLabel: string;
  formikRef?: React.RefObject<FormikProps<any>>;
};

const DailyQuestionsForm = (props: DailyQuestionsFormProps) => {
  const supportFactory = new QuestionSupportFactory();
  const [questionTypes, setQuestionTypes] = useState<QuestionType[]>([]);
  const [fields, setFields] = useState<DataFormField[]>([
    {
      name: 'questionsDeliveryTime',
      label: 'Delivery Time for daily questions',
      type: 'select',
      options: [
        {label: '~6:00 AM', value: 'MORNING'},
        {label: '~1:00 PM', value: 'AFTERNOON'},
        {label: '~7:00 PM', value: 'EVENING'},
      ],
      placeholder: '',
      columns: 6,
      hint: "The ca. time when the daily questions will be delivered to the user. The time is relative to the user's timezone.",
    },
    {
      type: 'description',
      label: props.description,
      data: 'add-question-container',
    },
  ]);

  const validation = Yup.object().shape({
    questionsDeliveryTime: Yup.string().ensure().required('Delivery time for Questions is required.'),
    questions: Yup.array()
      .ensure()
      .of(
        //@ts-ignore
        Yup.lazy(value => {
          return supportFactory
            .supportFor(value.type)
            .createValidationSchema()
            .concat(
              Yup.object().shape({
                content: Yup.string().required('Content is required'),
              }),
            );
        }),
      ),
  });

  useEffect(() => {
    axios
      .get(`/api/v0/questionnaires/question-types`)
      .then(response => response.data)
      .then(setQuestionTypes)
      .catch(error => Promise.reject(error));
  }, [props.interval]);

  useEffect(() => {
    setFields(fieldsWithQuestions(props.interval.questions));
  }, [props.interval, questionTypes]);

  const fieldsWithQuestions = (questions: Question[]) => {
    const newFields = [...fields].filter(f => !f.name || !f.name.startsWith('questions['));
    updateAddQuestionComponent(newFields);
    if (questionTypes.length > 0) {
      questions.forEach((question, index) => {
        newFields.push(...createCommonQuestionFields(question, index));
        newFields.push(...createTypeSpecificQuestionFields(question, index));
      });
    }
    return newFields;
  };

  const updateAddQuestionComponent = (newFields: DataFormField[]) => {
    newFields.find(f => f.data === 'add-question-container').bottomComponent = context =>
      createAddQuestionComponent(context);
  };

  const createAddQuestionComponent = formikContext => {
    if (props.interval.status === 'DRAFT') {
      return (
        <>
          <SuiTypography component="div" variant="caption" color="error">
            <ErrorMessage name="questions">{error => (typeof error === 'string' ? error : undefined)}</ErrorMessage>
          </SuiTypography>
          <QuestionTypeAutoComplete
            questionTypes={questionTypes}
            readOnly={false}
            onQuestionTypeSelected={t => addQuestion(t, formikContext)}
          />
        </>
      );
    }
  };

  const createCommonQuestionFields = (question: Question, index: number): DataFormField[] => {
    const questionType = questionTypes.find(d => d.type === question.type);
    return [
      {
        name: `questions[${index}].divider`,
        type: 'divider',
      },
      {
        name: `questions[${index}].section`,
        type: 'section',
        label: `Question #${index + 1}: ${questionType.summary}`,
        rightComponent: context => createQuestionControls(question.id, context),
      },
      {
        name: `questions[${index}].description`,
        type: 'description',
        label: questionType.description,
      },
      {
        name: `questions[${index}].type`,
        type: 'hidden',
        value: question.type,
        data: question.id,
      },
      {
        name: `questions[${index}].id`,
        type: 'hidden',
        value: question.id,
        data: question.id,
      },
      {
        name: `questions[${index}].content`,
        label: 'Question',
        type: 'text',
        placeholder: '',
        icon: <HelpOutline />,
        data: question.id,
      },
    ];
  };

  const createTypeSpecificQuestionFields = (question: Question, index: number): DataFormField[] => {
    return supportFactory.supportFor(question.type).createFields(question, index);
  };

  const createQuestionControls = (questionId, formikContext) => {
    return (
      <SuiBox ml={1} sx={{display: props.interval.status === 'DRAFT' ? 'flex' : 'none', alignItems: 'center', mt: 1}}>
        <Tooltip title="Delete Question">
          <span>
            <AiOutlineDelete
              style={{color: '#8392ab', cursor: 'pointer'}}
              onClick={() => removeQuestion(questionId, formikContext)}
            />
          </span>
        </Tooltip>
      </SuiBox>
    );
  };

  const addQuestion = (questionType: QuestionType, formikContext) => {
    const {values} = formikContext;
    const newInterval = structuredClone(values);
    const question = supportFactory.supportFor(questionType.type).createQuestion();
    newInterval.questions.push(question);
    props.updateInterval(newInterval);
  };

  const removeQuestion = (questionId, formikContext) => {
    const {values} = formikContext;
    const indexOfQuestion = values.questions.findIndex(g => g.id === questionId);
    const newInterval = structuredClone(values);
    newInterval.questions.splice(indexOfQuestion, 1);
    props.updateInterval(newInterval);
  };

  return (
    <>
      <Grid container item xs={12}>
        <Grid item xs={12} lg={12}>
          <DataForm
            validation={validation}
            onSubmit={props.onSubmit}
            fields={fields}
            initialValues={props.interval}
            readOnly={props.interval.status !== 'DRAFT'}
            hideButtons={props.interval.status !== 'DRAFT'}
            submitLabel={props.submitLabel}
            leftButton={props.leftButton}
            formikRef={props.formikRef}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DailyQuestionsForm;
