import {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import {Page} from 'components/BootPagination';
import NotificationsList, {UserNotification} from 'pages/me/notifications/NotificationsList';
import axios from 'axios';
import DashboardLayout from 'layout/DashboardLayout';
import DashboardNavbar from 'layout/navbar/DashboardNavbar';
import Footer from 'layout/footer/Footer';
import {useRecoilState} from 'recoil';
import {unreadNotificationState} from 'state/notification';
import {subscribe} from 'components/Firebase/FirebaseMessaging';
import {updateUnreadNotifications} from 'pages/notifictions/NotificationOrchestrator';

const NotificationsPage = () => {
  const [page, setPage] = useState<Page<UserNotification>>({content: []} as Page<UserNotification>);
  const [pageNumber, setPageNumber] = useState(0);
  const [unreadNotifications, setUnreadNotifications] = useRecoilState(unreadNotificationState);

  useEffect(() => {
    updateUnreadNotifications(count => setUnreadNotifications(count));
  }, []);

  useEffect(() => {
    loadNotifications();
    const unsubscribe = subscribe(loadNotifications);
    return () => {
      markAllRead();
      unsubscribe();
    };
  }, [pageNumber]);

  const loadNotifications = () => {
    axios
      .get(`/api/v0/notifications?page=${pageNumber}&size=10&sort=read,asc&sort=auditMetadata.lastModified,desc`)
      .then(response => response.data)
      .then(setPage)
      .catch(error => Promise.reject(error));
  };

  const onToggleNotificationRead = async (notification: UserNotification) => {
    try {
      const response = await axios.patch(`/api/v0/notifications/${notification.id}`, {read: !notification.read});
      const n = response.data;
      page.content.find(notification => notification.id === n.id).read = n.read;
      setPage({...page});
      updateNotificationCount(notification);
    } catch (error) {
      alert(error);
    }
  };

  const updateNotificationCount = (notification: UserNotification) => {
    if (notification.read) {
      setUnreadNotifications(unreadNotifications - 1);
    } else {
      setUnreadNotifications(unreadNotifications + 1);
    }
  };

  const onDeleteNotification = async (notification: UserNotification) => {
    try {
      await axios.delete(`/api/v0/notifications/${notification.id}`);
      const index = page.content.findIndex(n => n.id === notification.id);
      page.content.splice(index, 1);
      page.totalElements--;
      setPage({...page});
      if (!notification.read) {
        setUnreadNotifications(unreadNotifications - 1);
      }
    } catch (error) {
      alert(error);
    }
  };

  const markAllRead = async () => {
    try {
      await axios.patch(`/api/v0/notifications`, {read: true});
      page.content.forEach(notification => (notification.read = true));
      setPage({...page});
      setUnreadNotifications(0);
    } catch (error) {
      alert(error);
    }
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <Grid container>
          <Grid container item xs={12} xl={12} mt={{xs: 1, lg: 3}} mb={1}>
            <Grid container item xs={12} rowSpacing={3}>
              <Grid item xs={12}>
                <NotificationsList
                  {...{
                    title: 'Notifications',
                    size: page.totalElements,
                    page: page,
                    onPageChange: setPageNumber,
                    onToggleNotificationRead: onToggleNotificationRead,
                    onDeleteNotification: onDeleteNotification,
                    onMarkAllRead: markAllRead,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Footer />
      </DashboardLayout>
    </>
  );
};

export default NotificationsPage;
