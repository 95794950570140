import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';

import Card from '@mui/material/Card';

import SuiBox from 'components/SuiBox/index';
import SuiTypography from 'components/SuiTypography/index';
import CalendarRoot from 'components/Calendar/CalendarRoot.jsx';
import {CalendarOptions} from '@fullcalendar/core';
import SuiAvatar from 'components/SuiAvatar/index';
import * as React from 'react';
import {MdOutlineCalendarToday} from 'react-icons/md';

export type CalendarEvent = {
  title: string;
  start: string;
  end?: string;
  className?: string;
  [key: string]: any;
};

export type CalendarProps = CalendarOptions & {
  events: CalendarEvent[];
  header: {
    title?: string;
    date?: string;
  };
};

const Calendar = (props: CalendarProps) => {
  const validClassNames = ['primary', 'secondary', 'info', 'success', 'warning', 'error', 'light', 'dark'];
  const {header, events, ...calendarOptions} = props;

  const styledEvents = events
    ? events.map(el => ({
        ...el,
        className:
          validClassNames.find(item => item === el.className) && !calendarOptions.initialView.startsWith('list')
            ? `event-${el.className}`
            : 'event-light',
      }))
    : [];

  const renderEventContent = (eventInfo: any) => {
    return (
      <>
        <SuiBox
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          flexDirection="row"
          p={0.1}
          borderRadius="lg"
          bgcolor={eventInfo.event?.backgroundColor}
          color={eventInfo.event?.textColor}>
          <SuiAvatar
            src={encodeURI(eventInfo.event.extendedProps.picture)}
            bgColor={'light'}
            alt={eventInfo.event.title}
            variant="circle"
            size="xs"
            shadow="sm"
          />
          <SuiTypography variant="button" fontWeight="regular" ml={1}>
            {eventInfo.event.title}
          </SuiTypography>
        </SuiBox>
      </>
    );
  };

  return (
    <Card sx={{height: '100%'}}>
      <SuiBox pt={2} px={2} lineHeight={1}>
        {header.title ? (
          <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {header.title}
          </SuiTypography>
        ) : null}
        {header.date ? (
          <SuiTypography component="p" variant="button" color="text" fontWeight="medium">
            {header.date}
          </SuiTypography>
        ) : null}
      </SuiBox>
      <CalendarRoot p={2}>
        <FullCalendar
          {...calendarOptions}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
          events={styledEvents}
          height="100%"
          eventContent={renderEventContent}
          noEventsContent={
            <SuiBox display="flex" sx={{justifyContent: 'center', alignItems: 'center', flexDirection: 'row', pb: 2}}>
              <MdOutlineCalendarToday fontSize={20} color={'#d2d2d2'} />
              <SuiTypography variant="h6" fontWeight="medium" color={'#d2d2d2'} sx={{ml: 1}}>
                no check-ins scheduled
              </SuiTypography>
            </SuiBox>
          }
          allDaySlot={false}
          displayEventTime={false}
        />
      </CalendarRoot>
    </Card>
  );
};

export default Calendar;
