import {useEffect, useState} from 'react';
import {Client as TwilioClient, Message} from '@twilio/conversations';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {authenticatedUserState} from 'state/authentication';
import {TwilioController, twilioControllerState} from 'components/Chat/twilio/TwilioController';
import axios from 'axios';
import {clientsState} from 'state/clients';

const TwilioSession = () => {
  const [twilioToken, setTwilioToken] = useState<string>();
  const authenticatedUser = useRecoilValue(authenticatedUserState);
  const setClients = useSetRecoilState(clientsState);

  const setTwilioController = useSetRecoilState(twilioControllerState);

  useEffect(() => {
    if (twilioToken) {
      const twilioClient = new TwilioClient(twilioToken);

      twilioClient.on('tokenAboutToExpire', async () => {
        const newToken = await fetchTwilioToken();
        await twilioClient.updateToken(newToken);
      });

      twilioClient.on('tokenExpired', async () => {
        const newToken = await fetchTwilioToken();
        setTwilioToken(newToken);
      });

      twilioClient.on('connectionStateChanged', async state => {
        if (state === 'connected') {
          setTwilioController(new TwilioController(twilioClient, authenticatedUser));
        } else {
          setTwilioController(null);
        }
        console.log(`Twilio connection state changed to ${state}`);
      });

      twilioClient.on('messageAdded', async (message: Message) => {
        if (message.conversation.notificationLevel === 'default') {
          setClients(clients => {
            return clients?.map(client => {
              if (client.client.userId === message.author) {
                return {client: client.client, unreadMessages: client.unreadMessages + 1};
              }
              return client;
            });
          });
        }
      });

      return () => {
        twilioClient?.removeAllListeners();
      };
    }
  }, [twilioToken]);

  useEffect(() => {
    const load = async () => {
      const token = await fetchTwilioToken();
      setTwilioToken(token);
    };
    load();
  }, []);

  const fetchTwilioToken = async (): Promise<string> => {
    const response = await axios.get(`/api/v0/messages/twilio/token`);
    return await response.data;
  };

  return <></>;
};

export default TwilioSession;
