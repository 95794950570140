import {HydrationGoal, IntervalGoal} from 'pages/coaching/tasks/type-prepare-interval/IntervalGoalTypes';
import * as Yup from 'yup';
import {BaseSchema} from 'yup';
import {DataFormField} from 'components/DataForm/index';
import {IntervalGoalSupport} from 'pages/coaching/tasks/type-prepare-interval/IntervalGoalSupportFactory';
import {MdOutlineLocalDrink, MdOutlineWaterDrop} from 'react-icons/md';
import ObjectID from 'bson-objectid';

export class HydrationGoalSupport implements IntervalGoalSupport<HydrationGoal> {
  createFields(goal: IntervalGoal, index: number): DataFormField[] {
    return [
      {
        name: `goals[${index}].minHydration.value`,
        label: 'Min Intake',
        type: 'number',
        placeholder: '',
        icon: <MdOutlineWaterDrop />,
        hint: 'Minimum amount of water to drink',
        data: goal.id,
        columns: 3,
      },
      {
        name: `goals[${index}].minHydration.unit`,
        label: 'Unit',
        type: 'select',
        options: [
          {label: 'ml', value: 'MILLILITER'},
          {label: 'oz', value: 'OUNCE'},
        ],
        placeholder: '',
        hint: 'The unit the minimum amount is measured in',
        data: goal.id,
        columns: 3,
      },
    ];
  }

  createGoal(): HydrationGoal {
    return {
      id: ObjectID().toHexString(),
      type: 'hydration',
      minHydration: {
        value: 0,
        unit: 'OUNCE',
      },
    };
  }

  createValidationSchema(): BaseSchema {
    return Yup.object().shape({
      minHydration: Yup.object().shape({
        value: Yup.number().positive('Min Intake must be a positive number.').required('Min Intake is required'),
        unit: Yup.string()
          .test('unit', 'Unit must be ml or oz', v => {
            return v === 'MILLILITER' || v === 'OUNCE';
          })
          .required('Unit is required'),
      }),
    });
  }

  getIcon(): React.ReactElement {
    return <MdOutlineLocalDrink fontSize={'1.4rem'} />;
  }
}
