/**
 =========================================================
 * Soft UI Dashboard PRO React - v3.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

export default styled(Box)(({theme}) => {
  const {palette, typography, borders} = theme;

  const {text, dark, inputColors} = palette;
  const {size} = typography;
  const {borderRadius, borderWidth} = borders;

  return {
    border: `${borderWidth[1]} dashed ${inputColors.borderColor.main} !important`,
    borderRadius: borderRadius.md,

    '& .filepond--item': {
      width: 'calc(25% - 0.5em)',
    },

    '& .filepond--root': {
      color: `${text.main} !important`,
      fontSize: `${size.sm} !important`,
    },

    '& .filepond--file ': {
      fontSize: `${size.sm} !important`,
    },

    '& .filepond--panel-root': {
      backgroundColor: 'white',
    },

    '& .filepond--image-preview': {
      backgroundColor: `${dark.main} !important`,
    },

    '& .filepond--item-panel': {
      backgroundColor: `${dark.main} !important`,
    },
  };
});
