export default class ProgressColor {
  constructor(private progress: number) {}

  public color(): 'inherit' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' {
    if (this.progress >= 0.9) {
      return 'success';
    } else if (this.progress >= 0.6) {
      return 'warning';
    }
    return 'error';
  }
}
