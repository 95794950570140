export type UserFile = {
  id: string;
  name: string;
  location: string;
  size: number;
  contentType: string;
  visibility: 'PRIVATE' | 'PUBLIC';
};

const imageSrc = (userFile: UserFile) => {
  if (userFile.location.startsWith('http://') || userFile.location.startsWith('https://')) {
    return userFile.location;
  }
  return process.env.REACT_APP_API_URL + userFile.location;
};

export default imageSrc;
