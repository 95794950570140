import Grid from '@mui/material/Grid';

import DashboardLayout from 'layout/DashboardLayout';
import DashboardNavbar from 'layout/navbar/DashboardNavbar';
import Footer from 'layout/footer/Footer';

import {useEffect, useState} from 'react';
import {Page} from 'components/BootPagination/index';
import {Plan} from 'pages/coaching/plans/PlanTypes';
import axios from 'axios';
import SuiBox from 'components/SuiBox/index';
import PlanCard from 'pages/coaching/plans/PlanCard';
import NewPlanCard from 'pages/coaching/plans/NewPlanCard';
import {useNavigate} from 'react-router-dom';

const PlansPage = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState<Page<Plan>>({content: []});
  const [inCreation, setInCreation] = useState<boolean>(false);

  useEffect(() => {
    axios
      .get(`/api/v0/plans`)
      .then(response => response.data)
      .then(setPage)
      .catch(error => Promise.reject(error));
  }, []);

  const deletePlan = planId => {
    const newPage = {...page};
    newPage.content = newPage.content.filter(plan => plan.id !== planId);
    setPage(newPage);
  };

  const createPlan = () => {
    setInCreation(true);
    axios
      .post(`/api/v0/plans`, {})
      .then(response => response.data)
      .then(plan => {
        navigate(`/coaching/plans/${plan.id}/edit`);
      })
      .catch(error => Promise.reject(error));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container item xs={12} pb={3}>
        <SuiBox mt={{xs: 1, lg: 3}} mb={1} sx={{width: '100%'}}>
          <Grid container item spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <NewPlanCard title={{variant: 'h5', text: 'New Plan'}} onClick={createPlan} disabled={inCreation} />
            </Grid>
            {page.content
              ? page.content.map((plan, index) => (
                  <Grid item xs={12} md={6} lg={4} key={index}>
                    <PlanCard plan={plan} deletePlan={deletePlan} />
                  </Grid>
                ))
              : null}
          </Grid>
        </SuiBox>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
};

export default PlansPage;
