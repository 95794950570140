import Grid from '@mui/material/Grid';

import SuiBox from 'components/SuiBox';
import PricingCard from 'pages/me/subscription/PricingCard';
import {useEffect, useState} from 'react';
import {useRecoilValue} from 'recoil';
import {
  CoachSubscriptionCadence,
  CoachSubscriptionPlan,
  coachSubscriptionState,
} from 'components/Paywall/coachSubscription';

export type PricingCardsProps = {
  cadence: CoachSubscriptionCadence;
};

type PriceConfiguration = {
  basic: string;
  committed: string;
  allIn: string;
};

const monthlyPriceConfig: PriceConfiguration = {
  basic: '40',
  committed: '100',
  allIn: '150',
};

const yearlyPriceConfig: PriceConfiguration = {
  basic: '420',
  committed: '1,080',
  allIn: '1,560',
};

const PricingCards = (props: PricingCardsProps) => {
  const subscription = useRecoilValue(coachSubscriptionState);
  const [priceConfig, setPriceConfig] = useState<PriceConfiguration>(monthlyPriceConfig);

  useEffect(() => {
    if (props.cadence === 'MONTHLY') {
      setPriceConfig(monthlyPriceConfig);
    } else {
      setPriceConfig(yearlyPriceConfig);
    }
  }, [props.cadence]);

  const getLabel = (plan: CoachSubscriptionPlan) => {
    if (subscription.plan === plan) {
      if (plan === 'FREE') {
        return `You're on it`;
      } else if (subscription.cadence === props.cadence) {
        return `You're on it`;
      }
    }
    if (plan === 'FREE') {
      return 'Downgrade';
    }
    return 'Subscribe';
  };

  const getColor = (plan: CoachSubscriptionPlan) => {
    if (subscription.plan === plan) {
      if (plan === 'FREE') {
        return 'success';
      } else if (subscription.cadence === props.cadence) {
        return 'success';
      }
    }
    return 'primary';
  };

  const getEnablement = (plan: CoachSubscriptionPlan) => {
    if (subscription.plan === plan) {
      if (plan === 'FREE') {
        return false;
      } else if (subscription.cadence === props.cadence) {
        return false;
      }
    }
    return true;
  };

  return (
    <SuiBox position="relative" zIndex={10} mt={3} px={{xs: 1, sm: 0}}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} lg={3}>
          <PricingCard
            badge={{color: 'secondary', label: 'FREE'}}
            price={{currency: '$', value: '0'}}
            specifications={[
              {label: 'Up to 1 active client', includes: true},
              {label: 'All features included', includes: true},
            ]}
            action={{
              type: 'internal',
              route: `/me/subscriptions/subscribe/FREE/${props.cadence}`,
              color: getColor('FREE'),
              label: getLabel('FREE'),
              enabled: getEnablement('FREE'),
              confirm: {
                title: 'Are you sure?',
                description: `Switching to the free plan will retain your client's information. If you wish to add more clients in the future, you'll need to upgrade your account to access their data. Before downgrading, make sure to cancel all active client subscriptions to facilitate the downgrade process.`,
                confirmText: 'Yes, downgrade',
              },
            }}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <PricingCard
            badge={{color: 'secondary', label: 'BASIC'}}
            price={{currency: '$', value: priceConfig.basic}}
            specifications={[
              {label: 'Up to 5 active clients', includes: true},
              {label: 'All features included', includes: true},
            ]}
            action={{
              type: 'internal',
              route: `/me/subscriptions/subscribe/BASIC/${props.cadence}`,
              color: getColor('BASIC'),
              label: getLabel('BASIC'),
              enabled: getEnablement('BASIC'),
            }}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <PricingCard
            badge={{color: 'secondary', label: 'COMMITTED'}}
            price={{currency: '$', value: priceConfig.committed}}
            specifications={[
              {label: 'Up to 20 active clients', includes: true},
              {label: 'All features included', includes: true},
            ]}
            action={{
              type: 'internal',
              route: `/me/subscriptions/subscribe/COMMITTED/${props.cadence}`,
              color: getColor('COMMITTED'),
              label: getLabel('COMMITTED'),
              enabled: getEnablement('COMMITTED'),
            }}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <PricingCard
            badge={{color: 'secondary', label: 'ALL IN'}}
            price={{currency: '$', value: priceConfig.allIn}}
            specifications={[
              {label: 'Up to 50 active clients', includes: true},
              {label: 'All features included', includes: true},
            ]}
            action={{
              type: 'internal',
              route: `/me/subscriptions/subscribe/ALL_IN/${props.cadence}`,
              color: getColor('ALL_IN'),
              label: getLabel('ALL_IN'),
              enabled: getEnablement('ALL_IN'),
            }}
          />
        </Grid>
      </Grid>
    </SuiBox>
  );
};

export default PricingCards;
