import {useEffect, useState} from 'react';
import axios from 'axios';
import BootPagination, {Page} from 'components/BootPagination/index';
import {IntervalExecution} from 'components/IntervalExecutionCard/IntervalTypes';
import {UserDetails} from 'pages/coaching/tasks/TaskTypes';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import IntervalExecutionView from 'components/IntervalExecutionCard/IntervalExecutionView';
import Badge from '@mui/material/Badge';
import SuiBox from 'components/SuiBox/index';
import {FaRegSadTear} from 'react-icons/fa';
import SuiTypography from 'components/SuiTypography/index';
import * as React from 'react';
import Paywall from 'components/Paywall/Paywall';

type IntervalExecutionBrowserProps = {
  subscriptionId: string;
  intervalNumber: number;
  client: UserDetails;
  showLabels: boolean;
};

const IntervalExecutionBrowser = (props: IntervalExecutionBrowserProps) => {
  const [page, setPage] = useState<Page<IntervalExecution>>({content: []} as Page<IntervalExecution>);
  const [pageNumber, setPageNumber] = useState(props.intervalNumber - 1);

  useEffect(() => {
    const load = async () => {
      let pageToLoad = pageNumber;
      let loadedPage = await loadPage(pageToLoad);
      while (pageToLoad > 0 && loadedPage.empty) {
        pageToLoad = pageToLoad - 1;
        loadedPage = await loadPage(pageToLoad);
      }
      setPageNumber(pageToLoad);
      setPage(loadedPage);
    };
    load();
  }, [pageNumber, props.subscriptionId]);

  const loadPage = async (pageNumberToLoad: number) => {
    try {
      const response = await axios.get(
        `/api/v0/interval-executions?size=1&page=${pageNumberToLoad > 0 ? pageNumberToLoad : 0}&subscription=${
          props.subscriptionId
        }&sort=interval.number,asc`,
      );
      return await response.data;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setPageNumber(props.intervalNumber - 1);
  }, [props.intervalNumber]);

  const renderTitleBadge = () => {
    if (props.showLabels) {
      if (pageNumber == props.intervalNumber) {
        return <Badge badgeContent={'current'} color="primary" sx={{ml: 4, mt: 1}} />;
      } else if (pageNumber === props.intervalNumber - 1) {
        return <Badge badgeContent={'previous'} color="warning" sx={{ml: 4, mt: 1}} />;
      } else if (pageNumber < props.intervalNumber) {
        return <Badge badgeContent={'older'} color="warning" sx={{ml: 4, mt: 1}} />;
      } else if (pageNumber === props.intervalNumber + 1) {
        return <Badge badgeContent={'next'} color="info" sx={{ml: 4, mt: 1}} />;
      } else {
        return <Badge badgeContent={'newer'} color="info" sx={{ml: 4, mt: 1}} />;
      }
    }
  };

  const renderContent = () => {
    if (page.content.length > 0) {
      return (
        <IntervalExecutionView
          intervalExecution={page.content[0]}
          client={props.client}
          titleComponent={renderTitleBadge()}
        />
      );
    } else {
      return renderUnfinishedCheckin();
    }
  };

  const renderUnfinishedCheckin = () => {
    return (
      <Grid container>
        <Grid item xs={12} py={3}>
          <SuiBox display="flex" sx={{justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
            <FaRegSadTear fontSize={150} color={'#e9ecef'} />
            <SuiTypography variant="h6" fontWeight="medium" sx={{mt: 2}} color={'secondary'}>
              Check-In not finished due to previous cancellation
            </SuiTypography>
          </SuiBox>
        </Grid>
      </Grid>
    );
  };

  const renderPagination = () => {
    if (page?.totalPages > 1) {
      return (
        <Grid container direction="row" justifyContent="center" alignItems="center" sx={{p: 3}}>
          <BootPagination page={page} onClick={setPageNumber} />
        </Grid>
      );
    }
  };

  return (
    <>
      <Paywall>
        <Card sx={{minHeight: 350}}>
          <Grid container>
            <Grid container item xs={12} justifyContent="center" alignItems="center">
              {renderContent()}
            </Grid>
            {renderPagination()}
          </Grid>
        </Card>
      </Paywall>
    </>
  );
};

export default IntervalExecutionBrowser;
