import * as React from 'react';
import {PaymentProviderStatus} from 'pages/me/settings/payment/PaymentSettingsTab';
import Badge from '@mui/material/Badge';

export type PaymentProviderStatusBadgeProps = {
  status: PaymentProviderStatus;
};

const PaymentProviderStatusBadge = (props: PaymentProviderStatusBadgeProps) => {
  const determineBadgeColor = (status: PaymentProviderStatus) => {
    switch (status) {
      case 'CONNECTED':
        return 'primary';
      case 'PENDING':
        return 'warning';
      case 'DISCONNECTED':
        return 'secondary';
      case 'INVALID':
        return 'error';
    }
  };

  return (
    <>
      <Badge
        badgeContent={props.status?.toLowerCase()}
        color={determineBadgeColor(props.status)}
        sx={{
          '& .MuiBadge-badge': {
            color: '#ffffff',
          },
        }}
      />
    </>
  );
};

export default PaymentProviderStatusBadge;
