import * as React from 'react';
import {useEffect, useState} from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Table from 'components/SuiTable/index';
import BootPagination, {Page} from 'components/BootPagination/index';
import SuiBox from 'components/SuiBox/index';
import {FaStripe} from 'react-icons/fa';
import {PaymentProvider} from 'pages/me/settings/payment/PaymentSettingsTab';
import {useTheme} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import SuiTypography from 'components/SuiTypography/index';
import Divider from '@mui/material/Divider';
import SuiButton from 'components/SuiButton/index';
import {VscDebugDisconnect} from 'react-icons/vsc';
import PaymentProviderIcon from 'pages/me/settings/payment/PaymentProviderIcon';
import PaymentProviderStatusBadge from 'pages/me/settings/payment/PaymentProviderStatusBadge';

export type PaymentProviderTableProps = {
  onConnect: () => void;
  onReconnect: (providerId: string) => void;
};

const PaymentProviderTable = (props: PaymentProviderTableProps) => {
  const {palette} = useTheme();
  const navigate = useNavigate();
  const [page, setPage] = useState<Page<PaymentProvider>>();
  const [pageNumber, setPageNumber] = useState(0);
  const [canConnect, setCanConnect] = useState(true);

  const columns = [
    {name: 'provider', align: 'left', width: '100px'},
    {name: 'description', align: 'left'},
    {name: 'status', align: 'center'},
    {name: 'actions', align: 'center'},
  ];

  useEffect(() => {
    const load = async () => {
      const response = await axios.get(`/api/v0/payment/providers?page=${pageNumber}&size=10&sort=created,DESC`);
      setPage(response.data);
    };
    load();
  }, []);

  const createActions = (provider: PaymentProvider) => {
    if (provider.status === 'PENDING') {
      return (
        <>
          <SuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <VscDebugDisconnect
              title={'Reconnect'}
              size={20}
              color={palette.primary.main}
              style={{cursor: 'pointer'}}
              onClick={e => {
                e.stopPropagation();
                props.onReconnect(provider.id);
              }}
            />
          </SuiBox>
        </>
      );
    }
  };

  const createRows = () =>
    page?.content?.map(provider => ({
      provider: (
        <>
          <SuiBox sx={{display: 'flex', justifyContent: 'center', height: '100%', paddingTop: 0.6}}>
            <PaymentProviderIcon providerType={provider.details.type} fontSize={40} />
          </SuiBox>
        </>
      ),
      description: provider.description,
      status: <PaymentProviderStatusBadge status={provider.status} />,
      actions: createActions(provider),
      onClick: () => {
        navigate(`/me/settings/payment/${provider.id}`);
      },
    }));

  const renderProviders = () => {
    return (
      <Grid container mt={{xs: 1, lg: 3}} mb={1}>
        <Grid item xs={12}>
          <Table columns={columns} rows={createRows()} tableStyles={{boxShadow: 'none'}} />
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center" sx={{p: 3}}>
          <BootPagination page={page} onClick={setPageNumber} />
        </Grid>
      </Grid>
    );
  };

  const renderNoProviders = () => {
    return (
      <Grid container>
        <Grid item xs={12} pb={3}>
          <Divider />
          <SuiBox display="flex" sx={{justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
            <FaStripe fontSize={100} color={'#e9ecef'} />
            <SuiTypography variant="h6" fontWeight="medium" sx={{mt: 2}} color={'secondary'}>
              <SuiButton
                color={'primary'}
                size={'small'}
                disabled={!canConnect}
                onClick={() => {
                  setCanConnect(false);
                  props.onConnect();
                }}
                sx={{minWidth: '0px'}}>
                <VscDebugDisconnect color={'#ffffff'} style={{marginRight: 3}} />
                Connect
              </SuiButton>
            </SuiTypography>
          </SuiBox>
        </Grid>
      </Grid>
    );
  };

  const renderContent = () => {
    if (page?.content?.length === 0) {
      return renderNoProviders();
    }
    return renderProviders();
  };

  return <>{renderContent()}</>;
};

export default PaymentProviderTable;
