import {EditPlanProps} from 'pages/coaching/plans/edit/EditPlan.types';
import QuestionnaireForm from 'components/Questionnaire/QuestionnaireForm';
import {useEffect, useState} from 'react';
import {Questionnaire} from 'components/Questionnaire/Questionnaire';
import {CoachingFeature, Plan} from 'pages/coaching/plans/PlanTypes';
import SuiBox from 'components/SuiBox/index';

interface InitialQuestionnaireTemplateFeature extends CoachingFeature {
  template: Questionnaire;
}

const EditInitialQuestionnaireTemplateTab = (props: EditPlanProps) => {
  const findTemplateFeature = (plan: Plan): InitialQuestionnaireTemplateFeature => {
    if (plan.coachingFeatures == null) {
      plan.coachingFeatures = [];
    }
    let feature = plan.coachingFeatures.find(feature => feature.type === 'feature-initial-questionnaire-template');
    return feature as InitialQuestionnaireTemplateFeature;
  };

  const initialTemplate = (): Questionnaire => {
    let feature = findTemplateFeature(props.plan);
    if (feature) {
      return {
        id: 'template',
        description: feature.template.description,
        questions: feature.template.questions,
        status: 'DRAFT',
      };
    }
    return {
      id: 'template',
      description: '',
      questions: [],
      status: 'DRAFT',
    };
  };

  useEffect(() => {
    setQuestionnaireTemplate(initialTemplate());
  }, [props.plan]);

  const [questionnaireTemplate, setQuestionnaireTemplate] = useState<Questionnaire>(() => initialTemplate());

  const onSaveTemplate = async (values: Questionnaire, helpers) => {
    const plan = {...props.plan};
    let feature = findTemplateFeature(plan);
    if (feature) {
      feature.template = values;
    } else {
      plan.coachingFeatures.push({
        type: 'feature-initial-questionnaire-template',
        template: values,
      } as InitialQuestionnaireTemplateFeature);
    }
    await props.onSubmit(plan, helpers);
    setQuestionnaireTemplate(values);
  };

  return (
    <>
      <SuiBox pt={2}>
        <QuestionnaireForm
          title={'Initial Questionnaire Template'}
          description={
            'When clients subscribe to this plan you need to create an initial questionnaire for the new subscriber to kick off the ' +
            'coaching. With an initial questionnaire template you can define questions that will be used to create the initial questionnaire ' +
            "for the new subscriber. With this you don't need to repeat the same questions for every new subscriber. When creating the " +
            'questionnaire you will still have the option to add or delete questions before publishing.'
          }
          submitLabel={'Save'}
          questionDescription={'Add your questions below.'}
          questionnaire={questionnaireTemplate}
          onSubmit={onSaveTemplate}
        />
      </SuiBox>
    </>
  );
};

export default EditInitialQuestionnaireTemplateTab;
