import Grid from '@mui/material/Grid';
import {UserDetails} from 'pages/coaching/tasks/TaskTypes';
import SuiTypography from 'components/SuiTypography/index';
import {SleepGoal} from 'pages/coaching/tasks/type-prepare-interval/IntervalGoalTypes';
import {GiNightSleep} from 'react-icons/gi';
import IntervalGoalCard from 'components/IntervalExecutionCard/goals-results/IntervalGoalCard';
import Hint from 'components/Hint/Hint';

type SleepGoalViewProps = {
  goal: SleepGoal;
  client: UserDetails;
};

const SleepGoalView = (props: SleepGoalViewProps) => {
  return (
    <>
      <SuiTypography variant="button" fontWeight="regular" color={'text'}>
        You defined the following minimum hours of sleep for {props.client.name}.
        <Hint
          hint={
            'Green values are exceeding the minimum sleep hours, yellow values are below the minimum, and red values are missing.'
          }
        />
      </SuiTypography>
      <Grid container item xs={12} rowSpacing={3} sx={{pt: 1, pb: 1}}>
        <Grid item xs={12} md={4} pr={{xs: 0, md: 0, lg: 3}}>
          <IntervalGoalCard
            icon={<GiNightSleep />}
            color="dark"
            type="Min Hours"
            value={
              <>
                <span style={{color: '#439946'}}>{props.goal.minHours}h</span>
              </>
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SleepGoalView;
