import * as Yup from 'yup';
import {BaseSchema} from 'yup';
import {DataFormField} from 'components/DataForm/index';
import {TextFieldsOutlined} from '@mui/icons-material';
import {QuestionSupport} from 'components/Questionnaire/QuestionSupportFactory';
import {
  CompoundAnswer,
  StringAnswer,
  StringAnswerStatistics,
  StringQuestion,
} from 'components/Questionnaire/Questionnaire';
import SuiTypography from 'components/SuiTypography/index';
import AnswerTable from 'components/Questionnaire/types/AnswerTable';
import ObjectID from 'bson-objectid';

export class StringQuestionSupport implements QuestionSupport<StringQuestion, StringAnswer, StringAnswerStatistics> {
  createFields(): DataFormField[] {
    return [];
  }

  createQuestion(): StringQuestion {
    return {id: ObjectID().toHexString(), content: '', type: 'string'};
  }

  createValidationSchema(): BaseSchema {
    return Yup.object().shape({});
  }

  getIcon(): React.ReactElement {
    return <TextFieldsOutlined fontSize={'medium'} />;
  }

  renderAnswer(answer: StringAnswer): React.ReactElement {
    return (
      <SuiTypography variant="button" fontWeight="regular">
        {answer.content}
      </SuiTypography>
    );
  }

  renderCompoundAnswer(
    answer: CompoundAnswer<StringQuestion, StringAnswer, StringAnswerStatistics>,
  ): React.ReactElement {
    return <AnswerTable answer={answer} labelProvider={(a: StringAnswer) => (a.content ? a.content : '')} />;
  }
}
