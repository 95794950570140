import {UserDetails} from 'pages/coaching/tasks/TaskTypes';
import SuiTypography from 'components/SuiTypography/index';
import Grid from '@mui/material/Grid';
import Table from 'components/SuiTable';
import moment from 'moment/moment';
import {HydrationGoalResult, HydrationResult} from 'components/IntervalExecutionCard/IntervalTypes';
import {Hydration, HydrationUnit} from 'pages/coaching/tasks/type-prepare-interval/IntervalGoalTypes';

type HydrationGoalResultViewProps = {
  result: HydrationGoalResult;
  client: UserDetails;
};

const HydrationGoalResultView = (props: HydrationGoalResultViewProps) => {
  const columns = [
    {name: 'day', align: 'left'},
    {name: 'hydration', align: 'center'},
  ];

  const unit = (hydration: Hydration) => {
    if (hydration.unit === 'MILLILITER') {
      return 'ml';
    }
    return 'oz';
  };

  const createRows = () => {
    const hydrations = new Map<string, HydrationResult>(Object.entries(props.result.hydrations));
    const rows = [];
    hydrations.forEach((value, key) => {
      rows.push({
        key: new Date(key),
        day: moment(key).format('dddd, MM-DD-YYYY'),
        hydration: createValuesComponent(value, props.result.goal.minHydration, unit(value)),
      });
    });
    rows.sort((a, b) => (a.key > b.key ? 1 : -1));
    rows.push(createAverageRow(hydrations, props.result.goal.minHydration));
    return rows;
  };

  const createAverageRow = (hydrations: Map<string, Hydration>, minHydration: Hydration) => {
    const values = Array.from(hydrations.values());
    return {
      day: <b>Daily Average</b>,
      hydration: createValuesComponent(
        {
          value: average(values, minHydration.unit),
          unit: minHydration.unit,
        },
        minHydration,
        unit(minHydration),
        'bold',
      ),
    };
  };

  const createValuesComponent = (value: Hydration, minValue: Hydration, suffix, fontWeight?) => {
    const convertedValue = hydrationValue(value, minValue.unit);
    const convertedMinValue = hydrationValue(minValue, minValue.unit);
    let color = '#439946';
    if (convertedValue === 0) {
      color = 'error';
    } else if (convertedValue < convertedMinValue) {
      color = '#d9b305';
    }
    return (
      <SuiTypography
        variant="button"
        fontWeight={fontWeight ? fontWeight : 'regular'}
        color={color}
        sx={{display: 'inline-block', width: 'max-content'}}>
        {value.value + suffix}
      </SuiTypography>
    );
  };

  const hydrationValue = (hydration: Hydration, unit: HydrationUnit): number => {
    if (hydration.unit === unit) {
      return hydration.value;
    }
    if (hydration.unit === 'MILLILITER' && unit === 'OUNCE') {
      return hydration.value * 0.033814;
    }
    if (hydration.unit === 'OUNCE' && unit === 'MILLILITER') {
      return hydration.value * 29.5735;
    }
    return hydration.value;
  };

  const average = (values: Hydration[], unit: HydrationUnit) => {
    const convertedValues = values.map(value => hydrationValue(value, unit));
    return Math.floor(convertedValues.reduce((a, b) => a + b, 0) / values.length);
  };

  return (
    <>
      <Grid item xs={12} sx={{pt: 1}}>
        <Table columns={columns} rows={createRows()} tableStyles={{boxShadow: 'none'}} />
      </Grid>
    </>
  );
};

export default HydrationGoalResultView;
