import React, {PropsWithChildren} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {ThemeProvider} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import SuiBox from 'components/SuiBox/index';
import Card from '@mui/material/Card';
import SuiTypography from 'components/SuiTypography/index';
import SuiButton from 'components/SuiButton/index';
import theme from 'assets/theme';

const logo = require('assets/images/logo-transparent.png');

const AuthErrorHandler = ({children}: PropsWithChildren<any>) => {
  const auth0 = useAuth0();

  const render = () => {
    if (auth0.error) {
      return renderError(auth0.error);
    }
    return children;
  };

  const renderError = (error: Error) => {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Grid container spacing={3} justifyContent="center" sx={{minHeight: '75vh'}}>
          <Grid item xs={12} sm={10} md={6}>
            <SuiBox mt={12} mb={3} px={{xs: 0, lg: 2}}>
              <Card>
                <SuiBox pt={3} px={3} pb={1} textAlign="center">
                  <SuiBox component="img" src={logo} alt={'inmycorner'} />
                  <SuiBox textAlign="center" p={0}>
                    <SuiTypography variant="h4" fontWeight="bold" textGradient textAlign="center">
                      Login Failed
                    </SuiTypography>
                  </SuiBox>
                  <br />
                  <SuiTypography textGradient textAlign="center">
                    {error ? error.message : 'Please retry the login process'}
                  </SuiTypography>
                </SuiBox>
                <SuiBox p={3}>
                  <Grid container item xs={12} justifyContent="center">
                    <Grid item xs={3}>
                      <SuiButton size="small" color="primary" fullWidth xs={6} onClick={() => retry()}>
                        Retry
                      </SuiButton>
                    </Grid>
                  </Grid>
                </SuiBox>
              </Card>
            </SuiBox>
          </Grid>
        </Grid>
      </ThemeProvider>
    );
  };

  const retry = () => {
    auth0.logout({returnTo: window.location.origin});
  };

  return render();
};

export default AuthErrorHandler;
