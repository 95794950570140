import {Conversation, Message, Paginator, Participant} from '@twilio/conversations';

export class TwilioMessageController {
  private currentPage: Paginator<Message>;

  constructor(private readonly conversation: Conversation) {}

  public async loadMessages() {
    this.currentPage = await this.conversation.getMessages(25);
    return this.currentPage.items;
  }

  public async loadOlderMessages() {
    if (!this.currentPage.hasPrevPage) {
      return [];
    }
    if (this.currentPage && this.currentPage.hasPrevPage) {
      this.currentPage = await this.currentPage.prevPage();
    }
    return this.currentPage.items;
  }

  public hasOlderMessages() {
    return this.currentPage?.hasPrevPage;
  }

  public onNewMessage(callback: (message: Message) => void) {
    this.conversation.on(Conversation.messageAdded, callback);
  }

  public removeNewMessageListener(callback: (message: Message) => void) {
    this.conversation.removeListener(Conversation.messageAdded, callback);
  }

  public onTypingStarted(callback: (participant: Participant) => void) {
    this.conversation.removeAllListeners(Conversation.typingStarted);
    this.conversation.on(Conversation.typingStarted, (participant: Participant) => {
      callback(participant);
    });
  }

  public onTypingEnded(callback: (participant: Participant) => void) {
    this.conversation.removeAllListeners(Conversation.typingEnded);
    this.conversation.on(Conversation.typingEnded, (participant: Participant) => {
      callback(participant);
    });
  }

  public removeTypingListeners() {
    this.conversation.removeAllListeners(Conversation.typingStarted);
    this.conversation.removeAllListeners(Conversation.typingEnded);
  }
}
