import {Task, UserDetails} from 'pages/coaching/tasks/TaskTypes';
import {Card, Grid} from '@mui/material';
import axios from 'axios';
import {useLocation, useNavigate} from 'react-router-dom';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {PlanSubscription} from 'pages/coaching/plans/PlanTypes';
import DataForm, {DataFormField} from 'components/DataForm/index';
import * as Yup from 'yup';
import ClientCard from 'components/ClientCard/ClientCard';
import SuiTypography from 'components/SuiTypography/index';

type SubscriptionOnboardingTaskProps = {
  task: Task;
};

const SubscriptionOnboardingTask = (props: SubscriptionOnboardingTaskProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [subscription, setSubscription] = useState<PlanSubscription>();
  const [readOnly, setReadOnly] = useState<boolean>(false);

  const fields: DataFormField[] = [
    {
      name: 'phase',
      label: 'Next Step',
      type: 'select',
      placeholder: '',
      options: [
        {label: 'Send Initial Questionnaire', value: 'COLLECT_INFO'},
        {label: 'Prepare first Check-in', value: 'ACTIVE'},
      ],
      hint: 'If you migrate an existing client you probably want to skip the initial questionnaire and go directly to the first Check-in.',
      columns: 6,
    },
  ];

  const validation = Yup.object().shape({
    phase: Yup.string()
      .test('phase', 'Next Step must be Initial Questionnaire or Check-in', v => {
        return v === 'COLLECT_INFO' || v === 'ACTIVE';
      })
      .required('Next Step is required'),
  });

  useEffect(() => {
    axios
      .get(`/api/v0/subscriptions/${props.task.referencedEntity}`)
      .then(response => response.data)
      .then((s: PlanSubscription) => {
        if (s.phase === 'ONBOARDING') {
          s.phase = 'COLLECT_INFO';
        } else {
          setReadOnly(true);
        }
        setSubscription(s);
      })
      .catch(error => Promise.reject(error));
  }, [props.task.referencedEntity]);

  const getClient = () => {
    const clientAttribute = props.task.attributes.find(a => a.key === 'client');
    return clientAttribute.value as UserDetails;
  };

  const getSubscriptionId = () => {
    const clientAttribute = props.task.attributes.find(a => a.key === 'subscription');
    return clientAttribute.value as string;
  };

  const onSubmit = async (values, actions) => {
    try {
      const response = await axios.patch(`/api/v0/subscriptions/${props.task.referencedEntity}`, values);
      const updated: PlanSubscription = response.data;
      if (location.state != null) {
        navigate(location.state);
      } else {
        setReadOnly(true);
        setSubscription(updated);
      }
    } catch (error) {
      actions.setStatus(error?.response?.data?.message);
    }
    actions.setSubmitting(false);
  };

  return (
    <>
      <Grid container alignItems="flex-start" spacing={3}>
        <Grid item xs={12} xl={8}>
          <Card>
            <Grid container>
              <Grid item xs={12} pt={2} px={2}>
                <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  {`Onboard ${getClient().name} to '${subscription?.plan.name}'`}
                </SuiTypography>
              </Grid>
              <Grid item xs={12} px={2}>
                <SuiTypography variant="button" color="text" fontWeight="regular">
                  Its time to decide how to onboard your new client. You can either send them an initial questionnaire
                  or prepare the first Check-in. Either way, you can write a personal message to your client in the next
                  step.
                </SuiTypography>
              </Grid>
              <Grid item xs={12}>
                <DataForm
                  validation={validation}
                  onSubmit={onSubmit}
                  fields={fields}
                  initialValues={subscription}
                  submitLabel="save"
                  readOnly={readOnly}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid container item xs={12} xl={4} spacing={3}>
          <Grid item xs={12}>
            <ClientCard clientId={getClient().user} subscriptionId={getSubscriptionId()} layout={'vertical'} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SubscriptionOnboardingTask;
