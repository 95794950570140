import React, {useEffect, useState} from 'react';
import DataForm, {DataFormField} from 'components/DataForm/index';
import * as Yup from 'yup';
import {useParams} from 'react-router-dom';
import {CoachSubscription} from 'components/Paywall/coachSubscription';
import axios from 'axios';
import DashboardNavbar from 'layout/navbar/DashboardNavbar';
import DashboardLayout from 'layout/DashboardLayout';
import SuiBox from 'components/SuiBox/index';
import Grid from '@mui/material/Grid';
import {Card} from '@mui/material';
import SuiTypography from 'components/SuiTypography/index';

const AdminMaxClients = () => {
  const {userId} = useParams();
  const [coachSubscription, setCoachSubscription] = useState<CoachSubscription>();

  const fields: DataFormField[] = [
    {
      name: 'maxClients',
      label: 'Max Clients',
      type: 'text',
      placeholder: '',
      columns: 12,
    },
  ];

  useEffect(() => {
    const loadSubscription = async () => {
      const response = await axios.get(`/api/v0/admin/coach/subscriptions/${userId}`);
      setCoachSubscription(response.data);
    };
    loadSubscription();
  }, [userId]);

  const validation = Yup.object().shape({
    maxClients: Yup.number().required('Max Clients is required.'),
  });

  const submitForm = async (values, actions) => {
    try {
      const response = await axios.post(`/api/v0/admin/coach/subscriptions/${userId}`, {maxClients: values.maxClients});
      actions.setSubmitting(false);
      setCoachSubscription(response.data);
    } catch (error) {
      actions.setStatus(error?.response?.data?.message);
    }
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox py={3}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container alignItems="flex-start">
                <Grid container item xs={12} rowSpacing={3} pr={{xl: 3}} py={3}>
                  <Card sx={{width: '100%'}}>
                    <Grid item xs={12} pt={4} px={2}>
                      <SuiBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        px={2}
                        sx={{width: '100%'}}>
                        <SuiTypography variant="h6">Subscriptions</SuiTypography>
                      </SuiBox>
                      <SuiTypography variant="button" color="text" fontWeight="regular" px={2}>
                        Override max clients for a coach. If you set the value to 0 teh max clients from the active
                        subscription will be used.
                      </SuiTypography>
                    </Grid>
                    <Grid item xs={12} lg={6} px={2}>
                      <DataForm
                        validation={validation}
                        onSubmit={submitForm}
                        fields={fields}
                        initialValues={coachSubscription}
                        submitLabel="save"
                      />
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SuiBox>
      </DashboardLayout>
    </>
  );
};

export default AdminMaxClients;
