import {useNavigate} from 'react-router-dom';

// @mui material components
import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiBadge from 'components/SuiBadge';
import SuiButton from 'components/SuiButton';
import {useConfirm} from 'components/ConfirmDialog/index';

export type PricingCardProps = {
  badge: {
    color: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'light' | 'dark';
    label: string;
  };
  price: {
    currency: string;
    value: string;
  };
  specifications: {
    label: string;
    includes: boolean;
  }[];
  action: {
    type: 'external' | 'internal';
    route: string;
    label: string;
    enabled: boolean;
    confirm?: {
      title: string;
      description: string;
      confirmText: string;
    };
    color: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'light' | 'dark';
  };
};

const PricingCard = (props: PricingCardProps) => {
  const navigate = useNavigate();
  const confirm = useConfirm();

  const renderSpecifications = () => {
    return props.specifications.map(({label, includes}) => (
      <SuiBox key={label} display="flex" alignItems="center" p={1}>
        <SuiBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="1.5rem"
          height="1.5rem"
          borderRadius="50%"
          shaodw="md"
          bgColor={includes ? 'success' : 'secondary'}
          variant="gradient"
          mr={2}>
          <SuiTypography variant="button" color="white" sx={{lineHeight: 0}}>
            <Icon sx={{fontWeight: 'bold'}}>{includes ? 'done' : 'remove'}</Icon>
          </SuiTypography>
        </SuiBox>
        <SuiTypography variant="body2" color="text">
          {label}
        </SuiTypography>
      </SuiBox>
    ));
  };

  const performAction = async () => {
    if (props.action.confirm) {
      try {
        await confirm({
          title: props.action.confirm.title,
          content: props.action.confirm.description,
          confirmationText: props.action.confirm.confirmText,
          confirmationButtonProps: {
            variant: 'gradient',
            color: props.action.color,
          },
        });
        navigate(props.action.route);
      } catch (error) {
        // do nothing
      }
    } else {
      navigate(props.action.route);
    }
  };

  return (
    <Card>
      <SuiBox pt={3} pb={2} px={2} textAlign="center">
        <SuiBadge
          variant="contained"
          color={props.badge.color}
          size="sm"
          badgeContent={props.badge.label}
          circular
          container
        />
        <SuiBox my={1}>
          <SuiTypography variant="h1">
            <SuiTypography display="inline" component="small" variant="h2">
              {props.price.currency}
            </SuiTypography>
            {props.price.value}
          </SuiTypography>
        </SuiBox>
      </SuiBox>
      <SuiBox pb={3} px={3}>
        {renderSpecifications()}
        {props.action.type === 'internal' ? (
          <SuiBox mt={3}>
            <SuiButton
              onClick={() => performAction()}
              variant="gradient"
              color={props.action.color}
              fullWidth
              disabled={!props.action.enabled}>
              {props.action.label}&nbsp;
            </SuiButton>
          </SuiBox>
        ) : (
          <SuiBox mt={3}>
            <SuiButton
              component="a"
              href={props.action.route}
              target="_blank"
              rel="noreferrer"
              variant="gradient"
              color={props.action.color}
              fullWidth>
              {props.action.label}&nbsp;
            </SuiButton>
          </SuiBox>
        )}
      </SuiBox>
    </Card>
  );
};

export default PricingCard;
