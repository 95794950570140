import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useNavigate, useParams} from 'react-router-dom';
import {Grid, SpeedDial, SpeedDialAction, SpeedDialIcon, Tab, Tabs} from '@mui/material';
import DashboardLayout from 'layout/DashboardLayout';
import DashboardNavbar from 'layout/navbar/DashboardNavbar';
import Footer from 'layout/footer/Footer';
import AppBar from '@mui/material/AppBar';
import {GroupOutlined} from '@mui/icons-material';
import PlanSubscribersTab from 'pages/coaching/plans/plan/subscribers/PlanSubscribersTab';
import PlanDetailsTab from 'pages/coaching/plans/plan/PlanDetailsTab';
import {Plan} from 'pages/coaching/plans/PlanTypes';
import {useSetRecoilState} from 'recoil';
import {breadCrumbSegmentsState} from 'state/breadcrumb';
import Card from '@mui/material/Card';
import {RiUserSettingsLine} from 'react-icons/ri';
import SuiBox from 'components/SuiBox/index';
import PlanInvitationsTab from 'pages/coaching/plans/plan/invitations/PlanInvitationsTab';
import {FaEdit, FaEnvelopeOpenText, FaStripeS} from 'react-icons/fa';
import {CgDetailsMore} from 'react-icons/cg';
import {loadProductUrl} from 'pages/coaching/plans/edit/payment/EditPlanPaymentTab';
import {useRemoteConfig} from 'components/Firebase/FirebaseConfigProvider';

export type PlanPageProps = {
  tab: 'subscribers' | 'details' | 'invitations';
};

const PlanPage = (props: PlanPageProps) => {
  const {feature_payment} = useRemoteConfig();
  const {planId} = useParams();
  const [plan, setPlan] = useState<Plan>({id: ''});
  const setPlanSegment = useSetRecoilState(breadCrumbSegmentsState(planId));

  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(props.tab);

  const [contextSwitchDialOpen, setContextSwitchDialOpen] = useState(false);
  const handleContextSwitchDialOpen = () => setContextSwitchDialOpen(true);
  const handleContextSwitchDialClose = () => setContextSwitchDialOpen(false);

  const [productLink, setProductLink] = useState<string>();

  useEffect(() => {
    setTabValue(props.tab);
    setProductLink(loadProductUrl(plan));
  }, [props.tab]);

  useEffect(() => {
    setProductLink(loadProductUrl(plan));
  }, [plan]);

  useEffect(() => {
    axios
      .get(`/api/v0/plans/${planId}`)
      .then(response => response.data)
      .catch(error => Promise.reject(error))
      .then(p => {
        setPlanSegment(p.name);
        setPlan(p);
      });
  }, []);

  const handleSetTabValue = (event, newValue) => {
    let url = `/coaching/plans/${planId}`;
    if (newValue === 'subscribers') {
      url += '/subscribers';
    }
    if (newValue === 'invitations') {
      url += '/invitations';
    }
    if (newValue === 'details') {
      url += '/details';
    }
    navigate(url);
    setTabValue(newValue);
  };

  const renderContent = () => {
    switch (tabValue) {
      case 'subscribers':
        return <PlanSubscribersTab plan={plan} />;
      case 'invitations':
        return <PlanInvitationsTab plan={plan} />;
      case 'details':
        return <PlanDetailsTab plan={plan} />;
    }
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox py={3}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container alignItems="flex-start">
                <Grid container item xs={12} rowSpacing={3} pr={{xl: 3}} py={3}>
                  <Card sx={{width: '100%'}}>
                    <SpeedDial
                      ariaLabel="User Operations"
                      sx={{mt: 1, mr: 2, position: 'absolute', top: 0, right: 0}}
                      icon={<SpeedDialIcon icon={<RiUserSettingsLine color={'white'} fontSize={'1.6rem'} />} />}
                      FabProps={{color: 'primary', size: 'medium'}}
                      direction={'left'}
                      onClose={handleContextSwitchDialClose}
                      onOpen={handleContextSwitchDialOpen}
                      open={contextSwitchDialOpen}>
                      <SpeedDialAction
                        key={'Edit'}
                        icon={<FaEdit fontSize={'18'} />}
                        tooltipTitle={'Edit'}
                        onClick={() => navigate(`/coaching/plans/${planId}/edit`)}
                      />
                      {feature_payment?.asBoolean() && (
                        <SpeedDialAction
                          key={'Edit on Stripe'}
                          icon={<FaStripeS fontSize={'15'} />}
                          tooltipTitle={'Edit on Stripe'}
                          onClick={() => window.open(productLink, '_blank')}
                        />
                      )}
                      <SpeedDialAction
                        key={'Invite Client'}
                        icon={<FaEnvelopeOpenText fontSize={'18'} />}
                        tooltipTitle={'Invite Client'}
                        onClick={() => navigate(`/coaching/plans/${planId}/invite`)}
                      />
                    </SpeedDial>
                    <Grid item xs={12} p={3} mr={10}>
                      <AppBar position="static">
                        <Tabs
                          orientation="horizontal"
                          value={tabValue}
                          onChange={handleSetTabValue}
                          sx={{background: 'transparent'}}>
                          <Tab value="subscribers" label="Subscribers" icon={<GroupOutlined />} />
                          <Tab value="invitations" label="Invitations" icon={<FaEnvelopeOpenText size={'14'} />} />
                          <Tab value="details" label="Details" icon={<CgDetailsMore />} />
                        </Tabs>
                      </AppBar>
                    </Grid>
                    <Grid item xs={12} pb={3}>
                      <Grid container item xs={12}>
                        <Grid item xs={12}>
                          <Grid container item xs={12}>
                            {renderContent()}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SuiBox>
        <Footer />
      </DashboardLayout>
    </>
  );
};

export default PlanPage;
