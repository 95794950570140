import {useEffect, useState} from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import {Icon, Tooltip} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import SuiBox from 'components/SuiBox';
import DashboardLayout from 'layout/DashboardLayout';
import DashboardNavbar from 'layout/navbar/DashboardNavbar';
import Footer from 'layout/footer/Footer';
import SuiButton from 'components/SuiButton';
import SuiTypography from 'components/SuiTypography';
import SuiAlert from 'components/SuiAlert';
import EditTermsForm from './EditTermsForm';
import DataTable from 'components/SuiDataTable';
import Card from '@mui/material/Card';
import {useConfirm} from 'components/ConfirmDialog';
import BootPagination, {Page} from 'components/BootPagination';
import moment from 'moment/moment';

type Terms = {
  id: string;
  title: string;
  content: string;
  published: string;
};

function AdminTermsPage() {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const [page, setPage] = useState<Page<Terms>>({content: []} as Page<Terms>);
  const [pageNumber, setPageNumber] = useState(0);
  const [showForm, setShowForm] = useState(false);

  const columns = [
    {Header: 'title', accessor: 'title', align: 'left'},
    {Header: 'published', accessor: 'published', align: 'left'},
    {Header: 'control', accessor: 'control', align: 'left', disableSortBy: true, disableFilters: true},
  ];

  useEffect(() => {
    axios
      .get(`/api/v0/terms?page=${pageNumber}&size=10&sort=published,ASC`)
      .then(response => response.data)
      .then(setPage)
      .catch(error => Promise.reject(error));
  }, [pageNumber]);

  const deleteTerms = async id => {
    try {
      await confirm({
        title: 'Danger Zone: Delete Terms',
        content: 'Are you sure you want to delete this terms? There will be no way back!',
        confirmationText: 'Delete',
      });
      await sendDeleteTerms(id);
    } catch (error) {
      // Do nothing
    }
  };

  const sendDeleteTerms = async id => {
    try {
      await axios.delete(`/api/v0/terms/${id}`);
      page.content = page.content.filter(term => term.id !== id);
      setPage({...page});
    } catch (error) {
      alert('Error deleting terms');
    }
  };

  const renderDeleteTerms = term => {
    if (term.published === null) {
      return (
        <>
          <SuiButton variant="text" color="secondary" onClick={() => deleteTerms(term.id)} sx={{minWidth: '0px'}}>
            <Tooltip title="Delete Terms" placement="top">
              <Icon>delete</Icon>
            </Tooltip>
          </SuiButton>
        </>
      );
    }
  };

  const createRows = () =>
    page.content.map(term => ({
      title: term.title,
      published: term.published !== null ? `Yes (${moment(term.published).local().format('MM-DD-YYYY, h:mma')})` : 'No',
      control: (
        <>
          <Grid container justifyContent="flex-start">
            <SuiButton
              variant="text"
              color="secondary"
              onClick={() => navigate(`/admin/terms/${term.id}`)}
              sx={{minWidth: '0px'}}>
              <Tooltip title="Edit Terms" placement="top">
                <Icon>edit</Icon>
              </Tooltip>
            </SuiButton>
            {renderDeleteTerms(term)}
          </Grid>
        </>
      ),
    }));

  const createTable = () => {
    return {
      columns: columns,
      rows: createRows(),
    };
  };

  const renderForm = () => {
    return (
      <>
        <Grid container item xs={12} xl={8}>
          <Grid container item xs={12} rowSpacing={3}>
            <Grid item xs={12}>
              <Card>
                <Grid container>
                  <Grid item xs={10}>
                    <SuiBox p={3} lineHeight={1}>
                      <SuiTypography variant="h5" fontWeight="medium">
                        Terms and Conditions
                      </SuiTypography>
                      <SuiTypography variant="button" fontWeight="regular" color="text">
                        Every user has to accept newly published terms. Terms can be published by setting a published
                        date.
                      </SuiTypography>
                    </SuiBox>
                  </Grid>
                  <Grid container item xs={2} justifyContent="flex-end" alignItems="flex-start" p={2}>
                    <SuiButton size="small" variant="outlined" color="primary" onClick={() => setShowForm(false)}>
                      Cancel
                    </SuiButton>
                  </Grid>
                </Grid>
                <EditTermsForm />
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  const renderTable = () => {
    return (
      <>
        <Grid container item xs={12} xl={8} order={{xs: 2, xl: 1}}>
          <Grid container item xs={12} rowSpacing={3}>
            <Grid item xs={12}>
              <Card>
                <Grid container>
                  <Grid item xs={10}>
                    <SuiBox p={3} lineHeight={1}>
                      <SuiTypography variant="h5" fontWeight="medium">
                        Terms and Conditions
                      </SuiTypography>
                      <SuiTypography variant="button" fontWeight="regular" color="text">
                        Every user has to accept newly published terms. Terms can be published by setting a published
                        date.
                      </SuiTypography>
                    </SuiBox>
                  </Grid>
                  <Grid container item xs={2} justifyContent="flex-end" alignItems="flex-start" p={2}>
                    <SuiButton size="small" color="primary" onClick={() => setShowForm(true)}>
                      <Icon sx={{fontWeight: 'bold'}}>add</Icon>
                    </SuiButton>
                  </Grid>
                </Grid>
                <DataTable table={createTable()} showTotalEntries={false} />
                <Grid container direction="row" justifyContent="center" alignItems="center" sx={{p: 3}}>
                  <BootPagination page={page} onClick={setPageNumber} />
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} xl={4} pl={{xs: 0, xl: 3}} order={{xs: 1, xl: 2}}>
          <SuiAlert color="info" fontSize="small">
            Once terms are published they cant be update nor deleted. Published terms will not roll out immediately.
            Instead it will take up to 1 hour until the terms are live for every user.
          </SuiAlert>
        </Grid>
      </>
    );
  };

  const renderContent = () => {
    if (showForm) {
      return renderForm();
    }
    return renderTable();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <Grid container>{renderContent()}</Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AdminTermsPage;
