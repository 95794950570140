import {useEffect, useMemo, useState} from 'react'; // porp-types is a library for typechecking of props
import {Line} from 'react-chartjs-2'; // @mui material components
import Card from '@mui/material/Card'; // Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography'; // DefaultLineChart configurations
import configs, {Configs} from './ProgressChartConfig'; // Soft UI Dashboard PRO React base styles
import colors from 'assets/theme/base/colors';
import axios from 'axios';
import {Page} from 'components/BootPagination/index';
import {ChartDataset} from 'chart.js';

export type ProgressionChartProps = {
  clientId: string;
  days: number;
  title: string;
  description: React.ReactNode;
  height: string;
};

type CombinedMeasurement = {
  weight: number;
  unit: 'LBS' | 'KG';
  calories: number;
  sleep: number;
  steps: number;
};

type MeasurementDataSet = {
  [key: string]: CombinedMeasurement;
};

const ProgressionChart = (props: ProgressionChartProps) => {
  const [combinedMeasurements, setCombinedMeasurements] = useState<MeasurementDataSet>({});

  useEffect(() => {
    Promise.all([retrieveMeasurements('weight', props.days), retrieveMeasurements('macros', props.days)])
      .then(allMeasurements => {
        const dataSet: MeasurementDataSet = {};
        allMeasurements.forEach((measurements: any[]) => {
          measurements.forEach((measurement: any) => {
            const timestamp = measurement.timestamp.split('T')[0];
            if (dataSet[timestamp]) {
              dataSet[timestamp] = {
                ...dataSet[timestamp],
                ...measurement,
              };
            } else {
              dataSet[timestamp] = measurement;
            }
          });
        });
        return dataSet;
      })
      .then(setCombinedMeasurements);
  }, [props.clientId, props.days]);

  const retrieveMeasurements = <T,>(path: string, days: number): Promise<T[]> => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/api/v0/data/measurements/${path}?page=0&size=${days}&days=${days}&client=${encodeURI(
            props.clientId,
          )}&sort=timestamp,asc`,
        )
        .then(response => response.data)
        .then((page: Page<T>) => {
          resolve(page.content);
        })
        .catch(error => reject(error));
    });
  };

  const [chartDatasets, setChartDatasets] = useState<ChartDataset[]>([]);

  useEffect(() => {
    const datasets = computeDatasets();
    setChartDatasets(
      datasets.datasets.map(dataset => ({
        ...dataset,
        tension: 0.4,
        borderWidth: 3,
        pointRadius: 2,
        pointBackgroundColor: colors[dataset.color] ? colors[dataset.color || 'dark'].main : colors.dark.main,
        borderColor: colors[dataset.color] ? colors[dataset.color || 'dark'].main : colors.dark.main,
        maxBarThickness: 6,
        spanGaps: true,
      })),
    );
  }, [combinedMeasurements]);

  const computeDatasets = () => {
    const keys = Object.keys(combinedMeasurements).sort((a, b) => {
      return new Date(a).getTime() - new Date(b).getTime();
    });
    return {
      labels: keys,
      datasets: [
        {
          label: 'Calories',
          color: 'primary',
          data: keys.map(key => combinedMeasurements[key].calories),
          yAxisID: 'y',
        },
        {
          label: 'Weight',
          color: 'dark',
          data: keys.map(key => combinedMeasurements[key].weight),
          yAxisID: 'y1',
        },
      ],
    };
  };

  const [config, setConfig] = useState<Configs>({});
  useEffect(() => {
    const keys = Object.keys(combinedMeasurements).sort((a, b) => {
      return new Date(a).getTime() - new Date(b).getTime();
    });
    setConfig(configs(keys, chartDatasets));
  }, [chartDatasets]);

  const renderChart = (
    <SuiBox p={2} sx={{backgroundColor: 'transparent'}}>
      {props.title || props.description ? (
        <SuiBox px={props.description ? 1 : 0} pt={props.description ? 1 : 0}>
          {props.title && (
            <SuiBox mb={1}>
              <SuiTypography variant="h6">{props.title}</SuiTypography>
            </SuiBox>
          )}
          <SuiBox mb={2}>
            <SuiTypography component="div" variant="button" fontWeight="regular" color="text">
              {props.description}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      ) : null}
      {useMemo(
        () => (
          <SuiBox height={props.height}>
            <Line data={config.data} options={config.options} />
          </SuiBox>
        ),
        [config],
      )}
    </SuiBox>
  );

  return props.title || props.description ? <Card>{renderChart}</Card> : renderChart;
};

export default ProgressionChart;
