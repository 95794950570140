import * as React from 'react';
import {useEffect, useState} from 'react';
import Table from 'components/SuiTable';
import {useNavigate} from 'react-router-dom';
import SuiBox from 'components/SuiBox/index';
import SuiTypography from 'components/SuiTypography/index';
import Grid from '@mui/material/Grid';
import {atom, useRecoilValue} from 'recoil';
import {clientsState, ClientState} from 'state/clients';
import Card from '@mui/material/Card';
import {BiMessageRoundedCheck} from 'react-icons/bi';
import {CircularProgress} from '@mui/material';

type Row = {
  name: string[];
  onClick: () => void;
};

export const loadingMessagesState = atom<boolean>({
  key: 'loadingMessagesState',
  default: true,
});

const UnreadMessagesList = () => {
  const navigate = useNavigate();
  const clientsFromState = useRecoilValue(clientsState);
  const loadingMessages = useRecoilValue(loadingMessagesState);
  const [clients, setClients] = useState<ClientState[]>([]);

  const [rows, setRows] = useState<Row[]>([]);

  const columns = [{name: 'name', align: 'left'}];

  useEffect(() => {
    const clients = [...clientsFromState]
      .sort((a, b) => a.client.name.localeCompare(b.client.name))
      .filter(client => client.unreadMessages > 0);
    setClients(clients);
  }, [clientsFromState]);

  useEffect(() => {
    createRows();
  }, [clients]);

  const createRows = () => {
    const rows = clients.map(client => ({
      name: [client.client.picture, client.client.name],
      onClick: () =>
        navigate(`/coaching/clients/${encodeURI(client.client.userId)}/${client.client.subscriptions[0].id}/messages`),
      badgeCount: client.unreadMessages,
    }));
    setRows(rows);
  };

  const renderNoUnreadMessages = () => {
    return (
      <SuiBox display="flex" sx={{justifyContent: 'center', alignItems: 'center', flexDirection: 'row', pb: 2}}>
        <BiMessageRoundedCheck fontSize={25} color={'#d2d2d2'} />
        <SuiTypography variant="h6" fontWeight="medium" color={'#d2d2d2'} sx={{ml: 1}}>
          no unread messages
        </SuiTypography>
      </SuiBox>
    );
  };

  const renderLoadingMessages = () => {
    return (
      <SuiBox display="flex" sx={{justifyContent: 'center', alignItems: 'center', flexDirection: 'column', pb: 3}}>
        <CircularProgress color="primary" />
      </SuiBox>
    );
  };

  const renderClients = () => {
    return <Table columns={columns} rows={rows} tableStyles={{boxShadow: 'none'}} hideColumns={true} />;
  };

  const renderContent = () => {
    if (loadingMessages) {
      return renderLoadingMessages();
    }
    if (clients?.length > 0) {
      return renderClients();
    }
    return renderNoUnreadMessages();
  };

  return (
    <>
      <Card>
        <SuiBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
          <SuiTypography variant="h6" fontWeight="medium">
            New Messages
          </SuiTypography>
        </SuiBox>
        <Grid container mt={{xs: 3, lg: 3}} mb={1}>
          <Grid item xs={12}>
            {renderContent()}
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default UnreadMessagesList;
