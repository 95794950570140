import React, {useMemo} from 'react';
import * as process from 'process';
import SuiTypography from 'components/SuiTypography/index';
import SuiBox from 'components/SuiBox/index';
import {SiAuth0, SiDatadog, SiFirebase, SiGithub, SiSentry, SiStripe, SiTerraform, SiTwilio} from 'react-icons/si';
import {RiSendBackward} from 'react-icons/ri';
import {RxActivityLog} from 'react-icons/rx';

export type ObservabilityBarProps = {
  isAdmin: boolean;
};

const ObservabilityBar = (props: ObservabilityBarProps) => {
  const renderEnvironment = () => {
    let environment = process.env.REACT_APP_SENTRY_ENVIRONMENT || 'local';
    return (
      <SuiBox sx={{fontSize: '12px', px: 2, borderBottomLeftRadius: 8}}>
        <SuiTypography variant="button" fontWeight="bold" textTransform="uppercase" color="white">
          {environment}
        </SuiTypography>
      </SuiBox>
    );
  };

  const determineBackgroundColor = () => {
    let environment = process.env.REACT_APP_SENTRY_ENVIRONMENT || 'local';
    let backgroundColor = '#14C7EA';
    if (environment === 'prod') {
      backgroundColor = '#ea0606';
    } else if (environment === 'dev') {
      backgroundColor = '#FFA500';
    }
    return backgroundColor;
  };

  const determineGitHubUrl = () => {
    const commit = process.env.REACT_APP_UI_COMMIT;
    if (commit !== 'n/a') {
      return `https://github.com/inmycorner/inmycorner-web/commit/${commit}`;
    }
    return 'https://github.com/inmycorner/inmycorner-web';
  };

  const determineSentryUrl = () => {
    let environment = process.env.REACT_APP_SENTRY_ENVIRONMENT || 'local';
    if (environment === 'local') {
      return 'https://inmycorner.sentry.io/projects/inmycorner-web/?environment=dev&project=4504345933709312';
    }
    return `https://inmycorner.sentry.io/releases/${process.env.REACT_APP_UI_COMMIT}/?environment=prod&project=4504345933709312`;
  };

  const determineFirebaseUrl = () => {
    let environment = process.env.REACT_APP_SENTRY_ENVIRONMENT || 'local';
    if (environment === 'prod') {
      return 'https://console.firebase.google.com/project/inmycorner-prod/overview';
    }
    return 'https://console.firebase.google.com/project/inmycorner-dev/overview';
  };

  const determineAuth0Url = () => {
    let environment = process.env.REACT_APP_SENTRY_ENVIRONMENT || 'local';
    if (environment === 'prod') {
      return 'https://manage.auth0.com/dashboard/us/inmycorner-prod/insights';
    }
    return 'https://manage.auth0.com/dashboard/us/dev-t4osw-2j/insights';
  };

  const determineTerraformUrl = () => {
    let environment = process.env.REACT_APP_SENTRY_ENVIRONMENT || 'local';
    if (environment === 'prod') {
      return 'https://app.terraform.io/app/inmycorner/workspaces/production-nyc';
    }
    return 'https://app.terraform.io/app/inmycorner/workspaces/development-nyc';
  };

  const determineLogtailUrl = () => {
    let environment = process.env.REACT_APP_SENTRY_ENVIRONMENT || 'local';
    if (environment === 'prod') {
      return 'https://logtail.com/team/156901/tail?s=254025';
    }
    return 'https://logtail.com/team/156901/tail?s=254016';
  };

  const renderContent = () => {
    if (props.isAdmin) {
      return (
        <>
          <SuiBox
            display={'flex'}
            flexDirection={'columns'}
            sx={{
              width: '100%',
              position: 'relative',
              top: 0,
              left: 0,
              justifyContent: 'center',
            }}>
            <SuiBox
              display={'flex'}
              flexDirection={'columns'}
              sx={{
                backgroundColor: determineBackgroundColor(),
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8,
                p: 0.3,
              }}>
              {renderEnvironment()}
              <SuiBox display={'flex'} flexDirection={'columns'} sx={{justifyContent: 'center', alignItems: 'center'}}>
                <SiGithub
                  {...{title: 'GitHub'}}
                  fontSize={'medium'}
                  color={'white'}
                  style={{marginRight: 8, cursor: 'pointer'}}
                  onClick={() => window.open(determineGitHubUrl(), '_blank')}
                />
                <SiSentry
                  {...{title: 'Sentry'}}
                  fontSize={'medium'}
                  color={'white'}
                  style={{marginRight: 8, cursor: 'pointer'}}
                  onClick={() => window.open(determineSentryUrl(), '_blank')}
                />
                <SiDatadog
                  {...{title: 'DataDog'}}
                  fontSize={'medium'}
                  color={'white'}
                  style={{marginRight: 8, cursor: 'pointer'}}
                  onClick={() =>
                    window.open('https://app.datadoghq.com/dashboard/nb9-xfg-hgi/do-inmycorner-api', '_blank')
                  }
                />
                <RxActivityLog
                  {...{title: 'Logtail'}}
                  fontSize={14}
                  color={'white'}
                  style={{marginRight: 8, cursor: 'pointer'}}
                  onClick={() => window.open(determineLogtailUrl(), '_blank')}
                />
                <SiAuth0
                  {...{title: 'Auth0'}}
                  fontSize={'medium'}
                  color={'white'}
                  style={{marginRight: 8, cursor: 'pointer'}}
                  onClick={() => window.open(determineAuth0Url(), '_blank')}
                />
                <SiTerraform
                  {...{title: 'Terraform'}}
                  fontSize={'medium'}
                  color={'white'}
                  style={{marginRight: 8, cursor: 'pointer'}}
                  onClick={() => window.open(determineTerraformUrl(), '_blank')}
                />
                <SiFirebase
                  {...{title: 'Firebase'}}
                  fontSize={'medium'}
                  color={'white'}
                  style={{marginRight: 8, cursor: 'pointer'}}
                  onClick={() => window.open(determineFirebaseUrl(), '_blank')}
                />
                <RiSendBackward
                  {...{title: 'SendGrid'}}
                  fontSize={'medium'}
                  color={'white'}
                  style={{marginRight: 8, cursor: 'pointer'}}
                  onClick={() => window.open('https://app.sendgrid.com/', '_blank')}
                />
                <SiTwilio
                  {...{title: 'Twilio'}}
                  fontSize={'medium'}
                  color={'white'}
                  style={{marginRight: 8, cursor: 'pointer'}}
                  onClick={() => window.open('https://console.twilio.com/', '_blank')}
                />
                <SiStripe
                  {...{title: 'Stripe'}}
                  fontSize={'14'}
                  color={'white'}
                  style={{marginRight: 12, cursor: 'pointer'}}
                  onClick={() => window.open('https://dashboard.stripe.com/', '_blank')}
                />
              </SuiBox>
            </SuiBox>
          </SuiBox>
        </>
      );
    }
  };

  return <>{useMemo(() => renderContent(), [props.isAdmin])}</>;
};

export default ObservabilityBar;
