import {Card, CircularProgress, Grid} from '@mui/material';
import DashboardLayout from 'layout/DashboardLayout';
import DashboardNavbar from 'layout/navbar/DashboardNavbar';
import Footer from 'layout/footer/Footer';
import {useNavigate} from 'react-router-dom';
import * as React from 'react';
import {useEffect} from 'react';
import axios from 'axios';
import SuiBox from 'components/SuiBox/index';

const BillingPortalPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const loadPortalSession = async () => {
      const response = await axios.get('/api/v0/coach/subscriptions/portal');
      if (response.data.url) {
        window.location.replace(response.data.url);
      } else {
        navigate('/me/subscriptions');
      }
    };
    loadPortalSession();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container alignItems="flex-start">
              <Grid container item xs={12} rowSpacing={3} pr={{xl: 3}} py={3}>
                <Card sx={{width: '100%', py: 3}}>
                  <SuiBox sx={{height: '300px'}} display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress color="primary" />
                  </SuiBox>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
};

export default BillingPortalPage;
