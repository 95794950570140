import React from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {RecoilRoot} from 'recoil';
import {SoftUIControllerProvider} from 'context';
import ProtectedApp from './ProtectedApp';
import AxiosConfigurer from 'AxiosConfigurer';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import {Integration} from '@sentry/types';
import Auth0ProviderWithHistory from 'Auth0ProviderWithHistory';
import AuthErrorHandler from 'AuthErrorHandler';
import FirebaseConfigProvider from 'components/Firebase/FirebaseConfigProvider';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing() as Integration],
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    release: process.env.REACT_APP_UI_COMMIT,
  });
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <FirebaseConfigProvider>
      <Auth0ProviderWithHistory>
        <AxiosConfigurer />
        <RecoilRoot>
          <SoftUIControllerProvider>
            <AuthErrorHandler>
              <ProtectedApp />
            </AuthErrorHandler>
          </SoftUIControllerProvider>
        </RecoilRoot>
      </Auth0ProviderWithHistory>
    </FirebaseConfigProvider>
  </BrowserRouter>,
);
