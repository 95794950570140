import Grid from '@mui/material/Grid';

import SuiBox from 'components/SuiBox';

import axios from 'axios';
import * as Yup from 'yup';
import DataForm, {DataFormField} from 'components/DataForm';
import {Facebook, Instagram, Twitter, YouTube} from '@mui/icons-material';
import Card from '@mui/material/Card';
import SuiTypography from 'components/SuiTypography/index';
import * as React from 'react';
import {CoachProfile} from 'pages/me/profile/ProfilePage';

export type EditProfileBasicTabProps = {
  profile: CoachProfile;
  onProfileUpdate: (profile: CoachProfile) => Promise<void>;
};

const EditProfileBasicTab = (props: EditProfileBasicTabProps) => {
  const fields: DataFormField[] = [
    {name: 'fullName', label: 'Full Name', type: 'text'},
    {
      type: 'section',
      label: 'Contact Information',
      hint: 'Your location is important to be discovered by clients that search for coaches in certain areas.',
    },
    {name: 'address.street', label: 'Street', type: 'text', columns: 6},
    {name: 'address.city', label: 'City', type: 'text', columns: 6},
    {name: 'address.state', label: 'State', type: 'text', columns: 6},
    {name: 'address.zip', label: 'Zip', type: 'text', columns: 6},
    {name: 'address.country', label: 'Country', type: 'text', columns: 6},
    {type: 'divider', label: ''},
    {type: 'section', label: 'About You'},
    {name: 'biography', label: 'Biography', type: 'editor', placeholder: 'eg. something about you...'},
    {type: 'divider', label: ''},
    {type: 'section', label: 'Social Media'},
    {
      type: 'description',
      label: 'Add your handles to you social media accounts to make them visible on your public profile page.',
    },
    {name: 'social.instagram', label: 'Instagram', type: 'text', columns: 6, icon: <Instagram />},
    {name: 'social.facebook', label: 'Facebook', type: 'text', columns: 6, icon: <Facebook />},
    {name: 'social.twitter', label: 'Twitter', type: 'text', columns: 6, icon: <Twitter />},
    {name: 'social.youtube', label: 'Youtube', type: 'text', columns: 6, icon: <YouTube />},
  ];

  const validation = Yup.object().shape({});

  const submitForm = async (values, actions) => {
    try {
      const response = await axios.patch(`/api/v0/profile/coach`, values);
      await props.onProfileUpdate(response.data);
    } catch (error) {
      actions.setStatus(error?.response?.data?.message);
    }
    actions.setSubmitting(false);
  };

  return (
    <>
      <Grid container py={3}>
        <Grid item xs={12} xl={8} order={{xs: 2, xl: 1}}>
          <SuiBox>
            <Grid container>
              <Grid item xs={12} lg={12}>
                <Card sx={{height: '100%'}} className="imc-tour-profile">
                  <Grid item xs={12} pt={2} px={2}>
                    <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                      Coach Profile
                    </SuiTypography>
                  </Grid>
                  <Grid item xs={12} px={2}>
                    <SuiTypography variant="button" color="text" fontWeight="regular">
                      {
                        "Your profile is the first thing people see when they visit your profile page. Make sure it's up to date, crisp and accurate. Invest some time in it and you'll be rewarded with more bookings."
                      }
                    </SuiTypography>
                  </Grid>
                  <DataForm
                    validation={validation}
                    fields={fields}
                    onSubmit={submitForm}
                    initialValues={props.profile}
                  />
                </Card>
              </Grid>
            </Grid>
          </SuiBox>
        </Grid>
      </Grid>
    </>
  );
};

export default EditProfileBasicTab;
