import * as React from 'react';
import {useEffect, useState} from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import SuiTypography from 'components/SuiTypography/index';
import {QuestionnaireResponse} from 'components/Questionnaire/Questionnaire';
import Divider from '@mui/material/Divider';
import {QuestionSupportFactory} from 'components/Questionnaire/QuestionSupportFactory';
import moment from 'moment/moment';

type QuestionnaireResponseViewProps = {
  questionnaireId: string;
};

const QuestionnaireResponseView = (props: QuestionnaireResponseViewProps) => {
  const supportFactory = new QuestionSupportFactory();
  const [questionnaireResponse, setQuestionnaireResponse] = useState<QuestionnaireResponse>({
    answers: [],
    status: 'DRAFT',
  });

  useEffect(() => {
    if (props.questionnaireId) {
      axios
        .get(`/api/v0/questionnaires/${props.questionnaireId}/response`)
        .then(response => response.data)
        .then(setQuestionnaireResponse)
        .catch(error => Promise.reject(error));
    }
  }, [props.questionnaireId]);

  const renderComment = () => {
    if (questionnaireResponse && questionnaireResponse.comment) {
      return (
        <>
          <Grid item xs={12} key={'comment-description'}>
            <SuiTypography variant="button" fontWeight="bold">
              Comment from Client:
            </SuiTypography>
          </Grid>
          <Grid item key={'comment'}>
            <SuiTypography variant="button" fontWeight="regular">
              {questionnaireResponse.comment}
            </SuiTypography>
          </Grid>
          <Divider />
        </>
      );
    }
  };

  const renderAnswer = (answer, index) => {
    return (
      <Grid item container key={'answer-' + index}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <SuiTypography variant="button" fontWeight="bold">
            #{index + 1}: {answer.question.content}
          </SuiTypography>
        </Grid>
        <Grid item sx={{pl: 3}}>
          {supportFactory.supportFor(answer.type).renderAnswer(answer)}
        </Grid>
      </Grid>
    );
  };

  const renderNoContent = () => {
    if (!questionnaireResponse) {
      return (
        <Grid item xs={12} px={2}>
          <SuiTypography variant="button" fontWeight="regular" sx={{fontStyle: 'italic'}}>
            not yet answered.
          </SuiTypography>
        </Grid>
      );
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} pt={2} px={2}>
          <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Questionnaire
          </SuiTypography>
          <SuiTypography variant="button" fontWeight="regular">
            Submitted on {moment(questionnaireResponse.lastModified).local().format('dddd, MM-DD-YYYY')}.
          </SuiTypography>
        </Grid>
        {renderNoContent()}
        <Grid item xs={12} px={2}>
          {renderComment()}
        </Grid>
        <Grid container item xs={12} lg={12} px={2} pb={2}>
          {questionnaireResponse && questionnaireResponse.answers.map((answer, index) => renderAnswer(answer, index))}
        </Grid>
      </Grid>
    </>
  );
};

export default QuestionnaireResponseView;
