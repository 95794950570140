import axios from 'axios';
import {
  FeatureSettings,
  FeatureSettingsDescription,
  FeatureSettingsType,
  Settings,
} from 'utils/settings/UserSettingsTypes';

export default class UserSettings {
  private _settings: Settings;
  private _featureSettingsDescriptions: FeatureSettingsDescription[];

  public async getSettings<T extends FeatureSettings>(type: FeatureSettingsType): Promise<T> {
    return new Promise((resolve, reject) => {
      this.getUserSettings()
        .then((userSettings: Settings) => {
          userSettings.settings.forEach(featureSettings => {
            if (featureSettings.type === type) {
              resolve(structuredClone(featureSettings) as T);
            }
          });
          resolve(this.determineDefaultSettings(type) as T);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  private determineDefaultSettings(type: FeatureSettingsType): FeatureSettings {
    const description = this._featureSettingsDescriptions.find(desc => desc.type === type);
    return structuredClone(description.defaultSettings);
  }

  public async updateSettings<T extends FeatureSettings>(featureSettings: T): Promise<Settings> {
    const userSettings = await this.getUserSettings();
    return await this.updateUserSettings(userSettings, featureSettings);
  }

  private async updateUserSettings<T extends FeatureSettings>(userSettings: Settings, featureSettings: T) {
    const beforeUpdate = structuredClone(userSettings);
    this.updateFeatureSettings(userSettings, featureSettings.type, featureSettings);
    if (this.hasChanged(beforeUpdate, userSettings)) {
      const response = await axios.patch(`/api/v0/settings`, userSettings);
      this._settings = response.data;
      return this._settings;
    } else {
      return userSettings;
    }
  }

  private updateFeatureSettings<T extends FeatureSettings>(
    userSettings: Settings,
    type: FeatureSettingsType,
    featureSettings: T,
  ) {
    const index = userSettings.settings.findIndex(setting => setting.type === type);
    if (index === -1) {
      userSettings.settings.push(featureSettings);
    } else {
      userSettings.settings[index] = featureSettings;
    }
  }

  private hasChanged(beforeUpdate: Settings, userSettings: Settings) {
    return JSON.stringify(beforeUpdate) !== JSON.stringify(userSettings);
  }

  private getUserSettings(): Promise<Settings> {
    if (this._settings) {
      return this.getFeatureSettingsDescriptions().then(() => Promise.resolve(this._settings));
    }
    return this.getFeatureSettingsDescriptions().then(
      () =>
        new Promise((resolve, reject) => {
          axios
            .get(`/api/v0/settings`)
            .then(response => response.data)
            .then((settings: Settings) => {
              this._settings = settings;
              resolve(settings);
            })
            .catch(error => {
              reject(error);
            });
        }),
    );
  }

  private getFeatureSettingsDescriptions(): Promise<FeatureSettingsDescription[]> {
    if (this._featureSettingsDescriptions && this._featureSettingsDescriptions.length > 0) {
      return Promise.resolve(this._featureSettingsDescriptions);
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v0/settings/descriptions`)
        .then(response => response.data)
        .then((descriptions: FeatureSettingsDescription[]) => {
          this._featureSettingsDescriptions = descriptions;
          resolve(descriptions);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}
