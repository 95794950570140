import {IntervalGoal, SingleMacroGoal} from 'pages/coaching/tasks/type-prepare-interval/IntervalGoalTypes';
import * as Yup from 'yup';
import {BaseSchema} from 'yup';
import {DataFormField} from 'components/DataForm/index';
import {DinnerDiningOutlined, ScaleOutlined} from '@mui/icons-material';
import {IntervalGoalSupport} from 'pages/coaching/tasks/type-prepare-interval/IntervalGoalSupportFactory';
import ObjectID from 'bson-objectid';

export class SingleMacroGoalSupport implements IntervalGoalSupport<SingleMacroGoal> {
  createFields(goal: IntervalGoal, index: number): DataFormField[] {
    return [
      {
        name: `goals[${index}].macroType`,
        label: 'Macro Nutrition Type',
        type: 'select',
        options: [
          {label: 'protein', value: 'PROTEIN'},
          {label: 'carbs', value: 'CARBS'},
          {label: 'fat', value: 'FAT'},
          {label: 'fiber', value: 'FIBER'},
        ],
        placeholder: '',
        hint: 'Type of the macro',
        data: goal.id,
        columns: 3,
      },
      {
        name: `goals[${index}].min`,
        label: 'Min',
        type: 'number',
        placeholder: '',
        icon: <ScaleOutlined />,
        hint: 'Daily minimum intake in grams',
        data: goal.id,
        columns: 3,
      },
      {
        name: `goals[${index}].max`,
        label: 'Max',
        type: 'number',
        placeholder: '',
        icon: <ScaleOutlined />,
        hint: 'Daily maximum intake in grams',
        data: goal.id,
        columns: 3,
      },
    ];
  }

  createGoal(): SingleMacroGoal {
    return {
      id: ObjectID().toHexString(),
      type: 'single-macro',
      macroType: 'PROTEIN',
      min: 0,
      max: 0,
    };
  }

  createValidationSchema(): BaseSchema {
    return Yup.object().shape({
      min: Yup.number().positive('Min value must be a positive number.').required('Min value is required'),
      max: Yup.number().when('min', min => {
        return Yup.number()
          .positive('Max value must be a positive number.')
          .moreThan(min, 'Max value must be greater than min value.')
          .required('Max value is required');
      }),
    });
  }

  getIcon(): React.ReactElement {
    return <DinnerDiningOutlined fontSize={'medium'} />;
  }
}
