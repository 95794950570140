import React, {Suspense, useEffect, useState} from 'react';
import {useAuth0, withAuthenticationRequired} from '@auth0/auth0-react';
import {useSetRecoilState} from 'recoil';
import {accessTokenState} from 'state/authentication';
import Grid from '@mui/material/Grid';
import {CircularProgress} from '@mui/material';
import App from 'App';
import Terms from 'pages/terms/Terms';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import {ErrorBoundary} from 'react-error-boundary';

const ProtectedApp = () => {
  const {getAccessTokenSilently, getIdTokenClaims} = useAuth0();
  const setAccessToken = useSetRecoilState(accessTokenState);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const loadUser = async () => {
      const auth0AccessToken = await getAccessTokenSilently();
      const claims = await getIdTokenClaims();
      axios.defaults.headers.common.Authorization = `Bearer ${auth0AccessToken}`;
      setAccessToken(() => {
        return {
          userName: claims.name !== claims.email ? claims.name : claims.nickname,
          email: claims.email,
          picture: claims.picture,
        };
      });
      setLoaded(() => true);
    };

    loadUser();
  }, [getAccessTokenSilently, getIdTokenClaims]);

  const errorHandler = (error, info) => {
    if (error && info) {
      throw error;
    }
    // TODO: handle error
  };

  const renderLoadingScreen = () => (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{minHeight: '100vh'}}>
      <Grid item xs={3}>
        <CircularProgress color="primary" />
      </Grid>
    </Grid>
  );

  const errorFallback = () => {
    return <div>An error has occured. Please try to reload the page.</div>;
  };

  const renderContent = () => {
    if (loaded) {
      return (
        <Terms>
          <App />
        </Terms>
      );
    }
    return renderLoadingScreen();
  };

  const wrapWithErrorBoundary = () => {
    if (process.env.REACT_APP_SENTRY_DSN) {
      return (
        <Sentry.ErrorBoundary fallback={errorFallback} showDialog>
          <Suspense fallback={renderLoadingScreen()}>{renderContent()}</Suspense>
        </Sentry.ErrorBoundary>
      );
    }
    return (
      <ErrorBoundary onError={errorHandler} fallbackRender={errorFallback}>
        <Suspense fallback={renderLoadingScreen()}>{renderContent()}</Suspense>
      </ErrorBoundary>
    );
  };

  return <>{wrapWithErrorBoundary()}</>;
};

export default withAuthenticationRequired(ProtectedApp);
