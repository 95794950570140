import * as React from 'react';
import {useState} from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import SuiBox from 'components/SuiBox/index';
import SuiTypography from 'components/SuiTypography/index';
import DashboardLayout from 'layout/DashboardLayout';
import DashboardNavbar from 'layout/navbar/DashboardNavbar';
import Card from '@mui/material/Card';
import DataForm, {DataFormField} from 'components/DataForm/index';
import * as Yup from 'yup';
import 'pages/admin/entity/style.css';

function AdminEntityPage() {
  const [entity, setEntity] = useState(null);

  const fields: DataFormField[] = [
    {name: 'collection', label: 'Collection', type: 'text', placeholder: '', columns: 6},
    {name: 'id', label: 'Id', type: 'text', placeholder: '', columns: 6},
  ];

  const validation = Yup.object().shape({
    collection: Yup.string().ensure().required('Collection is required.'),
    id: Yup.string().ensure().required('Id is required.'),
  });

  const onSubmit = async (values, actions) => {
    try {
      const response = await axios.get(`/api/v0/admin/entities/${values.collection}/${values.id}`);
      setEntity(response.data);
    } catch (error) {
      actions.setStatus(error?.response?.data?.message);
      setEntity('Entity not found.');
    }
  };

  const syntaxHighlight = json => {
    if (!json) return '';
    const code = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return code.replace(
      /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
      function (match) {
        var cls = 'number';
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = 'key';
          } else {
            cls = 'string';
          }
        } else if (/true|false/.test(match)) {
          cls = 'boolean';
        } else if (/null/.test(match)) {
          cls = 'null';
        }
        return '<span class="' + cls + '">' + match + '</span>';
      },
    );
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox py={3}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container alignItems="flex-start">
                <Grid container item xs={12} rowSpacing={3} pr={{xl: 3}} py={3}>
                  <Card sx={{width: '100%'}}>
                    <Grid item xs={12} pt={4} px={2}>
                      <SuiTypography variant="h6" fontWeight="medium">
                        Fetch Entity
                      </SuiTypography>
                    </Grid>
                    <Grid item xs={12} px={2}>
                      <SuiTypography variant="button" color="text" fontWeight="regular">
                        Fetch an Entity from the database by id and collection.
                      </SuiTypography>
                    </Grid>
                    <DataForm
                      validation={validation}
                      onSubmit={onSubmit}
                      fields={fields}
                      initialValues={{}}
                      submitLabel="fetch"
                    />
                    <Grid p={1}>
                      <pre
                        dangerouslySetInnerHTML={{
                          __html: syntaxHighlight(JSON.stringify(entity, undefined, 4)),
                        }}
                      />
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SuiBox>
      </DashboardLayout>
    </>
  );
}

export default AdminEntityPage;
