import React from 'react';
import Grid from '@mui/material/Grid';
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiAvatar from 'components/SuiAvatar';
import imageSrc from 'utils/UserImage';
import {Skeleton} from '@mui/material';
import PropTypes from 'prop-types';
import {Plan} from 'pages/coaching/plans/PlanTypes';
import Divider from '@mui/material/Divider';
import SafeHtml from 'components/SafeHtml/SafeHtml';
import {PaymentProvider} from 'pages/me/settings/payment/PaymentSettingsTab';

type PlanSpecificationProps = {
  plan: Plan;
  paymentProvider?: PaymentProvider;
};

const PlanSpecification = (props: PlanSpecificationProps) => {
  const renderText = (label, value) => {
    return (
      <Grid item xs={12} pb={0}>
        <SuiBox>
          <SuiTypography variant="caption" fontWeight="bold">
            {label}
          </SuiTypography>
        </SuiBox>
        <SuiBox>
          <SuiTypography variant="body2" color="text">
            {value}
          </SuiTypography>
        </SuiBox>
      </Grid>
    );
  };

  const renderIcon = () => {
    if (props.plan.icon) {
      return <SuiAvatar src={imageSrc(props.plan.icon)} alt={props.plan.name} size="xxl" sx={{p: 2}} />;
    }
    return <Skeleton variant="rectangular" width={80} height={80} />;
  };

  return (
    <>
      <Grid container spacing={2} pl={3} py={1}>
        <Grid item xs={12}>
          <SuiBox>
            <SuiTypography variant="caption" fontWeight="bold">
              Image
            </SuiTypography>
          </SuiBox>
          <SuiBox>
            <SuiTypography variant="body2" color="text" component="span">
              {renderIcon()}
            </SuiTypography>
          </SuiBox>
        </Grid>
        {renderText('Name', props.plan.name)}
        {renderText('Summary', props.plan.summary)}
        <Grid item xs={12}>
          <SuiBox>
            <SuiTypography variant="caption" fontWeight="bold">
              Description
            </SuiTypography>
          </SuiBox>
          <SuiBox mb={1}>
            <SafeHtml html={props.plan.description} variant="body2" color="text" px={3} />
          </SuiBox>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {renderText('Payment', props.paymentProvider?.description ?? '-')}
        {renderText('Capacity', props.plan.metadata ? props.plan.metadata.capacity : '-')}
      </Grid>
    </>
  );
};

PlanSpecification.defaultProps = {
  specification: {},
};

PlanSpecification.propTypes = {
  specification: PropTypes.instanceOf(Object).isRequired,
};

export default PlanSpecification;
