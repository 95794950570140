import {BaseSchema} from 'yup';
import {DataFormField} from 'components/DataForm/index';
import {
  Answer,
  AnswerStatistics,
  CompoundAnswer,
  Question,
  QuestionTypeIdentifier,
} from 'components/Questionnaire/Questionnaire';
import {StringQuestionSupport} from 'components/Questionnaire/types/StringQuestionSupport';
import {BooleanQuestionSupport} from 'components/Questionnaire/types/BooleanQuestionSupport';
import {NumberQuestionSupport} from 'components/Questionnaire/types/NumberQuestionSupport';
import {RangeQuestionSupport} from 'components/Questionnaire/types/RangeQuestionSupport';
import {PhotoQuestionSupport} from 'components/Questionnaire/types/PhotoQuestionSupport';

export interface QuestionSupport<T extends Question, S extends Answer<T>, U extends AnswerStatistics> {
  createQuestion: () => T;
  createValidationSchema: () => BaseSchema;
  createFields: (question: Question, index: number) => DataFormField[];
  getIcon: () => React.ReactElement;
  renderAnswer: (answer: S) => React.ReactElement;
  renderCompoundAnswer: (answer: CompoundAnswer<T, S, U>) => React.ReactElement;
}

export class QuestionSupportFactory {
  private supports = {
    string: new StringQuestionSupport(),
    boolean: new BooleanQuestionSupport(),
    number: new NumberQuestionSupport(),
    range: new RangeQuestionSupport(),
    photo: new PhotoQuestionSupport(),
  };

  public supportFor(type: QuestionTypeIdentifier) {
    const support = this.supports[type];
    if (!support) {
      throw new Error(`No support for type ${type}`);
    }
    return support;
  }
}
