import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

// Soft UI Dashboard PRO React components
import SuiButton from 'components/SuiButton';
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

// Custom styles for the SidenavCard
import {card, cardContent} from 'layout/sidenav/styles/sidenavCard';

// Soft UI Dashboard PRO React context
import {useSoftUIController} from 'context';
import * as React from 'react';
import {useNavigate} from 'react-router-dom';
import {MdSupportAgent} from 'react-icons/md';

function SidenavCard() {
  const navigate = useNavigate();
  const [controller] = useSoftUIController();
  const {miniSidenav, sidenavColor} = controller;

  return (
    <Card sx={theme => card(theme, {miniSidenav})}>
      <CardContent sx={theme => cardContent(theme, {sidenavColor})}>
        <SuiBox lineHeight={1}>
          <SuiBox display={'flex'} style={{alignItems: 'center'}}>
            <MdSupportAgent fontSize={27} style={{marginTop: 0, marginRight: 5, color: 'white'}} />
            <SuiTypography variant="h6" color="white">
              Need Help?
            </SuiTypography>
          </SuiBox>
          <SuiBox mb={1.825} mt={0}>
            <SuiTypography variant="caption" color="white" fontWeight="medium">
              Learn about inmycorner by
              <br />
              reading the FAQ or watching
              <br />
              the onboarding videos.
            </SuiTypography>
          </SuiBox>
          <SuiButton size="small" color="white" fullWidth onClick={() => navigate('/help')}>
            Go to Help
          </SuiButton>
        </SuiBox>
      </CardContent>
    </Card>
  );
}

export default SidenavCard;
