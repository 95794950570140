import {IntervalGoal, StepsGoal} from 'pages/coaching/tasks/type-prepare-interval/IntervalGoalTypes';
import * as Yup from 'yup';
import {BaseSchema} from 'yup';
import {DataFormField} from 'components/DataForm/index';
import {IntervalGoalSupport} from 'pages/coaching/tasks/type-prepare-interval/IntervalGoalSupportFactory';
import {GiWalk} from 'react-icons/gi';
import ObjectID from 'bson-objectid';

export class StepsGoalSupport implements IntervalGoalSupport<StepsGoal> {
  createFields(goal: IntervalGoal, index: number): DataFormField[] {
    return [
      {
        name: `goals[${index}].minSteps`,
        label: 'Min Steps',
        type: 'number',
        placeholder: '',
        icon: <GiWalk />,
        hint: 'Desired minimum steps per day',
        data: goal.id,
        columns: 3,
      },
    ];
  }

  createGoal(): StepsGoal {
    return {
      id: ObjectID().toHexString(),
      type: 'steps',
      minSteps: 10000,
    };
  }

  createValidationSchema(): BaseSchema {
    return Yup.object().shape({
      minSteps: Yup.number().positive('Min steps must be a positive number.').required('Min steps is required'),
    });
  }

  getIcon(): React.ReactElement {
    return <GiWalk fontSize={'1.45rem'} />;
  }
}
