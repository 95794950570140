import {Form, Formik, FormikContextType} from 'formik';
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';
import Grid from '@mui/material/Grid';
import FormField from 'components/DataForm/FormField';
import Divider from '@mui/material/Divider';
import SuiTypography from 'components/SuiTypography';
import {BaseSchema} from 'yup';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import moment from 'moment';
import {v4 as uuid} from 'uuid';
import Hint from 'components/Hint/Hint';
import React, {useState} from 'react';
import {Fade} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';

export type SelectOption = {
  label: string;
  value: string;
};

export type DataFormFieldType =
  | 'text'
  | 'editor'
  | 'description'
  | 'divider'
  | 'section'
  | 'datetime-local'
  | 'number'
  | 'date'
  | 'checkbox'
  | 'switch'
  | 'hidden'
  | 'select';

export type DataFormField = {
  name?: string;
  label?: string;
  type: DataFormFieldType;
  placeholder?: string;
  columns?: number;
  rows?: number;
  icon?: React.ReactElement;
  inputProps?: any;
  rightComponent?: (formikContext: FormikContextType<any>) => React.ReactElement;
  bottomComponent?: (formikContext: FormikContextType<any>) => React.ReactElement;
  data?: any;
  readOnly?: () => boolean;
  hint?: string;
  value?: string;
  options?: SelectOption[];
  tabIndex?: number;
};

export type DataFormProps = {
  initialValues: any;
  validation: BaseSchema;
  onSubmit: (values: any, formikHelpers: FormikHelpers<any>) => void | Promise<any>;
  fields: DataFormField[];
  readOnly?: boolean;
  hideButtons?: boolean;
  submitLabel?: string;
  leftButton?: React.ReactElement;
  formikRef?: React.RefObject<FormikProps<any>>;
};

const DataForm = (props: DataFormProps) => {
  const [showProgress, setShowProgress] = useState(false);

  const isSubmitEnabled = form => {
    return !form.isSubmitting && !props.readOnly && !showProgress;
  };

  const value = (field, form) => {
    if (field.value) {
      return field.value;
    }
    const fieldValue = form.getFieldMeta(field.name).value;
    if (field.type === 'datetime-local') {
      return fieldValue && fieldValue.length > 0 ? moment(fieldValue).format('YYYY-MM-DDTkk:mm') : '';
    }
    return fieldValue == null ? '' : fieldValue;
  };

  const renderFieldContent = (index, field, form) => {
    if (field.type === 'divider') {
      return <Divider />;
    } else if (field.type === 'section') {
      return renderSection(field, form);
    } else if (field.type === 'description') {
      return renderDescription(field, form);
    }
    return renderFormField(index, field, form);
  };

  const renderSection = (field, form) => {
    return (
      <SuiBox sx={{display: 'flex'}}>
        <SuiBox sx={{flexGrow: 1}}>
          <SuiTypography variant="button">
            {field.label} {renderHint(field)}
          </SuiTypography>
        </SuiBox>
        {field.rightComponent !== undefined ? field.rightComponent(form) : null}
      </SuiBox>
    );
  };

  const renderHint = field => {
    if (field.hint) {
      return <Hint hint={field.hint} />;
    }
  };

  const renderDescription = (field, form) => {
    return (
      <>
        <SuiBox sx={{display: 'flex'}}>
          <SuiBox sx={{flexGrow: 1}}>
            <SuiTypography sx={{mb: 0}} variant="button" color="text" fontWeight="regular">
              {field.label}
            </SuiTypography>
          </SuiBox>
          {field.rightComponent !== undefined ? field.rightComponent(form) : null}
        </SuiBox>
        {field.bottomComponent !== undefined ? field.bottomComponent(form) : null}
      </>
    );
  };

  const isFieldReadOnly = field => {
    if (props.readOnly) {
      return true;
    }
    if (field.readOnly !== undefined) {
      return field.readOnly();
    }
    return false;
  };

  const renderFormField = (index, field, form) => {
    return (
      <FormField
        field={field}
        value={value(field, form)}
        error={form.getFieldMeta(field.name).error != undefined}
        readOnly={isFieldReadOnly(field)}
      />
    );
  };

  const renderValidationErrors = form => {
    if (!form.isValid && form.submitCount > 0) {
      return (
        <SuiTypography component="div" variant="caption" color="error" sx={{px: 2}}>
          {`The values you entered must be valid.`}
        </SuiTypography>
      );
    }
  };

  const renderStatus = form => {
    if (form.status) {
      return (
        <SuiTypography component="div" variant="caption" color="error" sx={{px: 2}}>
          {form.status}
        </SuiTypography>
      );
    }
  };

  const renderProgress = form => {
    return (
      <Fade in={showProgress && !form.status} timeout={800}>
        <CircularProgress color="primary" size={18} sx={{mr: 1}} thickness={4.6} />
      </Fade>
    );
  };

  return (
    <>
      <Formik
        innerRef={props.formikRef ? props.formikRef : null}
        enableReinitialize
        initialValues={props.initialValues}
        validationSchema={props.validation}
        validateOnBlur={true}
        validateOnChange={false}
        onSubmit={async (values, formikHelpers) => {
          formikHelpers.setStatus(undefined);
          setShowProgress(true);
          await props.onSubmit(values, formikHelpers);
          setTimeout(() => {
            setShowProgress(false);
          }, 1300);
        }}>
        {form => (
          <Form id={uuid()} autoComplete="off">
            <SuiBox p={2}>
              <SuiBox>
                <SuiBox>
                  <Grid container rowSpacing={1} columnSpacing={3}>
                    {props.fields.map((field, index) => (
                      <Grid
                        item
                        key={index}
                        xs={12}
                        xl={field.columns ? field.columns : 12}
                        sx={{display: field.type === 'hidden' ? 'none' : 'block'}}>
                        {renderFieldContent(index, field, form)}
                      </Grid>
                    ))}
                  </Grid>
                </SuiBox>
                <SuiBox
                  mt={2}
                  width="100%"
                  display={props.hideButtons ? 'none' : 'flex'}
                  justifyContent={props.leftButton ? 'space-between' : 'flex-end'}>
                  {props.leftButton}
                  <SuiBox display={'flex'} sx={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                    {renderValidationErrors(form)}
                    {renderStatus(form)}
                    {renderProgress(form)}
                    <SuiButton disabled={!isSubmitEnabled(form)} type="submit" size="small" color="primary">
                      {props.submitLabel ? props.submitLabel : 'Submit'}
                    </SuiButton>
                  </SuiBox>
                </SuiBox>
              </SuiBox>
            </SuiBox>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default DataForm;
