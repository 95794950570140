import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import IntervalGoalsForm from 'components/Interval/IntervalGoalsForm';
import DailyQuestionsForm from 'components/Interval/DailyQuestionsForm';
import PrepareIntervalGeneralForm from 'pages/coaching/tasks/type-prepare-interval/PrepareIntervalGeneralForm';
import {useEffect, useRef, useState} from 'react';
import {Step, StepLabel, Stepper} from '@mui/material';
import SuiBox from 'components/SuiBox/index';
import SuiButton from 'components/SuiButton/index';
import SuiTypography from 'components/SuiTypography/index';
import {Interval} from 'components/IntervalExecutionCard/IntervalTypes';
import axios from 'axios';
import {FormikHelpers} from 'formik/dist/types';
import {useLocation, useNavigate} from 'react-router-dom';
import {useConfirm} from 'components/ConfirmDialog/index';

type PrepareIntervalWizardProps = {
  id: string;
  isFirstInterval: boolean;
};

function getSteps() {
  return ['General', 'Goals', 'Daily Questions'];
}

const PrepareIntervalWizard = (props: PrepareIntervalWizardProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const confirm = useConfirm();
  const [interval, setInterval] = useState<Interval>({
    description: '',
    goals: [],
    questions: [],
    status: 'DRAFT',
    questionsDeliveryTime: 'MORNING',
  });
  const headerRef = useRef(null);
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;

  const handleNext = () => {
    setActiveStep(activeStep + 1);
    headerRef.current.scrollIntoView();
  };
  const handleBack = () => {
    setActiveStep(activeStep - 1);
    headerRef.current.scrollIntoView();
  };

  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return (
          <PrepareIntervalGeneralForm interval={interval} isFirstInterval={props.isFirstInterval} onSubmit={update} />
        );
      case 1:
        return (
          <IntervalGoalsForm
            interval={interval}
            description={
              interval.status === 'DRAFT'
                ? "Add the goals below that you’d like your client to respond to in the next check-in. Goals can't be edited once the check-in is submitted. "
                : null
            }
            isFirstInterval={props.isFirstInterval}
            onSubmit={update}
            minGoals={1}
            leftButton={renderBackButton()}
            updateInterval={setInterval}
            submitLabel={'next'}
          />
        );
      case 2:
        return (
          <DailyQuestionsForm
            interval={interval}
            description={
              interval.status === 'DRAFT'
                ? "Add the daily questions below. Questions can't be edited once the check-in is published. Please note " +
                  'that the questions will be sent to the client daily. Formulate the questions in a way that they ' +
                  'make sense every day.'
                : null
            }
            isFirstInterval={props.isFirstInterval}
            onSubmit={update}
            leftButton={renderBackButton()}
            updateInterval={setInterval}
            submitLabel={'submit'}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    fetchInterval()
      .then(setInterval)
      .catch(error => Promise.reject(error));
  }, [props.id]);

  const fetchIntervalAndGoBack = () => {
    fetchInterval()
      .then(i => {
        setInterval(i);
        handleBack();
      })
      .catch(error => Promise.reject(error));
  };

  const fetchInterval = (): Promise<Interval> => {
    return axios
      .get(`/api/v0/intervals/${props.id}`)
      .then(response => response.data)
      .then(interval => {
        interval.start = interval.start.substring(0, 10); // only date
        interval.end = interval.end.substring(0, 10); // only date
        return interval;
      });
  };

  const renderBackButton = () => {
    return (
      <SuiButton size="small" color="light" onClick={fetchIntervalAndGoBack}>
        back
      </SuiButton>
    );
  };

  const update = async (interval: Interval, actions: FormikHelpers<any>) => {
    if (isLastStep) {
      await updateAndDone(interval, actions);
    } else {
      await updateAndNext(interval, actions);
    }
  };

  const updateAndNext = async (current: Interval, actions: FormikHelpers<any>) => {
    const interval = {...current};
    interval.start += interval.start.indexOf('T00:00:00Z') === -1 ? 'T00:00:00Z' : '';
    interval.end += interval.end.indexOf('T00:00:00Z') === -1 ? 'T00:00:00Z' : '';
    try {
      const response = await axios.patch(`/api/v0/intervals/${props.id}`, interval);
      const data = response.data;
      data.start = data.start.substring(0, 10); // only date
      data.end = data.end.substring(0, 10); // only date
      setInterval(data);
      handleNext();
    } catch (error) {
      actions.setStatus(error?.response?.data?.message);
      const errorData = error?.response?.data;
      if (errorData) {
        actions.setStatus(errorData.message);
        console.error(
          `Error updating check-in (${interval.id}). Error: "${errorData.message}". Data: start "${interval.start}", end "${interval.end}"`,
        );
      }
    }
    actions.setSubmitting(false);
  };

  const updateAndDone = async (interval: Interval, actions: FormikHelpers<any>) => {
    try {
      await confirm({
        title: `Submit Check-in ${interval.number}`,
        content:
          'Submitting the check-in makes it available to your client. After submitting it, ' +
          'you will not be able to edit the check-in. Are you sure you want to continue?',
        confirmationText: 'Submit',
        confirmationButtonProps: {
          color: 'primary',
        },
        cancellationButtonProps: {
          color: 'light',
        },
      });
      await executeInterval(interval, actions);
    } catch (error) {
      actions.setSubmitting(false);
    }
  };

  const executeInterval = async (interval, actions) => {
    interval.status = 'SUBMITTED';
    interval.start += interval.start.indexOf('T00:00:00Z') === -1 ? 'T00:00:00Z' : '';
    interval.end += interval.end.indexOf('T00:00:00Z') === -1 ? 'T00:00:00Z' : '';
    try {
      await axios.patch(`/api/v0/intervals/${props.id}`, interval);
      if (location.state != null) {
        navigate(location.state);
      } else {
        setInterval(interval);
      }
    } catch (error) {
      interval.status = 'DRAFT';
      const errorData = error?.response?.data;
      if (errorData) {
        actions.setStatus(errorData.message);
        console.error(
          `Error executing check-in (${interval.id}). Error: "${errorData}". Data: start "${interval.start}", end "${interval.end}"`,
        );
      }
    }
    actions.setSubmitting(false);
  };

  const renderReadOnlyNavigation = () => {
    if (interval.status === 'SUBMITTED') {
      return (
        <SuiBox px={2} pb={2} width="100%" display="flex" justifyContent="space-between">
          {activeStep === 0 ? (
            <SuiBox />
          ) : (
            <SuiButton size={'small'} color="light" onClick={handleBack}>
              back
            </SuiButton>
          )}
          <SuiButton
            size={'small'}
            color="dark"
            onClick={!isLastStep ? handleNext : undefined}
            sx={{display: isLastStep ? 'none' : 'block'}}>
            {isLastStep ? 'submit' : 'next'}
          </SuiButton>
        </SuiBox>
      );
    }
  };

  const description = () => {
    if (interval.status === 'DRAFT') {
      return (
        'Please enter the check-in below for your client. Double check everything is correct before submitting.' +
        ' Once submitted, the check-in will be available to the client and can no longer be edited.'
      );
    }
    return "The check-in below was already submitted and is available to your client. You can't edit the Check-In anymore.";
  };

  return (
    <>
      <Card>
        <Grid container justifyContent="center">
          <Grid item xs={12} pt={2} px={2}>
            <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize" ref={headerRef}>
              Check-in #{interval.number}
            </SuiTypography>
          </Grid>
          <Grid item xs={12} px={2}>
            <SuiTypography variant="button" color="text" fontWeight="regular">
              {description()}
            </SuiTypography>
          </Grid>
          <Grid item xs={12}>
            <Stepper activeStep={activeStep} alternativeLabel sx={{pt: 3, my: 0}}>
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <SuiBox>
              <SuiBox>
                {getStepContent(activeStep)}
                {renderReadOnlyNavigation()}
              </SuiBox>
            </SuiBox>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default PrepareIntervalWizard;
