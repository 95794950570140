import {Client, Conversation} from '@twilio/conversations';
import {atom} from 'recoil';
import {UserDetails} from 'pages/coaching/tasks/TaskTypes';
import {TwilioConversationFetcher} from 'components/Chat/twilio/TwilioConversationFetcher';
import {TwilioMessageController} from 'components/Chat/twilio/TwilioMessageController';

export const twilioControllerState = atom<TwilioController>({
  key: 'twilioControllerState',
  default: null,
  dangerouslyAllowMutability: true,
});

export class TwilioController {
  private readonly conversationFetcher: TwilioConversationFetcher;
  private readonly messageControllers: Map<string, TwilioMessageController> = new Map();

  constructor(private readonly client: Client, private readonly user: UserDetails) {
    this.conversationFetcher = new TwilioConversationFetcher(client, user);
  }

  public loadConversation = async (clientId: string) => {
    return await this.conversationFetcher.getConversation(clientId);
  };

  public loadAllConversations = async () => {
    return await this.conversationFetcher.getConversations();
  };

  public async getUnreadCount(clientId: string) {
    const conversation = await this.loadConversation(clientId);
    if (conversation) {
      const count = await conversation.getMessagesCount();
      return count > 0 ? count - (conversation.lastReadMessageIndex + 1) : 0;
    }
    return 0;
  }

  public messages(conversation: Conversation) {
    if (!this.messageControllers.has(conversation.sid)) {
      this.messageControllers.set(conversation.sid, new TwilioMessageController(conversation));
    }
    return this.messageControllers.get(conversation.sid);
  }

  public isConnected() {
    return this.client.connectionState === 'connected';
  }
}
