import {Grid} from '@mui/material';
import YouTube from 'react-youtube';

type Video = {
  title: string;
  videoId: string;
};

const videos: Video[] = [
  {
    title: 'IMC Coaching Platform Tour',
    videoId: '7hpgg-0Ha7s',
  },
  {
    title: 'IMC Coaching Dashboard',
    videoId: 'BJBwxPfBQdI',
  },
  {
    title: 'How to create an initial questionnaire template',
    videoId: 'SEavAha0Nl0',
  },
  {
    title: 'How to set up your check-in template',
    videoId: 'mUXBgUcbdRo',
  },
  {
    title: 'How to access notifications and Stripe',
    videoId: 'YVm2HFY_JCw',
  },
  {
    title: 'How to access client information',
    videoId: 'k34rTjajufg',
  },
  {
    title: 'Client app information',
    videoId: 'n0rY0Sm_-6U',
  },
  {
    title: 'How to create a plan and GO LIVE',
    videoId: 'pePSCVtBVHQ',
  },
  {
    title: 'Setting up a new Stripe connection- set up payment',
    videoId: 'MmQa8coEO18',
  },
  {
    title: 'Coach check-in response',
    videoId: 'tqsRAa-a0uw',
  },
];

const VideosTab = () => {
  return (
    <>
      <Grid container p={3} spacing={3}>
        {videos.map((video, index) => (
          <Grid key={index} item xs={12} sm={6} lg={4} style={{minHeight: '300px'}}>
            <YouTube
              videoId={video.videoId}
              style={{minHeight: '300px'}}
              opts={{
                height: '300px',
                width: '100%',
              }}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default VideosTab;
