import React, {useMemo} from 'react';
import SuiTypography from 'components/SuiTypography/index';
import SuiBox from 'components/SuiBox/index';
import {useRemoteConfig} from 'components/Firebase/FirebaseConfigProvider';
import {AiOutlineNotification} from 'react-icons/ai';

const BroadcastMessage = () => {
  const {broadcast_message, broadcast_message_color} = useRemoteConfig();

  const renderContent = () => {
    if (broadcast_message && broadcast_message.asString() !== '') {
      return (
        <>
          <SuiBox
            display={'flex'}
            flexDirection={'columns'}
            sx={{
              width: '100%',
              position: 'absolute',
              top: 0,
              right: 0,
              justifyContent: 'flex-end',
            }}>
            <SuiBox
              display={'flex'}
              flexDirection={'columns'}
              sx={{
                backgroundColor: broadcast_message_color?.asString(),
                borderBottomLeftRadius: 8,
                px: 1,
                py: 0.3,
              }}>
              <AiOutlineNotification
                fontSize={'medium'}
                color={'white'}
                style={{marginRight: 10, marginTop: 2, cursor: 'pointer'}}
              />
              <SuiTypography variant="button" fontWeight="bold" color="white">
                {broadcast_message.asString()}
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        </>
      );
    }
  };

  return <>{useMemo(() => renderContent(), [])}</>;
};

export default BroadcastMessage;
