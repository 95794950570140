import Table from 'components/SuiTable';
import * as React from 'react';
import {useEffect, useState} from 'react';
import axios from 'axios';
import BootPagination, {Page} from 'components/BootPagination';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import SuiBox from 'components/SuiBox/index';
import SuiTypography from 'components/SuiTypography/index';
import {FaRegSadTear} from 'react-icons/fa';
import {CoachInvitation} from 'pages/admin/invitations/AdminInvitationTypes';
import DashboardLayout from 'layout/DashboardLayout';
import DashboardNavbar from 'layout/navbar/DashboardNavbar';
import Card from '@mui/material/Card';
import Footer from 'layout/footer/Footer';
import SuiButton from 'components/SuiButton/index';
import {Icon, Tooltip} from '@mui/material';
import {useNavigate} from 'react-router-dom';

function AdminInvitationsPage() {
  const navigate = useNavigate();
  const [page, setPage] = useState<Page<CoachInvitation>>({} as Page<CoachInvitation>);
  const [pageNumber, setPageNumber] = useState(0);

  const columns = [
    {name: 'name', align: 'left'},
    {name: 'email', align: 'left'},
    {name: 'created', align: 'left'},
  ];

  useEffect(() => {
    axios
      .get(`/api/v0/admin/coach-invitations?page=${pageNumber}&size=10&sort=auditMetadata.created,DESC`)
      .then(response => response.data)
      .then(setPage)
      .catch(error => Promise.reject(error));
  }, [pageNumber]);

  const createRows = () =>
    page.content?.map(invitation => ({
      name: [null, invitation.name],
      email: invitation.email,
      created: moment(invitation.created).format('MM-DD-YYYY'),
    }));

  const renderInvitations = () => {
    return (
      <Grid container mt={{xs: 1, lg: 3}} mb={1}>
        <Grid item xs={12}>
          <Table columns={columns} rows={createRows()} tableStyles={{boxShadow: 'none'}} />
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center" sx={{p: 3}}>
          <BootPagination page={page} onClick={setPageNumber} />
        </Grid>
      </Grid>
    );
  };

  const renderNoSubscribers = () => {
    return (
      <Grid container>
        <Grid item xs={12} py={3}>
          <SuiBox display="flex" sx={{justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
            <FaRegSadTear fontSize={150} color={'#e9ecef'} />
            <SuiTypography variant="h6" fontWeight="medium" sx={{mt: 2}} color={'secondary'}>
              No invitations yet!
            </SuiTypography>
          </SuiBox>
        </Grid>
      </Grid>
    );
  };

  const renderContent = () => {
    if (page.content?.length === 0) {
      return renderNoSubscribers();
    }
    return renderInvitations();
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox py={3}>
          <SuiBox>
            <Grid container>
              <Grid item xs={12} mb={3}>
                <SuiButton
                  color="primary"
                  size={'small'}
                  onClick={() => navigate(`/admin/coach-invitations/create`)}
                  sx={{minWidth: '0px'}}>
                  <Tooltip title="Add Coach Invitation" placement="top">
                    <Icon>add</Icon>
                  </Tooltip>
                </SuiButton>
              </Grid>
              <Grid item xs={12} lg={8}>
                <Card sx={{height: '100%'}}>{renderContent()}</Card>
              </Grid>
            </Grid>
          </SuiBox>
        </SuiBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default AdminInvitationsPage;
