import {EditPlanProps} from 'pages/coaching/plans/edit/EditPlan.types';
import * as React from 'react';
import {useEffect, useState} from 'react';
import DataForm, {DataFormField} from 'components/DataForm/index';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import SuiTypography from 'components/SuiTypography/index';
import {CoachingFeature, Plan} from 'pages/coaching/plans/PlanTypes';
import axios from 'axios';
import {PaymentProvider} from 'pages/me/settings/payment/PaymentSettingsTab';
import PlanPaymentInfo from 'pages/coaching/plans/edit/payment/PlanPaymentInfo';
import Link from '@mui/material/Link';
import PaymentProviderIcon from 'pages/me/settings/payment/PaymentProviderIcon';
import SuiBox from 'components/SuiBox';
import Divider from '@mui/material/Divider';
import {FaStripe} from 'react-icons/fa';
import SuiButton from 'components/SuiButton/index';
import {useNavigate} from 'react-router-dom';
import {MdPayment} from 'react-icons/md';
import {FormikContextType} from 'formik';
import {CircularProgress} from '@mui/material';

export interface PlanPaymentFeature extends CoachingFeature {
  providerId: string;
  metadata?: {
    [key: string]: string;
  };
}

export const findPlanPaymentFeature = (plan: Plan): PlanPaymentFeature => {
  if (plan.coachingFeatures == null) {
    plan.coachingFeatures = [];
  }
  let feature = plan.coachingFeatures.find(feature => feature.type === 'feature-payment');
  return feature as PlanPaymentFeature;
};

export const loadProductUrl = (plan: Plan) => {
  const feature = loadPaymentFeature(plan);
  if (feature.providerId !== null && feature.metadata) {
    if (feature.metadata.stripeLivemode === 'true') {
      return 'https://dashboard.stripe.com/products/' + feature.metadata.stripeProductId;
    }
    return 'https://dashboard.stripe.com/test/products/' + feature.metadata.stripeProductId;
  }
  return 'https://dashboard.stripe.com/products';
};

export const loadPaymentFeature = (plan: Plan): PlanPaymentFeature => {
  let feature = findPlanPaymentFeature(plan);
  if (feature) {
    return feature;
  }
  return {
    type: 'feature-payment',
    providerId: null,
  };
};

const EditPlanPaymentTab = (props: EditPlanProps) => {
  const [planPaymentFeature, setPlanPaymentFeature] = useState<PlanPaymentFeature>(loadPaymentFeature(props.plan));
  const [providers, setProviders] = useState<PaymentProvider[]>();
  const navigate = useNavigate();
  const [fields, setFields] = useState<DataFormField[]>([]);
  const [productLink, setProductLink] = useState<string>();

  useEffect(() => {
    const load = async () => {
      const response = await axios.get(`/api/v0/payment/providers?page=0
        &size=100
        &sort=auditMetadata.lastModified,desc`);
      const providers: PaymentProvider[] = response.data.content;
      setProviders(providers);
      const options = providers.map(provider => {
        return {value: provider.id, label: provider.description};
      });
      setFields([
        {
          name: 'providerId',
          label: 'Payment Provider',
          type: 'select',
          columns: 6,
          options: options,
          rightComponent: ctx => createProductLink(providers, ctx),
        },
      ]);
      setPlanPaymentFeature(loadPaymentFeature(props.plan));
    };
    load();
  }, [props.plan, productLink]);

  useEffect(() => {
    setProductLink(loadProductUrl(props.plan));
  }, [props.plan]);

  const createProductLink = (providers: PaymentProvider[], ctx: FormikContextType<any>) => {
    if (loadPaymentFeature(props.plan).providerId !== null) {
      return (
        <Link href={productLink} target="_blank">
          <SuiBox sx={{display: 'flex', justifyContent: 'center', height: '100%'}}>
            <SuiTypography variant="button" fontWeight="regular" color={'primary'} pt={1.2} pl={2} pr={0.8}>
              update pricing and details on
            </SuiTypography>
            <PaymentProviderIcon
              providerType={providers.find(p => p.id === ctx.getFieldMeta('providerId').value)?.details.type}
              fontSize={40}
            />
          </SuiBox>
        </Link>
      );
    }
  };

  const validation = Yup.object().shape({
    providerId: Yup.string().ensure().required('Payment Provider is required.'),
  });

  const onSubmit = async (values, actions) => {
    const plan = {...props.plan};
    let feature = findPlanPaymentFeature(plan);
    if (feature) {
      feature.providerId = values.providerId;
    } else {
      plan.coachingFeatures.push({
        type: 'feature-payment',
        providerId: values.providerId,
      } as PlanPaymentFeature);
    }
    await props.onSubmit(plan, actions);
    setPlanPaymentFeature(values);
    setProductLink(loadProductUrl(plan));
  };

  const renderNoProviders = () => {
    return (
      <Grid container>
        <Grid item xs={12} pb={3}>
          <Divider />
          <SuiBox display="flex" sx={{justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
            <FaStripe fontSize={100} color={'#e9ecef'} />
            <SuiTypography variant="h6" fontWeight="medium" color={'secondary'} sx={{mt: 2}}>
              You have no payment providers connected yet...
            </SuiTypography>
            <SuiTypography variant="h6" fontWeight="medium" sx={{mt: 2}} color={'secondary'}>
              <SuiButton
                color={'primary'}
                size={'small'}
                onClick={() => {
                  navigate('/me/settings/payment');
                }}
                sx={{minWidth: '0px'}}>
                <MdPayment color={'#ffffff'} style={{marginRight: 3}} />
                Go to Payment Settings
              </SuiButton>
            </SuiTypography>
          </SuiBox>
        </Grid>
      </Grid>
    );
  };

  const renderForm = () => {
    return (
      <>
        <Grid item xs={12} px={2}>
          <PlanPaymentInfo />
        </Grid>
        <Divider />
        <DataForm
          validation={validation}
          onSubmit={onSubmit}
          fields={fields}
          initialValues={planPaymentFeature}
          submitLabel="save"
        />
      </>
    );
  };

  const renderLoading = () => {
    return (
      <Grid container item xs={12} px={2} justifyContent={'center'}>
        <Grid item p={3}>
          <CircularProgress color="primary" />
        </Grid>
      </Grid>
    );
  };

  const renderContent = () => {
    if (!providers) {
      return renderLoading();
    }
    if (providers?.length > 0) {
      return renderForm();
    }
    return renderNoProviders();
  };

  return (
    <>
      <Grid item xs={12} pt={4} px={2}>
        <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Accept Payments
        </SuiTypography>
      </Grid>
      <Grid item xs={12} px={2}>
        <SuiTypography variant="button" color="text" fontWeight="regular">
          When clients subscribe to this plan you need to collect payment from them. Here you can select the payment
          provider you want to use.
        </SuiTypography>
      </Grid>
      {renderContent()}
    </>
  );
};

export default EditPlanPaymentTab;
