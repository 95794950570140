import {EditPlanProps} from 'pages/coaching/plans/edit/EditPlan.types';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {CoachingFeature, Plan} from 'pages/coaching/plans/PlanTypes';
import {IntervalGoal} from 'pages/coaching/tasks/type-prepare-interval/IntervalGoalTypes';
import {Question} from 'components/Questionnaire/Questionnaire';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {FaFlagCheckered} from 'react-icons/fa';
import {QuizOutlined} from '@mui/icons-material';
import AppBar from '@mui/material/AppBar';
import TabOrientation from 'components/TabOrientation/TabOrientation';
import {Orientation} from '@mui/material';
import Grid from '@mui/material/Grid';
import SuiTypography from 'components/SuiTypography/index';
import IntervalGoalsForm from 'components/Interval/IntervalGoalsForm';
import DailyQuestionsForm from 'components/Interval/DailyQuestionsForm';
import {Interval, IntervalQuestionsDeliveryTime} from 'components/IntervalExecutionCard/IntervalTypes';
import {GoSettings} from 'react-icons/go';
import IntervalTemplateGeneralTab from 'pages/coaching/plans/edit/templates/IntervalTemplateGeneralTab';
import {FormikProps} from 'formik/dist/types';

export interface IntervalTemplate {
  description: string;
  goals: IntervalGoal[];
  questions: Question[];
  duration: number;
  questionsDeliveryTime: IntervalQuestionsDeliveryTime;
}

interface IntervalTemplateFeature extends CoachingFeature {
  template: IntervalTemplate;
}

const EditIntervalTemplateTab = (props: EditPlanProps) => {
  const [tabValue, setTabValue] = useState<'general' | 'goals' | 'questions'>('general');
  const [tabsOrientation, setTabsOrientation] = useState<Orientation>('horizontal');
  const formikRef = React.useRef<FormikProps<any>>(null);

  const findTemplateFeature = (plan: Plan): IntervalTemplateFeature => {
    if (plan.coachingFeatures == null) {
      plan.coachingFeatures = [];
    }
    let feature = plan.coachingFeatures.find(feature => feature.type === 'feature-check-in-template');
    return feature as IntervalTemplateFeature;
  };

  const loadTemplate = (): IntervalTemplate => {
    let feature = findTemplateFeature(props.plan);
    if (feature) {
      return feature.template;
    }
    return {
      description: '',
      duration: 7,
      goals: [],
      questions: [],
      questionsDeliveryTime: 'MORNING',
    };
  };
  const [intervalTemplate, setIntervalTemplate] = useState<IntervalTemplate>(() => loadTemplate());

  useEffect(() => {
    setIntervalTemplate(() => loadTemplate());
  }, [props.plan]);

  const [editInterval, setEditInterval] = useState<Interval>({
    id: 'template',
    description: intervalTemplate.description,
    goals: intervalTemplate.goals,
    questions: intervalTemplate.questions,
    status: 'DRAFT',
    questionsDeliveryTime: intervalTemplate.questionsDeliveryTime,
  });

  const handleSetTabValue = (event, newValue) => {
    formikRef.current.validateForm().then(errors => {
      formikRef.current.handleSubmit();
      if (Object.keys(errors).length === 0) {
        setTabValue(newValue);
      }
    });
  };

  useEffect(() => {
    setIntervalTemplate(() => loadTemplate());
  }, [props.plan]);

  useEffect(() => {
    setEditInterval({
      id: 'template',
      description: intervalTemplate.description,
      goals: intervalTemplate.goals,
      questions: intervalTemplate.questions,
      status: 'DRAFT',
      questionsDeliveryTime: intervalTemplate.questionsDeliveryTime,
    });
  }, [intervalTemplate]);

  const renderTabs = () => {
    switch (tabValue) {
      case 'general':
        return (
          <IntervalTemplateGeneralTab
            template={intervalTemplate}
            onSubmit={(values, actions) => {
              onSaveTemplate(values, actions);
            }}
            formikRef={formikRef}
          />
        );
      case 'goals':
        return (
          <IntervalGoalsForm
            interval={editInterval}
            description={
              'Add the goals below that you’d like to be present when preparing the first Check-In of your clients for this plan.'
            }
            isFirstInterval={true}
            minGoals={0}
            submitLabel={'save'}
            onSubmit={(values, actions) => {
              onSaveTemplate(values, actions);
            }}
            updateInterval={updated => {
              setEditInterval(updated);
            }}
            formikRef={formikRef}
          />
        );
      case 'questions':
        return (
          <DailyQuestionsForm
            interval={editInterval}
            description={
              'Add the questions below that you’d like to be present when preparing the first Check-In of your clients for this plan.'
            }
            isFirstInterval={true}
            submitLabel={'save'}
            onSubmit={(values, actions) => {
              onSaveTemplate(values, actions);
            }}
            updateInterval={updated => {
              setEditInterval(updated);
            }}
            formikRef={formikRef}
          />
        );
    }
  };

  const onSaveTemplate = (values: IntervalTemplate | Interval, helpers) => {
    const newTemplate = copyToTemplate(values);
    const plan = {...props.plan};
    let feature = findTemplateFeature(plan);
    if (feature) {
      feature.template = newTemplate;
    } else {
      plan.coachingFeatures.push({
        type: 'feature-check-in-template',
        template: newTemplate,
      } as IntervalTemplateFeature);
    }
    props.onSubmit(plan, helpers);
    setIntervalTemplate(newTemplate);
  };

  const copyToTemplate = (template: IntervalTemplate | Interval) => {
    const newTemplate = structuredClone(intervalTemplate);
    newTemplate.description = template.description;
    newTemplate.duration = (template as IntervalTemplate).duration
      ? (template as IntervalTemplate).duration
      : intervalTemplate.duration;
    newTemplate.questionsDeliveryTime = template.questionsDeliveryTime;
    newTemplate.goals = template.goals;
    newTemplate.questions = template.questions;
    return newTemplate;
  };

  return (
    <>
      <TabOrientation changeOrientation={setTabsOrientation} />
      <Grid container pt={2}>
        <Grid item xs={12} pt={2} px={2}>
          <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Check-in Template
          </SuiTypography>
        </Grid>
        <Grid item xs={12} px={2}>
          <SuiTypography variant="button" color="text" fontWeight="regular">
            When clients subscribe to this plan you need to create a check-in for the new subscriber to kick off the
            coaching. With the initial check-in template you can define goals and daily questions that will be used to
            create the initial check-in for the new subscriber. With this you don&apos;t need to repeat the same
            check-in content for every new subscriber. When creating the check-in you will still have the option to add
            or delete goals/questions before submitting.
          </SuiTypography>
        </Grid>
        <AppBar position="static">
          <Tabs
            orientation={tabsOrientation}
            value={tabValue}
            onChange={handleSetTabValue}
            sx={{background: 'transparent', px: 3, pt: 3}}>
            <Tab label="General" value="general" icon={<GoSettings fontSize={'medium'} />} />
            <Tab label="Goals" value="goals" icon={<FaFlagCheckered fontSize={'small'} />} />
            <Tab label="Daily Questions" value="questions" icon={<QuizOutlined />} />
          </Tabs>
        </AppBar>
        <Grid item xs={12}>
          {renderTabs()}
        </Grid>
      </Grid>
    </>
  );
};

export default EditIntervalTemplateTab;
