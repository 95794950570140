import * as React from 'react';
import SuiTypography from 'components/SuiTypography/index';
import Grid from '@mui/material/Grid';

const ConnectStripeDialog = () => {
  return (
    <>
      <SuiTypography variant="button" color="text" fontWeight="regular">
        By connecting your stripe account you confirm that you:
      </SuiTypography>
      <Grid container px={3} columnSpacing={1}>
        <Grid item xs={12} md={6}>
          <SuiTypography variant="button" color="text" fontWeight="regular">
            &bull; do taxes on your income
          </SuiTypography>
        </Grid>
        <Grid item xs={12} md={6}>
          <SuiTypography variant="button" color="text" fontWeight="regular">
            &bull; get charged stripe fees
          </SuiTypography>
        </Grid>
        <Grid item xs={12} md={6}>
          <SuiTypography variant="button" color="text" fontWeight="regular">
            &bull; need to handle chargebacks
          </SuiTypography>
        </Grid>
        <Grid item xs={12} md={6}>
          <SuiTypography variant="button" color="text" fontWeight="regular">
            &bull; need to handle refunds
          </SuiTypography>
        </Grid>
        <Grid item xs={12} md={6}>
          <SuiTypography variant="button" color="text" fontWeight="regular">
            &bull; need to handle any disputes
          </SuiTypography>
        </Grid>
        <Grid item xs={12} md={6}>
          <SuiTypography variant="button" color="text" fontWeight="regular">
            &bull; need to handle any fraud
          </SuiTypography>
        </Grid>
        <Grid item xs={12} md={6}>
          <SuiTypography variant="button" color="text" fontWeight="regular">
            &bull; need to handle to handle any other issues that may arise
          </SuiTypography>
        </Grid>
      </Grid>
      <SuiTypography variant="button" color="text" fontWeight="regular">
        After connecting you account it might take a while for the connection to complete. You will be notified when the
        connection is complete. You can continue using the app while the connection is being established. You can
        disconnect your account at any time.
      </SuiTypography>
    </>
  );
};

export default ConnectStripeDialog;
