import SuiBox from 'components/SuiBox/index';
import MiniStatisticsCard from 'components/ClientCard/MiniStatisticsCard/index';
import {useNavigate} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import {useRecoilValue} from 'recoil';
import {clientStatisticsState} from 'components/ClientCard/clientStatistics';

const ClientStatisticsCard = () => {
  const navigate = useNavigate();
  const clientStatistics = useRecoilValue(clientStatisticsState);

  return (
    <>
      <Grid item container xs={12} columnSpacing={3}>
        <Grid item xs={12} xl={6}>
          <SuiBox pb={3}>
            <MiniStatisticsCard
              title={{text: 'clients', fontWeight: 'bold'}}
              count={clientStatistics?.uniqueClients}
              icon={{color: 'success', component: 'person'}}
              onClick={() => navigate('/coaching/clients')}
            />
          </SuiBox>
        </Grid>
        <Grid item xs={12} xl={6}>
          <SuiBox pb={3}>
            <MiniStatisticsCard
              title={{text: 'subscriptions', fontWeight: 'bold'}}
              count={clientStatistics?.allClients}
              icon={{color: 'warning', component: 'people'}}
              onClick={() => navigate('/coaching/clients')}
            />
          </SuiBox>
        </Grid>
      </Grid>
    </>
  );
};

export default ClientStatisticsCard;
