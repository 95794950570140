import {useEffect, useState} from 'react';
import breakpoints from 'assets/theme/base/breakpoints';
import {Orientation} from '@mui/material';

type TabOrientationProps = {
  changeOrientation: (orientation: Orientation) => void;
};

const TabOrientation = (props: TabOrientationProps) => {
  const [tabsOrientation, setTabsOrientation] = useState<'vertical' | 'horizontal'>('horizontal');

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? changeOrientation('vertical')
        : changeOrientation('horizontal');
    }

    window.addEventListener('resize', handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener('resize', handleTabsOrientation);
  }, [tabsOrientation]);

  const changeOrientation = (orientation: Orientation) => {
    setTabsOrientation(orientation);
    props.changeOrientation(orientation);
  };

  return <></>;
};

export default TabOrientation;
