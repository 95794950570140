import {IconButton, styled, Tooltip, tooltipClasses, TooltipProps} from '@mui/material';
import {InfoOutlined} from '@mui/icons-material';

export type HintProps = {
  hint: string;
};

const Hint = (props: HintProps) => {
  // noinspection RequiredAttributes
  const CustomWidthTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
    },
  });

  return (
    <>
      <CustomWidthTooltip title={props.hint}>
        <IconButton sx={{p: 0}}>
          <InfoOutlined fontSize={'small'} sx={{mb: 0.3, pb: 0.2, color: '#adb5bd'}} />
        </IconButton>
      </CustomWidthTooltip>
    </>
  );
};

export default Hint;
