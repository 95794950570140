import Table from 'components/SuiTable';
import * as React from 'react';
import {useEffect, useState} from 'react';
import axios from 'axios';
import BootPagination, {Page} from 'components/BootPagination';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import {Navigate} from 'react-router-dom';
import {Plan} from 'pages/coaching/plans/PlanTypes';
import SuiBox from 'components/SuiBox/index';
import SuiTypography from 'components/SuiTypography/index';
import {FaRegSadTear} from 'react-icons/fa';
import {Invitation, InvitationStatus} from 'pages/coaching/clients/invite/InvitationTypes';
import Badge from '@mui/material/Badge';
import {useConfirm} from 'components/ConfirmDialog/index';
import {MdCancel, MdRestore, MdSend} from 'react-icons/md';
import {useTheme} from '@mui/material';

type PlanInvitationsTabProps = {
  plan: Plan;
};

function PlanInvitationsTab(props: PlanInvitationsTabProps) {
  const {palette} = useTheme();
  const confirm = useConfirm();
  const [page, setPage] = useState<Page<Invitation>>({} as Page<Invitation>);
  const [pageNumber, setPageNumber] = useState(0);

  const columns = [
    {name: 'name', align: 'left'},
    {name: 'email', align: 'left'},
    {name: 'sent', align: 'center'},
    {name: 'status', align: 'center'},
    {name: 'actions', align: 'center'},
  ];

  useEffect(() => {
    if (props.plan.id) {
      axios
        .get(`/api/v0/invitations?page=${pageNumber}&size=10&sort=auditMetadata.created,DESC&plan=${props.plan.id}`)
        .then(response => response.data)
        .then(setPage)
        .catch(error => Promise.reject(error));
    }
  }, [pageNumber, props.plan]);

  const determineBadgeColor = (status: InvitationStatus) => {
    switch (status) {
      case 'ACCEPTED':
        return 'primary';
      case 'PENDING':
        return 'info';
      case 'CANCELED':
        return 'error';
    }
  };

  const cancelInvitation = async (invitation: Invitation) => {
    try {
      await confirm({
        title: `Cancel Invitation`,
        content: `Are you sure you want to cancel the invitation for ${invitation.name}?`,
        confirmationText: 'Cancel',
        confirmationButtonProps: {
          color: 'error',
        },
      });
      await updateInvitation(invitation, 'CANCELED');
    } catch (error) {
      // do nothing
    }
  };

  const restoreInvitation = async (invitation: Invitation) => {
    try {
      await confirm({
        title: `Restore Invitation`,
        content: `Are you sure you want to restore the invitation for ${invitation.name}?`,
        confirmationText: 'Restore',
        confirmationButtonProps: {
          color: 'success',
        },
      });
      await updateInvitation(invitation, 'PENDING');
    } catch (error) {
      // do nothing
    }
  };

  const resendInvitation = async (invitation: Invitation) => {
    try {
      await confirm({
        title: `Resend Invitation`,
        content: `Are you sure you want to resend the invitation for ${invitation.name}? If the user has an active subscription this will not change anything.`,
        confirmationText: 'Resend',
        confirmationButtonProps: {
          color: 'success',
        },
      });
      await updateInvitation(invitation, 'PENDING');
    } catch (error) {
      // do nothing
    }
  };

  const updateInvitation = async (invitation: Invitation, status: InvitationStatus) => {
    try {
      const response = await axios.patch(`/api/v0/invitations/${invitation.id}`, {status: status});
      const updated = response.data;
      const newPage = {...page};
      const newInvitation = newPage.content?.find(invitation => invitation.id === updated.id);
      newInvitation.status = updated.status;
      setPage(newPage);
    } catch (error) {
      alert('Could not restore invitation');
    }
  };

  const createActions = (invitation: Invitation) => {
    switch (invitation.status) {
      case 'ACCEPTED':
        return (
          <MdSend
            title={'Resend Invitation'}
            size={20}
            color={palette.secondary.main}
            style={{cursor: 'pointer'}}
            onClick={() => resendInvitation(invitation)}
          />
        );
      case 'PENDING':
        return (
          <MdCancel
            title={'Cancel Invitation'}
            size={20}
            color={palette.error.main}
            style={{cursor: 'pointer'}}
            onClick={() => cancelInvitation(invitation)}
          />
        );
      case 'CANCELED':
        return (
          <MdRestore
            title={'Restore Invitation'}
            size={20}
            color={palette.secondary.main}
            style={{cursor: 'pointer'}}
            onClick={() => restoreInvitation(invitation)}
          />
        );
    }
  };

  const createRows = () =>
    page.content?.map(invitation => ({
      name: [null, invitation.name],
      email: invitation.email,
      sent: moment(invitation.created).local().format('MM-DD-YYYY'),
      status: (
        <Badge
          badgeContent={invitation.status.toLowerCase()}
          color={determineBadgeColor(invitation.status)}
          sx={{
            '& .MuiBadge-badge': {
              color: '#ffffff',
            },
          }}
        />
      ),
      actions: (
        <SuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          {createActions(invitation)}
        </SuiBox>
      ),
    }));

  const renderInvitations = () => {
    return (
      <Grid container mt={{xs: 1, lg: 3}} mb={1}>
        <Grid item xs={12}>
          <Table columns={columns} rows={createRows()} tableStyles={{boxShadow: 'none'}} />
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center" sx={{p: 3}}>
          <BootPagination page={page} onClick={setPageNumber} />
        </Grid>
      </Grid>
    );
  };

  const renderNoSubscribers = () => {
    return (
      <Grid container>
        <Grid item xs={12} py={3}>
          <SuiBox display="flex" sx={{justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
            <FaRegSadTear fontSize={150} color={'#e9ecef'} />
            <SuiTypography variant="h6" fontWeight="medium" sx={{mt: 2}} color={'secondary'}>
              No invitations yet!
            </SuiTypography>
          </SuiBox>
        </Grid>
      </Grid>
    );
  };

  const renderContent = () => {
    if (props.plan.status === 'DRAFT') {
      return <Navigate replace to={`/coaching/plans/${props.plan.id}/edit`} />;
    }
    if (props.plan.id) {
      if (page.content?.length === 0) {
        return renderNoSubscribers();
      }
      return renderInvitations();
    }
  };

  return renderContent();
}

export default PlanInvitationsTab;
