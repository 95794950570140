import {FormikHelpers, FormikProps} from 'formik/dist/types';
import Grid from '@mui/material/Grid';
import DataForm, {DataFormField} from 'components/DataForm/index';
import * as Yup from 'yup';
import {IntervalTemplate} from 'pages/coaching/plans/edit/templates/EditIntervalTemplateTab';

type IntervalTemplateGeneralTabProps = {
  template: IntervalTemplate;
  onSubmit: (template: IntervalTemplate, actions: FormikHelpers<any>) => void;
  formikRef: React.RefObject<FormikProps<any>>;
};

const IntervalTemplateGeneralTab = (props: IntervalTemplateGeneralTabProps) => {
  const fields: DataFormField[] = [
    {
      name: 'description',
      label: 'Description',
      type: 'editor',
      placeholder: 'eg. what is this check-in all about?',
    },
    {
      name: 'duration',
      label: 'Check-in Duration',
      type: 'number',
      placeholder: '7',
      columns: 6,
      hint: 'The default number of days between check-ins. This can be changed during check-in preparation',
    },
  ];

  const validation = Yup.object().shape({
    duration: Yup.number()
      .positive('Default Check-In Duration must be a positive number.')
      .min(1, 'Default Check-In Duration must be greater than or equal to 1.')
      .required('Default Check-In Duration is required.'),
  });

  return (
    <>
      <Grid container item xs={12}>
        <Grid item xs={12} lg={12}>
          <DataForm
            validation={validation}
            onSubmit={props.onSubmit}
            fields={fields}
            initialValues={props.template}
            readOnly={false}
            submitLabel="save"
            formikRef={props.formikRef}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default IntervalTemplateGeneralTab;
