import {CompoundQuestionnaireResponse} from 'components/Questionnaire/Questionnaire';
import {QuestionSupportFactory} from 'components/Questionnaire/QuestionSupportFactory';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import SuiTypography from 'components/SuiTypography/index';
import * as React from 'react';

export type CompoundQuestionnaireResponseViewProps = {
  response: CompoundQuestionnaireResponse;
};

const CompoundQuestionnaireResponseView = (props: CompoundQuestionnaireResponseViewProps) => {
  const supportFactory = new QuestionSupportFactory();

  const renderCompoundAnswer = (answer, index: number) => {
    return (
      <Grid item container key={'answer-' + index}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <SuiTypography variant="button" fontWeight="bold">
            #{index + 1}: {answer.question.content}
          </SuiTypography>
        </Grid>
        <Grid item xs={12} sx={{pl: 2}}>
          {supportFactory.supportFor(answer.question.type).renderCompoundAnswer(answer)}
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {props.response &&
        props.response.answers.map((answer, index) => {
          return renderCompoundAnswer(answer, index);
        })}
    </>
  );
};

export default CompoundQuestionnaireResponseView;
