import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';

import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiAvatar from 'components/SuiAvatar';
import {Skeleton} from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {SyntheticEvent, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import imageSrc from 'utils/UserImage';
import axios from 'axios';
import {Plan} from 'pages/coaching/plans/PlanTypes';
import {useConfirm} from 'components/ConfirmDialog/index';
import {FaStripeS} from 'react-icons/fa';
import {loadPaymentFeature, loadProductUrl} from 'pages/coaching/plans/edit/payment/EditPlanPaymentTab';
import {useRemoteConfig} from 'components/Firebase/FirebaseConfigProvider';

type PlanCardProps = {
  plan: Plan;
  deletePlan: (planId: string) => void;
};

const PlanCard = (props: PlanCardProps) => {
  const {feature_payment} = useRemoteConfig();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const [menu, setMenu] = useState(null);
  const [subscribers, setSubscribers] = useState([]);

  useEffect(() => {
    axios
      .get(`/api/v0/plans/${props.plan.id}/subscribers?size=10&page=0`)
      .then(response => response.data.content)
      .then(setSubscribers)
      .catch(error => Promise.reject(error));
  }, []);

  const openMenu = event => {
    event.stopPropagation();
    setMenu(event.currentTarget);
  };

  const onSubscriberClick = event => {
    event.stopPropagation();
    navigate(`/coaching/plans/${props.plan.id}/subscribers`);
  };

  const renderSubscribers = subscribers.map((subscriber, key) => {
    const subscriberKey = `subscriber-${key}`;
    return (
      <SuiAvatar
        key={subscriberKey}
        src={subscriber.picture}
        alt={subscriber.name}
        title={subscriber.name}
        size="xs"
        onClick={onSubscriberClick}
        sx={({borders: {borderWidth}, palette: {white}}) => ({
          border: `${borderWidth[2]} solid ${white.main}`,
          cursor: 'pointer',
          position: 'relative',

          '&:not(:first-of-type)': {
            ml: -1.25,
          },

          '&:hover, &:focus': {
            zIndex: '10',
          },
        })}
      />
    );
  });

  const renderPromoteMenuItem = () => {
    if (props.plan.status === 'DRAFT') {
      return (
        <MenuItem
          onClick={e => {
            setMenu(null);
            e.stopPropagation();
            navigate(`/coaching/plans/${props.plan.id}/go-live`);
          }}>
          <Icon sx={{mr: 1}}>rocket_launch</Icon> Go Live
        </MenuItem>
      );
    }
  };

  const renderEditOnStripeMenuItem = () => {
    const feature = loadPaymentFeature(props.plan);
    if (feature_payment?.asBoolean() && feature?.providerId !== null) {
      return (
        <MenuItem
          onClick={e => {
            setMenu(null);
            e.stopPropagation();
            window.open(loadProductUrl(props.plan), '_blank');
          }}>
          <FaStripeS style={{marginRight: 8}} /> Edit on Stripe
        </MenuItem>
      );
    }
  };

  const renderDeleteMenuItem = () => {
    if (props.plan.status === 'DRAFT') {
      return (
        <MenuItem
          onClick={e => {
            setMenu(null);
            e.stopPropagation();
            deletePlan();
          }}>
          <Icon sx={{mr: 1}}>delete</Icon> Delete
        </MenuItem>
      );
    }
  };

  const deletePlan = async () => {
    try {
      await confirm({
        title: 'Delete Plan',
        content: 'Deleting a plan cannot be undone. Are you sure you want to delete this plan?',
        confirmationText: 'Delete',
        confirmationButtonProps: {
          color: 'error',
        },
      });
      await sendDeletePlan();
    } catch (error) {
      // do nothing
    }
  };

  const sendDeletePlan = async () => {
    try {
      await axios.delete(`/api/v0/plans/${props.plan.id}`);
      props.deletePlan(props.plan.id);
    } catch (error) {
      alert('Error deleting plan');
    }
  };

  const renderMenu = () => (
    <Menu
      anchorEl={menu}
      anchorOrigin={{vertical: 'top', horizontal: 'left'}}
      transformOrigin={{vertical: 'top', horizontal: 'right'}}
      open={Boolean(menu)}
      onClose={(e: SyntheticEvent) => {
        e.stopPropagation();
        setMenu(null);
      }}
      keepMounted>
      <MenuItem
        onClick={e => {
          setMenu(null);
          e.stopPropagation();
          navigate(`/coaching/plans/${props.plan.id}/edit`);
        }}>
        <Icon sx={{mr: 1}}>edit</Icon> Edit
      </MenuItem>
      {renderEditOnStripeMenuItem()}
      {renderPromoteMenuItem()}
      {renderDeleteMenuItem()}
    </Menu>
  );

  const renderIcon = () => {
    if (props.plan.icon !== null) {
      return (
        <SuiAvatar
          src={imageSrc(props.plan.icon)}
          alt={props.plan.name}
          size="xl"
          variant="circular"
          bgColor="light"
          imgProps={{
            sx: {
              height: '100%',
              width: '100%',
              objectFit: 'cover',
            },
          }}
        />
      );
    }
    return <Skeleton variant="circular" width={74} height={74} />;
  };

  const onClickCard = () => {
    if (props.plan.status === 'DRAFT') {
      navigate(`/coaching/plans/${props.plan.id}/edit`);
    } else {
      navigate(`/coaching/plans/${props.plan.id}`);
    }
  };

  const renderPlanInfo = () => {
    return (
      <>
        <SuiTypography variant="button" fontWeight="medium">
          {props.plan.availability.subscribed} / {props.plan.availability.total}
        </SuiTypography>
        <SuiTypography variant="button" fontWeight="medium" color="secondary">
          subscribers
        </SuiTypography>
      </>
    );
  };

  const renderDraftLabel = () => {
    if (props.plan.status === 'DRAFT') {
      return (
        <SuiBox
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 1000,
            backgroundColor: 'primary.main',
            borderBottomRightRadius: '10px',
            px: 2,
          }}>
          <SuiTypography variant="h6" textTransform="uppercase" color="white" fontWeight="medium">
            draft
          </SuiTypography>
        </SuiBox>
      );
    }
  };

  const truncate = (str, n) => {
    if (str && str.length > n) {
      return str.substring(0, n) + '...';
    } else {
      return str;
    }
  };

  return (
    <Card
      sx={{
        minHeight: '225px',
        height: '100%',
        cursor: 'pointer',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'contain',
      }}
      onClick={onClickCard}>
      {renderDraftLabel()}
      <SuiBox p={2}>
        <SuiBox display="flex" alignItems="center">
          {renderIcon()}
          <SuiBox ml={2} lineHeight={0}>
            <SuiBox mb={1} lineHeight={0}>
              <SuiTypography variant="h6" textTransform="capitalize" fontWeight="medium">
                {truncate(props.plan.name, 30)}
              </SuiTypography>
            </SuiBox>
            {subscribers.length > 0 ? (
              <SuiBox display="flex">{renderSubscribers}</SuiBox>
            ) : (
              <SuiBox display="flex">
                <span style={{height: 24}} />
              </SuiBox>
            )}
          </SuiBox>
          <SuiTypography
            color="secondary"
            onClick={openMenu}
            sx={{
              ml: 'auto',
              alignSelf: 'flex-start',
              py: 1.25,
            }}>
            <Icon fontSize="medium" sx={{cursor: 'pointer'}}>
              more_vert
            </Icon>
          </SuiTypography>
          {renderMenu()}
        </SuiBox>
        <SuiBox my={2} lineHeight={1}>
          <SuiTypography variant="button" fontWeight="regular" color="text">
            {props.plan.summary}
          </SuiTypography>
        </SuiBox>
        <Divider />
        <SuiBox display="flex" justifyContent="flex-start" alignItems="center">
          <SuiBox sx={{flexGrow: 1}} />
          <SuiBox display="flex" flexDirection="column" lineHeight={0}>
            {renderPlanInfo()}
          </SuiBox>
        </SuiBox>
      </SuiBox>
    </Card>
  );
};

PlanCard.propTypes = {
  plan: PropTypes.instanceOf(Object).isRequired,
};

export default PlanCard;
