// Import the functions you need from the SDKs you need
import {deleteToken, getMessaging, getToken, onMessage} from 'firebase/messaging';
import {MessagePayload} from '@firebase/messaging';
import UserSettings from 'utils/settings/UserSettings';
import {NotificationSettings} from 'pages/notifictions/NotificationTypes';
import firebaseApp from 'components/Firebase/FirebaseApp';

let messaging = getMessaging(firebaseApp);

export const getFirebaseToken = async (): Promise<string | undefined> => {
  try {
    return await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    });
  } catch (e) {
    console.warn('Error getting firebase token', e);
    return undefined;
  }
};

export const onLogout = async (userSettings: UserSettings) => {
  const token = await getFirebaseToken();
  await deleteToken(messaging);
  const settings = (await userSettings.getSettings('notifications')) as NotificationSettings;
  settings.coachFirebaseTokens.splice(settings.coachFirebaseTokens.indexOf(token), 1);
  await userSettings.updateSettings(settings);
};

// handle incoming messages
let globalSubscription = null;
const subscribers = [];

export const subscribe = onNewMessage => {
  if (globalSubscription === null) {
    globalSubscription = onMessage(messaging, payload => {
      subscribers.forEach(s => s(payload as MessagePayload));
    });
  }
  subscribers.push(onNewMessage);
  return () => {
    subscribers.splice(subscribers.indexOf(onNewMessage), 1);
  };
};
