import Grid from '@mui/material/Grid';
import {UserDetails} from 'pages/coaching/tasks/TaskTypes';
import SuiTypography from 'components/SuiTypography/index';
import {CaloriesGoal} from 'pages/coaching/tasks/type-prepare-interval/IntervalGoalTypes';
import IntervalGoalCard from 'components/IntervalExecutionCard/goals-results/IntervalGoalCard';
import Hint from 'components/Hint/Hint';
import {ImFire} from 'react-icons/im';

type CaloriesGoalViewProps = {
  goal: CaloriesGoal;
  client: UserDetails;
};

const CaloriesGoalView = (props: CaloriesGoalViewProps) => {
  return (
    <>
      <SuiTypography variant="button" fontWeight="regular" color={'text'}>
        You defined the following calories goal for {props.client.name}.
        <Hint
          hint={
            'Green values are within the goal range, yellow values are outside the goal range, and red values are missing.'
          }
        />
      </SuiTypography>
      <Grid container item xs={12} rowSpacing={3} sx={{pt: 1, pb: 1}}>
        <Grid item xs={12} md={4} pr={{xs: 0, md: 0, lg: 3}}>
          <IntervalGoalCard
            icon={<ImFire />}
            color="info"
            type="Calories"
            value={
              <>
                <span style={{color: '#439946'}}>{props.goal.minCalories}</span> -{' '}
                <span style={{color: '#439946'}}>{props.goal.maxCalories}</span>
              </>
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CaloriesGoalView;
