// formik components
// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import * as Yup from 'yup';
import DataForm, {DataFormField} from 'components/DataForm';
import Card from '@mui/material/Card';
import FileUploader from 'components/FileUploader';
import axios from 'axios';
import SuiTypography from 'components/SuiTypography/index';
import * as React from 'react';
import {CoachProfile} from 'pages/me/profile/ProfilePage';
import {YouTube} from '@mui/icons-material';

export type EditProfileMediaTabProps = {
  profile: CoachProfile;
  onProfileUpdate: (profile: CoachProfile) => Promise<void>;
};

const EditProfileMediaTab = (props: EditProfileMediaTabProps) => {
  const fields: DataFormField[] = [
    {type: 'divider', label: ''},
    {
      type: 'description',
      label:
        'The introduction video can be viewed by anyone who visits your profile page. It is a great way to introduce yourself and your business.',
    },
    {name: 'introductionVideo', label: 'Introduction Video', type: 'text', columns: 6, icon: <YouTube />},
  ];

  const validation = Yup.object().shape({});

  const submitForm = async (values, actions) => {
    const data = {
      introductionVideo: values.introductionVideo,
      pictures: props.profile.pictures,
    };
    try {
      const response = await axios.patch(`/api/v0/profile/coach`, data);
      await props.onProfileUpdate(response.data);
    } catch (error) {
      actions.setStatus(error?.response?.data?.message);
    }
  };

  return (
    <>
      <Grid container py={3}>
        <Grid item xs={12} xl={8} order={{xs: 2, xl: 1}}>
          <Card>
            <Grid item xs={12} pt={2} px={2}>
              <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                Media
              </SuiTypography>
            </Grid>
            <Grid item xs={12} px={2}>
              <SuiTypography variant="button" color="text" fontWeight="regular">
                {
                  'Good looking photos and videos are a great way to attract new clients. You can upload up to 5 photos and link 1 YouTube video. Your first picture will be used as your profile picture.'
                }
              </SuiTypography>
            </Grid>
            <SuiBox>
              <Grid container>
                <Grid item xs={12} lg={12}>
                  <FileUploader
                    label="Profile Pictures"
                    message='Drag & Drop your profile pictures or <span class="filepond--label-action">Browse</span>'
                    visibility="PUBLIC"
                    maxFiles={5}
                    userFiles={props.profile.pictures}
                    onFileUploaded={file => {
                      props.profile.pictures.push(file);
                    }}
                    onFileRemoved={file => {
                      props.profile.pictures = props.profile.pictures.filter(p => p.id !== file.id);
                    }}
                    targetHeight={600}
                    targetWidth={600}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Card sx={{height: '100%'}}>
                    <DataForm
                      validation={validation}
                      fields={fields}
                      onSubmit={submitForm}
                      initialValues={props.profile}
                    />
                  </Card>
                </Grid>
              </Grid>
            </SuiBox>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default EditProfileMediaTab;
