// react-routers components

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { Icon } from "@mui/material";
import SuiAvatar from "components/SuiAvatar";

function VersionInfoCard({ title, link, linkText, icon, color }) {
  const renderLink = () => {
    if (link.startsWith("http") || link.startsWith("https")) {
      return (
        <Link href={link} target="_blank">
          {linkText}
        </Link>
      );
    }
    return linkText;
  };

  return (
    <Card sx={{ height: "100%" }}>
      <SuiBox display="flex" justifyContent="left" alignItems="center" p={2}>
        <SuiAvatar bgColor={color}>
          <Icon fontSize="medium">{icon}</Icon>
        </SuiAvatar>

        <Grid item xs={8}>
          <SuiBox ml={2} lineHeight={1}>
            <SuiTypography
              variant="button"
              color="dark"
              opacity={0.7}
              textTransform="capitalize"
              fontWeight="bold"
            >
              {title}
            </SuiTypography>
            <SuiTypography variant="h6" fontWeight="bold" color="dark">
              {renderLink()}
            </SuiTypography>
          </SuiBox>
        </Grid>
      </SuiBox>
    </Card>
  );
}
//
// Typechecking props for the ProfileInfoCard
VersionInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  color: PropTypes.oneOf([
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]).isRequired,
};

export default VersionInfoCard;
