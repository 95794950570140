// @mui material components
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';

// Soft UI Dasboard PRO Material components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiButton from 'components/SuiButton';

// General page components
import NotificationItem from 'pages/me/notifications/NotificationItem';
import Grid from '@mui/material/Grid';
import BootPagination, {Page} from 'components/BootPagination';
import {NotificationType} from 'pages/notifictions/NotificationTypes';
import * as React from 'react';
import {BiMessageRoundedX} from 'react-icons/bi';

export type AuditMetadata = {
  created: string;
  lastModified: string;
};

export type UserNotificationMetadata = {
  color: string;
  iconLocation: string;
  action: string;
};

export type UserNotification = {
  id: string;
  auditMetadata: AuditMetadata;
  title: string;
  content: string;
  metadata: UserNotificationMetadata;
  read: boolean;
  type: NotificationType;
};

export type NotificationListProps = {
  title: string;
  size: number;
  page: Page<UserNotification>;
  onPageChange: (page: number) => void;
  onToggleNotificationRead: (notification: UserNotification) => Promise<void>;
  onDeleteNotification: (notification: UserNotification) => Promise<void>;
  onMarkAllRead: () => Promise<void>;
};

const NotificationsList = (props: NotificationListProps) => {
  const getNotificationCount = () => {
    if (props.size === 1) {
      return props.size + ' notification';
    }
    return props.size + ' notifications';
  };

  const renderContent = () => {
    if (props.page.empty) {
      return (
        <SuiBox display="flex" sx={{justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
          <BiMessageRoundedX fontSize={150} color={'#e9ecef'} />
          <SuiTypography variant="h6" fontWeight="medium" sx={{mt: 2}} color={'secondary'}>
            No notifications yet!
          </SuiTypography>
        </SuiBox>
      );
    } else {
      return (
        <SuiBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {props.page.content.map((notification, index) => (
            <NotificationItem
              key={notification.id}
              notification={notification}
              noDivider={index === props.page.content.length - 1}
              onToggleNotificationRead={props.onToggleNotificationRead}
              onDeleteNotification={props.onDeleteNotification}
            />
          ))}
        </SuiBox>
      );
    }
  };

  return (
    <>
      <Grid>
        <Card>
          <SuiBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
            <SuiTypography variant="h6" fontWeight="medium">
              {props.title}
            </SuiTypography>
            <SuiTypography variant="button" fontWeight="regular" color="text">
              {getNotificationCount()}
              <SuiButton
                size="small"
                variant="outlined"
                color="primary"
                onClick={props.onMarkAllRead}
                sx={{ml: 3}}
                disabled={props.page.empty}>
                Mark all Read
              </SuiButton>
            </SuiTypography>
          </SuiBox>
          <Divider />
          <SuiBox pb={2} px={2}>
            {renderContent()}
          </SuiBox>
        </Card>
      </Grid>
      <Grid container direction="row" justifyContent="center" alignItems="center" sx={{p: 3}}>
        <BootPagination page={props.page} onClick={props.onPageChange} />
      </Grid>
    </>
  );
};

export default NotificationsList;
