import {useEffect, useState} from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import ClientInfo from 'components/ClientCard/ClientInfo';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import {Client, Subscription} from 'pages/coaching/clients/client/Client';
import moment from 'moment/moment';
import ClientNoteForm from 'components/ClientCard/ClientNoteForm';
import Card from '@mui/material/Card';
import {useMediaQuery, useTheme} from '@mui/material';
import {YouTube} from '@mui/icons-material';

type ClientCardProps = {
  clientId: string;
  subscriptionId: string;
  layout: 'horizontal' | 'vertical';
};

const ClientCard = (props: ClientCardProps) => {
  const [client, setClient] = useState<Client>({
    subscriptions: [],
  } as Client);
  const [subscription, setSubscription] = useState<Subscription>();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    axios
      .get(`/api/v0/clients/${encodeURI(props.clientId)}`)
      .then(response => response.data)
      .then(c => {
        setClient(c);
        setSubscription(determineSubscription(c));
      })
      .catch(error => Promise.reject(error));
  }, [props.clientId, props.subscriptionId]);

  const determineSubscription = client => {
    return client.subscriptions.find(s => s.id === props.subscriptionId);
  };

  const determineClientInfo = () => {
    return {
      name: determineClientName(),
      subscribedSince: subscription ? moment(subscription.created).local().format('MM-DD-YYYY') : '',
      status: subscription ? (subscription.canceled ? 'not active' : 'active') : '',
      location: determineLocation(),
    };
  };

  const determineLocation = () => {
    if (client.profile && client.profile.address && client.profile.address.country) {
      return client.profile.address.country;
    }
    return 'unknown';
  };

  const determineClientName = () => {
    if (client.profile && client.profile.fullName) {
      return `${client.name} (${client.profile.fullName})`;
    }
    return;
  };

  const determineSocialMedia = () => {
    let socialMedia = [];
    if (client.profile && client.profile.social) {
      if (client.profile.social.facebook) {
        socialMedia.push({
          link: `https://facebook.com/${client.profile.social.facebook}`,
          icon: <FacebookIcon />,
          color: 'facebook',
        });
      }
      if (client.profile.social.twitter) {
        socialMedia.push({
          link: `https://twitter.com/${client.profile.social.twitter}`,
          icon: <TwitterIcon />,
          color: 'twitter',
        });
      }
      if (client.profile.social.instagram) {
        socialMedia.push({
          link: `https://instagram.com/${client.profile.social.instagram}`,
          icon: <InstagramIcon />,
          color: 'instagram',
        });
      }
      if (client.profile.social.youtube) {
        socialMedia.push({
          link: `https://youtube.com/${client.profile.social.youtube}`,
          icon: <YouTube />,
          color: 'youtube',
        });
      }
    }
    return socialMedia;
  };

  return (
    <>
      <Card>
        <Grid container>
          <Grid item xs={isXs || props.layout === 'vertical' ? 12 : 6}>
            <ClientInfo
              id={client.userId}
              avatar={client.picture}
              title={`${client.name}`}
              description={`${client.name} is subscribed to your plan '${
                subscription ? subscription.plan.name : null
              }'. Here are some details about your client:`}
              info={determineClientInfo()}
              social={determineSocialMedia()}
            />
          </Grid>
          <Grid item xs={isXs || props.layout === 'vertical' ? 12 : 6}>
            <ClientNoteForm clientId={client.userId} />
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default ClientCard;
