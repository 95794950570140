import {Task} from 'pages/coaching/tasks/TaskTypes';
import {Card, Grid} from '@mui/material';
import axios from 'axios';
import * as React from 'react';
import {useEffect, useState} from 'react';
import SuiTypography from 'components/SuiTypography/index';
import SuiButton from 'components/SuiButton/index';
import {useTour} from '@reactour/tour';
import {TbBus} from 'react-icons/tb';

type ProductTour = {
  id: string;
  title: string;
  description: string;
  type: string;
  status: 'OPEN' | 'COMPLETED';
};

type ProductTourTaskProps = {
  task: Task;
};

const ProductTourTask = (props: ProductTourTaskProps) => {
  const {setIsOpen, setCurrentStep} = useTour();
  const [productTour, setProductTour] = useState<ProductTour>();

  useEffect(() => {
    axios
      .get(`/api/v0/product-tours/${props.task.referencedEntity}`)
      .then(response => response.data)
      .then((s: ProductTour) => {
        setProductTour(s);
      })
      .catch(error => Promise.reject(error));
  }, [props.task.referencedEntity]);

  const onTourStart = async () => {
    setCurrentStep(0);
    setIsOpen(true);
    if (productTour?.status === 'OPEN') {
      productTour.status = 'COMPLETED';
      try {
        const response = await axios.patch(`/api/v0/product-tours/${props.task.referencedEntity}`, productTour);
        setProductTour(response.data);
      } catch (error) {
        alert('Error while updating product tour');
      }
    }
  };

  return (
    <>
      <Grid container alignItems="flex-start" spacing={3}>
        <Grid item xs={12} xl={8}>
          <Card className={'imc-tour-task'}>
            <Grid container>
              <Grid item xs={12} pt={2} px={2}>
                <SuiTypography variant="h6" fontWeight="medium">
                  {productTour?.title}
                </SuiTypography>
              </Grid>
              <Grid item xs={12} px={2}>
                <SuiTypography variant="button" color="text" fontWeight="regular">
                  This is a product tour task. You can start the tour by clicking the button below. After starting the
                  tour, this task will be marked as completed. Feel free to come back to this task later and start the
                  tour again.
                </SuiTypography>
              </Grid>
              <Grid container item direction={'column'} xs={12} p={3} justifyContent={'center'} alignItems={'center'}>
                <TbBus color={'#67748e'} style={{marginLeft: 50}} />
                <SuiButton size="small" color="primary" onClick={onTourStart}>
                  Start Tour
                </SuiButton>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default ProductTourTask;
