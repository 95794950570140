import Grid from '@mui/material/Grid';
import SuiTypography from 'components/SuiTypography/index';
import DataForm, {DataFormField} from 'components/DataForm/index';
import * as Yup from 'yup';
import {useState} from 'react';
import DashboardNavbar from 'layout/navbar/DashboardNavbar';
import DashboardLayout from 'layout/DashboardLayout';
import SuiBox from 'components/SuiBox';
import {Card} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {useConfirm} from 'components/ConfirmDialog/index';
import {CoachInvitation} from 'pages/admin/invitations/AdminInvitationTypes';
import axios from 'axios';

const AdminCreateInvitationPage = () => {
  const navigate = useNavigate();
  const [invitation, setInvitation] = useState<CoachInvitation>({} as CoachInvitation);
  const confirm = useConfirm();

  const fields: DataFormField[] = [
    {name: 'name', label: 'Name', type: 'text', placeholder: '', columns: 6},
    {name: 'email', label: 'Email', type: 'text', placeholder: '', columns: 6},
  ];

  const validation = Yup.object().shape({
    name: Yup.string().ensure().required('Name is required.'),
    email: Yup.string().ensure().required('Email is required.').email(),
  });

  const onSubmit = async (values, actions) => {
    try {
      await confirm({
        title: 'Create Coach Invitation',
        content:
          'After creating the invitation, the coach will be able to login into inmycorner using the email address you have provided. ' +
          'Are you sure you want to create the invitation?',
        confirmationText: 'Create',
        confirmationButtonProps: {
          color: 'primary',
        },
      });
      await createInvitation(values, actions);
    } catch (error) {
      actions.setSubmitting(false);
    }
  };

  const createInvitation = async (values, actions) => {
    try {
      const response = await axios.post(`/api/v0/admin/coach-invitations`, values);
      setInvitation(response.data);
      navigate(`/admin/coach-invitations`);
    } catch (error) {
      actions.setStatus(error?.response?.data?.message);
    }
    actions.setSubmitting(false);
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox py={3}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container alignItems="flex-start">
                <Grid container item xs={12} xl={8} rowSpacing={3} pr={{xl: 3}} py={3}>
                  <Card sx={{width: '100%'}}>
                    <Grid item xs={12} pt={4} px={2}>
                      <SuiTypography variant="h6" fontWeight="medium">
                        Create Invitation
                      </SuiTypography>
                    </Grid>
                    <Grid item xs={12} px={2}>
                      <SuiTypography variant="button" color="text" fontWeight="regular">
                        Sending an invitation to a coach will allow them to register at inmycorner.
                      </SuiTypography>
                    </Grid>
                    <DataForm
                      validation={validation}
                      onSubmit={onSubmit}
                      fields={fields}
                      initialValues={invitation}
                      submitLabel="invite"
                    />
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SuiBox>
      </DashboardLayout>
    </>
  );
};

export default AdminCreateInvitationPage;
