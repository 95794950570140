import React, {useEffect, useState} from 'react';

import {FilePond, FileStatus, registerPlugin} from 'react-filepond';

import 'filepond/dist/filepond.min.css';

import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import axios from 'axios';
import imageSrc, {UserFile} from 'utils/UserImage';
import StyledFilepond from 'components/FileUploader/StyledFilepond';
import {ActualFileObject, FilePondErrorDescription, FilePondFile, FilePondInitialFile} from 'filepond';
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginImageResize,
  FilePondPluginImageTransform,
);

interface OnFile {
  (file: UserFile): void;
}

type FileUploaderProps = {
  label: string;
  message: string;
  visibility: 'PRIVATE' | 'PUBLIC';
  onFileUploaded: OnFile;
  onFileRemoved: OnFile;
  acceptedFiles: string[];
  userFiles: UserFile[];
  thumbnailHeight: number;
  maxFiles: number;
  targetHeight?: number;
  targetWidth?: number;
};

class UserFileMapping {
  constructor(readonly id: string, readonly file: UserFile) {}
}

const FileUploader = (props: FileUploaderProps) => {
  const [files, setFiles] = useState<Array<FilePondInitialFile | ActualFileObject | Blob | string>>([]);
  const [userFileMapping, setUserFileMapping] = useState<UserFileMapping[]>([]);

  useEffect(() => {
    const fileToUse = props.userFiles.filter(f => f !== null).map(f => new UserFileMapping(f.id, f));
    setUserFileMapping(fileToUse);
    setFiles(
      fileToUse.map(file => {
        return {
          source: imageSrc(file.file),
          file: {
            name: file.file.name,
            size: file.file.size,
            type: file.file.contentType,
          },
          options: {
            type: 'local',
            metadata: {
              userFile: file.file,
            },
          },
        };
      }),
    );
  }, [setFiles, setUserFileMapping, props.userFiles]);

  const onUpdateFiles = (files: FilePondFile[]) => {
    // @ts-ignore
    setFiles(files);
  };

  const onProcessFile = (error: FilePondErrorDescription | null, file: FilePondFile) => {
    if (error === null) {
      const userFile = JSON.parse(file.serverId);
      userFileMapping.push(new UserFileMapping(file.id, userFile));
      props.onFileUploaded(userFile);
    } else throw error;
  };

  const onRemoveFile = (error: FilePondErrorDescription | null, file: FilePondFile) => {
    if (error === null) {
      if (file.status === FileStatus.IDLE) {
        let userFile: UserFile = file.getMetadata('userFile');
        if (userFile === undefined) {
          // @ts-ignore
          userFile = userFileMapping.find(f => f.id === file.id).file;
          setUserFileMapping(userFileMapping.filter(m => m.id !== file.id));
        }
        props.onFileRemoved(userFile);
      }
    } else throw error;
  };

  const loadFile = (
    userFile: UserFile,
    source: string,
    load: (file: ActualFileObject | Blob) => void,
    error: (errorText: string) => void,
  ) => {
    if (userFile.visibility === 'PRIVATE') {
      axios
        .get(source, {
          responseType: 'blob',
        })
        .then(response => response.data)
        .then(blob => {
          blob.name = userFile.name;
          load(blob);
        })
        .catch(e => {
          error('Could not load file');
          return Promise.reject(e);
        });
    } else {
      fetch(source)
        .then(response => response.blob())
        .then(blob => {
          // @ts-ignore
          blob.name = userFile.name;
          load(blob);
        })
        .catch(e => {
          error('Could not load file');
          return Promise.reject(e);
        });
    }
  };

  const load = (source: string, load: (file: ActualFileObject | Blob) => void, error: (errorText: string) => void) => {
    const fileId = source.substring(source.lastIndexOf('/') + 1);
    const userFile: UserFileMapping | undefined = userFileMapping.find(f => f.id === fileId);
    if (userFile === undefined) {
      error(`File with id ${fileId} not found`);
      throw new Error(`File with id ${fileId} not found`);
    }
    loadFile(userFile.file, source, load, error);
  };

  return (
    <>
      <Grid item px={2} pt={2}>
        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SuiTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
            {props.label}
          </SuiTypography>
        </SuiBox>
        <StyledFilepond>
          <FilePond
            files={files}
            onupdatefiles={onUpdateFiles}
            onprocessfile={onProcessFile}
            onremovefile={onRemoveFile}
            allowMultiple={true}
            maxFiles={props.maxFiles}
            server={{
              timeout: 7000,
              process: {
                url: process.env.REACT_APP_API_URL + '/api/v0/files',
                method: 'POST',
                headers: {
                  Authorization: axios.defaults.headers.common.Authorization as string,
                },
                withCredentials: false,
                ondata: formData => {
                  formData.append(
                    'visibility',
                    new Blob([JSON.stringify({visibility: props.visibility})], {
                      type: 'application/json',
                    }),
                  );
                  return formData;
                },
              },
              load: load,
              revert: null,
            }}
            name="file"
            labelIdle={props.message}
            acceptedFileTypes={props.acceptedFiles}
            // @ts-ignore
            credits={{}}
            imagePreviewMaxHeight={props.thumbnailHeight}
            allowImageResize
            imageResizeTargetHeight={props.targetHeight ?? 500}
            imageResizeTargetWidth={props.targetWidth ?? 500}
            imageResizeUpscale={false}
            imResizeMode="contain"
          />
        </StyledFilepond>
      </Grid>
    </>
  );
};

FileUploader.defaultProps = {
  visibility: 'PRIVATE',
  acceptedFiles: ['image/*'],
  userFiles: [],
  thumbnailHeight: 150,
  maxFiles: 1,
  message: 'Drag & Drop your files or <span class="filepond--label-action">Browse</span>',
};

FileUploader.propTypes = {
  label: PropTypes.string.isRequired,
  message: PropTypes.string,
  visibility: PropTypes.string,
  acceptedFiles: PropTypes.instanceOf(Array),
  onFileUploaded: PropTypes.func,
  onFileRemoved: PropTypes.func,
  userFiles: PropTypes.instanceOf(Array),
  thumbnailHeight: PropTypes.number,
  maxFiles: PropTypes.number,
};

export default FileUploader;
