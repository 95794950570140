import {MacrosGoalResult} from 'components/IntervalExecutionCard/IntervalTypes';
import {UserDetails} from 'pages/coaching/tasks/TaskTypes';
import SuiTypography from 'components/SuiTypography/index';
import Grid from '@mui/material/Grid';
import Table from 'components/SuiTable';
import moment from 'moment/moment';
import {Macros} from 'pages/coaching/tasks/type-prepare-interval/IntervalGoalTypes';

type MacrosGoalResultViewProps = {
  result: MacrosGoalResult;
  client: UserDetails;
};

const MacrosGoalResultView = (props: MacrosGoalResultViewProps) => {
  const columns = [
    {name: 'day', align: 'left'},
    {name: 'protein', align: 'left'},
    {name: 'carbs', align: 'left'},
    {name: 'fat', align: 'left'},
    {name: 'fiber', align: 'left'},
    {name: 'calories', align: 'center'},
  ];

  const createRows = () => {
    const macros = new Map(Object.entries(props.result.macros));
    const minCalories = props.result.goal.min.calories;
    const maxCalories = props.result.goal.max.calories;
    const rows = [];
    macros.forEach((value, key) => {
      rows.push({
        key: new Date(key),
        day: moment(key).format('dddd, MM-DD-YYYY'),
        protein: createValuesComponent(
          value.protein,
          props.result.goal.min.protein,
          props.result.goal.max.protein,
          'g',
        ),
        carbs: createValuesComponent(value.carbs, props.result.goal.min.carbs, props.result.goal.max.carbs, 'g'),
        fat: createValuesComponent(value.fat, props.result.goal.min.fat, props.result.goal.max.fat, 'g'),
        fiber: createValuesComponent(value.fiber, props.result.goal.min.fiber, props.result.goal.max.fiber, 'g'),
        calories: createValuesComponent(value.calories, minCalories, maxCalories, ''),
      });
    });
    rows.sort((a, b) => (a.key > b.key ? 1 : -1));
    rows.push(createAverageRow(macros, minCalories, maxCalories));
    return rows;
  };

  const createAverageRow = (macros: Map<string, Macros>, minCalories: number, maxCalories: number) => {
    const values = Array.from(macros.values());
    return {
      day: <b>Daily Average</b>,
      protein: createValuesComponent(
        average(values.map(m => m.protein)),
        props.result.goal.min.protein,
        props.result.goal.max.protein,
        'g',
        'bold',
      ),
      carbs: createValuesComponent(
        average(values.map(m => m.carbs)),
        props.result.goal.min.carbs,
        props.result.goal.max.carbs,
        'g',
        'bold',
      ),
      fat: createValuesComponent(
        average(values.map(m => m.fat)),
        props.result.goal.min.fat,
        props.result.goal.max.fat,
        'g',
        'bold',
      ),
      fiber: createValuesComponent(
        average(values.map(m => m.fiber)),
        props.result.goal.min.fiber,
        props.result.goal.max.fiber,
        'g',
        'bold',
      ),
      calories: createValuesComponent(average(values.map(m => m.calories)), minCalories, maxCalories, '', 'bold'),
    };
  };

  const createValuesComponent = (value, minValue, maxValue, suffix, fontWeight?) => {
    let color = '#439946';
    if (value === 0) {
      color = 'error';
    } else if (value < minValue || value > maxValue) {
      color = '#d9b305';
    }
    const formattedValue = Math.round(value * 100) / 100;
    return (
      <SuiTypography
        variant="button"
        fontWeight={fontWeight ? fontWeight : 'regular'}
        color={color}
        sx={{display: 'inline-block', width: 'max-content'}}>
        {formattedValue + suffix}
      </SuiTypography>
    );
  };

  const average = (values: number[]) => {
    const value = Math.floor(values.reduce((a, b) => a + b, 0) / values.length);
    return Math.round(value * 100) / 100;
  };

  return (
    <>
      <Grid item xs={12} sx={{pt: 1}}>
        <Table columns={columns} rows={createRows()} tableStyles={{boxShadow: 'none'}} />
      </Grid>
    </>
  );
};

export default MacrosGoalResultView;
