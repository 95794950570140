import * as React from 'react';
import {Card} from '@mui/material';
import Zoom from 'react-medium-image-zoom';
import ProtectedImage from 'components/IntervalExecutionCard/goals-results/photo/ProtectedImage';
import SuiBox from 'components/SuiBox/index';
import IconButton from '@mui/material/IconButton';
import {NoPhotographyOutlined} from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import SuiTypography from 'components/SuiTypography/index';
import {UserFile} from 'utils/UserImage';

export type Photo = {
  name: string;
  file?: UserFile;
};

export type PhotoGalleryProps = {
  photos: Photo[];
};

const PhotoGallery = (props: PhotoGalleryProps) => {
  const renderPhoto = (photo: Photo) => {
    if (photo.file) {
      return renderClientPhoto(photo.file);
    }
    return renderNoPhoto();
  };

  const renderClientPhoto = (photo: UserFile) => (
    <Card>
      <Zoom>
        <ProtectedImage
          file={photo}
          imageStyle={{
            height: '100%',
            width: '100%',
            maxHeight: '300px',
            objectFit: 'cover',
            borderRadius: '12px',
          }}
        />
      </Zoom>
    </Card>
  );

  const renderNoPhoto = () => (
    <Card
      sx={{
        mb: 1,
        height: '100%',
        minHeight: '15em',
      }}>
      <SuiBox display="flex" justifyContent="center" alignItems="center" borderRadius="12px" sx={{height: '100%'}}>
        <IconButton
          style={{
            fontSize: 100,
            color: '#eeeeee',
            cursor: 'default',
          }}>
          <NoPhotographyOutlined />
        </IconButton>
      </SuiBox>
    </Card>
  );

  return (
    <>
      <Grid container sx={{mt: 2, mb: 2}} columnSpacing={2}>
        {props.photos.map((photo, index) => {
          return (
            <Grid container item key={index} xs={12} md={3} direction="column" justifyContent={'space-between'}>
              <Grid item sx={{}}>
                {renderPhoto(photo)}
              </Grid>
              <Grid container item justifyContent="center">
                <Grid item sx={{pt: 3, pb: 4}}>
                  <SuiTypography variant="caption" fontWeight="bold">
                    {photo.name}
                  </SuiTypography>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default PhotoGallery;
