import Grid from '@mui/material/Grid';
import ClientCard from 'components/ClientCard/ClientCard';
import IntervalExecutionBrowser from 'components/IntervalExecutionBrowser/IntervalExecutionBrowser';
import {Client} from 'pages/coaching/clients/client/Client';
import {useEffect, useState} from 'react';
import axios from 'axios';
import {Page} from 'components/BootPagination/index';
import {IntervalExecution} from 'components/IntervalExecutionCard/IntervalTypes';
import SubscriptionCard from 'pages/coaching/clients/client/SubscriptionCard';

type ClientCheckinsTabProps = {
  client: Client;
  clientId: string;
  subscriptionId: string;
};

const ClientCheckinsTab = (props: ClientCheckinsTabProps) => {
  const [lastIntervalNumber, setLastIntervalNumber] = useState(0);

  useEffect(() => {
    const load = async () => {
      const response = await axios.get(
        `/api/v0/interval-executions?size=1&page=0&subscription=${props.subscriptionId}&sort=interval.number,desc`,
      );
      const p: Page<IntervalExecution> = response.data;
      if (p.content.length > 0) {
        setLastIntervalNumber(p.content[0].interval.number);
      }
    };
    load();
  }, [props.subscriptionId]);

  const clientDetails = () => {
    return {
      name: props.client.name,
      picture: props.client.picture,
      user: props.client.userId,
    };
  };

  return (
    <>
      <Grid container alignItems="flex-start" spacing={3}>
        <Grid item xs={12} xl={8}>
          <IntervalExecutionBrowser
            subscriptionId={props.subscriptionId}
            intervalNumber={lastIntervalNumber}
            client={clientDetails()}
            showLabels={false}
          />
        </Grid>
        <Grid container item xs={12} xl={4} spacing={3}>
          <Grid item xs={12}>
            <ClientCard clientId={props.clientId} subscriptionId={props.subscriptionId} layout={'vertical'} />
          </Grid>
          <Grid item xs={12}>
            <SubscriptionCard clientId={props.clientId} subscriptionId={props.subscriptionId} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ClientCheckinsTab;
