import {UserDetails} from 'pages/coaching/tasks/TaskTypes';
import SuiTypography from 'components/SuiTypography/index';
import Grid from '@mui/material/Grid';
import Table from 'components/SuiTable';
import moment from 'moment/moment';
import {Steps, StepsGoalResult} from 'components/IntervalExecutionCard/IntervalTypes';

type StepsGoalResultViewProps = {
  result: StepsGoalResult;
  client: UserDetails;
};

const StepsGoalResultView = (props: StepsGoalResultViewProps) => {
  const columns = [
    {name: 'day', align: 'left'},
    {name: 'steps', align: 'center'},
  ];

  const createRows = () => {
    const steps = new Map<string, Steps>(Object.entries(props.result.steps));
    const rows = [];
    steps.forEach((value, key) => {
      rows.push({
        key: new Date(key),
        day: moment(key).format('dddd, MM-DD-YYYY'),
        steps: createValuesComponent(value.value, props.result.goal.minSteps, ''),
      });
    });
    rows.sort((a, b) => (a.key > b.key ? 1 : -1));
    rows.push(createAverageRow(steps, props.result.goal.minSteps));
    return rows;
  };

  const createAverageRow = (steps: Map<string, Steps>, minSteps: number) => {
    const values = Array.from(steps.values());
    return {
      day: <b>Daily Average</b>,
      steps: createValuesComponent(average(values), minSteps, '', 'bold'),
    };
  };

  const createValuesComponent = (value, minValue, suffix, fontWeight?) => {
    let color = '#439946';
    if (value === 0) {
      color = 'error';
    } else if (value < minValue) {
      color = '#d9b305';
    }
    return (
      <SuiTypography
        variant="button"
        fontWeight={fontWeight ? fontWeight : 'regular'}
        color={color}
        sx={{display: 'inline-block', width: 'max-content'}}>
        {value + suffix}
      </SuiTypography>
    );
  };

  const average = (values: Steps[]) => {
    return Math.floor(values.map(s => s.value).reduce((a, b) => a + b, 0) / values.length);
  };

  return (
    <>
      <Grid item xs={12} sx={{pt: 1}}>
        <Table columns={columns} rows={createRows()} tableStyles={{boxShadow: 'none'}} />
      </Grid>
    </>
  );
};

export default StepsGoalResultView;
