import {Task, UserDetails} from 'pages/coaching/tasks/TaskTypes';
import {Grid} from '@mui/material';
import ClientCard from 'components/ClientCard/ClientCard';
import IntervalContextView from 'components/IntervalContextView/IntervalContextView';
import * as React from 'react';
import PrepareIntervalWizard from 'pages/coaching/tasks/type-prepare-interval/PrepareIntervalWizard';

type PrepareIntervalTaskProps = {
  task: Task;
};

const PrepareIntervalTask = (props: PrepareIntervalTaskProps) => {
  const getClient = () => {
    const clientAttribute = props.task.attributes.find(a => a.key === 'client');
    return clientAttribute.value as UserDetails;
  };

  const getSubscriptionId = () => {
    const clientAttribute = props.task.attributes.find(a => a.key === 'subscription');
    return clientAttribute.value as string;
  };

  const getIntervalNumber = (): number => {
    const nutritionTask = props.task.attributes.find(a => a.key === 'interval-number');
    return nutritionTask.value as number;
  };
  const isFirstInterval = () => {
    return getIntervalNumber() === 1;
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Grid container alignItems="flex-start" spacing={3}>
            <Grid item xs={12}>
              <ClientCard clientId={getClient().user} subscriptionId={getSubscriptionId()} layout={'horizontal'} />
            </Grid>
            <Grid container item xs={12} xl={12} spacing={3}>
              <Grid container item xs={12} xl={6} alignItems={'flex-start'}>
                <IntervalContextView
                  subscriptionId={getSubscriptionId()}
                  intervalStartingNumber={getIntervalNumber() - 1}
                  client={getClient()}
                />
              </Grid>
              <Grid container item xs={12} xl={6} alignItems={'flex-start'}>
                <PrepareIntervalWizard id={props.task.referencedEntity} isFirstInterval={isFirstInterval()} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PrepareIntervalTask;
