import PlanSpecification from 'components/PlanSummary';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

const PlanDetailsTab = ({plan}) => {
  return (
    <>
      <Grid item mt={3} xs={12}>
        <PlanSpecification plan={plan} />
      </Grid>
    </>
  );
};

PlanDetailsTab.propTypes = {
  plan: PropTypes.instanceOf(Object).isRequired,
};

export default PlanDetailsTab;
