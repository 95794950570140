import {useEffect, useState} from 'react';
// formik components
import {useNavigate} from 'react-router-dom';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';

// Soft UI Dashboard PRO React example components
// NewUser page components
// NewUser layout schemas for form and form feilds
import axios from 'axios';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import DataForm, {DataFormField} from 'components/DataForm';
import Card from '@mui/material/Card';

const EditTermsForm = ({termsId}) => {
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({title: '', content: '', published: ''});

  const fields: DataFormField[] = [
    {name: 'title', label: 'Title', type: 'text', placeholder: 'eg. Terms v. 1.2.5'},
    {name: 'content', label: 'Content', type: 'editor', placeholder: 'eg. whatever the user has to agree to.'},
    {name: 'published', label: 'Published', type: 'datetime-local', placeholder: "published terms can't be updated"},
  ];

  const validation = Yup.object().shape({
    title: Yup.string().required('Title is required.'),
    content: Yup.string()
      .required('Content is required.')
      .test('len', 'Content is required.', val => {
        return val !== undefined && val.length > 0 && val.replace(/(<([^>]+)>)/gi, '').length > 0;
      }),
  });

  useEffect(() => {
    if (termsId !== undefined) {
      axios
        .get(`/api/v0/terms/${termsId}`)
        .then(response => response.data)
        .catch(error => Promise.reject(error))
        .then(setInitialValues);
    }
  }, [setInitialValues]);

  const submitForm = async (values, actions) => {
    if (values.published) {
      values.published = new Date(values.published).toISOString();
    }
    try {
      if (termsId === undefined) {
        const response = await axios.post(`/api/v0/terms`, values);
        actions.setSubmitting(false);
        navigate('/admin/terms/' + response.data.id);
      } else {
        const response = await axios.put(`/api/v0/terms/${termsId}`, values);
        actions.setSubmitting(false);
        setInitialValues(response.data);
      }
    } catch (error) {
      actions.setStatus(error?.response?.data?.message);
    }
  };

  const isReadOnly = () => {
    return initialValues.published !== null && initialValues.published.length > 0;
  };

  return (
    <>
      <SuiBox>
        <Grid container>
          <Grid item xs={12} lg={12}>
            <Card>
              <DataForm
                validation={validation}
                fields={fields}
                onSubmit={submitForm}
                initialValues={initialValues}
                readOnly={isReadOnly()}
              />
            </Card>
          </Grid>
        </Grid>
      </SuiBox>
    </>
  );
};

EditTermsForm.propTypes = {
  termsId: PropTypes.string,
};

export default EditTermsForm;
