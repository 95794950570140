import {useEffect} from 'react';
import {useRecoilValue} from 'recoil';
import {userSettingsState} from 'utils/settings/userSettingsState';
import {GeneralSettings} from 'pages/me/settings/general/GeneralSettingsTypes';
import moment from 'moment';

const TimezoneOrchestrator = () => {
  const userSettings = useRecoilValue(userSettingsState);

  useEffect(() => {
    userSettings
      .getSettings('general')
      .then((settings: GeneralSettings) => {
        const offset = moment().utcOffset();
        if (settings.utcOffsetMinutes !== offset) {
          settings.utcOffsetMinutes = offset;
        }
        return settings;
      })
      .then(settings => userSettings.updateSettings(settings));
  }, []);

  return null;
};

export default TimezoneOrchestrator;
