import {atomFamily} from 'recoil';

export const breadCrumbSegmentsState = atomFamily<string, string>({
  key: 'breadCrumbSegmentsState',
  default: key => key,
});

// export function updateBreadcrumbSegment(key: string, value: string) {
//   useRecoilCallback(({set}) => async () => {
//     set(breadCrumbSegmentsState(key), value);
//   });
// }
