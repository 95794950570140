import {atom, selector} from 'recoil';
import axios from 'axios';
import {UserDetails} from 'pages/coaching/tasks/TaskTypes';

export const accessTokenState = atom({
  key: 'accessTokenState',
  default: null,
});

const updateUserDetails = async token => {
  const response = await axios.patch('/api/v0/me/details', {
    name: token.userName,
    email: token.email,
    picture: token.picture,
  });
  return response.data;
};

const ensureUserDetails = async token => {
  const userDetailsResponse = await axios.get('/api/v0/me/details');
  const userDetails = userDetailsResponse.data;
  if (userDetails.email) {
    return userDetails;
  }
  return await updateUserDetails(token);
};

const sourceUserDetails = selector<UserDetails>({
  key: 'sourceUserDetailsState',
  get: async ({get}) => {
    const token = get(accessTokenState);
    if (token !== null) {
      return await ensureUserDetails(token);
    }
    return {};
  },
});

export const authenticatedUserState = atom<UserDetails>({
  key: 'authenticatedUserState',
  default: sourceUserDetails,
});

export const authenticatedUserRolesState = selector({
  key: 'authenticatedUserRolesState',
  get: async ({get}) => {
    get(authenticatedUserState);
    try {
      const response = await axios.get('/api/v0/me');
      return response.data.roles;
    } catch (error) {
      return [];
    }
  },
});
