/**
 =========================================================
 * Soft UI Dashboard PRO React - v3.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';

// Soft UI Dasboard PRO Material components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

// General page components
import {Task, TaskStatus} from 'pages/coaching/tasks/TaskTypes';

import * as React from 'react';
import {useEffect, useState} from 'react';
import axios from 'axios';
import BootPagination, {Page} from 'components/BootPagination/index';
import {CircularProgress, Grid} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AppBar from '@mui/material/AppBar';
import {AssignmentLateOutlined, AssignmentTurnedInOutlined} from '@mui/icons-material';
import TaskListItem from 'pages/coaching/tasks/TaskListItem';
import {subscribe} from 'components/Firebase/FirebaseMessaging';
import {useRecoilValue} from 'recoil';
import {taskListState} from 'pages/coaching/tasks/TaskListState';
import {FaRegSmileWink, FaRegThumbsUp} from 'react-icons/fa';
import Paywall from 'components/Paywall/Paywall';

type TaskListProps = {
  clientId?: string;
  subscriptionId?: string;
  identifier?: string;
  tasksPerPage?: number;
};

const TaskList = (props: TaskListProps) => {
  const [page, setPage] = useState<Page<Task>>();
  const [pageNumber, setPageNumber] = useState(0);
  const [taskStatus, setTaskStatus] = useState<TaskStatus>('OPEN');
  const taskListStatus = useRecoilValue(taskListState);

  useEffect(() => {
    const state = taskListStatus.get(computeStateKey());
    if (state) {
      setTaskStatus(state.status);
      setPageNumber(state.page);
    }
  }, [props.subscriptionId, props.identifier]);

  useEffect(() => {
    loadTasks();
    const unsubscribe = subscribe(loadTasks);
    return () => unsubscribe();
  }, [pageNumber, taskStatus, props.clientId, props.subscriptionId]);

  const loadTasks = () => {
    let size = props.tasksPerPage || 10;
    let taskUrl = `/api/v0/tasks?page=${pageNumber}&size=${size}&sort=dueDate,desc&sort=auditMetadata.lastModified,asc&status=${taskStatus}`;
    if (props.clientId !== undefined) {
      taskUrl += `&client=${props.clientId}`;
    }
    if (props.subscriptionId !== undefined) {
      taskUrl += `&subscription=${props.subscriptionId}`;
    }
    axios
      .get(encodeURI(taskUrl))
      .then(response => response.data)
      .then(setPage)
      .catch(error => Promise.reject(error));
  };

  const handleSetTabValue = (event, newValue: TaskStatus) => {
    setTaskStatus(newValue);
    taskListStatus.set(computeStateKey(), {status: newValue, page: 0});
  };

  const handleSetPageNumber = newPageNumber => {
    setPageNumber(newPageNumber);
    taskListStatus.set(computeStateKey(), {status: taskStatus, page: newPageNumber});
  };

  const computeStateKey = () => {
    return props.identifier + '-' + props.subscriptionId;
  };

  const renderNoTasks = () => {
    if (!page) {
      return renderLoading();
    } else if (page && page.content.length === 0) {
      if (taskStatus === 'OPEN') {
        return renderAllDone();
      }
      return renderNoDone();
    }
  };

  const renderLoading = () => {
    return (
      <SuiBox display="flex" justifyContent="center" alignItems="center" mb={3}>
        <CircularProgress color="primary" />
      </SuiBox>
    );
  };

  const renderAllDone = () => {
    return (
      <SuiBox display="flex" sx={{justifyContent: 'center', alignItems: 'center', flexDirection: 'column', mt: 3}}>
        <FaRegThumbsUp fontSize={130} color={'#e9ecef'} />
        <SuiTypography variant="h6" fontWeight="medium" sx={{mt: 2}} color={'secondary'}>
          All done. Nice Job!
        </SuiTypography>
      </SuiBox>
    );
  };

  const renderNoDone = () => {
    return (
      <SuiBox display="flex" sx={{justifyContent: 'center', alignItems: 'center', flexDirection: 'column', mt: 3}}>
        <FaRegSmileWink fontSize={130} color={'#e9ecef'} />
        <SuiTypography variant="h6" fontWeight="medium" sx={{mt: 2}} color={'secondary'}>
          Nothing done yet.
        </SuiTypography>
      </SuiBox>
    );
  };

  return (
    <Paywall>
      <Card sx={{minHeight: 350}}>
        <SuiBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
          <SuiTypography variant="h6" fontWeight="medium">
            Tasks
          </SuiTypography>
          <SuiTypography variant="button" fontWeight="regular" color="text">
            {page && page.totalElements ? page.totalElements : 0} Task{page && page.totalElements !== 1 ? 's' : ''}
          </SuiTypography>
        </SuiBox>
        <Grid container>
          <Grid item xs={12} xl={12}>
            <AppBar position="static">
              <Tabs
                orientation="horizontal"
                value={taskStatus}
                onChange={handleSetTabValue}
                sx={{background: 'transparent', px: 3, pt: 3}}>
                <Tab label="Open" value="OPEN" icon={<AssignmentLateOutlined />} />
                <Tab label="Done" value="DONE" icon={<AssignmentTurnedInOutlined />} />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
        <Divider />
        <SuiBox pb={2} px={2}>
          <SuiBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
            {page &&
              page.content.map((task, index) => (
                <TaskListItem key={task.id} task={task} noDivider={index === page.numberOfElements - 1} />
              ))}
            {renderNoTasks()}
          </SuiBox>
          <Grid
            container
            item
            xs={12}
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{p: 3, pb: {xs: 0}}}>
            <BootPagination page={page ? page : {content: []}} onClick={handleSetPageNumber} />
          </Grid>
        </SuiBox>
      </Card>
    </Paywall>
  );
};

export default TaskList;
