import Table from 'components/SuiTable';
import * as React from 'react';
import {useEffect, useState} from 'react';
import axios from 'axios';
import BootPagination, {Page} from 'components/BootPagination';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import {Navigate, useNavigate} from 'react-router-dom';
import {Plan, Subscriber} from 'pages/coaching/plans/PlanTypes';
import SuiBox from 'components/SuiBox/index';
import SuiTypography from 'components/SuiTypography/index';
import {FaUsers} from 'react-icons/fa';

type PlanSubscribersTabProps = {
  plan: Plan;
};

function PlanSubscribersTab(props: PlanSubscribersTabProps) {
  const navigate = useNavigate();
  const [page, setPage] = useState<Page<Subscriber>>({} as Page<Subscriber>);
  const [pageNumber, setPageNumber] = useState(0);

  const columns = [
    {name: 'name', align: 'left'},
    {name: 'since', align: 'left'},
  ];

  useEffect(() => {
    if (props.plan.id) {
      axios
        .get(`/api/v0/plans/${props.plan.id}/subscribers?page=${pageNumber}&size=10&sort=created,DESC`)
        .then(response => response.data)
        .then(setPage)
        .catch(error => Promise.reject(error));
    }
  }, [pageNumber, props.plan]);

  const createRows = () =>
    page.content?.map(subscriber => ({
      name: [subscriber.picture, subscriber.name],
      since: moment(subscriber.created).local().format('MM-DD-YYYY'),
      onClick: () => {
        navigate(`/coaching/clients/${encodeURI(subscriber.user)}/${subscriber.subscription}`);
      },
    }));

  const renderSubscribers = () => {
    return (
      <Grid container mt={{xs: 1, lg: 3}} mb={1}>
        <Grid item xs={12}>
          <Table columns={columns} rows={createRows()} tableStyles={{boxShadow: 'none'}} />
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center" sx={{p: 3}}>
          <BootPagination page={page} onClick={setPageNumber} />
        </Grid>
      </Grid>
    );
  };

  const renderNoSubscribers = () => {
    return (
      <Grid container>
        <Grid item xs={12} py={3}>
          <SuiBox display="flex" sx={{justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
            <FaUsers fontSize={150} color={'#e9ecef'} />
            <SuiTypography variant="h6" fontWeight="medium" sx={{mt: 2}} color={'secondary'}>
              No subscribers yet!
            </SuiTypography>
          </SuiBox>
        </Grid>
      </Grid>
    );
  };

  const renderContent = () => {
    if (props.plan.status === 'DRAFT') {
      return <Navigate replace to={`/coaching/plans/${props.plan.id}/edit`} />;
    }
    if (props.plan.id) {
      if (page.content?.length === 0) {
        return renderNoSubscribers();
      }
      return renderSubscribers();
    }
  };

  return renderContent();
}

export default PlanSubscribersTab;
