import {atom, selector} from 'recoil';
import {accessTokenState} from 'state/authentication';
import axios from 'axios';

export type ClientStatistics = {
  uniqueClients: number;
  allClients: number;
};

export const loadClientStatistics = async (): Promise<ClientStatistics> => {
  const clientStatisticsResponse = await axios.get(`/api/v0/clients/statistics`);
  return clientStatisticsResponse.data;
};

const sourceClientStatistics = selector<ClientStatistics>({
  key: 'sourceClientStatistics',
  get: async ({get}) => {
    const token = get(accessTokenState);
    if (token !== null) {
      return await loadClientStatistics();
    }
    return {
      uniqueClients: 0,
      allClients: 0,
    };
  },
});

export const clientStatisticsState = atom<ClientStatistics>({
  key: 'clientStatisticsState',
  default: sourceClientStatistics,
});
