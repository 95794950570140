/**
 =========================================================
 * Soft UI Dashboard PRO React - v3.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { breadCrumbSegmentsState } from "state/breadcrumb";
import { useRecoilCallback } from "recoil";

function Breadcrumbs({icon, title, route, light}) {
  const routes = route.slice(0, -1);

  const computePath = (el, idx) => {
    let path = '';
    for (let i = 0; i <= idx; i++) {
      path += '/' + routes[i];
    }
    return path;
  };

  const getBreadcrumbSegment = useRecoilCallback(({snapshot}) => (key: string) => {
    return snapshot.getLoadable(breadCrumbSegmentsState(key)).valueMaybe();
  });

  const computeLabel = el => {
    return truncate(getBreadcrumbSegment(el), 30);
  };

  const truncate = (str, n) => {
    if (str && str.length > n) {
      return str.substring(0, n) + '...';
    } else {
      return str;
    }
  };

  return (
    <SuiBox mr={{xs: 0, xxl: 8}}>
      <MuiBreadcrumbs
        sx={{
          '& .MuiBreadcrumbs-separator': {
            color: ({palette: {grey}}) => grey[600],
          },
        }}>
        <Link to="/">
          <SuiTypography
            component="span"
            variant="body2"
            color={light ? 'white' : 'dark'}
            opacity={light ? 0.8 : 0.5}
            sx={{lineHeight: 0}}>
            <Icon>{icon}</Icon>
          </SuiTypography>
        </Link>
        {routes.map((el, idx) => (
          <Link to={computePath(el, idx)} key={el}>
            <SuiTypography
              component="span"
              variant="button"
              fontWeight="regular"
              textTransform="capitalize"
              color={light ? 'white' : 'dark'}
              opacity={light ? 0.8 : 0.5}
              sx={{lineHeight: 0}}>
              {computeLabel(el)}
            </SuiTypography>
          </Link>
        ))}
        <SuiTypography
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          color={light ? 'white' : 'dark'}
          sx={{lineHeight: 0}}>
          {computeLabel(title.replace('-', ' '))}
        </SuiTypography>
      </MuiBreadcrumbs>
    </SuiBox>
  );
}

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
  light: false,
};

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  light: PropTypes.bool,
};

export default Breadcrumbs;
