import * as React from 'react';
import {useEffect, useState} from 'react'; // @mui material components
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'; // Soft UI Dashboard PRO React example components
import DashboardLayout from 'layout/DashboardLayout';
import Footer from 'layout/footer/Footer';
import {useNavigate, useParams} from 'react-router-dom';
import {Client} from 'pages/coaching/clients/client/Client';
import axios from 'axios';
import ClientHeader from 'pages/coaching/clients/client/ClientHeader';
import Grid from '@mui/material/Grid';
import ClientTasksTab from 'pages/coaching/clients/client/ClientTasksTab';
import {QuizOutlined} from '@mui/icons-material';
import ClientQuestionnairesTab from 'pages/coaching/clients/client/ClientQuestionnairesTab';
import ClientCheckinsTab from 'pages/coaching/clients/client/ClientCheckinsTab';
import {FaTasks} from 'react-icons/fa';
import {GiProgression} from 'react-icons/gi';
import {breadCrumbSegmentsState} from 'state/breadcrumb';
import {useRecoilCallback} from 'recoil';
import {HiOutlineChat} from 'react-icons/hi';
import ClientMessagesTab from 'pages/coaching/clients/client/ClientMessagesTab';
import {useRemoteConfig} from 'components/Firebase/FirebaseConfigProvider';
import ClientPaymentTab from 'pages/coaching/clients/client/ClientPaymentTab';
import {MdPayment} from 'react-icons/md';

export type ClientSection = {
  id: string;
  label: string;
  icon: string | React.ReactElement;
  component: React.ReactElement;
  visible: boolean;
};

const ClientPage = () => {
  const {feature_messaging, feature_payment} = useRemoteConfig();
  const navigate = useNavigate();
  const {clientId} = useParams();
  const {subscriptionId} = useParams();
  const {tab} = useParams();
  const encodedClientId = encodeURI(clientId);

  const updateBreadcrumbSegment = useRecoilCallback(({set}) => async (key: string, value: string) => {
    set(breadCrumbSegmentsState(key), value);
  });

  const [client, setClient] = useState<Client>({
    subscriptions: [],
  } as Client);

  const clientSections: ClientSection[] = [
    {
      id: 'tasks',
      label: 'Tasks',
      icon: <FaTasks fontSize={'small'} />,
      component: <ClientTasksTab clientId={clientId} subscriptionId={subscriptionId} />,
      visible: true,
    },
    {
      id: 'messages',
      label: 'Messages',
      icon: <HiOutlineChat fontSize={'large'} />,
      component: <ClientMessagesTab clientId={clientId} subscriptionId={subscriptionId} />,
      visible: feature_messaging?.asBoolean(),
    },
    {
      id: 'checkins',
      label: 'Check-Ins',
      icon: <GiProgression />,
      component: <ClientCheckinsTab client={client} clientId={clientId} subscriptionId={subscriptionId} />,
      visible: true,
    },
    {
      id: 'questionnaires',
      label: 'Questionnaires',
      icon: <QuizOutlined />,
      component: <ClientQuestionnairesTab clientId={clientId} subscriptionId={subscriptionId} />,
      visible: true,
    },
    {
      id: 'payments',
      label: 'Payments',
      icon: <MdPayment />,
      component: <ClientPaymentTab clientId={clientId} subscriptionId={subscriptionId} />,
      visible: feature_payment?.asBoolean(),
    },
  ];

  const [tabValue, setTabValue] = useState(clientSections[0].id);

  useEffect(() => {
    if (clientSections.map(section => section.id).includes(tab)) {
      setTabValue(tab);
    }
  }, [tab]);

  const handleTabValueChange = newValue => {
    let selectedTab = clientSections[0].id;
    if (clientSections.map(section => section.id).includes(newValue)) {
      selectedTab = newValue;
    }
    navigate(`/coaching/clients/${encodedClientId}/${subscriptionId}/${selectedTab}`);
    setTabValue(selectedTab);
  };

  const handleSubscriptionIdChange = subscriptionId => {
    if (tab !== undefined) {
      navigate(`/coaching/clients/${encodedClientId}/${subscriptionId}/${tab}`);
    } else {
      navigate(`/coaching/clients/${encodedClientId}/${subscriptionId}`);
    }
  };

  useEffect(() => {
    const load = async () => {
      const response = await axios.get(`/api/v0/clients/${encodedClientId}`);
      const client = response.data;
      updateBreadcrumbSegment(encodedClientId, client.name);
      client.subscriptions.forEach(subscription => updateBreadcrumbSegment(subscription.id, subscription.plan.name));
      setClient(client);
    };
    load();
  }, []);

  const computeSubscriptionOptions = () => {
    const options = [];
    client.subscriptions.forEach(subscription => {
      options.push({
        value: subscription.id,
        label: subscription.plan.name,
      });
    });
    return options;
  };

  const renderContent = () => {
    const selectedTab = clientSections.find(section => section.id === tabValue);
    return (
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Grid container item xs={12}>
            {selectedTab.component}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <DashboardLayout>
      <ClientHeader
        subscriptionId={subscriptionId}
        onSubscriptionIdChange={handleSubscriptionIdChange}
        client={client}
        planOptions={computeSubscriptionOptions()}
        selectedTab={tabValue}
        onTabChange={handleTabValueChange}
        sections={clientSections}
      />
      <SuiBox my={3}>{renderContent()}</SuiBox>
      <Footer />
    </DashboardLayout>
  );
};

export default ClientPage;
