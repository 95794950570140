// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';

import DashboardLayout from 'layout/DashboardLayout';
import DashboardNavbar from 'layout/navbar/DashboardNavbar';
import Footer from 'layout/footer/Footer';
import VersionInfo from 'pages/admin/dashboard/VersionInfo';
import UserManagement from 'pages/admin/dashboard/UserManagement';

function OverviewPage() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <Grid container>
          <Grid item xs={12} pb={3}>
            <Grid container item xs={12} rowSpacing={3}>
              <VersionInfo />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems="flex-start">
              <Grid container item xs={12} rowSpacing={3} pt={{xs: 6, lg: 3}} pr={{xs: 0, lg: 3}}>
                <UserManagement />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default OverviewPage;
