import {useEffect, useState} from 'react';
import DashboardLayout from 'layout/DashboardLayout';
import TabOrientation from 'components/TabOrientation/TabOrientation';
import DashboardNavbar from 'layout/navbar/DashboardNavbar';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import AppBar from '@mui/material/AppBar';
import {Link, Orientation, Tab, Tabs} from '@mui/material';
import {EditNotificationsOutlined} from '@mui/icons-material';
import Footer from 'layout/footer/Footer';
import {useNavigate} from 'react-router-dom';
import FaqTab from 'pages/help/FaqTab';
import VideosTab from 'pages/help/VideosTab';
import {useRecoilValue} from 'recoil';
import {authenticatedUserState} from 'state/authentication';
import * as Sentry from '@sentry/react';
import SuiTypography from 'components/SuiTypography';

type HelpPageProps = {
  tab: 'faq' | 'videos';
};

const HelpPage = (props: HelpPageProps) => {
  const navigate = useNavigate();
  const [tabsOrientation, setTabsOrientation] = useState<Orientation>('horizontal');
  const [tabValue, setTabValue] = useState(props.tab);
  const authenticatedUser = useRecoilValue(authenticatedUserState);

  const sendFeedback = () => {
    const eventId = Sentry.captureMessage(`${authenticatedUser.name} (${authenticatedUser.user}) has some feedback`);
    Sentry.showReportDialog({
      eventId,
      user: {
        email: authenticatedUser?.email,
        name: `${authenticatedUser.name}`,
      },
      title: 'Want to share some feedback?',
      subtitle: 'Great!',
      subtitle2: `If not, just click 'close' below.`,
      labelComments: 'What would you like us to know?',
      labelSubmit: 'Send Feedback',
    });
  };

  useEffect(() => {
    setTabValue(props.tab);
  }, [props.tab]);

  const handleSetTabValue = (event, newValue) => {
    let url = '/help';
    switch (newValue) {
      case 'faq':
        url += '/faq';
        break;
      case 'videos':
        url += '/videos';
        break;
    }
    navigate(url);
    setTabValue(newValue);
  };

  const renderTabContent = () => {
    if (tabValue === 'faq') {
      return <FaqTab />;
    }
    if (tabValue === 'videos') {
      return <VideosTab />;
    }
  };

  const renderContent = () => {
    return (
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Grid container item xs={12}>
            {renderTabContent()}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <DashboardLayout>
        <TabOrientation changeOrientation={setTabsOrientation} />
        <DashboardNavbar />
        <Grid container item xs={12} rowSpacing={3} pr={{xl: 3}} py={3}>
          <Grid item xs={12} container>
            <Card sx={{width: '100%'}}>
              <Grid item xs={12} p={3}>
                <AppBar position="static">
                  <Tabs
                    orientation={tabsOrientation}
                    value={tabValue}
                    onChange={handleSetTabValue}
                    sx={{background: 'transparent'}}>
                    <Tab value="faq" label="FAQ" icon={<EditNotificationsOutlined />} />
                    <Tab value="videos" label="Videos" icon={<EditNotificationsOutlined />} />
                  </Tabs>
                </AppBar>
              </Grid>
              <Grid item xs={12} px={3}>
                <SuiTypography display="block" variant="body2">
                  {`Can't find the content you're looking for or simply want to `}
                  <Link
                    sx={{cursor: 'pointer'}}
                    onClick={sendFeedback}
                    color="primary"
                    underline="hover"
                    variant="body2">{`share your feedback`}</Link>
                  ?
                </SuiTypography>
              </Grid>
              <Grid item xs={12}>
                {renderContent()}
              </Grid>
            </Card>
          </Grid>
        </Grid>
        <Footer />
      </DashboardLayout>
    </>
  );
};

export default HelpPage;
