import {atom} from 'recoil';
import {Client} from 'pages/coaching/clients/client/Client';
import axios from 'axios';
import {Page} from 'components/BootPagination/index';

export type ClientState = {
  client: Client;
  unreadMessages: number;
};

export const clientsState = atom<ClientState[]>({
  key: 'clientsState',
  default: [],
});

const pageSize = 50;
export const loadClients = async () => {
  let pageNumber = 0;
  const clients: ClientState[] = [];
  let page: Page<Client>;
  do {
    const response = await axios.get(`/api/v0/clients?page=${pageNumber}&size=${pageSize}`);
    page = response.data;
    clients.push(...page.content.map(client => ({client, unreadMessages: 0})));
    pageNumber++;
  } while (!page.last);
  return clients;
};
