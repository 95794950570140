// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';

// Soft UI Dashboard PRO React example components
import DashboardLayout from 'layout/DashboardLayout';
import DashboardNavbar from 'layout/navbar/DashboardNavbar';
import Footer from 'layout/footer/Footer';
import TaskList from 'pages/coaching/tasks/TaskList';

import PlanStatisticsCard from 'pages/coaching/plans/statistics/PlansStatisticsCard';
import Card from '@mui/material/Card';
import ClientList from 'components/ClientList/ClientList';
import SuiTypography from 'components/SuiTypography/index';
import ClientStatisticsCard from 'components/ClientCard/ClientStatisticsCard';
import UnreadMessagesList from 'components/Chat/UnreadMessagesList';
import {useRemoteConfig} from 'components/Firebase/FirebaseConfigProvider';
import CoachDashboardCalendar from 'pages/coaching/dashboard/CoachDashboardCalendar';

function CoachDashboard() {
  const {feature_messaging} = useRemoteConfig();

  const renderUnreadMessages = () => {
    if (feature_messaging?.asBoolean()) {
      return (
        <Grid item xs={12} pr={{xl: 3}}>
          <UnreadMessagesList />
        </Grid>
      );
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container alignItems="flex-start">
              <Grid container item xs={12} xl={6} rowSpacing={3}>
                {renderUnreadMessages()}
                <Grid item xs={12} pr={{xl: 3}}>
                  <TaskList tasksPerPage={7} identifier={'dashboard-tasks'} />
                </Grid>
              </Grid>

              <Grid container item xs={12} xl={6} mt={{xs: 3, xl: 0}} columnSpacing={3}>
                <Grid item xs={12}>
                  <ClientStatisticsCard />
                </Grid>

                <Grid item xs={12} pb={3}>
                  <CoachDashboardCalendar />
                </Grid>

                <Grid item xs={12} pb={3}>
                  <PlanStatisticsCard />
                </Grid>

                <Grid item xs={12}>
                  <Card sx={{width: '100%'}}>
                    <SuiTypography variant="h6" sx={{pl: 2, pt: 2}}>
                      Newest Clients
                    </SuiTypography>
                    <ClientList
                      showPagination={false}
                      showFilter={false}
                      maxRows={5}
                      sorting={'created'}
                      showBadge={false}
                    />
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CoachDashboard;
