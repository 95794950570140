import axios from 'axios';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import SuiBox from 'components/SuiBox/index';
import DashboardLayout from 'layout/DashboardLayout';
import DashboardNavbar from 'layout/navbar/DashboardNavbar';
import Footer from 'layout/footer/Footer';
import DataForm, {DataFormField} from 'components/DataForm/index';
import Card from '@mui/material/Card';
import {useParams} from 'react-router-dom';

const AdminSendNotifications = () => {
  const {userId} = useParams();

  const initialValues = {
    userId: userId,
    title: '',
    content: '',
    metadata: {
      color: 'secondary',
    },
  };

  const fields: DataFormField[] = [
    {name: 'title', label: 'Title', type: 'text', placeholder: 'eg. Important message from your admin'},
    {
      name: 'content',
      label: 'Content',
      type: 'text',
      rows: 5,
    },
    {name: 'metadata.color', label: 'Color', type: 'text', placeholder: 'secondary'},
  ];

  const validation = Yup.object().shape({
    title: Yup.string().required('Title is required.'),
    content: Yup.string()
      .required('Content is required.')
      .test('len', 'Content is required.', val => {
        return val !== undefined && val.length > 0 && val.replace(/(<([^>]+)>)/gi, '').length > 0;
      }),
    metadata: Yup.object().shape({
      color: Yup.string()
        .oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark', 'light'])
        .required('Color is required.'),
    }),
  });

  const submitForm = async (values, actions) => {
    try {
      await axios.post(`/api/v0/admin/notifications/${encodeURI(values.userId)}`, {...values, userId: userId});
    } catch (error) {
      actions.setStatus(error?.response?.data?.message);
    }
    actions.setSubmitting(false);
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox py={3}>
          <SuiBox>
            <Grid container>
              <Grid item xs={12} lg={8}>
                <Card sx={{height: '100%'}}>
                  <DataForm
                    validation={validation}
                    fields={fields}
                    onSubmit={submitForm}
                    initialValues={initialValues}
                  />
                </Card>
              </Grid>
            </Grid>
          </SuiBox>
        </SuiBox>
        <Footer />
      </DashboardLayout>
    </>
  );
};

export default AdminSendNotifications;
