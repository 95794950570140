import {atom, selector} from 'recoil';
import {accessTokenState} from 'state/authentication';
import axios from 'axios';

export type CoachSubscriptionPlan = 'FREE' | 'BASIC' | 'COMMITTED' | 'ALL_IN';

export type CoachSubscriptionCadence = 'MONTHLY' | 'YEARLY';

export type CoachSubscription = {
  plan?: CoachSubscriptionPlan;
  cadence?: CoachSubscriptionCadence;
  stripeCustomerId?: string;
  stripeSubscriptionId?: string;
  maxClients: number;
  payed: boolean;
};

export const loadCoachSubscription = async (): Promise<CoachSubscription> => {
  const coachSubscriptionsResponse = await axios.get('/api/v0/coach/subscriptions');
  return coachSubscriptionsResponse.data;
};

const sourceCoachSubscription = selector<CoachSubscription>({
  key: 'sourceCoachSubscriptionState',
  get: async ({get}) => {
    const token = get(accessTokenState);
    if (token !== null) {
      return await loadCoachSubscription();
    }
    return {
      plan: 'FREE',
      cadence: 'MONTHLY',
      maxClients: 1,
      payed: false,
    };
  },
});

export const coachSubscriptionState = atom<CoachSubscription>({
  key: 'coachSubscriptionState',
  default: sourceCoachSubscription,
});
