import * as React from 'react';
import {useEffect, useState} from 'react';
import axios from 'axios';
import BootPagination, {Page} from 'components/BootPagination/index';
import {Questionnaire} from 'components/Questionnaire/Questionnaire';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import SuiTypography from 'components/SuiTypography/index';
import QuestionnaireResponseView from 'components/Questionnaire/QuestionnaireResponseView';
import SuiBox from 'components/SuiBox/index';
import {BsClipboardData} from 'react-icons/bs';
import Paywall from 'components/Paywall/Paywall';

type QuestionnaireResponseBrowserProps = {
  subscriptionId: string;
};

const QuestionnaireResponseBrowser = (props: QuestionnaireResponseBrowserProps) => {
  const [page, setPage] = useState<Page<Questionnaire> | null>(null);
  const [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    axios
      .get(`/api/v0/questionnaires?size=1&page=${pageNumber}&status=published&subscription=${props.subscriptionId}`)
      .then(response => response.data)
      .then(setPage)
      .catch(error => Promise.reject(error));
  }, [pageNumber, props.subscriptionId]);

  const renderContent = () => {
    if (page?.content.length > 0) {
      return <QuestionnaireResponseView questionnaireId={page.content[0].id} />;
    }
    if (page) {
      return (
        <SuiBox display="flex" sx={{justifyContent: 'center', alignItems: 'center', flexDirection: 'column', pt: 3}}>
          <BsClipboardData fontSize={150} color={'#e9ecef'} />
          <SuiTypography variant="h6" fontWeight="medium" sx={{mt: 2}} color={'secondary'}>
            No data so far
          </SuiTypography>
        </SuiBox>
      );
    }
  };

  const renderPagination = () => {
    if (page?.totalPages > 1) {
      return (
        <Grid container direction="row" justifyContent="center" alignItems="center" sx={{p: 3}}>
          <BootPagination page={page} onClick={setPageNumber} />
        </Grid>
      );
    }
  };

  return (
    <>
      <Paywall>
        <Card sx={{minHeight: 350}}>
          <Grid container>
            <Grid item xs={12}>
              {renderContent()}
            </Grid>
            {renderPagination()}
          </Grid>
        </Card>
      </Paywall>
    </>
  );
};

export default QuestionnaireResponseBrowser;
