/**
 =========================================================
 * Soft UI Dashboard PRO React - v3.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { forwardRef, PropsWithChildren } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for SuiAvatar
import SuiAvatarRoot from "components/SuiAvatar/SuiAvatarRoot";

// @ts-ignore
const SuiAvatar = forwardRef(({bgColor, size, shadow, ...rest}: PropsWithChildren<any>, ref) => (
  <SuiAvatarRoot ref={ref} ownerState={{shadow, bgColor, size}} imgProps={{referrerPolicy: 'no-referrer'}} {...rest} />
));

// Setting default values for the props of SuiAvatar
SuiAvatar.defaultProps = {
  // @ts-ignore
  bgColor: 'transparent',
  size: 'md',
  shadow: 'none',
};

// Typechecking props for the SuiAvatar
SuiAvatar.propTypes = {
  // @ts-ignore
  bgColor: PropTypes.oneOf([
    'transparent',
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'light',
    'dark',
  ]),
  size: PropTypes.oneOf(['xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
  shadow: PropTypes.oneOf(['none', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'inset']),
};

export default SuiAvatar;
