import * as Yup from 'yup';
import {BaseSchema} from 'yup';
import {DataFormField} from 'components/DataForm/index';
import {LinearScaleOutlined} from '@mui/icons-material';
import {QuestionSupport} from 'components/Questionnaire/QuestionSupportFactory';
import SuiTypography from 'components/SuiTypography/index';
import {
  CompoundAnswer,
  Question,
  RangeAnswer,
  RangeAnswerStatistics,
  RangeQuestion,
} from 'components/Questionnaire/Questionnaire';
import AnswerTable from 'components/Questionnaire/types/AnswerTable';
import AverageNumberView from 'components/Questionnaire/types/AverageNumberView';
import Grid from '@mui/material/Grid';
import ObjectID from 'bson-objectid';

export class RangeQuestionSupport implements QuestionSupport<RangeQuestion, RangeAnswer, RangeAnswerStatistics> {
  createFields(question: Question, index: number): DataFormField[] {
    return [
      {
        name: `questions[${index}].min`,
        label: 'Minimum',
        type: 'number',
        data: question.id,
        columns: 3,
      },
      {
        name: `questions[${index}].max`,
        label: 'Maximum',
        type: 'number',
        data: question.id,
        columns: 3,
      },
    ];
  }

  createQuestion(): RangeQuestion {
    return {id: ObjectID().toHexString(), content: '', type: 'range', min: 0, max: 100};
  }

  createValidationSchema(): BaseSchema {
    return Yup.object().shape({
      min: Yup.number().required('Minimum is required.'),
      max: Yup.number()
        .required('Max is required.')
        .when('min', (min, schema) => {
          return schema.test('min', 'Maximum must be greater min', val => {
            return val > min;
          });
        }),
    });
  }

  getIcon(): React.ReactElement {
    return <LinearScaleOutlined fontSize={'medium'} />;
  }

  renderAnswer(answer: RangeAnswer): React.ReactElement {
    return (
      <SuiTypography variant="button" fontWeight="regular">
        {answer.value}
      </SuiTypography>
    );
  }

  renderCompoundAnswer(answer: CompoundAnswer<RangeQuestion, RangeAnswer, RangeAnswerStatistics>): React.ReactElement {
    return (
      <>
        <Grid item xs={12} sx={{pt: 2, pb: 1}}>
          <AverageNumberView average={answer.statistics.average} />
        </Grid>
        <AnswerTable answer={answer} labelProvider={(a: RangeAnswer) => (a.value ? a.value + '' : '')} />
      </>
    );
  }
}
