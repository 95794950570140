import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';

import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

function NewPlanCard({icon, title, outlined, onClick, disabled}) {
  return (
    <Card
      className="imc-tour-new-plan"
      raised
      sx={{
        height: '100%',
        backgroundColor: outlined && 'transparent',
        boxShadow: outlined && 'none',
        border: 'none',
        '&:hover': {
          backgroundColor: '#fcfcfc',
          cursor: !disabled ? 'pointer' : 'default',
        },
        minHeight: '226px',
        disabled: disabled,
      }}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}>
      <SuiBox display="flex" flexDirection="column" justifyContent="center" textAlign="center" height="100%" p={3}>
        <SuiBox color="secondary" mb={0.5}>
          <Icon fontSize="medium" sx={{fontWeight: 'bold'}}>
            {icon}
          </Icon>
        </SuiBox>
        <SuiTypography variant={title.variant} color="secondary">
          {title.text}
        </SuiTypography>
      </SuiBox>
    </Card>
  );
}

// Setting default values for the props of PlaceholderCard
NewPlanCard.defaultProps = {
  icon: 'add',
  outlined: false,
  disabled: false,
};

// Typechecking props for the PlaceholderCard
NewPlanCard.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.shape({
    variant: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
  outlined: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default NewPlanCard;
