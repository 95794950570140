import {IntervalGoal, StringGoal} from 'pages/coaching/tasks/type-prepare-interval/IntervalGoalTypes';
import * as Yup from 'yup';
import {BaseSchema} from 'yup';
import {DataFormField} from 'components/DataForm/index';
import {FlagCircleOutlined, TextFieldsOutlined} from '@mui/icons-material';
import {IntervalGoalSupport} from 'pages/coaching/tasks/type-prepare-interval/IntervalGoalSupportFactory';
import ObjectID from 'bson-objectid';

export class StringGoalSupport implements IntervalGoalSupport<StringGoal> {
  createFields(goal: IntervalGoal, index: number): DataFormField[] {
    return [
      {
        name: `goals[${index}].content`,
        label: 'Content',
        type: 'text',
        placeholder: '',
        icon: <FlagCircleOutlined />,
        data: goal.id,
      },
    ];
  }

  createGoal(): StringGoal {
    return {id: ObjectID().toHexString(), content: '', type: 'text'};
  }

  createValidationSchema(): BaseSchema {
    return Yup.object().shape({
      content: Yup.string().required('Content is required'),
    });
  }

  getIcon(): React.ReactElement {
    return <TextFieldsOutlined fontSize={'medium'} />;
  }
}
