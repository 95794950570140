/**
 =========================================================
 * Soft UI Dashboard PRO React - v3.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useEffect, useState} from 'react';

// react-router components
import {Link, useLocation, useNavigate} from 'react-router-dom';

import {useAuth0} from '@auth0/auth0-react';
import {onLogout} from 'components/Firebase/FirebaseMessaging';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @material-ui core components
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import Badge from '@mui/material/Badge';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

// Soft UI Dashboard PRO React example components
import Breadcrumbs from 'layout/navbar/Breadcrumbs';

// Custom styles for DashboardNavbar
import {navbar, navbarContainer, navbarIconButton, navbarMobileMenu, navbarRow} from 'layout/navbar/styles';

// Soft UI Dashboard PRO React context
import {setMiniSidenav, setTransparentNavbar, useSoftUIController} from 'context';

import {useRecoilValue} from 'recoil';
import {authenticatedUserState} from 'state/authentication';
import {unreadNotificationState} from 'state/notification';

// Images
import SuiAvatar from 'components/SuiAvatar';
import {Backdrop} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import {userSettingsState} from 'utils/settings/userSettingsState';
import {TbMenu} from 'react-icons/tb';

const DashboardNavbar = ({absolute, light, isMini}) => {
  const navigate = useNavigate();
  const {logout} = useAuth0();
  const [navbarType, setNavbarType] = useState<'sticky' | 'static'>();
  const [controller, dispatch] = useSoftUIController();
  const {miniSidenav, transparentNavbar, fixedNavbar} = controller;
  const route = useLocation().pathname.split('/').slice(1);
  const authenticatedUser = useRecoilValue(authenticatedUserState);
  const unreadNotifications = useRecoilValue(unreadNotificationState);
  const userSettings = useRecoilValue(userSettingsState);
  const [logoutAttempted, setLogoutAttempted] = useState(false);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType('sticky');
    } else {
      setNavbarType('static');
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /**
     The event listener that's calling the handleTransparentNavbar function when
     scrolling the window.
     */
    window.addEventListener('scroll', handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener('scroll', handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  const breadcrumbStyle = theme => {
    let breadcrumbStyle = navbarRow(theme, {isMini});
    breadcrumbStyle.display = {xs: 'none', lg: 'flex'};
    breadcrumbStyle.flexGrow = 1;
    breadcrumbStyle.marginLeft = '1rem';
    return breadcrumbStyle;
  };

  // @ts-ignore
  return (
    <AppBar
      position={absolute ? 'absolute' : navbarType}
      color="inherit"
      sx={theme => navbar(theme, {transparentNavbar, absolute, light})}>
      <Toolbar sx={theme => navbarContainer(theme)}>
        <SuiBox>
          <IconButton color="inherit" sx={navbarMobileMenu} onClick={handleMiniSidenav}>
            <TbMenu className={'text-dark'} />
          </IconButton>
        </SuiBox>
        <SuiBox color="inherit" mb={{xs: 1, md: 0}} sx={breadcrumbStyle}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </SuiBox>
        {isMini ? null : (
          <SuiBox sx={theme => navbarRow(theme, {isMini})}>
            <SuiBox
              color={light ? 'white' : 'inherit'}
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'flex-end',
              }}>
              <SuiBox sx={{alignItems: 'stretch'}}>
                <Link to="/me/profile">
                  <IconButton sx={navbarIconButton} size="small">
                    <SuiAvatar src={authenticatedUser.picture} variant="rounded" size="xs" />

                    <SuiTypography variant="button" fontWeight="medium" color={light ? 'white' : 'dark'}>
                      {authenticatedUser.name}
                    </SuiTypography>
                  </IconButton>
                </Link>
                <Link to="/me/settings">
                  <IconButton size="small" color="inherit" sx={navbarIconButton}>
                    <Icon
                      sx={({
                        palette: {
                          // @ts-ignore
                          dark,
                          // @ts-ignore
                          white,
                        },
                      }) => ({
                        color: light ? white.main : dark.main,
                      })}>
                      settings
                    </Icon>
                  </IconButton>
                </Link>
                <Link to="/me/notifications" className="imc-tour-notifications">
                  {/*@ts-ignore*/}
                  <IconButton
                    size="small"
                    color="inherit"
                    sx={navbarIconButton}
                    aria-controls="notification-menu"
                    aria-haspopup="true"
                    variant="contained"
                    onClick={() => navigate('/me/notifications')}>
                    {/*@ts-ignore*/}
                    <Badge badgeContent={unreadNotifications} color="error" overlap="circular" variant="dot">
                      <Icon
                        sx={({
                          palette: {
                            // @ts-ignore
                            dark,
                            // @ts-ignore
                            white,
                          },
                        }) => ({
                          color: light ? white.main : dark.main,
                        })}
                        className={light ? 'text-white' : 'text-dark'}>
                        inbox
                      </Icon>
                    </Badge>
                  </IconButton>
                </Link>
                <IconButton
                  size="small"
                  color="inherit"
                  sx={navbarIconButton}
                  onClick={() => {
                    setLogoutAttempted(true);
                    onLogout(userSettings).finally(() => {
                      logout({returnTo: window.location.origin});
                    });
                  }}>
                  <Icon>logout</Icon>
                </IconButton>
              </SuiBox>
            </SuiBox>
          </SuiBox>
        )}
        <Backdrop sx={{color: '#fff', zIndex: theme => theme.zIndex.drawer + 1}} open={logoutAttempted}>
          <CircularProgress color="primary" />
        </Backdrop>
      </Toolbar>
    </AppBar>
  );
};

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
