import {useEffect} from 'react';
import axios from 'axios';
import {useAuth0} from '@auth0/auth0-react';

const AxiosConfigurer = () => {
  const {logout} = useAuth0();

  useEffect(() => {
    configureAxios();
  }, []);

  const configureAxios = () => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.defaults.headers.post['Content-Type'] = 'application/json';

    axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (error.response?.status === 401) {
          logout({returnTo: window.location.origin});
        }
        throw error;
      },
    );
  };

  return <></>;
};

export default AxiosConfigurer;
