import Grid from '@mui/material/Grid';
import SuiTypography from 'components/SuiTypography/index';
import DataForm, {DataFormField} from 'components/DataForm/index';
import * as Yup from 'yup';
import {Invitation} from 'pages/coaching/clients/invite/InvitationTypes';
import React, {useEffect, useState} from 'react';
import DashboardNavbar from 'layout/navbar/DashboardNavbar';
import DashboardLayout from 'layout/DashboardLayout';
import SuiBox from 'components/SuiBox';
import {Card} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import {Page} from 'components/BootPagination/index';
import {Plan} from 'pages/coaching/plans/PlanTypes';
import axios from 'axios';
import {useConfirm} from 'components/ConfirmDialog/index';

const InvitationPage = () => {
  const {planId} = useParams();
  const navigate = useNavigate();
  const [invitation, setInvitation] = useState<Invitation>({} as Invitation);
  const [fields, setFields] = useState<DataFormField[]>([]);
  const [plans, setPlans] = useState<Plan[]>([]);
  const confirm = useConfirm();

  useEffect(() => {
    axios
      .get(`/api/v0/plans`)
      .then(response => response.data)
      .then((page: Page<Plan>) => {
        setPlans(page.content);
        setFields(createFields(page.content));
        const promotedPlans = page.content.filter(plan => plan.status === 'PROMOTED');
        if (!planId && promotedPlans.length > 0) {
          invitation.planId = promotedPlans[0].id;
        } else {
          invitation.planId = planId;
        }
      })
      .catch(error => Promise.reject(error));
  }, [planId]);

  const createFields = (plans: Plan[]): DataFormField[] => {
    return [
      {name: 'name', label: 'Name', type: 'text', placeholder: '', columns: 6},
      {
        name: 'email',
        label: 'Email',
        type: 'text',
        placeholder: '',
        columns: 6,
        hint: 'The email address of the client that the client need to use to login into inmycorner.',
      },
      {
        name: 'planId',
        label: 'Plan',
        type: 'select',
        placeholder: '',
        readOnly: () => planId !== undefined,
        columns: 6,
        options: plans.filter(plan => plan.status === 'PROMOTED').map(plan => ({value: plan.id, label: plan.name})),
      },
    ];
  };

  const validation = Yup.object().shape({
    name: Yup.string().ensure().required('Name is required.'),
    email: Yup.string().ensure().required('Email is required.').email(),
    planId: Yup.string().ensure().required('Plan is required.'),
  });

  const onSubmit = async (values, actions) => {
    try {
      await confirm({
        title: 'Create Invitation',
        content:
          'After creating the invitation, the client will receive an invitation email and the client will be able to login into ' +
          'inmycorner using the email address you have provided. Are you sure you want to create the invitation?',
        confirmationText: 'Create',
        confirmationButtonProps: {
          color: 'primary',
        },
      });
      await createInvitation(values, actions);
    } catch (error) {
      actions.setSubmitting(false);
    }
  };

  const createInvitation = async (values, actions) => {
    try {
      const response = await axios.post(`/api/v0/invitations`, values);
      setInvitation(response.data);
      navigate(`/coaching/plans/${response.data.planId}/invitations`);
    } catch (error) {
      actions.setStatus(error?.response?.data?.message);
    }
    actions.setSubmitting(false);
  };

  const computeTitle = () => {
    if (planId) {
      return 'Create Invitation for "' + plans.find(plan => plan.id === planId)?.name + '"';
    } else {
      return 'Create Invitation';
    }
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox py={3}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container alignItems="flex-start">
                <Grid container item xs={12} xl={8} rowSpacing={3} pr={{xl: 3}} py={3}>
                  <Card sx={{width: '100%'}}>
                    <Grid item xs={12} pt={4} px={2}>
                      <SuiTypography variant="h6" fontWeight="medium">
                        {computeTitle()}
                      </SuiTypography>
                    </Grid>
                    <Grid item xs={12} px={2}>
                      <SuiTypography variant="button" color="text" fontWeight="regular">
                        Sending an invitation to a client will allow them to subscribe to your plan within the
                        inmycorner app. You will be notified when the client has accepted the invitation.
                      </SuiTypography>
                      <br />
                    </Grid>
                    <DataForm
                      validation={validation}
                      onSubmit={onSubmit}
                      fields={fields}
                      initialValues={invitation}
                      submitLabel="invite"
                    />
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SuiBox>
      </DashboardLayout>
    </>
  );
};

export default InvitationPage;
