import Divider from '@mui/material/Divider';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

// Soft UI Dashboard PRO React base styles
import colors from 'assets/theme/base/colors';
import typography from 'assets/theme/base/typography';
import SuiAvatar from 'components/SuiAvatar/index';

type Social = {
  icon: React.ReactElement;
  link: string;
  color: string;
};

type InfoCardProps = {
  id: string;
  avatar: string;
  title: string;
  description: string;
  info: {[key: string]: string};
  social: Social[];
};

function ClientInfo(props: InfoCardProps) {
  const labels = [];
  const values = [];
  const {socialMediaColors} = colors;
  const {size} = typography;

  // Convert this form `objectKey` of the object key in to this `object key`
  Object.keys(props.info).forEach(el => {
    if (el.match(/[A-Z\s]+/)) {
      const uppercaseLetter = Array.from(el).find(i => i.match(/[A-Z]+/));
      const newElement = el.replace(uppercaseLetter, ` ${uppercaseLetter.toLowerCase()}`);

      labels.push(newElement);
    } else {
      labels.push(el);
    }
  });

  // Push the object values into the values array
  Object.values(props.info).forEach(el => values.push(el));

  // Render the card info items
  const renderItems = labels.map((label, key) => (
    <SuiBox key={label} display="flex" py={1} pr={2}>
      <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
        {label}: &nbsp;
      </SuiTypography>
      <SuiTypography variant="button" fontWeight="regular" color="text">
        &nbsp;{values[key]}
      </SuiTypography>
    </SuiBox>
  ));

  // Render the card social media icons
  const renderSocial = props.social.map(({link, icon, color}) => (
    <SuiBox
      key={color}
      component="a"
      href={link}
      target="_blank"
      rel="noreferrer"
      fontSize={size.lg}
      color={socialMediaColors[color].main}
      pr={1}
      pl={0.5}
      lineHeight={1}>
      {icon}
    </SuiBox>
  ));

  const accountType = () => {
    if (props.id) {
      if (props.id.startsWith('auth0')) {
        return 'username/password';
      } else if (props.id.startsWith('google')) {
        return 'google';
      } else if (props.id.startsWith('apple')) {
        return 'apple';
      }
    }
    return 'unknown';
  };

  return (
    <>
      <SuiBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {props.title}
        </SuiTypography>
      </SuiBox>
      <SuiBox p={2}>
        <SuiBox mb={2} lineHeight={1} sx={{display: 'flex'}}>
          <SuiBox lineHeight={1}>
            <SuiTypography variant="button" color="text" fontWeight="regular">
              <SuiAvatar
                src={props.avatar ? encodeURI(props.avatar) : null}
                bgColor={'light'}
                alt="profile-image"
                variant="rounded"
                size="xl"
                shadow="sm"
              />
            </SuiTypography>
          </SuiBox>
          <SuiBox ml={2} lineHeight={1}>
            <SuiTypography variant="button" color="text" fontWeight="regular">
              {props.description}
            </SuiTypography>
          </SuiBox>
        </SuiBox>

        <SuiBox opacity={0.3}>
          <Divider />
        </SuiBox>
        <SuiBox>
          {renderItems}
          <SuiBox display="flex" py={1} pr={2}>
            <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
              social: &nbsp;
            </SuiTypography>
            {renderSocial}
          </SuiBox>
          <SuiBox display="flex" py={1} pr={2}>
            <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
              account: &nbsp;
            </SuiTypography>
            <SuiTypography variant="button" fontWeight="regular" color="text">
              &nbsp;{accountType()}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      </SuiBox>
    </>
  );
}

export default ClientInfo;
