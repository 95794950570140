import {SingleMacroGoal, SingleMacroType} from 'pages/coaching/tasks/type-prepare-interval/IntervalGoalTypes';
import Grid from '@mui/material/Grid';
import MacrosCard from 'components/IntervalExecutionCard/goals-results/macros/MacrosCard';
import {UserDetails} from 'pages/coaching/tasks/TaskTypes';
import {Scale} from '@mui/icons-material';
import SuiTypography from 'components/SuiTypography/index';
import Hint from 'components/Hint/Hint';

type SingleMacroGoalViewProps = {
  goal: SingleMacroGoal;
  client: UserDetails;
};

const SingleMacroGoalView = (props: SingleMacroGoalViewProps) => {
  const computeColor = (type: SingleMacroType) => {
    switch (type) {
      case 'CARBS':
        return 'warning';
      case 'PROTEIN':
        return 'success';
      case 'FAT':
        return 'error';
      case 'FIBER':
        return 'primary';
      default:
        return 'success';
    }
  };

  const computeName = (type: SingleMacroType) => {
    switch (type) {
      case 'CARBS':
        return 'Carbs';
      case 'PROTEIN':
        return 'Protein';
      case 'FAT':
        return 'Fat';
      case 'FIBER':
        return 'Fiber';
      default:
        return 'None';
    }
  };

  return (
    <>
      <SuiTypography variant="button" fontWeight="regular" color={'text'}>
        You defined the following {props.goal.macroType.toLowerCase()} goal for {props.client.name}.
        <Hint
          hint={
            'Green values are within the goal range, yellow values are outside the goal range, and red values are missing.'
          }
        />
      </SuiTypography>
      <Grid container item xs={12} rowSpacing={3} sx={{pt: 1, pb: 1}}>
        <Grid item xs={12} md={12} lg={12 / 5} pr={{xs: 0, md: 0, lg: 3}}>
          <MacrosCard
            icon={<Scale />}
            color={computeColor(props.goal.macroType)}
            type={computeName(props.goal.macroType)}
            value={
              <>
                <span style={{color: '#439946'}}>{props.goal.min + 'g'}</span> -{' '}
                <span style={{color: '#439946'}}>{props.goal.max + 'g'}</span>
              </>
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SingleMacroGoalView;
