import React from 'react';
import DataForm, {DataFormField} from 'components/DataForm/index';
import * as Yup from 'yup';

export type SearchUsersProps = {
  onSearch: (text) => void;
};

const SearchUsers = (props: SearchUsersProps) => {
  const fields: DataFormField[] = [{name: 'name', label: 'Name', type: 'text', placeholder: '', columns: 12}];

  const validation = Yup.object().shape({
    name: Yup.string().ensure().required('Name is required.'),
  });

  const onSubmit = async values => {
    props.onSearch(values.name);
  };

  return (
    <>
      <DataForm
        validation={validation}
        onSubmit={onSubmit}
        fields={fields}
        initialValues={{name: ''}}
        submitLabel="search user"
      />
    </>
  );
};

export default SearchUsers;
