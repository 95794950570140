import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SuiButton from 'components/SuiButton';
import PropTypes from 'prop-types';

// Inspired by https://github.com/jonatanklosko/material-ui-confirm
const ConfirmationDialog = ({open, options, onCancel, onConfirm, onClose}) => {
  const {
    title,
    description,
    content,
    confirmationText,
    cancellationText,
    dialogProps,
    confirmationButtonProps,
    cancellationButtonProps,
    titleProps,
    contentProps,
    allowClose,
  } = options;

  return (
    <Dialog fullWidth {...dialogProps} open={open} onClose={allowClose ? onClose : null}>
      {title && <DialogTitle {...titleProps}>{title}</DialogTitle>}
      {content ? (
        <DialogContent {...contentProps}>{content}</DialogContent>
      ) : (
        description && (
          <DialogContent {...contentProps}>
            <DialogContentText>{description}</DialogContentText>
          </DialogContent>
        )
      )}
      <DialogActions>
        <SuiButton size="small" {...cancellationButtonProps} onClick={onCancel}>
          {cancellationText}
        </SuiButton>
        <SuiButton size="small" color="error" {...confirmationButtonProps} onClick={onConfirm}>
          {confirmationText}
        </SuiButton>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  options: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    content: PropTypes.node,
    confirmationText: PropTypes.string,
    cancellationText: PropTypes.string,
    dialogProps: PropTypes.instanceOf(Object),
    confirmationButtonProps: PropTypes.instanceOf(Object),
    cancellationButtonProps: PropTypes.instanceOf(Object),
    titleProps: PropTypes.instanceOf(Object),
    contentProps: PropTypes.instanceOf(Object),
    allowClose: PropTypes.bool,
  }),
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
};

export default ConfirmationDialog;
