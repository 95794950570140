import {Pagination} from '@mui/material';

export type Page<T> = {
  content: T[];
  number?: number;
  totalPages?: number;
  totalElements?: number;
  size?: number;
  numberOfElements?: number;
  empty?: boolean;
  first?: boolean;
  last?: boolean;
};

export type PaginationProps = {
  page: Page<any>;
  onClick: (page: number) => void;
};

const BootPagination = (props: PaginationProps) => {
  const currentPage = () => {
    if (props.page?.number !== undefined) {
      return props.page?.number + 1;
    }
    return 1;
  };

  const isDisabled = () => {
    return props.page?.first && props.page?.last;
  };

  return (
    <>
      <Pagination
        count={props.page?.totalPages ? props.page?.totalPages : 1}
        page={currentPage()}
        color="primary"
        sx={{
          '& .Mui-selected': {
            color: '#fff !important',
          },
        }}
        disabled={isDisabled()}
        hidden={props.page?.empty}
        onChange={(e, newPage) => props.onClick(newPage - 1)}
      />
    </>
  );
};

export default BootPagination;
