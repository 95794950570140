import React, {PropsWithChildren, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {StepType, TourProvider} from '@reactour/tour';
import SuiButton from 'components/SuiButton/index';
import pxToRem from 'assets/theme/functions/pxToRem';

const ProductTour = ({children}: PropsWithChildren<any>) => {
  const navigate = useNavigate();

  const [step, setStep] = useState(0);

  const setCurrentStep = step => {
    switch (step) {
      case 1:
        navigate('/coaching/tasks');
        break;
      case 3:
        navigate('/coaching/plans');
        break;
      case 4:
        navigate('/coaching/clients');
        break;
      case 5:
        navigate('/me/profile');
        break;
      default:
        break;
    }
    setStep(step);
  };

  const steps: StepType[] = [
    {
      selector: '.imc-tour-task',
      content:
        'You already discovered it! This is a task. Almost everything in inmycorner is done via tasks. ' +
        'This includes client onboarding, creating questionnaires or preparing and reviewing Check-ins.',
    },
    {
      selector: '.imc-tour-task-list',
      content:
        'You can view your tasks on the tasks page or on the dashboard. ' +
        "Once you have added clients, you will also have a task list only for the client on the client's page.",
      padding: {
        mask: [0, 200],
      },
    },
    {
      selector: '.imc-tour-notifications',
      content:
        'Tasks as well as other events in inmycorner are announced via notifications. You can see all your notifications on the notifications page.',
    },
    {
      selector: '.imc-tour-new-plan',
      content:
        'In order to start your coaching adventure the first thing you need is to create your coaching plans. In order to coach clients ' +
        'you need at least one published plan. Once a plan is published you can add clients.',
    },
    {
      selector: '.imc-tour-clients',
      content:
        "Once you have added your clients you will see new tasks in your task list. You can see all your clients on the clients page. By clicking on a client you will be able to see all client specific tasks, the client's data as well as your private notes about the client.",
    },
    {
      selector: '.imc-tour-profile',
      content:
        'A client can see your details in the inmycorner app. It makes sense to create a flashy profile for your coaching business. You can do this here on the profile page.',
      position: 'top',
    },
  ];

  return (
    <>
      <TourProvider
        steps={steps}
        currentStep={step}
        setCurrentStep={setCurrentStep}
        nextButton={props => {
          return (
            <SuiButton size="small" color="primary" onClick={() => props.setCurrentStep(step + 1)}>
              {step === steps.length - 1 ? 'Close' : 'Next'}
            </SuiButton>
          );
        }}
        prevButton={props => {
          return (
            <SuiButton
              variant={'outlined'}
              size="small"
              color="primary"
              onClick={() => props.setCurrentStep(step - 1)}
              disabled={step === 0}>
              Previous
            </SuiButton>
          );
        }}
        styles={{
          maskWrapper: base => ({
            ...base,
            opacity: 0.45,
          }),
          popover: base => ({
            ...base,
            color: '#666768',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontSize: pxToRem(15),
            borderRadius: pxToRem(8),
            maxWidth: pxToRem(400),
          }),
          badge: base => ({
            ...base,
            color: 'white',
            backgroundColor: '#14C7EA',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontSize: pxToRem(15),
          }),
          dot: base => ({
            ...base,
            background: 'var(--reactour-accent, #14C7EA)',
            color: 'var(--reactour-accent, #14C7EA)',
          }),
        }}>
        {children}
      </TourProvider>
    </>
  );
};

export default ProductTour;
