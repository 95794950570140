import {useEffect, useState} from 'react'; // formik components
import Grid from '@mui/material/Grid';
import axios from 'axios';
import * as Yup from 'yup';
import DataForm, {DataFormField} from 'components/DataForm/index';
import SuiBox from 'components/SuiBox/index';
import {Tooltip} from '@mui/material';
import {Interval} from 'components/IntervalExecutionCard/IntervalTypes';
import {IntervalGoal, IntervalGoalDescription} from 'pages/coaching/tasks/type-prepare-interval/IntervalGoalTypes';
import {IntervalGoalSupportFactory} from 'pages/coaching/tasks/type-prepare-interval/IntervalGoalSupportFactory';
import IntervalGoalDescriptionAutoComplete from 'pages/coaching/tasks/type-prepare-interval/IntervalGoalDescriptionAutoComplete';
import {AiOutlineDelete} from 'react-icons/ai';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import {ErrorMessage} from 'formik';
import SuiTypography from 'components/SuiTypography/index';

type IntervalGoalsFormProps = {
  interval: Interval;
  description: string;
  isFirstInterval: boolean;
  onSubmit: (interval: Interval, actions: FormikHelpers<any>) => void;
  leftButton?: React.ReactElement;
  updateInterval: (interval: Interval) => void;
  minGoals: number;
  submitLabel: string;
  formikRef?: React.RefObject<FormikProps<any>>;
};

const IntervalGoalsForm = (props: IntervalGoalsFormProps) => {
  const supportFactory = new IntervalGoalSupportFactory();
  const [goalDescriptions, setGoalDescriptions] = useState<IntervalGoalDescription[]>([]);
  const [fields, setFields] = useState<DataFormField[]>([
    {
      type: 'description',
      label: props.description,
      data: 'add-goal-container',
    },
  ]);

  const validation = () => {
    let goalsValidation = Yup.array()
      .ensure()
      .of(
        //@ts-ignore
        Yup.lazy(value => {
          return supportFactory.supportFor(value.type).createValidationSchema();
        }),
      );
    if (props.minGoals > 0) {
      goalsValidation = goalsValidation.min(props.minGoals, 'At least one goal is required');
    }
    return Yup.object().shape({
      goals: goalsValidation,
    });
  };

  useEffect(() => {
    axios
      .get(`/api/v0/intervals/${props.interval.id}/goal-descriptions`)
      .then(response => response.data)
      .then(setGoalDescriptions)
      .catch(error => Promise.reject(error));
  }, [props.interval]);

  useEffect(() => {
    setFields(fieldsWithGoals(props.interval));
  }, [props.interval, goalDescriptions]);

  const fieldsWithGoals = (i: Interval) => {
    const newFields = [...fields].filter(f => !f.name || !f.name.startsWith('goals['));
    updateAddGoalComponent(newFields);
    if (goalDescriptions.length > 0) {
      i.goals.forEach((goal, index) => {
        newFields.push(...createCommonGoalFields(goal, index));
        newFields.push(...createTypeSpecificGoalFields(goal, index));
      });
    }
    return newFields;
  };

  const updateAddGoalComponent = (newFields: DataFormField[]) => {
    newFields.find(f => f.data === 'add-goal-container').bottomComponent = context => createAddGoalComponent(context);
  };

  const createAddGoalComponent = formikContext => {
    if (props.interval.status === 'DRAFT') {
      return (
        <>
          <SuiTypography component="div" variant="caption" color="error">
            <ErrorMessage name="goals">{error => (typeof error === 'string' ? error : undefined)}</ErrorMessage>
          </SuiTypography>
          <IntervalGoalDescriptionAutoComplete
            goalDescriptions={goalDescriptions}
            interval={props.interval}
            onDescriptionSelected={d => addGoal(d, formikContext)}
          />
        </>
      );
    }
  };

  const createCommonGoalFields = (goal: IntervalGoal, index: number): DataFormField[] => {
    const description = goalDescriptions.find(d => d.type === goal.type);
    return [
      {
        name: `goals[${index}].divider`,
        type: 'divider',
      },
      {
        name: `goals[${index}].section`,
        type: 'section',
        label: `Goal #${index + 1}: ${description.summary}`,
        rightComponent: context => createGoalControls(goal.id, context),
      },
      {
        name: `goals[${index}].description`,
        type: 'description',
        label: description.description,
      },
      {
        name: `goals[${index}].type`,
        type: 'hidden',
        value: goal.type,
        data: goal.id,
      },
      {
        name: `goals[${index}].id`,
        type: 'hidden',
        value: goal.id,
        data: goal.id,
      },
    ];
  };

  const createTypeSpecificGoalFields = (goal: IntervalGoal, index: number): DataFormField[] => {
    return supportFactory.supportFor(goal.type).createFields(goal, index);
  };

  const createGoalControls = (goalId, formikContext) => {
    return (
      <SuiBox
        ml={1}
        sx={{
          display: props.interval.status === 'DRAFT' ? 'flex' : 'none',
          alignItems: 'center',
          mt: 1,
        }}>
        <Tooltip title="Delete Goal">
          <span>
            <AiOutlineDelete
              style={{color: '#8392ab', cursor: 'pointer'}}
              onClick={() => removeGoal(goalId, formikContext)}
            />
          </span>
        </Tooltip>
      </SuiBox>
    );
  };

  const addGoal = (goalDescription: IntervalGoalDescription, formikContext) => {
    const {values} = formikContext;
    const newInterval = structuredClone(values);
    const goal = supportFactory.supportFor(goalDescription.type).createGoal();
    newInterval.goals.push(goal);
    props.updateInterval(newInterval);
  };

  const removeGoal = (goalId, formikContext) => {
    const {values} = formikContext;
    const indexOfGoal = values.goals.findIndex(g => g.id === goalId);
    const newInterval = structuredClone(values);
    newInterval.goals.splice(indexOfGoal, 1);
    props.updateInterval(newInterval);
  };

  return (
    <>
      <Grid container item xs={12}>
        <Grid item xs={12} lg={12}>
          <DataForm
            validation={validation()}
            onSubmit={props.onSubmit}
            fields={fields}
            initialValues={props.interval}
            readOnly={props.interval.status !== 'DRAFT'}
            hideButtons={props.interval.status !== 'DRAFT'}
            submitLabel={props.submitLabel}
            leftButton={props.leftButton}
            formikRef={props.formikRef}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default IntervalGoalsForm;
