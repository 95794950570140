import {Field} from 'formik';

type FormFieldHiddenProps = {
  name: string;
  value: string;
};

const FormFieldHidden = (props: FormFieldHiddenProps) => {
  return (
    <>
      <Field name={props.name} type={'hidden'} value={props.value} />;
    </>
  );
};

export default FormFieldHidden;
