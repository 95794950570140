import {
  Avatar,
  ChatContainer,
  Message,
  MessageGroup,
  MessageInput,
  MessageList,
  MessageSeparator,
  TypingIndicator,
} from '@chatscope/chat-ui-kit-react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import 'components/Chat/chat.css';
import {UserDetails} from 'pages/coaching/tasks/TaskTypes';
import {Client} from 'pages/coaching/clients/client/Client';
import SuiTypography from 'components/SuiTypography/index';
import SuiBox from 'components/SuiBox/index';
import {Conversation} from '@twilio/conversations';
import React, {useEffect, useState} from 'react';
import {TwilioMessageController} from 'components/Chat/twilio/TwilioMessageController';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {twilioControllerState} from 'components/Chat/twilio/TwilioController';
import moment from 'moment';
import {clientsState} from 'state/clients';
import axios from 'axios';

const defaultAvatar = require('assets/images/default-avatar.png');

export type ClientChatContainerProps = {
  coach: UserDetails;
  client: Client;
  conversation: Conversation;
};

type ChatMessage = {
  id: string;
  message: string;
  author: string;
  date: Date;
  index: number;
};

const ChatView = (props: ClientChatContainerProps) => {
  const twilioController = useRecoilValue(twilioControllerState);
  const [twilioMessageController, setTwilioMessageController] = useState<TwilioMessageController>();

  const [messages, setMessages] = useState<ChatMessage[]>([]);

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);

  const [isClientTyping, setIsClientTyping] = useState<boolean>(false);

  const [lastReadMessageIndex, setLastReadMessageIndex] = useState<number>(null);

  const setClients = useSetRecoilState(clientsState);

  const [messageListener, setMessageListener] = useState<(message) => void>(null);

  useEffect(() => {
    if (props.conversation && props.client && twilioController != null && twilioController.isConnected()) {
      props.conversation.setUserNotificationLevel('muted');
      const twilioMessageController = twilioController.messages(props.conversation);
      setTwilioMessageController(twilioMessageController);
      setLastReadMessageIndex(props.conversation.lastReadMessageIndex);
      const loadMessages = async () => {
        const messages = await twilioMessageController.loadMessages();
        const chatMessages = messages.map(message => {
          return {
            id: message.sid,
            message: message.body,
            author: message.author,
            date: message.dateUpdated,
            index: message.index,
          };
        });
        setMessages(chatMessages);
        setLoading(false);
        if (chatMessages.length > 0) {
          setClients(clients => {
            return clients.map(client => {
              if (client.client.userId === props.client?.userId) {
                return {client: client.client, unreadMessages: 0};
              }
              return client;
            });
          });
          await props.conversation.setAllMessagesRead();
        }
      };

      // typing indicator
      twilioMessageController.onTypingStarted(function (participant) {
        if (participant.identity === props.client?.userId) {
          setIsClientTyping(participant.identity === props.client.userId);
        }
      });
      twilioMessageController.onTypingEnded(function (participant) {
        if (participant.identity === props.client?.userId) {
          setIsClientTyping(false);
        }
      });

      loadMessages();
      return () => {
        props.conversation.setUserNotificationLevel('default');
        twilioMessageController.removeTypingListeners();
        if (messageListener) {
          twilioMessageController.removeNewMessageListener(messageListener);
        }
      };
    }
  }, [props.conversation, props.client, setLastReadMessageIndex, messageListener]);

  useEffect(() => {
    if (props.client?.userId) {
      axios.post(`/api/v0/messages/${props.client.userId}/mark-all-read`);
    }
  }, [props.client]);

  useEffect(() => {
    if (twilioMessageController) {
      const listener = function (message) {
        setLastReadMessageIndex(message.index);
        props.conversation.advanceLastReadMessageIndex(message.index);
        setMessages(messages => {
          return [
            ...messages,
            {
              id: message.sid,
              message: message.body,
              author: message.author,
              date: message.dateUpdated,
              index: message.index,
            },
          ];
        });
      };
      setMessageListener(() => listener);
      twilioMessageController.onNewMessage(listener);
    }
  }, [twilioMessageController]);

  const loadMoreMessages = async () => {
    if (!loadingMore) {
      setLoadingMore(true);
      const newMessages = await twilioMessageController.loadOlderMessages();
      const chatMessages = newMessages.map(message => {
        return {
          id: message.sid,
          message: message.body,
          author: message.author,
          date: message.dateUpdated,
          index: message.index,
        };
      });
      setMessages([...chatMessages, ...messages]);
      setLoadingMore(false);
    }
  };

  const onReachStart = () => {
    if (twilioMessageController.hasOlderMessages()) {
      if (loadingMore === true) {
        return;
      }
      setLoadingMore(true);
      loadMoreMessages();
    }
  };

  const renderMessages = () => {
    if (messages.length === 0) {
      return;
    }
    return (
      <MessageList
        scrollBehavior="auto"
        loading={loading}
        loadingMore={loadingMore}
        autoScrollToBottom={true}
        loadingMorePosition={'top'}
        onYReachStart={onReachStart}
        disableOnYReachWhenNoScroll={true}
        typingIndicator={renderTypingIndicator()}>
        {createMessageListContent(messages)}
      </MessageList>
    );
  };

  const renderTypingIndicator = () => {
    if (isClientTyping) {
      return (
        <TypingIndicator
          content={
            <SuiTypography variant="button" color="text" fontWeight="regular">
              {props.client?.name} is typing
            </SuiTypography>
          }
        />
      );
    }
  };

  const createMessageListContent = messages => {
    let previousMessage: ChatMessage = null;
    const elements = [];
    let renderedNewMessagesSeparator = false;
    groupMessagesByMinute(messages).map(group => {
      const groupSeparator = createGroupMessagesSeparator(group[0], previousMessage);
      if (groupSeparator) {
        elements.push(groupSeparator);
      }
      const newSeparator = createNewMessagesSeparator(group);
      if (!renderedNewMessagesSeparator && newSeparator) {
        elements.push(newSeparator);
        renderedNewMessagesSeparator = true;
      }
      elements.push(createMessageGroup(group));
      previousMessage = group[group.length - 1];
    });
    return elements;
  };

  const getAvatarUrl = (picture: string) => {
    if (picture) {
      return picture;
    }
    return defaultAvatar;
  };

  const createMessageGroup = (group: ChatMessage[]) => {
    return (
      <MessageGroup
        key={group[0].id}
        direction={group[0].author === props.coach?.user ? 'outgoing' : 'incoming'}
        sender={group[0].author === props.coach.user ? props.coach?.name : props.client?.name}
        sentTime="just now">
        <Avatar
          src={getAvatarUrl(group[0].author === props.coach?.user ? props.coach?.picture : props.client?.picture)}
          name={getAvatarUrl(group[0].author === props.coach?.user ? props.coach?.name : props.client?.name)}
        />
        <MessageGroup.Header key={`header-${group[0].id}`}>
          <SuiBox sx={{display: 'flex', width: '100%', justifyContent: 'flex-end'}}>
            <SuiTypography variant="caption" color="text" fontWeight="light">
              {moment(group[0].date).format('h:mm A')}
            </SuiTypography>
          </SuiBox>
        </MessageGroup.Header>
        <MessageGroup.Messages>
          {group.map(message => {
            return (
              <Message key={`message-${message.id}`}>
                <Message.CustomContent>
                  <SuiTypography
                    variant="button"
                    color={group[0].author === props.coach?.user ? 'white' : 'black'}
                    fontWeight="regular">
                    {message.message}
                  </SuiTypography>
                </Message.CustomContent>
              </Message>
            );
          })}
        </MessageGroup.Messages>
      </MessageGroup>
    );
  };

  const createGroupMessagesSeparator = (nextMessage: ChatMessage, previousMessage: ChatMessage | null) => {
    if (previousMessage) {
      const message1Date = moment(previousMessage.date);
      const message2Date = moment(nextMessage.date);
      if (!message1Date.isSame(message2Date, 'day')) {
        return (
          <MessageSeparator key={`separator-${nextMessage.id}`}>
            {moment(previousMessage.date).local().format('dddd, MM-DD-YYYY')}
          </MessageSeparator>
        );
      }
    }
  };

  const createNewMessagesSeparator = (nextGroup: ChatMessage[]) => {
    const containsLastReadMessage = nextGroup.some(message => message.index >= lastReadMessageIndex);
    const isClientMessages = nextGroup.some(message => message.author === props.client?.userId);
    if (containsLastReadMessage && isClientMessages && lastReadMessageIndex !== null) {
      return (
        <MessageSeparator style={{color: 'red'}} key={`separator-new-messages`}>
          New
        </MessageSeparator>
      );
    }
  };

  const groupMessagesByMinute = (messages: ChatMessage[]): ChatMessage[][] => {
    const groups: ChatMessage[][] = [];
    let currentGroup: ChatMessage[] = [];
    for (let i = 0; i < messages.length; i++) {
      const message = messages[i];
      const prevMessage = messages[i - 1];
      if (
        !prevMessage ||
        message.author !== prevMessage.author ||
        moment(message.date).diff(prevMessage.date, 'minutes') > 1 ||
        !moment(message.date).isSame(prevMessage.date, 'day')
      ) {
        // Start a new group if this is the first message, or if the author changes, or if the previous message is more than 1 minute ago, or if the messages are on different days
        currentGroup = [];
        groups.push(currentGroup);
      }
      currentGroup.push(message);
    }
    return groups;
  };

  return (
    <>
      <ChatContainer style={{width: '100%'}}>
        {renderMessages()}
        <MessageInput
          placeholder={`Message for ${props.client?.name}`}
          attachButton={false}
          autoFocus={true}
          onChange={(html, text) => {
            if (text.length > 0) {
              props.conversation?.typing();
            }
          }}
          onSend={(html, text) => {
            props.conversation?.sendMessage(text);
          }}
        />
      </ChatContainer>
    </>
  );
};

export default ChatView;
