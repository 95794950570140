/**
 =========================================================
 * Soft UI Dashboard PRO React - v3.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
// react-quill components
import ReactQuill from "react-quill";

// react-quill styles
import "react-quill/dist/quill.snow.css";

// Custom styles for the SuiEditor
import SuiEditorRoot from "components/SuiEditor/SuiEditorRoot";
import PropTypes from "prop-types";
import { PropsWithChildren } from "react";

function SuiEditor({error, success, disabled, ...rest}: PropsWithChildren<any>) {
  return (
    <SuiEditorRoot ownerState={{error, success, disabled}}>
      <ReactQuill theme="snow" {...rest} />
    </SuiEditorRoot>
  );
}

// Setting default values for the props of SuiEditor
SuiEditor.defaultProps = {
  error: false,
  success: false,
  disabled: false,
};

// Typechecking props for the SuiEditor
SuiEditor.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default SuiEditor;
