import * as React from 'react';
import {ForwardedRef, forwardRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {SnackbarContent, useSnackbar} from 'notistack';
import SuiBox from 'components/SuiBox/index';
import SuiTypography from 'components/SuiTypography/index';
import Icon from '@mui/material/Icon';
import Divider from '@mui/material/Divider';
import typography from 'assets/theme/base/typography';
import SuiAvatar from 'components/SuiAvatar/index';
import SuiSnackbarIconRoot from 'components/SuiSnackbar/SuiSnackbarIconRoot';
import {NotificationMessage} from 'pages/notifictions/NotificationTypes';

export type NotificationSnackbarProps = {
  message: NotificationMessage;
  onMessageClick: () => void;
};

const NotificationSnackbar = forwardRef((props: NotificationSnackbarProps, ref: ForwardedRef<any>) => {
  const navigate = useNavigate();
  const {closeSnackbar} = useSnackbar();
  const {size} = typography;
  let titleColor;
  let dividerColor;

  if (props.message.color === 'light') {
    titleColor = 'dark';
    dividerColor = false;
  } else {
    titleColor = 'white';
    dividerColor = true;
  }

  const truncate = (str, n) => {
    if (str && str.length > n) {
      return str.substring(0, n) + '...';
    } else {
      return str;
    }
  };

  const getContent = () => {
    if (props.message.content !== undefined) {
      return truncate(props.message.content, 80);
    }
    return '';
  };

  const onMessageClick = () => {
    closeSnackbar(props.message.id);
    if (props.message.action !== '') {
      props.onMessageClick();
      navigate(props.message.action);
    } else {
      navigate('/me/notifications');
    }
  };

  const determineIcon = () => {
    if (props.message.iconLocation !== '') {
      return null;
    }
    switch (props.message.type) {
      case 'system':
        return 'admin_panel_settings_outlined';
      default:
        return 'notifications';
    }
  };

  const renderIcon = () => {
    if (props.message.iconLocation !== '') {
      return <SuiAvatar size="sm" sx={{mr: 1}} src={props.message.iconLocation} alt="Avatar" />;
    } else {
      const icon = determineIcon();
      return (
        <SuiSnackbarIconRoot fontSize="small" ownerState={{color: props.message.color, bgWhite: false}}>
          {icon}
        </SuiSnackbarIconRoot>
      );
    }
  };

  return (
    <SnackbarContent ref={ref}>
      <SuiBox
        variant={'gradient'}
        bgColor={props.message.color}
        height="auto"
        shadow="md"
        onClick={onMessageClick}
        sx={{cursor: 'pointer', maxWidth: {xs: '100%', lg: '30rem'}, minWidth: {xs: '100%', lg: '21.875rem'}}}
        borderRadius="md"
        p={1}>
        <SuiBox display="flex" justifyContent="space-between" alignItems="center" color="dark" p={1.5}>
          <SuiBox display="flex" alignItems="center" lineHeight={0}>
            {renderIcon()}
            <SuiTypography variant="button" fontWeight="medium" color={titleColor} textGradient={false}>
              {truncate(props.message.title, 50) || ''}
            </SuiTypography>
          </SuiBox>
          <SuiBox display="flex" alignItems="center" lineHeight={0}>
            <Icon
              sx={{
                /*@ts-ignore*/
                color: ({palette: {dark, white}}) => (props.message.color === 'light' ? dark.main : white.main),
                fontWeight: ({typography: {fontWeightBold}}) => fontWeightBold,
                cursor: 'pointer',
                marginLeft: 2,
                transform: 'translateY(-1px)',
              }}
              onClick={e => {
                e.stopPropagation();
                closeSnackbar(props.message.id);
              }}>
              close
            </Icon>
          </SuiBox>
        </SuiBox>
        <Divider sx={{margin: 0}} light={dividerColor} />
        <SuiBox p={1.5} color={props.message.color === 'light' ? 'text' : 'white'} fontSize={size.sm}>
          {getContent()}
        </SuiBox>
      </SuiBox>
    </SnackbarContent>
  );
});

export default NotificationSnackbar;
