import moment from 'moment';
import Table from 'components/SuiTable/index';
import Grid from '@mui/material/Grid';
import {Answer, AnswerStatistics, CompoundAnswer, Question} from 'components/Questionnaire/Questionnaire';
import SuiTypography from 'components/SuiTypography/index';

type AnswerTableProps<T extends Question, S extends Answer<Question>> = {
  answer: CompoundAnswer<T, S, AnswerStatistics>;
  labelProvider: (answer: S) => string;
};

const AnswerTable = (props: AnswerTableProps<Question, Answer<Question>>) => {
  const columns = [
    {name: 'day', align: 'left'},
    {name: 'answer', align: 'left'},
  ];

  const createRows = () => {
    const answers = new Map(Object.entries(props.answer.answers));
    const rows = [];
    answers.forEach((value, key) => {
      rows.push({
        key: new Date(key),
        day: moment(key).format('dddd, MM-DD-YYYY'),
        answer: createValuesComponent(props.labelProvider(value)),
      });
    });
    rows.sort((a, b) => (a.key > b.key ? 1 : -1));
    return rows;
  };

  const createValuesComponent = value => {
    let color = 'text';
    if (!value || value === '') {
      color = 'error';
    }
    return (
      <SuiTypography variant="button" fontWeight={'regular'} color={color}>
        {!value || value === '' ? 'no answer' : value}
      </SuiTypography>
    );
  };

  return (
    <>
      <Grid item xs={12} sx={{pt: 1}}>
        <Table columns={columns} rows={createRows()} tableStyles={{boxShadow: 'none'}} />
      </Grid>
    </>
  );
};

export default AnswerTable;
