import {UserDetails} from 'pages/coaching/tasks/TaskTypes';
import SuiTypography from 'components/SuiTypography/index';
import Grid from '@mui/material/Grid';
import Table from 'components/SuiTable';
import moment from 'moment/moment';
import {SleepGoalResult, SleepHours} from 'components/IntervalExecutionCard/IntervalTypes';

type SleepGoalResultViewProps = {
  result: SleepGoalResult;
  client: UserDetails;
};

const SleepGoalResultView = (props: SleepGoalResultViewProps) => {
  const columns = [
    {name: 'day', align: 'left'},
    {name: 'hours', align: 'center'},
  ];

  const createRows = () => {
    const hours = new Map<string, SleepHours>(Object.entries(props.result.sleepHours));
    const rows = [];
    hours.forEach((value, key) => {
      rows.push({
        key: new Date(key),
        day: moment(key).format('dddd, MM-DD-YYYY'),
        hours: createValuesComponent(value.hours, props.result.goal.minHours, 'h'),
      });
    });
    rows.sort((a, b) => (a.key > b.key ? 1 : -1));
    rows.push(createAverageRow(hours, props.result.goal.minHours));
    return rows;
  };

  const createAverageRow = (hours: Map<string, SleepHours>, minHours: number) => {
    const values = Array.from(hours.values());
    return {
      day: <b>Daily Average</b>,
      hours: createValuesComponent(average(values), minHours, 'h', 'bold'),
    };
  };

  const createValuesComponent = (value, minValue, suffix, fontWeight?) => {
    let color = '#439946';
    if (value === 0) {
      color = 'error';
    } else if (value < minValue) {
      color = '#d9b305';
    }
    return (
      <SuiTypography
        variant="button"
        fontWeight={fontWeight ? fontWeight : 'regular'}
        color={color}
        sx={{display: 'inline-block', width: 'max-content'}}>
        {value + suffix}
      </SuiTypography>
    );
  };

  const average = (values: SleepHours[]) => {
    return Math.floor(values.map(h => h.hours).reduce((a, b) => a + b, 0) / values.length);
  };

  return (
    <>
      <Grid item xs={12} sx={{pt: 1}}>
        <Table columns={columns} rows={createRows()} tableStyles={{boxShadow: 'none'}} />
      </Grid>
    </>
  );
};

export default SleepGoalResultView;
