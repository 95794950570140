import {Box, CircularProgress, CircularProgressProps, Typography} from '@mui/material';

const CircularProgressWithLabel = (
  props: CircularProgressProps & {value: number; fontSize?: string; fontWeight?: string},
) => {
  const progressColor = () => {
    if (props.value === 0) {
      return 'error';
    }
    return 'text.secondary';
  };

  return (
    <Box sx={{position: 'relative', display: 'inline-flex'}}>
      <Box sx={{mt: 1}}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: theme => theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
          }}
          {...props}
          value={100}
        />
        <CircularProgress
          variant="determinate"
          sx={{
            animationDuration: '750ms',
            position: 'absolute',
            left: 0,
          }}
          {...props}
        />
      </Box>
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Typography
          variant="caption"
          component="div"
          color={progressColor()}
          fontSize={props.fontSize}
          fontWeight={props.fontWeight}>{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
};

export default CircularProgressWithLabel;
