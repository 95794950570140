import {FieldProps, FormikContextType} from 'formik';
import SuiSelect from 'components/SuiSelect/index';
import {SelectOption} from 'components/DataForm/index';

type FormFieldSelectProps = {
  fieldProps: FieldProps;
  formikContext: FormikContextType<any>;
  value: string;
  options: SelectOption[];
  placeholder: string;
  readOnly: boolean;
  tabIndex: number;
};

const FormFieldSelect = (props: FormFieldSelectProps) => {
  return (
    <>
      <SuiSelect
        name={props.fieldProps.field.name}
        {...props.fieldProps.field}
        value={props.options.find(option => option.value === props.value)}
        options={props.options}
        placeholder={props.placeholder}
        onChange={v => {
          props.formikContext.setFieldValue(props.fieldProps.field.name, v.value, true);
          props.fieldProps.field.onChange(props.fieldProps.field.name);
        }}
        onBlur={() => {
          props.fieldProps.form.setFieldTouched(props.fieldProps.field.name, true);
          props.fieldProps.field.onBlur(props.fieldProps.field.name);
        }}
        isDisabled={props.readOnly}
        inputProps={{tabIndex: props.tabIndex}}
      />
    </>
  );
};

export default FormFieldSelect;
