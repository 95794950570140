import {
  CaloriesGoalResult,
  HydrationGoalResult,
  IntervalGoalResult,
  MacrosGoalResult,
  PhotoGoalResult,
  SingleMacroGoalResult,
  SleepGoalResult,
  StepsGoalResult,
  StringGoalResult,
} from 'components/IntervalExecutionCard/IntervalTypes';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import SuiTypography from 'components/SuiTypography/index';
import {
  CaloriesGoal,
  HydrationGoal,
  IntervalGoal,
  MacrosGoal,
  PhotoGoal,
  SingleMacroGoal,
  SleepGoal,
  StepsGoal,
  StringGoal,
} from 'pages/coaching/tasks/type-prepare-interval/IntervalGoalTypes';
import MacrosGoalView from 'components/IntervalExecutionCard/goals-results/macros/MacrosGoalView';
import MacrosGoalResultView from 'components/IntervalExecutionCard/goals-results/macros/MacrosGoalResultView';
import StringGoalView from 'components/IntervalExecutionCard/goals-results/string/StringGoalView';
import StringGoalResultView from 'components/IntervalExecutionCard/goals-results/string/StringGoalResultView';
import PhotoGoalView from 'components/IntervalExecutionCard/goals-results/photo/PhotoGoalView';
import PhotoGoalResultView from 'components/IntervalExecutionCard/goals-results/photo/PhotoGoalResultView';
import {UserDetails} from 'pages/coaching/tasks/TaskTypes';
import CircularProgressWithLabel from 'components/IntervalExecutionCard/CircularProgressWithLabel';
import SuiBox from 'components/SuiBox/index';
import ProgressColor from 'components/IntervalExecutionCard/ProgressColor';
import SleepGoalResultView from 'components/IntervalExecutionCard/goals-results/sleep/SleepGoalResultView';
import SleepGoalView from 'components/IntervalExecutionCard/goals-results/sleep/SleepGoalView';
import HydrationGoalView from 'components/IntervalExecutionCard/goals-results/hydration/HydrationGoalView';
import HydrationGoalResultView from 'components/IntervalExecutionCard/goals-results/hydration/HydrationGoalResultView';
import StepsGoalView from 'components/IntervalExecutionCard/goals-results/steps/StepsGoalView';
import StepsGoalResultView from 'components/IntervalExecutionCard/goals-results/steps/StepsGoalResultView';
import SingleMacroGoalResultView from 'components/IntervalExecutionCard/goals-results/singlemacro/SingleMacroGoalResultView';
import SingleMacroGoalView from 'components/IntervalExecutionCard/goals-results/singlemacro/SingleMacroGoalView';
import CaloriesGoalView from 'components/IntervalExecutionCard/goals-results/calories/CaloriesGoalView';
import CaloriesGoalResultView from 'components/IntervalExecutionCard/goals-results/calories/CaloriesGoalResultView';

type IntervalGoalResultProps = {
  result: IntervalGoalResult<IntervalGoal>;
  client: UserDetails;
  index: number;
};

const IntervalGoalResultView = (props: IntervalGoalResultProps) => {
  const renderGoal = (goal: IntervalGoal) => {
    switch (goal.type) {
      case 'text':
        return <StringGoalView goal={goal as StringGoal} client={props.client} />;
      case 'macros':
        return <MacrosGoalView goal={goal as MacrosGoal} client={props.client} />;
      case 'single-macro':
        return <SingleMacroGoalView goal={goal as SingleMacroGoal} client={props.client} />;
      case 'calories':
        return <CaloriesGoalView goal={goal as CaloriesGoal} client={props.client} />;
      case 'photo':
        return <PhotoGoalView goal={goal as PhotoGoal} client={props.client} />;
      case 'steps':
        return <StepsGoalView goal={goal as StepsGoal} client={props.client} />;
      case 'sleep':
        return <SleepGoalView goal={goal as SleepGoal} client={props.client} />;
      case 'hydration':
        return <HydrationGoalView goal={goal as HydrationGoal} client={props.client} />;
    }
    throw new Error(`Unsupported goal type ${goal.type}`);
  };

  const renderGoalResult = (result: IntervalGoalResult<IntervalGoal>) => {
    switch (result.goal.type) {
      case 'text':
        return <StringGoalResultView result={result as StringGoalResult} client={props.client} />;
      case 'macros':
        return <MacrosGoalResultView result={result as MacrosGoalResult} client={props.client} />;
      case 'single-macro':
        return <SingleMacroGoalResultView result={result as SingleMacroGoalResult} client={props.client} />;
      case 'calories':
        return <CaloriesGoalResultView result={result as CaloriesGoalResult} client={props.client} />;
      case 'photo':
        return <PhotoGoalResultView result={result as PhotoGoalResult} />;
      case 'steps':
        return <StepsGoalResultView result={result as StepsGoalResult} client={props.client} />;
      case 'sleep':
        return <SleepGoalResultView result={result as SleepGoalResult} client={props.client} />;
      case 'hydration':
        return <HydrationGoalResultView result={result as HydrationGoalResult} client={props.client} />;
    }
    throw new Error(`Unsupported goal type ${result.goal.type}`);
  };

  return (
    <>
      <Grid item xs={12} xl={12}>
        <Divider />
        <Grid item xs={12}>
          <SuiBox display={'flex'} sx={{justifyContent: 'space-between', alignItems: 'center'}}>
            <SuiTypography variant="h6" fontWeight="bold" textTransform="capitalize">
              Goal #{props.index + 1}: {props.result.goal.type}
            </SuiTypography>
            <CircularProgressWithLabel
              value={props.result.progress * 100}
              color={new ProgressColor(props.result.progress).color()}
            />
          </SuiBox>
        </Grid>
        <Grid item xs={12} sx={{pl: {xs: 0, lg: 2}}}>
          <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
            {renderGoal(props.result.goal)}
          </SuiTypography>
        </Grid>
        <Grid item xs={12} sx={{pl: {xs: 0, lg: 2}}}>
          <SuiTypography variant="button" fontWeight="regular" color={'text'}>
            {renderGoalResult(props.result)}
          </SuiTypography>
        </Grid>
      </Grid>
    </>
  );
};

export default IntervalGoalResultView;
