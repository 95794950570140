import Grid from '@mui/material/Grid';
import {useEffect, useState} from 'react';
import axios from 'axios';
import {NotificationSettings, NotificationType} from 'pages/notifictions/NotificationTypes';
import DataForm, {DataFormField} from 'components/DataForm/index';
import * as Yup from 'yup';
import {useRecoilValue} from 'recoil';
import {userSettingsState} from 'utils/settings/userSettingsState';

const EditNotificationSettingsTab = () => {
  const userSettings = useRecoilValue(userSettingsState);
  const [notificationTypes, setNotificationTypes] = useState<NotificationType[]>([]);
  const [fields, setFields] = useState<DataFormField[]>([]);
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    axios
      .get('/api/v0/notifications/types')
      .then(response => response.data)
      .then(setNotificationTypes)
      .catch(error => Promise.reject(error));
  }, []);

  useEffect(() => {
    if (notificationTypes.length > 0) {
      updateFields();
      updateInitialValues();
    }
  }, [notificationTypes]);

  const updateFields = () => {
    const newFields: DataFormField[] = [
      {
        type: 'description',
        label: 'Notifications are delivered in app but can also be sent via Email.',
      },
      {
        type: 'switch',
        name: 'emailNotificationsEnabled',
        label: 'Receive Email Notifications',
        columns: 6,
      },
      {
        type: 'divider',
      },
      {
        type: 'description',
        label: 'Select which notification types you want to receive. You can always change these settings later.',
      },
    ];
    notificationTypes
      .filter(t => t.deactivatable)
      .forEach(t => {
        newFields.push({
          type: 'switch',
          name: t.type,
          label: t.description,
          columns: 6,
        });
        setFields(newFields);
      });
  };

  const updateInitialValues = () => {
    userSettings.getSettings('notifications').then((settings: NotificationSettings) => {
      const values = {
        emailNotificationsEnabled: settings.emailNotificationsEnabled,
      };
      notificationTypes.forEach(t => {
        values[t.type] = settings.deactivatedTypes.indexOf(t.type) === -1;
      });
      setInitialValues(values);
    });
  };

  const submitForm = async (values, actions) => {
    actions.setSubmitting(true);
    try {
      const settings = (await userSettings.getSettings('notifications')) as NotificationSettings;
      settings.emailNotificationsEnabled = values.emailNotificationsEnabled;
      settings.deactivatedTypes = determineDeactivatedTypes(values);
      await userSettings.updateSettings(settings);
      setInitialValues(values);
    } catch (error) {
      actions.setStatus(error?.response?.data?.message);
    }
    actions.setSubmitting(false);
  };

  const determineDeactivatedTypes = values => {
    const deactivatedTypes = [];
    for (const [key, value] of Object.entries(values)) {
      if (value === false && key !== 'emailNotificationsEnabled') {
        deactivatedTypes.push(key);
      }
    }
    return deactivatedTypes;
  };

  return (
    <>
      <Grid container item xs={12}>
        <DataForm
          validation={Yup.object().shape({})}
          fields={fields}
          onSubmit={submitForm}
          initialValues={initialValues}
        />
      </Grid>
    </>
  );
};

export default EditNotificationSettingsTab;
