import * as React from 'react';
import SuiTypography from 'components/SuiTypography/index';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import SuiBox from 'components/SuiBox/index';
import {FaMoneyBill, FaStripeS} from 'react-icons/fa';
import {VscDebugDisconnect} from 'react-icons/vsc';
import {useTheme} from '@mui/material';
import {MdPayment} from 'react-icons/md';

const PaymentProviderInfo = () => {
  const {palette} = useTheme();

  const renderTitle = (title: string, icon: React.ReactNode) => {
    return (
      <SuiBox position="relative" display={'flex'} flexDirection={'columns'} sx={{alignItems: 'flex-start'}}>
        <SuiBox>{icon}</SuiBox>
        <SuiTypography variant="button" fontWeight="medium">
          {title}
        </SuiTypography>
      </SuiBox>
    );
  };

  const renderCard = (title: string, icon: React.ReactNode, description: string) => {
    return (
      <Card sx={{p: 3, height: '100%'}}>
        {renderTitle(title, icon)}
        <SuiTypography variant="button" color="text" fontWeight="regular">
          {description}
        </SuiTypography>
      </Card>
    );
  };

  return (
    <>
      <SuiTypography variant="button" color="text" fontWeight="regular">
        {`We're excited to help you accept payments on our platform. Here's some important information you
          need to know:`}
      </SuiTypography>

      <Grid container p={3} spacing={2}>
        <Grid item xs={12} md={6} xl={3}>
          {renderCard(
            'Connecting Your Payment Provider Account',
            <VscDebugDisconnect fontSize={40} style={{paddingRight: 10}} color={palette.primary.main} />,
            'To start accepting payments, you can connect your payment provider account to our platform. ' +
              'This allows you to securely process transactions from your clients. Currently, we only support integration ' +
              'with Stripe as our payment provider.',
          )}
        </Grid>

        <Grid item xs={12} md={6} xl={3}>
          {renderCard(
            'Automatic Client Charges',
            <MdPayment fontSize={35} style={{paddingRight: 10, paddingBottom: 9}} color={'#25377f'} />,
            `Once your payment provider account is connected, our platform takes care of charging your clients the correct
                        amount for the coaching they purchase from you. You don't have to worry about manually handling
                        the payment process or calculating the charges – we handle it all for you!`,
          )}
        </Grid>

        <Grid item xs={12} md={6} xl={3}>
          {renderCard(
            'Tax Responsibilities',
            <FaMoneyBill fontSize={35} style={{paddingRight: 10, paddingBottom: 13}} color={'#85BB65'} />,
            `While our platform ensures accurate client charges, it's important to note that you are responsible for any
          applicable taxes related to your business. This includes any sales tax, VAT, or other taxes specific to your
          location or industry. Please consult with a tax professional to understand your tax obligations and ensure
          compliance.`,
          )}
        </Grid>

        <Grid item xs={12} md={6} xl={3}>
          {renderCard(
            'Support for Stripe',
            <FaStripeS fontSize={26} style={{paddingRight: 10}} color={'#635BFF'} />,
            `At the moment, we only support Stripe as our payment provider. If you don't have a Stripe account, you'll need
          to create one and link it to our platform. Stripe offers a secure and reliable payment processing solution
          trusted by many businesses worldwide.`,
          )}
        </Grid>
      </Grid>
      <SuiTypography variant="button" color="text" fontWeight="regular">
        {`If you have any further questions or need assistance with integrating your payment provider account, please
          don't hesitate to contact our support team. We're here to help!`}
      </SuiTypography>
    </>
  );
};

export default PaymentProviderInfo;
