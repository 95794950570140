import {useEffect, useState} from 'react';
import {useRecoilValue} from 'recoil';
import ChatView from 'components/Chat/ChatView';
import {Client} from 'pages/coaching/clients/client/Client';
import axios from 'axios';
import {twilioControllerState} from 'components/Chat/twilio/TwilioController';
import {authenticatedUserState} from 'state/authentication';
import {Conversation} from '@twilio/conversations';

export type ChatProps = {
  clientId: string;
};

const Chat = (props: ChatProps) => {
  const twilioController = useRecoilValue(twilioControllerState);
  const authenticatedUser = useRecoilValue(authenticatedUserState);

  const [conversation, setConversation] = useState<Conversation>(null);

  const [client, setClient] = useState<Client>();

  useEffect(() => {
    const loadConversation = async () => {
      if (twilioController) {
        setConversation(await twilioController.loadConversation(props.clientId));
      }
    };
    loadConversation();
  }, [twilioController, props.clientId]);

  useEffect(() => {
    const loadClient = async () => {
      const response = await axios.get(`/api/v0/clients/${encodeURI(props.clientId)}`);
      setClient(response.data);
    };
    loadClient();
  }, [props.clientId]);

  return (
    <>
      <ChatView coach={authenticatedUser} client={client} conversation={conversation} />
    </>
  );
};

export default Chat;
