import {StringGoalResult} from 'components/IntervalExecutionCard/IntervalTypes';
import SuiTypography from 'components/SuiTypography/index';
import {UserDetails} from 'pages/coaching/tasks/TaskTypes';

type StringGoalResultViewProps = {
  result: StringGoalResult;
  client: UserDetails;
};

const StringGoalResultView = (props: StringGoalResultViewProps) => {
  return (
    <>
      <SuiTypography variant="button" fontWeight="regular" color={'text'}>
        <b>{props.client.name}:</b> {props.result.content}
      </SuiTypography>
    </>
  );
};

export default StringGoalResultView;
