/**
 =========================================================
 * Soft UI Dashboard PRO React - v3.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import { styled } from "@mui/material/styles";

export default styled('div')(({theme, ownerState}) => {
  const {palette, functions, typography, borders} = theme;
  const {error, success, disabled} = ownerState;

  const {borderRadius} = borders;
  const {size} = typography;
  const {text} = palette;
  const {pxToRem} = functions;
  const {inputColors, grey, white} = palette;

  // styles for the input with error={true}
  const errorStyles = () => ({
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23fd5c70' viewBox='0 0 12 12'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23fd5c70' stroke='none'/%3E%3C/svg%3E\")",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: `right ${pxToRem(12)} top ${pxToRem(12)}`,
    backgroundSize: `${pxToRem(16)} ${pxToRem(16)}`,
    border: `1px solid ${inputColors.error}`,
  });

  // styles for the input with success={true}
  const successStyles = () => ({
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 8'%3E%3Cpath fill='%2366d432' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E\")",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: `right ${pxToRem(12)} top ${pxToRem(12)}`,
    backgroundSize: `${pxToRem(16)} ${pxToRem(16)}`,
    border: `1px solid ${inputColors.success}`,
  });

  return {
    '& .ql-toolbar': {
      backgroundColor: disabled ? `${grey[100]} !important` : white.main,
      borderRadius: `${borderRadius.md} ${borderRadius.md} 0 0`,
    },

    '& .ql-container': {
      backgroundColor: disabled ? `${grey[100]} !important` : white.main,
      borderRadius: `0 0 ${borderRadius.md} ${borderRadius.md}`,
    },

    '& .ql-editor': {
      minHeight: '200px',
      '& p': {
        fontSize: size.md,
        color: text.main,
      },

      '& ul li': {
        color: text.main,
        fontSize: size.md,
      },
    },

    '& .quill': {
      borderRadius: `${borderRadius.md} ${borderRadius.md}`,
      ...(error && errorStyles()),
      ...(success && successStyles()),
    },
  };
});
