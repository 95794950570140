import {useEffect, useState} from 'react';
import axios from 'axios';
import DataForm, {DataFormField} from 'components/DataForm/index';
import * as Yup from 'yup';

type ClienNoteFormProps = {
  clientId: string;
};

type ClientNote = {
  id?: string;
  clientId?: string;
  note?: string;
};

const ClientNoteForm = (props: ClienNoteFormProps) => {
  const [note, setNote] = useState<ClientNote>({});

  const fields: DataFormField[] = [
    {
      name: 'note',
      label: "Coach's Private Notes",
      type: 'editor',
      placeholder: 'eg. whatever you want to save about this client',
      hint: 'This note is private and only visible to you. Your clients will not see this at any time.',
    },
  ];

  const validation = Yup.object().shape({});

  useEffect(() => {
    axios
      .get(`/api/v0/clients/notes?client=${encodeURI(props.clientId)}`)
      .then(response => response.data)
      .then(setNote)
      .catch(error => Promise.reject(error));
  }, [props.clientId]);

  const submitForm = async (values, actions) => {
    try {
      const response = await axios.patch(`/api/v0/clients/notes/${note.id}`, values);
      setNote(response.data);
    } catch (error) {
      actions.setStatus(error?.response?.data?.message);
    }
  };

  return (
    <>
      <DataForm
        validation={validation}
        fields={fields}
        onSubmit={submitForm}
        initialValues={note}
        submitLabel={'Save'}
      />
    </>
  );
};

export default ClientNoteForm;
