import * as React from 'react';
import {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import axios from 'axios';
import {Plan} from 'pages/coaching/plans/PlanTypes';
import EditPlanDetailsTab from 'pages/coaching/plans/edit/EditPlanDetailsTab';
import {SpeedDial, SpeedDialAction, SpeedDialIcon} from '@mui/material';
import {GiCardExchange} from 'react-icons/gi';
import {useNavigate, useParams} from 'react-router-dom';
import {GoSettings} from 'react-icons/go';
import {useSetRecoilState} from 'recoil';
import {breadCrumbSegmentsState} from 'state/breadcrumb';
import EditTemplatesTab from 'pages/coaching/plans/edit/templates/EditTemplatesTab';
import {ImInsertTemplate} from 'react-icons/im';
import {MdPayment} from 'react-icons/md';
import EditPlanPaymentTab from 'pages/coaching/plans/edit/payment/EditPlanPaymentTab';
import {useRemoteConfig} from 'components/Firebase/FirebaseConfigProvider';

type EditPlanFormProps = {
  planId: string;
};

type EditPlanViewParams = {
  category: PlanCategory;
};

type PlanCategory = 'details' | 'templates' | 'payment';

const EditPlanView = (props: EditPlanFormProps) => {
  const {category} = useParams<EditPlanViewParams>();
  const navigate = useNavigate();
  const {feature_payment} = useRemoteConfig();
  const [activeCategory, setActiveCategory] = useState<PlanCategory>(category);
  const [categorySwitchDialOpen, setCategorySwitchDialOpen] = useState(false);
  const handleCategorySwitchDialOpen = () => setCategorySwitchDialOpen(true);
  const handleCategorySwitchDialClose = () => setCategorySwitchDialOpen(false);
  const setPlanSegment = useSetRecoilState(breadCrumbSegmentsState(props.planId));

  const [initialValues, setInitialValues] = useState<Plan>({});

  const handelCategorySwitch = (category: PlanCategory) => {
    handleCategorySwitchDialClose();
    setActiveCategory(category);
    navigate(`/coaching/plans/${props.planId}/edit/${category}`);
  };

  useEffect(() => {
    axios
      .get(`/api/v0/plans/${props.planId}`)
      .then(response => response.data)
      .then((p: Plan) => {
        setPlanSegment(p.name);
        setInitialValues(p);
      })
      .catch(error => Promise.reject(error));
  }, [props.planId]);

  const submitForm = async (values, actions) => {
    try {
      const response = await axios.put(`/api/v0/plans/${props.planId}`, values);
      const plan = response.data as Plan;
      actions.setSubmitting(false);
      setPlanSegment(plan.name);
      setInitialValues(plan);
    } catch (error) {
      actions.setStatus(error?.response?.data?.message);
    }
  };

  const renderForm = () => {
    switch (activeCategory) {
      case 'templates':
        return <EditTemplatesTab plan={initialValues} onSubmit={submitForm} />;
      case 'payment':
        return <EditPlanPaymentTab plan={initialValues} onSubmit={submitForm} />;
      default:
        return <EditPlanDetailsTab plan={initialValues} onSubmit={submitForm} />;
    }
  };

  return (
    <>
      <Card>
        <Grid container>
          <Grid item xs={12}>
            <SpeedDial
              ariaLabel="Switch Context"
              sx={{mt: 1, mr: 2, position: 'absolute', top: 0, right: 0}}
              icon={<SpeedDialIcon icon={<GiCardExchange color={'white'} fontSize={'1.6rem'} />} />}
              FabProps={{color: 'success', size: 'medium'}}
              direction={'left'}
              onClose={handleCategorySwitchDialClose}
              onOpen={handleCategorySwitchDialOpen}
              open={categorySwitchDialOpen}>
              <SpeedDialAction
                key={'Coaching Templates'}
                icon={<ImInsertTemplate fontSize={'medium'} />}
                tooltipTitle={'Coaching Templates'}
                onClick={() => handelCategorySwitch('templates')}
              />
              {feature_payment?.asBoolean() && (
                <SpeedDialAction
                  key={'Payment'}
                  icon={<MdPayment fontSize={20} />}
                  tooltipTitle={'Payment'}
                  onClick={() => handelCategorySwitch('payment')}
                />
              )}
              <SpeedDialAction
                key={'details'}
                icon={<GoSettings fontSize={'large'} />}
                tooltipTitle={'Plan Details'}
                onClick={() => handelCategorySwitch('details')}
              />
            </SpeedDial>
          </Grid>
          <Grid item xs={12} lg={12}>
            {renderForm()}
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default EditPlanView;
