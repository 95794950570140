/**
 =========================================================
 * Soft UI Dashboard PRO React - v3.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

type MiniStatisticsCardProps = {
  bgColor?: 'white' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'dark';
  title: {
    fontWeight: 'light' | 'regular' | 'medium' | 'bold';
    text: string;
  };
  count: string | number;
  percentage: {
    color: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'dark' | 'white';
    text: string | number;
  };
  icon: {
    color: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'dark';
    component: React.ReactNode;
  };
  direction: 'right' | 'left';
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

function MiniStatisticsCard(props: MiniStatisticsCardProps) {
  const getBgColor = () => {
    return props.bgColor ? props.bgColor : 'white';
  };

  const getTitle = () => {
    return props.title
      ? props.title
      : {
          fontWeight: 'medium',
          text: '',
        };
  };

  const getPercentage = () => {
    return props.percentage
      ? props.percentage
      : {
          color: 'success',
          text: '',
        };
  };

  const getDirection = () => {
    return props.direction ? props.direction : 'right';
  };

  return (
    <Card
      onClick={e => {
        e.stopPropagation();
        if (props.onClick) {
          props.onClick(e);
        }
      }}
      sx={{cursor: props.onClick ? 'pointer' : 'default'}}>
      <SuiBox bgColor={getBgColor()} variant="gradient">
        <SuiBox p={2}>
          <Grid container alignItems="center">
            {getDirection() === 'left' ? (
              <Grid item>
                <SuiBox
                  variant="gradient"
                  bgColor={getBgColor() === 'white' ? props.icon.color : 'white'}
                  color={getBgColor() === 'white' ? 'white' : 'dark'}
                  width="3rem"
                  height="3rem"
                  borderRadius="md"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  shadow="md">
                  <Icon fontSize="small" color="inherit">
                    {props.icon.component}
                  </Icon>
                </SuiBox>
              </Grid>
            ) : null}
            <Grid item xs={8}>
              <SuiBox ml={getDirection() === 'left' ? 2 : 0} lineHeight={1}>
                <SuiTypography
                  variant="button"
                  noWrap
                  color={getBgColor() === 'white' ? 'text' : 'white'}
                  opacity={getBgColor() === 'white' ? 1 : 0.7}
                  textTransform="capitalize"
                  fontWeight={getTitle().fontWeight}>
                  {getTitle().text}
                </SuiTypography>
                <SuiTypography variant="h5" fontWeight="bold" color={getBgColor() === 'white' ? 'dark' : 'white'}>
                  {props.count}{' '}
                  <SuiTypography variant="button" color={getPercentage().color} fontWeight="bold">
                    {getPercentage().text}
                  </SuiTypography>
                </SuiTypography>
              </SuiBox>
            </Grid>
            {getDirection() === 'right' ? (
              <Grid item xs={4}>
                <SuiBox
                  variant="gradient"
                  bgColor={getBgColor() === 'white' ? props.icon.color : 'white'}
                  color={getBgColor() === 'white' ? 'white' : 'dark'}
                  width="3rem"
                  height="3rem"
                  marginLeft="auto"
                  borderRadius="md"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  shadow="md">
                  <Icon fontSize="small" color="inherit">
                    {props.icon.component}
                  </Icon>
                </SuiBox>
              </Grid>
            ) : null}
          </Grid>
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

// Setting default values for the props of MiniStatisticsCard
MiniStatisticsCard.defaultProps = {
  bgColor: 'white',
  title: {
    fontWeight: 'medium',
    text: '',
  },
  percentage: {
    color: 'success',
    text: '',
  },
  direction: 'right',
};

// Typechecking props for the MiniStatisticsCard
MiniStatisticsCard.propTypes = {
  bgColor: PropTypes.oneOf(['white', 'primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark']),
  title: PropTypes.shape({
    fontWeight: PropTypes.oneOf(['light', 'regular', 'medium', 'bold']),
    text: PropTypes.string,
  }),
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark', 'white']),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  icon: PropTypes.shape({
    color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark']),
    component: PropTypes.node.isRequired,
  }).isRequired,
  direction: PropTypes.oneOf(['right', 'left']),
};

export default MiniStatisticsCard;
