import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import {Subscription} from 'pages/coaching/clients/client/Client';
import Card from '@mui/material/Card';
import SuiBox from 'components/SuiBox/index';
import SuiTypography from 'components/SuiTypography/index';
import Divider from '@mui/material/Divider';
import moment from 'moment';
import SuiButton from 'components/SuiButton/index';
import {useConfirm} from 'components/ConfirmDialog/index';

type SubscriptionCardProps = {
  clientId: string;
  subscriptionId: string;
};

const SubscriptionCard = (props: SubscriptionCardProps) => {
  const confirm = useConfirm();
  const [subscription, setSubscription] = useState<Subscription>();

  useEffect(() => {
    const load = async () => {
      const response = await axios.get(`/api/v0/subscriptions/${props.subscriptionId}`);
      setSubscription(response.data);
    };
    load();
  }, [props.subscriptionId, props.clientId]);

  const renderStatus = () => {
    if (!subscription?.canceled) {
      return (
        <SuiBox mb={2} lineHeight={1} sx={{display: 'flex'}}>
          <SuiBox ml={2} lineHeight={1}>
            <SuiTypography variant="button" color="text" fontWeight="regular">
              active since {moment(subscription?.created).local().format('MM-DD-YYYY')}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      );
    }
  };
  const renderCancelledBy = () => {
    if (subscription?.canceled) {
      return (
        <SuiBox mb={2} lineHeight={1} sx={{display: 'flex'}}>
          <SuiBox ml={2} lineHeight={1}>
            <SuiTypography variant="button" color="text" fontWeight="regular">
              Cancelled by {subscription.cancellerId === props.clientId ? 'Client' : 'Coach'}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      );
    }
  };

  const renderCancellationEffective = () => {
    if (subscription?.canceled) {
      return (
        <SuiBox mb={2} lineHeight={1} sx={{display: 'flex'}}>
          <SuiBox ml={2} lineHeight={1}>
            <SuiTypography variant="button" color="text" fontWeight="regular">
              Subscription active until {moment(subscription?.cancellationEffectiveAt).local().format('MM-DD-YYYY')}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      );
    }
  };

  const cancelSubscription = async () => {
    try {
      await confirm({
        title: `End Subscription`,
        content: `Are you sure you want to end the subscription for plan '${subscription.plan.name}?'`,
        confirmationText: 'End Subscription',
        confirmationButtonProps: {
          color: 'error',
        },
      });
      await sendCancelSubscription(true);
    } catch (error) {
      // do nothing
    }
  };

  const sendCancelSubscription = async (canceled: boolean) => {
    subscription.canceled = canceled;
    const response = await axios.patch(`/api/v0/subscriptions/${props.subscriptionId}`, subscription);
    const updated = response.data;
    setSubscription(updated);
  };

  const renderCancelButton = () => {
    if (!subscription?.canceled) {
      return (
        <SuiBox mb={2} lineHeight={1} display="flex" sx={{justifyContent: 'flex-end', alignItems: 'flex-end'}}>
          <SuiBox mr={2} lineHeight={1}>
            <SuiTypography variant="button" color="text" fontWeight="regular">
              <SuiButton size="small" color="error" onClick={() => cancelSubscription()}>
                End Subscription
              </SuiButton>
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      );
    }
  };

  const continueSubscription = async () => {
    try {
      await confirm({
        title: `Continue Subscription`,
        content: `You have ended this subscription. Are you sure you want to continue the subscription for plan '${subscription.plan.name}?'`,
        confirmationText: 'Continue Subscription',
        confirmationButtonProps: {
          color: 'primary',
        },
      });
      await sendCancelSubscription(false);
    } catch (error) {
      // do nothing
    }
  };

  const renderContinueSubscriptionButton = () => {
    if (
      subscription?.canceled &&
      subscription?.cancellerId === subscription?.coachId &&
      subscription?.phase !== 'CANCELLED'
    ) {
      return (
        <SuiBox mb={2} lineHeight={1} display="flex" sx={{justifyContent: 'flex-end', alignItems: 'flex-end'}}>
          <SuiBox mr={2} lineHeight={1}>
            <SuiTypography variant="button" color="text" fontWeight="regular">
              <SuiButton size="small" color="primary" onClick={() => continueSubscription()}>
                Continue Subscription
              </SuiButton>
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      );
    }
  };

  return (
    <>
      <Card>
        <Grid container>
          <Grid item xs={12}>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
              <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                Subscription
              </SuiTypography>
            </SuiBox>

            {renderStatus()}
            {renderCancellationEffective()}
            {renderCancelledBy()}

            <SuiBox opacity={0.3}>
              <Divider />
            </SuiBox>
            {renderCancelButton()}
            {renderContinueSubscriptionButton()}
          </Grid>
          <Grid item xs={12} />
        </Grid>
      </Card>
    </>
  );
};

export default SubscriptionCard;
