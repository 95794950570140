import SuiEditor from 'components/SuiEditor/index';
import {FieldProps} from 'formik';

type FormFieldEditorProps = {
  fieldProps: FieldProps;
  placeholder: string;
  value: string;
  readOnly: boolean;
  tabIndex: number;
};

const FormFieldEditor = (props: FormFieldEditorProps) => {
  return (
    <>
      <SuiEditor
        {...props.fieldProps.field}
        key={props.fieldProps.field.name}
        // @ts-ignore
        name={props.fieldProps.field.name}
        onChange={props.fieldProps.field.onChange(props.fieldProps.field.name)}
        onBlur={() => {
          props.fieldProps.form.setFieldTouched(props.fieldProps.field.name, true);
          props.fieldProps.field.onBlur(props.fieldProps.field.name);
        }}
        value={props.value}
        disabled={props.readOnly}
        readOnly={props.readOnly}
        placeholder={props.placeholder}
        inputProps={{tabIndex: props.tabIndex}}
      />
    </>
  );
};

export default FormFieldEditor;
