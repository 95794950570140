import Grid from '@mui/material/Grid';
import {UserDetails} from 'pages/coaching/tasks/TaskTypes';
import SuiTypography from 'components/SuiTypography/index';
import {StepsGoal} from 'pages/coaching/tasks/type-prepare-interval/IntervalGoalTypes';
import {GiWalk} from 'react-icons/gi';
import IntervalGoalCard from 'components/IntervalExecutionCard/goals-results/IntervalGoalCard';
import Hint from 'components/Hint/Hint';

type StepsGoalViewProps = {
  goal: StepsGoal;
  client: UserDetails;
};

const StepsGoalView = (props: StepsGoalViewProps) => {
  return (
    <>
      <SuiTypography variant="button" fontWeight="regular" color={'text'}>
        You defined the following minimum steps for {props.client.name}.
        <Hint
          hint={
            'Green values are exceeding the minimum steps, yellow values are below the minimum, and red values are missing.'
          }
        />
      </SuiTypography>
      <Grid container item xs={12} rowSpacing={3} sx={{pt: 1, pb: 1}}>
        <Grid item xs={12} md={4} pr={{xs: 0, md: 0, lg: 3}}>
          <IntervalGoalCard
            icon={<GiWalk />}
            color="success"
            type="Min Steps"
            value={
              <>
                <span style={{color: '#439946'}}>{props.goal.minSteps}</span>
              </>
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default StepsGoalView;
