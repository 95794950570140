import {Card} from '@mui/material';
import SuiBox from 'components/SuiBox/index';
import SuiTypography from 'components/SuiTypography/index';
import * as React from 'react';
import {GoShield} from 'react-icons/go';

const EncryptionInfoCard = () => {
  return (
    <>
      <Card sx={{height: '100%'}}>
        <SuiBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
          <SuiTypography variant="h6">Data Privacy</SuiTypography>
        </SuiBox>
        <SuiBox position="relative" p={2} display={'flex'} flexDirection={'columns'}>
          <SuiBox>
            <GoShield fontSize={50} style={{paddingRight: 10}} color={'#e9ecef'} />
          </SuiBox>
          <SuiTypography color={'secondary'} variant={'button'} fontWeight={'regular'}>
            {`All your and your client's data is encrypted and stored in a secure database. Only you and your clients have
            access to your data. We do not share your data with any third parties.`}
          </SuiTypography>
        </SuiBox>
      </Card>
    </>
  );
};

export default EncryptionInfoCard;
