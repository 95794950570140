import * as React from 'react';
import {useEffect, useState} from 'react';
import SuiInput from 'components/SuiInput/index';
import Table from 'components/SuiTable';
import {useNavigate} from 'react-router-dom';
import {Subscription} from 'pages/coaching/clients/client/Client';
import SuiBox from 'components/SuiBox/index';
import SuiTypography from 'components/SuiTypography/index';
import {FaUsers} from 'react-icons/fa';
import Grid from '@mui/material/Grid';
import {useRecoilValue} from 'recoil';
import {clientsState, ClientState} from 'state/clients';
import SuiAvatar from 'components/SuiAvatar/index';

type Row = {
  name: (string | React.ReactNode)[];
  subscriptions: string;
  onClick: () => void;
};

type ClientListProps = {
  showPagination: boolean;
  showFilter: boolean;
  maxRows: number;
  sorting: 'name' | 'created';
  showBadge: boolean;
};

const ClientList = (props: ClientListProps) => {
  const navigate = useNavigate();
  const clientsFromState = useRecoilValue(clientsState);
  const [clients, setClients] = useState<ClientState[]>([]);

  const [filter, setFilter] = useState('');

  const [rows, setRows] = useState<Row[]>([]);

  const columns = [
    {name: 'name', align: 'left'},
    {name: 'subscriptions', align: 'left'},
  ];

  useEffect(() => {
    let clients = sortClients();
    if (props.maxRows > 0) {
      clients = clients.slice(0, props.maxRows);
    }
    if (filter !== '') {
      setClients(clients.filter(client => matchesFilter(client.client)));
    } else {
      setClients(clients);
    }
  }, [clientsFromState, filter]);

  useEffect(() => {
    createRows();
  }, [clients]);

  const sortClients = () => {
    switch (props.sorting) {
      case 'name':
        return [...clientsFromState].sort((a, b) => a.client.name.localeCompare(b.client.name));
      case 'created':
        return [...clientsFromState].sort((a, b) => a.client.created.localeCompare(b.client.created));
    }
    return clientsFromState;
  };

  const matchesFilter = client => {
    if (client.name.toLowerCase().includes(filter.toLowerCase())) {
      return true;
    }
    return computeSubscriptionPlanNames(client.subscriptions).toLowerCase().includes(filter.toLowerCase());
  };

  const computeSubscriptionPlanNames = (subscriptions: Subscription[]) => {
    const plansNames = subscriptions.map(subscription => subscription.plan.name);
    return plansNames.join(', ');
  };

  const createRows = () => {
    const rows = clients.map(client => ({
      name: [
        <SuiAvatar
          key={client.client.userId}
          bgColor={'light'}
          src={client.client.picture}
          variant="circular"
          size="sm"
        />,
        client.client.name,
      ],
      subscriptions: computeSubscriptionPlanNames(client.client.subscriptions),
      onClick: () =>
        navigate(`/coaching/clients/${encodeURI(client.client.userId)}/${client.client.subscriptions[0].id}`),
      badgeCount: props.showBadge ? client.unreadMessages : undefined,
    }));
    setRows(rows);
  };

  const renderFilter = () => {
    if (props.showFilter) {
      return (
        <Grid container>
          <Grid item xs={12} sx={{pt: 2, px: 2, mr: 11}}>
            <SuiInput placeholder="Search..." onChange={event => setFilter(event.target.value)} />
          </Grid>
        </Grid>
      );
    }
  };

  const renderPagination = () => {
    return (
      <Grid
        container
        item
        xs={12}
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{p: 3, pb: {xs: 0}}}
      />
    );
  };

  const renderNoClients = () => {
    return (
      <SuiBox display="flex" sx={{justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <FaUsers fontSize={150} color={'#e9ecef'} />
        <SuiTypography variant="h6" fontWeight="medium" sx={{mt: 2}} color={'secondary'}>
          No clients yet!
        </SuiTypography>
      </SuiBox>
    );
  };

  const renderClients = () => {
    return <Table columns={columns} rows={rows} tableStyles={{boxShadow: 'none'}} />;
  };

  const renderContent = () => {
    if (clientsFromState?.length > 0) {
      return renderClients();
    }
    return renderNoClients();
  };

  return (
    <>
      {renderFilter()}
      <Grid container mt={{xs: 3, lg: 3}} mb={1} pb={2}>
        <Grid item xs={12}>
          {renderContent()}
        </Grid>
        {renderPagination()}
      </Grid>
    </>
  );
};

export default ClientList;
