import {IntervalGoal, IntervalGoalType} from 'pages/coaching/tasks/type-prepare-interval/IntervalGoalTypes';
import {StringGoalSupport} from 'pages/coaching/tasks/type-prepare-interval/goals/StringGoalSupport';
import {PhotoGoalSupport} from 'pages/coaching/tasks/type-prepare-interval/goals/PhotoGoalSupport';
import {MacrosGoalSupport} from 'pages/coaching/tasks/type-prepare-interval/goals/MacrosGoalSupport';
import {SleepGoalSupport} from 'pages/coaching/tasks/type-prepare-interval/goals/SleepGoalSupport';
import {BaseSchema} from 'yup';
import {DataFormField} from 'components/DataForm/index';
import {HydrationGoalSupport} from 'pages/coaching/tasks/type-prepare-interval/goals/HydrationGoalSupport';
import {StepsGoalSupport} from 'pages/coaching/tasks/type-prepare-interval/goals/StepsGoalSupport';
import {SingleMacroGoalSupport} from 'pages/coaching/tasks/type-prepare-interval/goals/SingleMacroGoalSupport';
import {CaloriesGoalSupport} from 'pages/coaching/tasks/type-prepare-interval/goals/CaloriesGoalSupport';

export interface IntervalGoalSupport<T extends IntervalGoal> {
  createGoal: () => T;
  createValidationSchema: () => BaseSchema;
  createFields: (goal: IntervalGoal, index: number) => DataFormField[];
  getIcon: () => React.ReactElement;
}

export class IntervalGoalSupportFactory {
  private supports = {
    text: new StringGoalSupport(),
    photo: new PhotoGoalSupport(),
    macros: new MacrosGoalSupport(),
    'single-macro': new SingleMacroGoalSupport(),
    calories: new CaloriesGoalSupport(),
    steps: new StepsGoalSupport(),
    sleep: new SleepGoalSupport(),
    hydration: new HydrationGoalSupport(),
  };

  public supportFor(type: IntervalGoalType) {
    const support = this.supports[type];
    if (!support) {
      throw new Error(`No support for type ${type}`);
    }
    return support;
  }
}
