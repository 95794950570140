import Table from 'components/SuiTable';
import * as React from 'react';
import {useEffect, useState} from 'react';
import axios from 'axios';
import BootPagination, {Page} from 'components/BootPagination';
import Grid from '@mui/material/Grid';
import SuiAvatar from 'components/SuiAvatar/index';
import SearchUsers from 'pages/admin/dashboard/SearchUsers';
import {Card, useTheme} from '@mui/material';
import SuiBox from 'components/SuiBox/index';
import {MdAccessTime, MdSend} from 'react-icons/md';
import {useNavigate} from 'react-router-dom';
import {FaUsers} from 'react-icons/fa';

type User = {
  user: string;
  name: string;
  email: string;
  picture: string;
};

function UserManagement() {
  const {palette} = useTheme();
  const navigate = useNavigate();
  const [page, setPage] = useState<Page<User>>({content: []} as Page<User>);
  const [pageNumber, setPageNumber] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');

  const columns = [
    {name: 'name', align: 'left'},
    {name: 'email', align: 'left'},
    {name: 'id', align: 'left'},
    {name: 'actions', align: 'center'},
  ];

  useEffect(() => {
    const loadUsers = async () => {
      if (searchTerm === '') {
        const response = await axios.get(
          `/api/v0/admin/users?page=${pageNumber}&size=10&sort=auditMetadata.created,DESC`,
        );
        setPage(response.data);
      } else {
        const response = await axios.get(
          `/api/v0/admin/users/search?page=${pageNumber}&size=10&sort=auditMetadata.created,DESC&query=${encodeURI(
            searchTerm,
          )}`,
        );
        setPage(response.data);
      }
    };
    loadUsers();
  }, [pageNumber, searchTerm]);

  const createRows = () =>
    page.content.map(user => ({
      name: [
        <SuiAvatar key={user.user} bgColor={'light'} src={user.picture} variant="circular" size="sm" />,
        user.name,
      ],
      email: user.email,
      id: user.user,
      actions: (
        <SuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <MdAccessTime
            title={'Timezone Trace'}
            size={20}
            color={palette.secondary.main}
            style={{cursor: 'pointer'}}
            onClick={() => navigate(`/admin/overview/users/${user.user}/timezone/trace`)}
          />
          <FaUsers
            title={'Max Client Override'}
            size={22}
            color={palette.secondary.main}
            style={{cursor: 'pointer', marginLeft: '10px'}}
            onClick={() => navigate(`/admin/overview/users/${user.user}/max-clients`)}
          />
          <MdSend
            title={'Send Notification'}
            size={20}
            color={palette.secondary.main}
            style={{cursor: 'pointer', marginLeft: '10px'}}
            onClick={() => navigate(`/admin/overview/users/${user.user}/send-notification`)}
          />
        </SuiBox>
      ),
    }));

  return (
    <Grid container>
      <Card sx={{width: '100%'}}>
        <Grid item xs={12}>
          <SearchUsers onSearch={setSearchTerm} />
        </Grid>
        <Grid item xs={12}>
          <Table columns={columns} rows={createRows()} tableStyles={{boxShadow: 'none'}} />
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center" sx={{p: 3}}>
          <BootPagination page={page} onClick={setPageNumber} />
        </Grid>
      </Card>
    </Grid>
  );
}

export default UserManagement;
