import * as React from 'react';
import {useEffect, useState} from 'react';
import axios from 'axios';
import BootPagination, {Page} from 'components/BootPagination/index';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import SuiTypography from 'components/SuiTypography/index';
import SuiBox from 'components/SuiBox/index';
import {BsClipboardData} from 'react-icons/bs';
import moment from 'moment/moment';
import Table from 'components/SuiTable/index';
import {FaCheckCircle, FaExclamationTriangle, FaStripeS} from 'react-icons/fa';
import {useTheme} from '@mui/material';
import Paywall from 'components/Paywall/Paywall';

export type Payment = {
  id: string;
  planSubscription: string;
  status: 'SUCCESS' | 'FAILED';
  amount: number;
  currency: string;
  periodStart: string;
  periodEnd: string;
  invoiceUrl: string;
  lastModified: string;
};

type PaymentsCardProps = {
  clientId: string;
  subscriptionId: string;
};

const PaymentsCard = (props: PaymentsCardProps) => {
  const [page, setPage] = useState<Page<Payment> | null>(null);
  const [pageNumber, setPageNumber] = useState(0);
  const encodedClientId = encodeURI(props.clientId);

  const {palette} = useTheme();

  const columns = [
    {name: 'date', align: 'left'},
    {name: 'amount', align: 'left'},
    {name: 'status', align: 'left'},
  ];

  useEffect(() => {
    const load = async () => {
      const response = await axios.get(
        `/api/v0/payment/payments?size=15&page=${pageNumber}&client=${encodedClientId}&subscription=${props.subscriptionId}&sort=auditMetadata.lastModified,desc `,
      );
      setPage(response.data);
    };
    load();
  }, [pageNumber, props.subscriptionId, props.clientId]);

  const createRows = () =>
    page.content?.map(payment => ({
      date: [
        <FaStripeS key={payment.id} fontSize={19} style={{paddingRight: 10, paddingTop: 3}} color={'#635BFF'} />,
        moment(payment.lastModified).local().format('MM-DD-YYYY'),
      ],
      amount: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: payment.currency,
        minimumFractionDigits: 0,
      }).format(payment.amount / 100),
      status:
        payment.status === 'SUCCESS' ? (
          <FaCheckCircle
            key={payment.id}
            fontSize={18}
            style={{marginLeft: 20, paddingTop: 4}}
            color={palette.success.main}
          />
        ) : (
          <FaExclamationTriangle
            key={payment.id}
            fontSize={18}
            style={{marginLeft: 20, paddingTop: 4}}
            color={palette.error.main}
          />
        ),
      onClick: () => {
        window.open(payment.invoiceUrl, '_blank', 'noreferrer');
      },
    }));

  const renderPayments = () => {
    return (
      <Grid container mt={{xs: 1, lg: 3}} mb={1}>
        <Grid item xs={12}>
          <Table columns={columns} rows={createRows()} tableStyles={{boxShadow: 'none'}} />
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center" sx={{p: 3}}>
          <BootPagination page={page} onClick={setPageNumber} />
        </Grid>
      </Grid>
    );
  };

  const renderContent = () => {
    if (page?.content.length > 0) {
      return renderPayments();
    }
    if (page) {
      return (
        <SuiBox
          display="flex"
          sx={{justifyContent: 'center', alignItems: 'center', flexDirection: 'column', pt: 3, pb: 3}}>
          <BsClipboardData fontSize={150} color={'#e9ecef'} />
          <SuiTypography variant="h6" fontWeight="medium" sx={{mt: 2}} color={'secondary'}>
            No payments so far
          </SuiTypography>
        </SuiBox>
      );
    }
  };

  return (
    <>
      <Paywall>
        <Card sx={{minHeight: 350}}>
          <Grid container>
            <Grid item xs={12}>
              {renderContent()}
            </Grid>
          </Grid>
        </Card>
      </Paywall>
    </>
  );
};

export default PaymentsCard;
