import React, {useContext, useEffect, useState} from 'react';
import firebaseApp from 'components/Firebase/FirebaseApp';
import {fetchAndActivate, getAll, getRemoteConfig} from 'firebase/remote-config';
import {Value} from '@firebase/remote-config';
import FirebaseDefaultConfig from 'components/Firebase/FirebaseDefaultConfig';

const FirebaseConfigContext = React.createContext<{[key: string]: Value}>({});
const remoteConfig = getRemoteConfig(firebaseApp);
remoteConfig.defaultConfig = FirebaseDefaultConfig;

// For development only
// remoteConfig.settings.minimumFetchIntervalMillis = 60000; // 1 minute

const FirebaseConfigProvider = (props: React.PropsWithChildren<{}>) => {
  const [config, setConfig] = useState<Record<string, Value>>({});

  useEffect(() => {
    const load = async () => {
      await fetchAndActivate(remoteConfig);
      const config = getAll(remoteConfig);
      setConfig(() => {
        return {...config};
      });
    };
    load();
  }, []);

  return <FirebaseConfigContext.Provider value={config}>{props.children}</FirebaseConfigContext.Provider>;
};

export default FirebaseConfigProvider;

export const useRemoteConfig = () => {
  return useContext(FirebaseConfigContext);
};
