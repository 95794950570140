import Grid from '@mui/material/Grid';
import {UserDetails} from 'pages/coaching/tasks/TaskTypes';
import SuiTypography from 'components/SuiTypography/index';
import {Hydration, HydrationGoal} from 'pages/coaching/tasks/type-prepare-interval/IntervalGoalTypes';
import {GiWaterBottle} from 'react-icons/gi';
import IntervalGoalCard from 'components/IntervalExecutionCard/goals-results/IntervalGoalCard';
import Hint from 'components/Hint/Hint';

type HydrationGoalViewProps = {
  goal: HydrationGoal;
  client: UserDetails;
};

const HydrationGoalView = (props: HydrationGoalViewProps) => {
  const unit = (hydration: Hydration) => {
    if (hydration.unit === 'MILLILITER') {
      return 'ml';
    }
    return 'oz';
  };

  return (
    <>
      <SuiTypography variant="button" fontWeight="regular" color={'text'}>
        You defined the following minimum water intake for {props.client.name}.
        <Hint
          hint={
            'Green values are exceeding the minimum intake, yellow values are below the minimum, and red values are missing.'
          }
        />
      </SuiTypography>
      <Grid container item xs={12} rowSpacing={3} sx={{pt: 1, pb: 1}}>
        <Grid item xs={12} md={4} pr={{xs: 0, md: 0, lg: 3}}>
          <IntervalGoalCard
            icon={<GiWaterBottle />}
            color="info"
            type="Min Intake"
            value={
              <>
                <span style={{color: '#439946'}}>
                  {props.goal.minHydration.value}
                  {unit(props.goal.minHydration)}
                </span>
              </>
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default HydrationGoalView;
