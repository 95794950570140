import DashboardLayout from 'layout/DashboardLayout';
import DashboardNavbar from 'layout/navbar/DashboardNavbar';
import Footer from 'layout/footer/Footer';
import Grid from '@mui/material/Grid';
import TaskList from 'pages/coaching/tasks/TaskList';

const TasksPage = () => {
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <Grid container>
          <Grid item xs={12} py={3} className={'imc-tour-task-list'}>
            <TaskList identifier={'all-tasks'} />
          </Grid>
        </Grid>
        <Footer />
      </DashboardLayout>
    </>
  );
};

export default TasksPage;
