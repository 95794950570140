import {Card} from '@mui/material';
import IntervalExecutionCalendar from 'components/Calendar/IntervalExecutionCalendar';
import {useNavigate} from 'react-router-dom';

const CoachDashboardCalendar = () => {
  const navigate = useNavigate();

  return (
    <>
      <Card sx={{width: '100%'}}>
        <IntervalExecutionCalendar
          mode={'week'}
          title={'Upcoming Check-ins'}
          onEventClick={(date, clientId, subscription) => {
            navigate(`/coaching/clients/${encodeURI(clientId)}/${subscription}/checkins`);
          }}
        />
      </Card>
    </>
  );
};

export default CoachDashboardCalendar;
