import {IntervalExecution} from 'components/IntervalExecutionCard/IntervalTypes';
import SuiBox from 'components/SuiBox/index';
import CircularProgressWithLabel from 'components/IntervalExecutionCard/CircularProgressWithLabel';
import SuiTypography from 'components/SuiTypography/index';
import ProgressColor from 'components/IntervalExecutionCard/ProgressColor';
import {Grid} from '@mui/material';

type IntervalExecutionProgressChartProps = {
  execution: IntervalExecution;
};

const IntervalExecutionProgressChart = (props: IntervalExecutionProgressChartProps) => {
  const renderGoalProgress = (result, index: number) => {
    return (
      <Grid item key={index} xs={12} xl={2} sx={{pt: {xs: 2}}}>
        <SuiBox key={index} display={'flex'} sx={{flexGrow: 1, justifyContent: 'center', width: '100%'}}>
          <SuiBox
            key={index}
            display={'flex'}
            sx={{
              flexDirection: 'column',
              alignItems: 'center',
              flexGrow: 1,
              justifyContent: 'center',
            }}>
            <SuiBox display={'flex'} sx={{justifyContent: 'center'}}>
              <CircularProgressWithLabel
                value={result.progress * 100}
                size={80}
                thickness={5}
                fontSize={'1.45rem'}
                fontWeight={'bold'}
                color={new ProgressColor(result.progress).color()}
              />
            </SuiBox>
            <SuiBox display={'flex'} sx={{justifyContent: 'center'}}>
              <SuiTypography
                variant="button"
                fontWeight="regular"
                color={'text'}
                textTransform={'capitalize'}
                sx={{flexGrow: 1}}>
                <b>Goal #{index + 1}:</b> {result.goal.type}
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        </SuiBox>
      </Grid>
    );
  };

  return (
    <>
      <SuiBox display={'flex'} sx={{justifyContent: 'center'}}>
        <CircularProgressWithLabel
          value={props.execution ? props.execution.progress.progress * 100 : 0}
          size={120}
          thickness={5}
          fontSize={'1.7rem'}
          fontWeight={'bold'}
          color={props.execution ? new ProgressColor(props.execution.progress.progress).color() : 'secondary'}
        />
      </SuiBox>
      <SuiBox display={'flex'} sx={{justifyContent: 'center', pb: 2}}>
        <SuiTypography variant="button" fontWeight="regular" color={'text'}>
          <b>{props.execution ? props.execution.progress.completedGoalsCount : 0}</b> out of{' '}
          <b>{props.execution ? props.execution.progress.goalCount : 0}</b> goals completed
        </SuiTypography>
      </SuiBox>
      <SuiBox display={'flex'} sx={{pt: 3, justifyContent: 'space-evenly'}}>
        <Grid container justifyContent="space-between">
          {props.execution && props.execution.goalResults.map((result, index) => renderGoalProgress(result, index))}
        </Grid>
      </SuiBox>
    </>
  );
};

export default IntervalExecutionProgressChart;
