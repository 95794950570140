import SuiBox from 'components/SuiBox/index';
import SuiTypography from 'components/SuiTypography/index';

type AverageNumberViewProps = {
  average: number;
};

const AverageNumberView = (props: AverageNumberViewProps) => {
  return (
    <>
      <SuiBox display={'flex'} sx={{justifyContent: 'center'}}>
        <SuiBox display={'flex'} sx={{justifyContent: 'center', alignItems: 'center'}}>
          <SuiTypography variant="h2" color={'info'} fontWeight={'bold'}>
            {props.average}
          </SuiTypography>
          <SuiTypography
            color={'secondary'}
            textTransform={'lowercase'}
            variant="button"
            fontWeight="bold"
            sx={{pt: 2, pl: 0.5}}>
            avg
          </SuiTypography>
        </SuiBox>
      </SuiBox>
    </>
  );
};

export default AverageNumberView;
