import {CaloriesGoal, IntervalGoal} from 'pages/coaching/tasks/type-prepare-interval/IntervalGoalTypes';
import * as Yup from 'yup';
import {BaseSchema} from 'yup';
import {DataFormField} from 'components/DataForm/index';
import {IntervalGoalSupport} from 'pages/coaching/tasks/type-prepare-interval/IntervalGoalSupportFactory';
import ObjectID from 'bson-objectid';
import {ImFire} from 'react-icons/im';

export class CaloriesGoalSupport implements IntervalGoalSupport<CaloriesGoal> {
  createFields(goal: IntervalGoal, index: number): DataFormField[] {
    return [
      {
        name: `goals[${index}].minCalories`,
        label: 'Min Calories',
        type: 'number',
        placeholder: '',
        icon: <ImFire />,
        hint: 'Daily minimum calories',
        data: goal.id,
        columns: 3,
      },
      {
        name: `goals[${index}].maxCalories`,
        label: 'Max Calories',
        type: 'number',
        placeholder: '',
        icon: <ImFire />,
        hint: 'Daily maximum calories',
        data: goal.id,
        columns: 3,
      },
    ];
  }

  createGoal(): CaloriesGoal {
    return {
      id: ObjectID().toHexString(),
      type: 'calories',
      minCalories: 0,
      maxCalories: 0,
    };
  }

  createValidationSchema(): BaseSchema {
    return Yup.object().shape({
      minCalories: Yup.number()
        .positive('Min calories must be a positive number.')
        .required('Min calories is required'),
      maxCalories: Yup.number().when('minCalories', min => {
        return Yup.number()
          .positive('Max calories must be a positive number.')
          .moreThan(min, 'Max calories must be greater than min calories.')
          .required('Max calories is required');
      }),
    });
  }

  getIcon(): React.ReactElement {
    return <ImFire fontSize={'large'} />;
  }
}
