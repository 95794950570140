import {PropsWithChildren, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import SuiBox from 'components/SuiBox/index';
import {setLayout, useSoftUIController} from 'context/index';

const DashboardLayout = ({children}: PropsWithChildren) => {
  const [controller, dispatch] = useSoftUIController();
  const {miniSidenav} = controller;
  const {pathname} = useLocation();

  useEffect(() => {
    setLayout(dispatch, 'dashboard');
  }, [pathname]);

  return (
    <SuiBox
      sx={({breakpoints, transitions, functions: {pxToRem}}) => ({
        p: 3,
        pt: 0,
        position: 'relative',

        [breakpoints.up('xxl')]: {
          pt: 3,
          marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
          transition: transitions.create(['margin-left', 'margin-right'], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}>
      {children}
    </SuiBox>
  );
};

export default DashboardLayout;
