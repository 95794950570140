import {AppBar, Card, Grid, Link, Tab, Tabs} from '@mui/material';
import DashboardLayout from 'layout/DashboardLayout';
import DashboardNavbar from 'layout/navbar/DashboardNavbar';
import Footer from 'layout/footer/Footer';
import {useEffect, useState} from 'react';
import SuiTypography from 'components/SuiTypography';
import PricingCards from './PricingCards';
import SuiBox from 'components/SuiBox';
import {
  CoachSubscriptionCadence,
  coachSubscriptionState,
  loadCoachSubscription,
} from 'components/Paywall/coachSubscription';
import {useRecoilState, useRecoilValue} from 'recoil';
import {FaStripe} from 'react-icons/fa';
import * as React from 'react';
import {useNavigate} from 'react-router-dom';
import {clientStatisticsState} from 'components/ClientCard/clientStatistics';

const SubscriptionsPage = () => {
  const navigate = useNavigate();
  const [subscription, setSubscription] = useRecoilState(coachSubscriptionState);
  const clientStatistics = useRecoilValue(clientStatisticsState);

  const [tabValue, setTabValue] = useState<CoachSubscriptionCadence>('MONTHLY');

  useEffect(() => {
    const load = async () => {
      const coachSubscription = await loadCoachSubscription();
      setSubscription(() => coachSubscription);
    };
    load();
  }, []);

  useEffect(() => {
    if (subscription?.cadence) {
      setTabValue(subscription.cadence);
    }
  }, [subscription]);

  const handleSetTabValue = (event: any, newValue) => {
    setTabValue(newValue);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container item xs={12} rowSpacing={3} pr={{xl: 3}} py={3}>
        <Grid item xs={12} container>
          <Card sx={{width: '100%'}}>
            <Grid item xs={12} p={3}>
              <Grid container>
                <Grid item xs={12}>
                  <SuiBox display="flex" justifyContent="space-between" alignItems="center" px={2} sx={{width: '100%'}}>
                    <SuiTypography variant="h6">Subscriptions</SuiTypography>
                  </SuiBox>
                  <SuiBox position="relative" px={2}>
                    <SuiTypography variant="button" color="text" fontWeight="regular">
                      To get the most out of your coaching experience, we recommend you subscribe to a plan. If you have
                      more then 50 clients please reach out to us at&nbsp;
                      <Link href={'mailto:info@inmycorner.app'} color={'primary'}>
                        info@inmycorner.app
                      </Link>
                      .
                    </SuiTypography>

                    {subscription?.stripeCustomerId && (
                      <Grid item xs={12}>
                        <SuiBox display="flex" justifyContent="flex-start" alignItems="center" sx={{width: '100%'}}>
                          <SuiTypography variant="button" color="text" fontWeight="regular">
                            You can manage your invoices and payment methods directly on
                          </SuiTypography>
                          <FaStripe
                            fontSize={50}
                            color={'#635BFF'}
                            style={{marginLeft: 7, cursor: 'pointer'}}
                            onClick={() => {
                              navigate('/me/subscriptions/billing-portal');
                            }}
                          />
                        </SuiBox>
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <SuiTypography variant="button" color="text" fontWeight="regular">
                        Right now you have <b>{clientStatistics.allClients}</b> client
                        {clientStatistics.allClients > 1 ? 's' : ''}. You can upgrade or downgrade your plan at any
                        time.
                      </SuiTypography>
                    </Grid>
                  </SuiBox>
                </Grid>
                <Grid item xs={12}>
                  <Grid container item xs={12} sm={10} md={8} lg={7} sx={{mx: 'auto'}}>
                    <SuiBox width="100%" mt={3}>
                      <AppBar position="static">
                        <Tabs value={tabValue} onChange={handleSetTabValue} sx={{background: 'transparent'}}>
                          <Tab
                            id="monthly"
                            value="MONTHLY"
                            label={
                              <SuiBox py={0.5} px={2}>
                                Monthly
                              </SuiBox>
                            }
                          />
                          <Tab
                            id="yearly"
                            value="YEARLY"
                            label={
                              <SuiBox py={0.5} px={2}>
                                Yearly
                              </SuiBox>
                            }
                          />
                        </Tabs>
                      </AppBar>
                    </SuiBox>
                  </Grid>
                  <PricingCards cadence={tabValue} />
                </Grid>
                <Grid item xs={12} ml={1}>
                  <SuiTypography variant="button" color="text" fontWeight="regular">
                    * Prices and feature sets are subject to change
                  </SuiTypography>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>

      <Footer />
    </DashboardLayout>
  );
};

export default SubscriptionsPage;
