/**
 =========================================================
 * Soft UI Dashboard PRO React - v3.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {ReactNode, useMemo} from 'react';

// uuid is a library for generating unique id
import {v4 as uuidv4} from 'uuid';

// @mui material components
import {styled, Table as MuiTable} from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiAvatar from 'components/SuiAvatar';
import SuiTypography from 'components/SuiTypography';

// Soft UI Dashboard PRO React base styles
import colors from 'assets/theme/base/colors';
import typography from 'assets/theme/base/typography';
import borders from 'assets/theme/base/borders';
import Badge from '@mui/material/Badge';

export type TableColumn = {
  name: string;
  align: string;
  width?: string;
};

export type TableRow = {
  hasBorder?: boolean;
  onClick?: (e: any) => void;
  badgeCount?: number;
  [key: string]: any;
};

export type TableProps = {
  columns: TableColumn[];
  rows: TableRow[];
  tableStyles?: {};
  hideColumns?: boolean;
};

function Table(props: TableProps) {
  const {light} = colors;
  const {size, fontWeightBold} = typography;
  const {borderWidth} = borders;

  const renderColumns = props.columns.map(({name, align, width}, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === props.columns.length - 1) {
      pl = 3;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <SuiBox
        key={name}
        component="th"
        width={width || 'auto'}
        pb={1.25}
        pl={align === 'left' ? pl : 3}
        pr={align === 'right' ? pr : 3}
        textAlign={align}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        color="secondary"
        opacity={0.7}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}>
        {name.toUpperCase()}
      </SuiBox>
    );
  });

  const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(even)': {
      backgroundColor: '#fafafa',
    },
  }));

  const renderRows = props.rows?.map((row, key) => {
    const rowKey = `row-${key}`;

    const renderIcon = (icon: string | ReactNode, name: string) => {
      if (typeof icon === 'string') {
        return <SuiAvatar src={icon} name={name} variant="rounded" size="sm" />;
      }
      return icon;
    };

    const tableRow = props.columns.map(({name, align}) => {
      let template;

      if (Array.isArray(row[name])) {
        template = (
          <SuiBox
            key={uuidv4()}
            component="td"
            p={1}
            borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}>
            <SuiBox display="flex" alignItems="center" py={0.5} px={1}>
              <SuiBox mr={2} display="felx" sx={{flexDirection: 'row'}}>
                {renderIcon(row[name][0], row[name][1])}
              </SuiBox>
              <Badge
                badgeContent={row.badgeCount}
                color="error"
                overlap="circular"
                sx={{
                  ml: -2,
                  mb: 3,
                  '& .MuiBadge-badge': {fontSize: 11, height: 16, minWidth: 15, color: '#ffffff'},
                }}
              />
              <SuiTypography variant="button" fontWeight="medium" sx={{width: 'max-content', ml: 1}}>
                {row[name][1]}
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        );
      } else if (typeof row[name] === 'string') {
        template = (
          <SuiBox
            key={uuidv4()}
            component="td"
            p={1}
            textAlign={align}
            borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}>
            <SuiTypography
              variant="button"
              fontWeight="regular"
              color="secondary"
              sx={{
                minWidth: '10rem',
                display: 'inline-block',
                wordWrap: 'break-word',
                whiteSpace: 'normal',
              }}>
              {row[name]}
            </SuiTypography>
          </SuiBox>
        );
      } else {
        template = (
          <SuiBox
            key={uuidv4()}
            component="td"
            p={1}
            textAlign={align}
            borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}>
            {row[name]}
          </SuiBox>
        );
      }

      return template;
    });

    const getTableRowStyle = () => {
      if (row.onClick !== undefined) {
        return {cursor: 'pointer'};
      }
      return {};
    };

    return (
      <StyledTableRow
        key={rowKey}
        onClick={e => {
          e.stopPropagation();
          if (row.onClick !== undefined) {
            row.onClick(e);
          }
        }}
        sx={getTableRowStyle()}>
        {tableRow}
      </StyledTableRow>
    );
  });

  return useMemo(
    () => (
      <TableContainer sx={props.tableStyles}>
        <MuiTable>
          <SuiBox component="thead" sx={{display: props.hideColumns ? 'none' : 'table-header-group'}}>
            <TableRow>{renderColumns}</TableRow>
          </SuiBox>
          <TableBody>{renderRows}</TableBody>
        </MuiTable>
      </TableContainer>
    ),
    [props.rows],
  );
}

export default Table;
