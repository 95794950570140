import {useState} from 'react'; // @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab'; // Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiAvatar from 'components/SuiAvatar'; // Soft UI Dashboard PRO React example components
import SuiBadgeDot from 'components/SuiBadgeDot';
import ProgressionChart from 'pages/coaching/clients/client/chart/ProgressionChart';
import {Client} from 'pages/coaching/clients/client/Client';
import SuiSelect from 'components/SuiSelect/index';
import {ClientSection} from 'pages/coaching/clients/client/ClientPage';
import TabOrientation from 'components/TabOrientation/TabOrientation';
import {Orientation} from '@mui/material';
import DashboardNavbar from 'layout/navbar/DashboardNavbar';

type PlanOption = {
  label: string;
  value: string;
};

type ClientHeaderProps = {
  client: Client;
  subscriptionId: string;
  onSubscriptionIdChange: (subscriptionId: string) => void;
  planOptions: PlanOption[];
  selectedTab: string;
  onTabChange: (tab: string) => void;
  sections: ClientSection[];
};

const ClientHeader = (props: ClientHeaderProps) => {
  const [tabsOrientation, setTabsOrientation] = useState<Orientation>('horizontal');

  const handleSetTabValue = (event, newValue) => props.onTabChange(newValue);

  const renderPlans = () => {
    if (props.planOptions.length === 1) {
      return (
        <SuiTypography variant="subtitle2" fontWeight="regular" pl={2} pb={0.5}>
          {props.planOptions[0].label}
        </SuiTypography>
      );
    }
    return (
      <SuiSelect
        placeholder="Subscription"
        value={props.planOptions.find(option => option.value === props.subscriptionId)}
        options={props.planOptions}
        onChange={e => props.onSubscriptionIdChange(e.value)}
        size="small"
      />
    );
  };

  return (
    <>
      <TabOrientation changeOrientation={setTabsOrientation} />
      <SuiBox position="relative">
        <DashboardNavbar absolute />
        <SuiBox display="flex" alignItems="center" position="relative" minHeight="14.75rem" borderRadius="xl">
          <SuiBox sx={{width: '100%', backgroundColor: '#ffffff', pt: 6}} borderRadius="xl">
            <ProgressionChart
              clientId={props.client.userId}
              days={25}
              title=""
              height="10.125rem"
              description={
                <SuiBox display="flex" ml={-1}>
                  <SuiBadgeDot color="primary" size="sm" badgeContent="Calories" />
                  <SuiBadgeDot color="secondary" size="sm" badgeContent="Weight" />
                </SuiBox>
              }
            />
          </SuiBox>
        </SuiBox>
        <Card
          sx={{
            backdropFilter: `saturate(200%) blur(30px)`,
            backgroundColor: 'rgba(250, 250, 250, 0.7)',
            //@ts-ignore
            boxShadow: ({boxShadows: {navbarBoxShadow}}) => navbarBoxShadow,
            position: 'relative',
            mt: -4,
            mx: 1,
            py: 2,
            px: 2,
          }}>
          <Grid container spacing={3} alignItems="center">
            <Grid container item xs={12} xl={4}>
              <Grid item xs={'auto'}>
                <SuiAvatar
                  src={props.client.picture ? encodeURI(props.client.picture) : null}
                  bgColor={'light'}
                  alt="profile-image"
                  variant="rounded"
                  size="xl"
                  shadow="sm"
                />
              </Grid>
              <Grid item xs>
                <SuiBox height="100%" lineHeight={1} sx={{ml: 3}}>
                  <SuiTypography variant="h5" fontWeight="medium" mb={0.5}>
                    {props.client.name}
                  </SuiTypography>
                  {renderPlans()}
                </SuiBox>
              </Grid>
            </Grid>
            <Grid item xs={12} xl={7} sx={{ml: 'auto', mt: {xs: 0, xl: 4}}}>
              <AppBar position="static">
                <Tabs
                  orientation={tabsOrientation}
                  value={props.selectedTab}
                  onChange={handleSetTabValue}
                  sx={{background: 'transparent'}}>
                  {props.sections
                    .filter(section => section.visible)
                    .map((section, index) => (
                      <Tab key={index} label={section.label} value={section.id} icon={section.icon} />
                    ))}
                </Tabs>
              </AppBar>
            </Grid>
          </Grid>
        </Card>
      </SuiBox>
    </>
  );
};

export default ClientHeader;
