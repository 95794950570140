import {IntervalGoal, PhotoGoal} from 'pages/coaching/tasks/type-prepare-interval/IntervalGoalTypes';
import * as Yup from 'yup';
import {BaseSchema} from 'yup';
import {DataFormField} from 'components/DataForm/index';
import {IntervalGoalSupport} from 'pages/coaching/tasks/type-prepare-interval/IntervalGoalSupportFactory';
import {LinkedCameraOutlined} from '@mui/icons-material';
import ObjectID from 'bson-objectid';

export class PhotoGoalSupport implements IntervalGoalSupport<PhotoGoal> {
  createFields(goal: IntervalGoal, index: number): DataFormField[] {
    return [
      {
        name: `goals[${index}].requireFront`,
        label: 'Front',
        type: 'switch',
        placeholder: '',
        data: goal.id,
        columns: 3,
        tabIndex: 1,
      },
      {
        name: `goals[${index}].requireBack`,
        label: 'Back',
        type: 'switch',
        placeholder: '',
        data: goal.id,
        columns: 3,
        tabIndex: 2,
      },
      {
        name: `goals[${index}].requireLeftSide`,
        label: 'Left Side',
        type: 'switch',
        placeholder: '',
        data: goal.id,
        columns: 3,
        tabIndex: 3,
      },
      {
        name: `goals[${index}].requireRightSide`,
        label: 'Right Side',
        type: 'switch',
        placeholder: '',
        data: goal.id,
        columns: 3,
        tabIndex: 4,
      },
    ];
  }

  createGoal(): PhotoGoal {
    return {
      type: 'photo',
      id: ObjectID().toHexString(),
      requireBack: false,
      requireFront: false,
      requireLeftSide: false,
      requireRightSide: false,
    };
  }

  createValidationSchema(): BaseSchema {
    return Yup.object().shape({});
  }

  getIcon(): React.ReactElement {
    return <LinkedCameraOutlined fontSize={'medium'} />;
  }
}
