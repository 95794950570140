import * as React from 'react';
import {useEffect, useState} from 'react';
import axios from 'axios';
import imageSrc, {UserFile} from 'utils/UserImage';
import {Buffer} from 'buffer';
import {CircularProgress} from '@mui/material';
import SuiBox from 'components/SuiBox/index';

type ProtectedImageProps = {
  file: UserFile;
  imageStyle?: React.CSSProperties;
};

type Image = {
  src: string;
  alt: string;
};

const ProtectImage = (props: ProtectedImageProps) => {
  const [image, setImage] = useState<Image>({src: '', alt: ''});

  useEffect(() => {
    if (props.file) {
      axios
        .get(imageSrc(props.file), {
          responseType: 'arraybuffer',
          headers: {
            accept: props.file.contentType,
          },
        })
        .then(function (response) {
          const data = `data:${response.headers['content-type']};base64,${Buffer.from(response.data, 'binary').toString(
            'base64',
          )}`;
          setImage({src: data, alt: props.file.name});
        });
    }
  }, [props.file]);

  const renderImage = () => {
    if (image) {
      return <img src={image.src} alt={image.alt} style={props.imageStyle} />;
    }
    return (
      <SuiBox sx={{height: '300px'}} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress color="primary" />;
      </SuiBox>
    );
  };

  return <>{renderImage()}</>;
};

export default ProtectImage;
