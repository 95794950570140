// prop-type is a library for typechecking of props
// formik components
import { ErrorMessage, Field, FieldProps, useFormikContext } from "formik"; // Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import React, { useEffect } from "react";
import Hint from "components/Hint/Hint";
import FormFieldEditor from "components/DataForm/FormFieldEditor";
import FormFieldInput from "components/DataForm/FormFieldInput";
import FormFieldSelect from "components/DataForm/FormFieldSelect";
import FormFieldCheckbox from "components/DataForm/FormFieldCheckbox";
import FormFieldHidden from "components/DataForm/FormFieldHidden";
import { DataFormField } from "components/DataForm/index";
import FormFieldSwitch from "components/DataForm/FormFieldSwitch";

type FormFieldProps = {
  field: DataFormField;
  value: string;
  error: boolean;
  readOnly: boolean;
};

const FormField = (props: FormFieldProps) => {
  const formikContext = useFormikContext();

  useEffect(() => {
    if (props.field.type === 'hidden') {
      formikContext.setFieldValue(props.field.name, props.value);
    }
  }, []);

  const renderField = (fieldProps: FieldProps) => {
    switch (props.field.type) {
      case 'editor':
        return (
          <FormFieldEditor
            fieldProps={fieldProps}
            placeholder={props.field.placeholder}
            value={props.value}
            readOnly={props.readOnly}
            tabIndex={props.field.tabIndex}
          />
        );
      case 'select':
        return (
          <FormFieldSelect
            fieldProps={fieldProps}
            formikContext={formikContext}
            value={props.value}
            options={props.field.options}
            placeholder={props.field.placeholder}
            readOnly={props.readOnly}
            tabIndex={props.field.tabIndex}
          />
        );
      default:
        return (
          <FormFieldInput
            fieldProps={fieldProps}
            icon={props.field.icon}
            placeholder={props.field.placeholder}
            value={props.value}
            multiline={props.field.rows && props.field.rows > 1}
            readOnly={props.readOnly}
            type={props.field.type}
            tabIndex={props.field.tabIndex}
          />
        );
    }
  };
  const renderHint = () => {
    if (props.field.hint) {
      return <Hint hint={props.field.hint} />;
    }
  };

  const renderCheckboxField = () => {
    return (
      <FormFieldCheckbox
        name={props.field.name}
        label={props.field.label}
        formikContext={formikContext}
        value={props.value}
        readOnly={props.readOnly}
      />
    );
  };

  const renderSwitchField = () => {
    return (
      <FormFieldSwitch
        name={props.field.name}
        label={props.field.label}
        formikContext={formikContext}
        value={props.value}
        readOnly={props.readOnly}
      />
    );
  };

  const renderHiddenField = () => {
    return <FormFieldHidden name={props.field.name} value={props.value} />;
  };

  const renderInputField = () => {
    return (
      <SuiBox mb={1.5}>
        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SuiTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
            {props.field.label} {renderHint()}
          </SuiTypography>
          <SuiBox mt={0.75}>
            <SuiTypography component="div" variant="caption" color="error">
              <ErrorMessage name={props.field.name} />
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        <SuiBox sx={{display: 'flex'}}>
          <SuiBox sx={{flexGrow: 1}}>
            <Field name={props.field.name}>{(fieldProps: FieldProps) => renderField(fieldProps)}</Field>
          </SuiBox>
          {props.field.rightComponent ? props.field.rightComponent(formikContext) : null}
        </SuiBox>
      </SuiBox>
    );
  };

  const renderContent = () => {
    if (props.field.type === 'hidden') {
      return renderHiddenField();
    } else if (props.field.type === 'checkbox') {
      return renderCheckboxField();
    } else if (props.field.type === 'switch') {
      return renderSwitchField();
    }
    return renderInputField();
  };

  return renderContent();
};

export default FormField;
