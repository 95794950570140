import React, {useState} from 'react';
import IntervalExecutionCalendar from 'components/Calendar/IntervalExecutionCalendar';
import {DialogTitle} from '@mui/material';
import {IoCalendarOutline} from 'react-icons/io5';
import SuiBox from 'components/SuiBox/index';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import {MdClose} from 'react-icons/md';

type EndExecutionSelectorProps = {
  onDateClick?: (date: Date) => void;
};

const EndExecutionSelector = (props: EndExecutionSelectorProps) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Dialog
        open={show}
        maxWidth={false}
        onClose={() => setShow(false)}
        sx={{
          height: '100%',
          borderRadius: '15px',
        }}>
        <DialogTitle display={'flex'} justifyContent={'flex-end'}>
          <IconButton onClick={() => setShow(false)} sx={{zIndex: 9999}}>
            <MdClose />
          </IconButton>
        </DialogTitle>
        <SuiBox sx={{mt: -8}}>
          <Grid container>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <IntervalExecutionCalendar
                  mode={'month'}
                  onDateClick={date => {
                    setShow(false);
                    props.onDateClick(date);
                  }}
                  onEventClick={date => {
                    setShow(false);
                    props.onDateClick(date);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </SuiBox>
      </Dialog>
      <IoCalendarOutline
        onClick={() => {
          setShow(true);
        }}
        style={{marginTop: 10, marginLeft: 10, cursor: 'pointer'}}
      />
    </>
  );
};

export default EndExecutionSelector;
