import * as React from 'react';
import {PropsWithChildren, useEffect, useMemo} from 'react';
import {selector, useRecoilValue, useSetRecoilState} from 'recoil';
import {coachSubscriptionState} from 'components/Paywall/coachSubscription';
import {clientStatisticsState, loadClientStatistics} from 'components/ClientCard/clientStatistics';
import {Backdrop} from '@mui/material';
import {GiTeamUpgrade} from 'react-icons/gi';
import SuiTypography from 'components/SuiTypography/index';
import SuiBox from 'components/SuiBox/index';
import SuiButton from 'components/SuiButton/index';
import {Link} from 'react-router-dom';
import {useRemoteConfig} from 'components/Firebase/FirebaseConfigProvider';
import {subscribe} from 'components/Firebase/FirebaseMessaging';

export type PaywallProps = {} & PropsWithChildren;

const paywallVisibleState = selector<boolean>({
  key: 'paywallVisibleState',
  get: async ({get}) => {
    const subscription = get(coachSubscriptionState);
    const clientStatistics = get(clientStatisticsState);
    return clientStatistics?.allClients > subscription?.maxClients;
  },
});

const Paywall = (props: PaywallProps) => {
  const {feature_coaching_paywall} = useRemoteConfig();
  const paywallVisible = useRecoilValue(paywallVisibleState);
  const setClientStatistics = useSetRecoilState(clientStatisticsState);

  useEffect(() => {
    return subscribe(onNewNotification);
  }, []);

  const onNewNotification = async payload => {
    if (payload.data?.type === 'new-plan-subscription') {
      const newStatistics = await loadClientStatistics();
      setClientStatistics(() => newStatistics);
    }
  };

  return (
    <>
      <div style={{position: 'relative'}}>
        {useMemo(() => {
          return (
            <Backdrop
              sx={{
                backgroundColor: 'rgba(250, 250, 250, 0.7)',
                borderRadius: '15px',
                zIndex: theme => theme.zIndex.drawer + 1,
                position: 'absolute',
                alignItems: 'flex-start',
                paddingTop: 3,
              }}
              open={paywallVisible && feature_coaching_paywall?.asBoolean()}>
              <SuiBox
                display="flex"
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  p: 3,
                  backgroundColor: '#6c757d',
                  borderRadius: '15px',
                }}>
                <GiTeamUpgrade fontSize={100} color={'white'} />
                <SuiTypography variant="h6" fontWeight="medium" sx={{mt: 2}} color={'white'}>
                  Oops, too many clients.
                </SuiTypography>
                <SuiTypography variant="h6" fontWeight="medium" color={'white'}>
                  Please upgrade your subscription.
                </SuiTypography>
                <SuiBox mt={3}>
                  <SuiButton component={Link} to={'/me/subscriptions'} color="primary" size="small">
                    Go to Subscriptions
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            </Backdrop>
          );
        }, [paywallVisible, feature_coaching_paywall])}

        {props.children}
      </div>
    </>
  );
};

export default Paywall;
