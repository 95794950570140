import {MacrosGoal} from 'pages/coaching/tasks/type-prepare-interval/IntervalGoalTypes';
import Grid from '@mui/material/Grid';
import MacrosCard from 'components/IntervalExecutionCard/goals-results/macros/MacrosCard';
import {UserDetails} from 'pages/coaching/tasks/TaskTypes';
import {Scale} from '@mui/icons-material';
import SuiTypography from 'components/SuiTypography/index';
import {ImFire} from 'react-icons/im';
import Hint from 'components/Hint/Hint';

type MacrosGoalViewProps = {
  goal: MacrosGoal;
  client: UserDetails;
};

const MacrosGoalView = (props: MacrosGoalViewProps) => {
  return (
    <>
      <SuiTypography variant="button" fontWeight="regular" color={'text'}>
        You defined the following macros and calories goal for {props.client.name}.
        <Hint
          hint={
            'Green values are within the goal range, yellow values are outside the goal range, and red values are missing.'
          }
        />
      </SuiTypography>
      <Grid container item xs={12} rowSpacing={3} sx={{pt: 1, pb: 1}}>
        <Grid item xs={12} md={12} lg={12 / 5} pr={{xs: 0, md: 0, lg: 3}}>
          <MacrosCard
            icon={<Scale />}
            color="success"
            type="Protein"
            value={
              <>
                <span style={{color: '#439946'}}>{props.goal.min.protein + 'g'}</span> -{' '}
                <span style={{color: '#439946'}}>{props.goal.max.protein + 'g'}</span>
              </>
            }
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12 / 5} pr={{xs: 0, md: 0, lg: 3}}>
          <MacrosCard
            icon={<Scale />}
            color="warning"
            type="Carbs"
            value={
              <>
                <span style={{color: '#439946'}}>{props.goal.min.carbs + 'g'}</span> -{' '}
                <span style={{color: '#439946'}}>{props.goal.max.carbs + 'g'}</span>
              </>
            }
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12 / 5} pr={{xs: 0, md: 0, lg: 3}}>
          <MacrosCard
            icon={<Scale />}
            color="error"
            type="Fat"
            value={
              <>
                <span style={{color: '#439946'}}>{props.goal.min.fat + 'g'}</span> -{' '}
                <span style={{color: '#439946'}}>{props.goal.max.fat + 'g'}</span>
              </>
            }
          />
        </Grid>
        <Grid item xs={12} md={12} xl={12 / 5} pr={{xs: 0, md: 0, lg: 3}}>
          <MacrosCard
            icon={<Scale />}
            color="primary"
            type="Fiber"
            value={
              <>
                <span style={{color: '#439946'}}>{props.goal.min.fiber + 'g'}</span> -{' '}
                <span style={{color: '#439946'}}>{props.goal.max.fiber + 'g'}</span>
              </>
            }
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12 / 5} pr={0}>
          <MacrosCard
            icon={<ImFire />}
            color="info"
            type="Calories"
            value={
              <>
                <span style={{color: '#439946'}}>{props.goal.min.calories}</span> -{' '}
                <span style={{color: '#439946'}}>{props.goal.max.calories}</span>
              </>
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default MacrosGoalView;
