import {SingleMacroGoalResult} from 'components/IntervalExecutionCard/IntervalTypes';
import {UserDetails} from 'pages/coaching/tasks/TaskTypes';
import SuiTypography from 'components/SuiTypography/index';
import Grid from '@mui/material/Grid';
import Table from 'components/SuiTable';
import moment from 'moment/moment';
import {SingleMacro} from 'pages/coaching/tasks/type-prepare-interval/IntervalGoalTypes';

type SingleMacroGoalResultViewProps = {
  result: SingleMacroGoalResult;
  client: UserDetails;
};

const SingleMacroGoalResultView = (props: SingleMacroGoalResultViewProps) => {
  const columns = () => {
    return [
      {name: 'day', align: 'left'},
      {name: 'value', align: 'left'},
    ];
  };

  const createRows = () => {
    const macros = new Map<string, SingleMacro>(Object.entries(props.result.macros));
    const min = props.result.goal.min;
    const max = props.result.goal.max;
    const rows = [];
    macros.forEach((value, key) => {
      rows.push({
        key: new Date(key),
        day: moment(key).format('dddd, MM-DD-YYYY'),
        value: createValuesComponent(value.value, props.result.goal.min, props.result.goal.max, 'g'),
      });
    });
    rows.sort((a, b) => (a.key > b.key ? 1 : -1));
    rows.push(createAverageRow(macros, min, max));
    return rows;
  };

  const createAverageRow = (macros: Map<string, SingleMacro>, min: number, max: number) => {
    const values = Array.from(macros.values());
    return {
      day: <b>Daily Average</b>,
      value: createValuesComponent(average(values.map(m => m.value)), min, max, 'g', 'bold'),
    };
  };

  const createValuesComponent = (value, minValue, maxValue, suffix, fontWeight?) => {
    let color = '#439946';
    if (value === 0) {
      color = 'error';
    } else if (value < minValue || value > maxValue) {
      color = '#d9b305';
    }
    const formattedValue = Math.round(value * 100) / 100;
    return (
      <SuiTypography
        variant="button"
        fontWeight={fontWeight ? fontWeight : 'regular'}
        color={color}
        sx={{display: 'inline-block', width: 'max-content'}}>
        {formattedValue + suffix}
      </SuiTypography>
    );
  };

  const average = (values: number[]) => {
    const value = Math.floor(values.reduce((a, b) => a + b, 0) / values.length);
    return Math.round(value * 100) / 100;
  };

  return (
    <>
      <Grid item xs={12} sx={{pt: 1}}>
        <Table columns={columns()} rows={createRows()} tableStyles={{boxShadow: 'none'}} />
      </Grid>
    </>
  );
};

export default SingleMacroGoalResultView;
